<template>
  <div class="item">
    <div class="item-left">
      <UserAvatar
        :userType="userType"
        :user="{
          firstName: participant.firstName,
          lastName: participant.lastName,
        }"
      />
      <div>
        <p class="user-type">
          {{ t(`userType.${userType.toLowerCase()}`) }}
        </p>
        <p class="user-text">
          {{ participant.firstName }}
          {{ participant.lastName }}
        </p>
      </div>
    </div>
    <div>
      <p class="user-text">
        {{ participant.phoneNumber }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { UserAvatar, ProposalUserDto } from '@bd/components'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CounterProposalListItem',
  components: { UserAvatar },
  props: {
    participant: {
      type: Object as PropType<ProposalUserDto>,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
})
</script>

<style lang="scss" scoped>
.item {
  @include flex(space-between);
  padding: 10px;
}

.item-left {
  @include flex();
}

.user-type {
  font-weight: 500;
  color: $comet;
  font-size: 0.75rem;
  padding-bottom: 5px;
}

.user-text {
  font-size: 1rem;
  color: $port-gore;
}

:deep(.user-avatar) {
  font-size: 100%;
  width: 40px;
  height: 40px;
}
</style>
