import { UserDto } from '@bd/api'
import { MutationTree } from 'vuex'
import { UserProfileState } from '@bd/store-modules/types/userProfile'
import { getDefaultUserObject } from './utils'

export const mutations: MutationTree<UserProfileState> = {
  SET_USER(state, payload: UserDto) {
    state.user = payload
  },
  CLEAR_USER(state) {
    state.user = getDefaultUserObject()
  },
}
