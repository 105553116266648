import { TOAST_CONFIG } from '../../config'
import { ToastAddParams } from '@bd/components'
import { i18n } from '@bd/agent/plugins/vue-i18n-next-plugin'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

type ToastMessages = 'eventExpired' | 'markSeenError'

export const NotificationToastMessage: {
  [key in ToastMessages]: ToastAddParams
} = {
  eventExpired: {
    severity: 'info',
    summary: t('notifications.expired.title'),
    life: 4000,
  },
  markSeenError: {
    severity: 'error',
    summary: t('notifications.mark_seen.error.title'),
    life: messageDuration.error,
  },
}

export type NotificationsViewType = 'page' | 'dropdown'
