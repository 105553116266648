<template>
  <div class="calendar-event-offers-list modal-list-container">
    <CalendarEventAttendeesListItem
      v-for="attendee of items"
      :key="attendee.id"
      :item="attendee"
      :userType="userType"
      @click="$emit('selectAttendee', attendee)"
    >
      <template #item-append="{ item }">
        <template v-if="!item.selected">
          <Svg
            v-if="isDesktopSize"
            :src="require('@bd/assets/icons/plus.svg')"
            class="plus-icon c-primary"
          />
          <i
            v-if="!isDesktopSize"
            class="item-append-icon pi pi-chevron-right"
          ></i>
        </template>
      </template>
    </CalendarEventAttendeesListItem>
  </div>
</template>

<script lang="ts">
import { Svg, useBreakpoints, CalendarEventAttendeeItem } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import CalendarEventAttendeesListItem from './CalendarEventAttendeesListItem.vue'
import { EventAttendeeAvailableTypes } from '../types'

export default defineComponent({
  components: {
    CalendarEventAttendeesListItem,
    Svg,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    items: {
      type: Array as PropType<CalendarEventAttendeeItem[]>,
      required: true,
    },
  },
  emits: ['selectAttendee'],
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../event-save';

.plus-icon {
  @include uniform-size(35px);
}
:deep(.item-append) {
  align-self: center;
  cursor: pointer;
  color: $primary-color;
  margin-left: auto;
}
</style>
