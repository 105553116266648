import { Module } from 'vuex'
import { AppState, ClientOfferState } from '@bd/client/types'

import { state } from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const offers: Module<ClientOfferState, AppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default offers
