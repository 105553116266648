<template>
  <ResponsiveContent>
    <template #mobile>
      <div class="mobile-offer-chooser mobile-section">
        <Button
          v-if="!isOfferChosen"
          class="btn-choose-offer p-button-secondary"
          :label="t('calendar.events.save_event.choose')"
          @click="onOfferChooseClick"
        />
        <div class="offer-details" v-else>
          <CalendarEventOfferAddress v-if="address" :address="address">
            <Button
              :label="t('change')"
              class="btn-change-offer btn-mobile-sm p-button-text"
              @click="onOfferChooseClick"
            />
          </CalendarEventOfferAddress>
          <div class="offer-seller" v-if="seller">
            <span class="body-12 mb-1">{{ t('userType.seller') }}</span>
            <span>{{ seller.firstName }} {{ seller.lastName }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div
        class="event-save-desktop-section"
        :class="{
          'no-offer': !isOfferChosen,
          highlighted: offerSelectionOverlayVisible && !isOfferChosen,
        }"
      >
        <h4 class="header h-4">
          {{ t('calendar.events.save_event.offer') }}
        </h4>
        <div
          class="event-save-card"
          @click="onOfferPickerTriggerClick"
          ref="offerPickerTrigger"
        >
          <div class="content">
            <div class="offer-picker-trigger" v-if="!isOfferChosen">
              <Svg
                :src="require('@bd/assets/icons/plus.svg')"
                class="mr-3 c-primary"
              />
              <h4 class="h-4">
                {{ t('calendar.events.save_event.offer_address') }}
              </h4>
            </div>

            <div class="offer-details" v-else>
              <div class="control-buttons">
                <div
                  class="clear-button-container"
                  @click.stop="$emit('clearOffer')"
                >
                  <i class="pi pi-times clear-button" />
                </div>
                <div class="edit-icon-wrapper" ref="editIcon">
                  <div class="edit-icon">
                    <Svg
                      :src="require('@bd/assets/icons/edit.svg')"
                      style="width: 12px; height: 12px"
                    />
                  </div>
                </div>
              </div>
              <CalendarEventOfferAddress v-if="address" :address="address" />
            </div>
          </div>
        </div>
        <CalendarEventOfferSelectionOverlay
          v-model:visible="offerSelectionOverlayVisible"
          @searchOffer="$emit('searchOffer', $event)"
          @outsideClick="onOfferSelectionOutsideClick"
        />
      </div>
    </template>
  </ResponsiveContent>
  <CalendarEventOfferSelectionModal
    v-model:visible="offerSelectionModalVisible"
    @searchOffer="$emit('searchOffer', $event)"
  />
</template>

<script lang="ts">
import { getOfferAddress, ResponsiveContent, Svg } from '@bd/components'
import { TemplateRef } from '@bd/helpers'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import { computed, defineComponent, PropType, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventOfferAddress from './CalendarEventOfferAddress.vue'
import CalendarEventOfferSelectionModal from './CalendarEventOfferSelectionModal.vue'
import CalendarEventOfferSelectionOverlay from './CalendarEventOfferSelectionOverlay.vue'

export default defineComponent({
  components: {
    Svg,
    ResponsiveContent,
    CalendarEventOfferSelectionModal,
    CalendarEventOfferSelectionOverlay,
    CalendarEventOfferAddress,
  },
  props: {
    selectedOffer: {
      type: Object as PropType<MeetingOfferSelectPayload>,
      required: false,
    },
  },
  emits: ['update:selectedOffer', 'searchOffer', 'clearOffer'],
  setup(props) {
    const { t } = useI18n()
    const offerSelectionModalVisible = ref(false)
    const isOfferChosen = computed(() => !!props.selectedOffer?.offer)
    const offer = computed(() => props.selectedOffer?.offer)
    const address = computed(() => {
      return offer.value?.address
        ? getOfferAddress(offer.value.address)
        : undefined
    })
    const seller = computed(() => props.selectedOffer?.seller)

    const offerSelectionOverlayVisible = ref(false)
    const editIcon = shallowRef<TemplateRef>()
    const offerPickerTrigger = shallowRef<TemplateRef>()

    const isOfferPickerTriggerClicked = ($event: PointerEvent) => {
      return (
        $event.target === offerPickerTrigger.value ||
        $event.composedPath().includes(offerPickerTrigger.value!)
      )
    }

    const onOfferPickerTriggerClick = ($event: PointerEvent) => {
      /* For the 'offer chosen' state -> prevent event handler from executing when the event target isn't the edit icon */
      if (
        !offerSelectionOverlayVisible.value &&
        isOfferChosen.value &&
        $event.target !== editIcon.value
      ) {
        return
      }

      if (isOfferPickerTriggerClicked($event)) {
        offerSelectionOverlayVisible.value = !offerSelectionOverlayVisible.value
      }
    }

    const onOfferChooseClick = () => {
      offerSelectionModalVisible.value = true
    }

    const onOfferSelectionOutsideClick = ($event: PointerEvent) => {
      if (!isOfferPickerTriggerClicked($event)) {
        offerSelectionOverlayVisible.value = false
      }
    }

    return {
      t,
      editIcon,
      isOfferChosen,
      address,
      seller,
      offerSelectionModalVisible,
      offerSelectionOverlayVisible,
      offerPickerTrigger,
      onOfferPickerTriggerClick,
      onOfferChooseClick,
      onOfferSelectionOutsideClick,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../calendar';
@import '../event-save';

.mobile-offer-chooser {
  background-color: $default-bg-color;
}
.event-save-desktop-section {
  @include offers-attendees-card;
  &.no-offer .event-save-card {
    cursor: pointer;
    @include flex;
    .content {
      @include flex;
    }
  }
  &.highlighted .event-save-card {
    background-color: $port-gore;
    * {
      color: $white;
    }
  }
}

.btn-choose-offer {
  width: 100%;
}
.btn-change-offer {
  margin-left: auto;
}

.calendar-event-offer-address {
  @include breakpoint-down(md) {
    padding-bottom: 1rem;
  }
}

.mobile .offer-details {
  @include flex($direction: column);
  flex-wrap: nowrap;
  width: 100%;
  .offer-seller {
    @include flex($direction: column, $align: flex-start);
    padding-top: 1rem;
    border-top: $divider-border;
    width: 100%;
  }
}
.desktop .offer-details {
  @include flex(
    $direction: column,
    $justify: space-between,
    $align: flex-start
  );
  height: 100%;
}
.edit-icon-wrapper {
  padding: 0.25rem;
  display: inline-flex;
  cursor: pointer;
  .edit-icon {
    @include edit-icon(30px);
    pointer-events: none;
  }
}
.offer-picker-trigger {
  @include flex;
  i {
    @include uniform-size(35px);
  }
}

.control-buttons {
  @include flex($align: flex-start);
}

.clear-button-container {
  padding: 0.25rem;
  .clear-button {
    @include edit-icon(30px);
    cursor: pointer;
  }
}
</style>
