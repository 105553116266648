
import { defineComponent, PropType, shallowRef, watch } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { useField } from 'vee-validate'
import { ClientDto } from '@bd/api'
import { ClientAdminDto } from '@bd/api'

export default defineComponent({
  name: 'AgentAutocomplete',
  components: { AutoComplete },
  emits: ['search'],
  props: {
    items: {
      type: Array as PropType<ClientDto[]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { errorMessage, value } = useField<ClientAdminDto | undefined>(
      'agent',
    )

    const selectionOrQuery = shallowRef<string | ClientAdminDto>('')

    const onClear = () => {
      value.value = undefined
      emit('search', '')
    }

    const renderItem = (item: ClientDto) => {
      const optionalEmail = item.email ? ` (${item.email})` : ''
      return `${item.firstName} ${item.lastName}${optionalEmail}`
    }

    const onItemSelected = (event: {
      originalEvent: Event
      value: ClientDto
    }) => {
      const agent: ClientDto = event.value
      value.value = {
        userId: agent.id,
        email: agent.email,
        phoneNumber: agent.phoneNumber,
        firstName: agent.firstName,
        lastName: agent.lastName,
        userRole: agent.userRole,
        displayableId: agent.displayableId,
      }
    }

    watch(value, (agent) => {
      if (agent) {
        selectionOrQuery.value = agent
      }
    })

    return {
      value,
      selectionOrQuery,
      onClear,
      errorMessage,
      renderItem,
      onItemSelected,
    }
  },
})
