
import { resetSchema } from '@bd/components/yup'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'
import { defineComponent, computed } from 'vue'
import { Form, SubmissionHandler } from 'vee-validate'
import AuthFormReset from '../AuthForm/AuthFormReset.vue'
import AuthLayout from '../AuthLayout.vue'
import { authFormService, useBreakpoints } from '@bd/components'
import { PasswordResetRequestDto } from '@bd/api'
import { useAppStore } from '@bd/store-modules'
import { useRootData } from '@bd/components'
import { ToType } from '@bd/helpers'

export default defineComponent({
  name: 'AuthPasswordReset',
  components: { AuthLayout, AuthFormReset, Form },
  props: {
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit', 'reset'],
  setup(_, { emit }) {
    const i18n = useI18n()
    const store = useAppStore()
    const { helpline } = common
    const { isLoading } = useRootData()
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)

    const onSubmit: SubmissionHandler<ToType<PasswordResetRequestDto>> = async (
      values,
      actions,
    ) => {
      const payload: PasswordResetRequestDto = values
      try {
        await store.dispatch('auth/resetPassword', payload)
        emit('reset')
      } catch (err) {
        const errorsData = authFormService.handleError(
          store.state.auth?.error,
          isDesktopSize.value,
        )
        if (errorsData) {
          actions.setErrors(errorsData)
        }
      }
    }
    return {
      ...i18n,
      onSubmit,
      helpline,
      resetSchema,
      isLoading,
    }
  },
})
