import { ActionTree } from 'vuex'
import { AppState } from '@bd/client/types'

export const actions: ActionTree<AppState, AppState> = {
  dialogVisibility({ commit }, payload: Partial<AppState['dialog']>) {
    commit('SET_DIALOG', payload)
  },
  setBottomNavigationVisibility({ commit }, visible: boolean) {
    commit('SET_BOTTOM_NAVIGATION_VISIBLE', visible, { root: true })
  },
}
