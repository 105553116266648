import { isUserLogged } from '@bd/store-modules/modules/auth/getters-helpers'
import { useAppStore } from '@bd/store-modules'
import { computed } from 'vue'

const useUserData = () => {
  const store = useAppStore()
  const user = computed(() => store.state.userProfile?.user)
  const isLogged = computed(() => isUserLogged(store.state))

  return {
    user,
    isLogged,
  }
}

export default useUserData
