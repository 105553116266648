<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import { Accordion } from './FilterAccordion.types'

export default defineComponent({
  setup() {
    provide<Accordion>('Accordion', {
      active: null,
    })
  },
})
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item:last-child {
    border-bottom: none;
  }
}
</style>
