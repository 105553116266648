
import { defineComponent, inject, onUnmounted, PropType, ref } from 'vue'
import { Accordion } from './FilterAccordion.types'

export default defineComponent({
  emits: ['accordionStatus'],
  props: {
    accordionItemStatus: {
      type: Array as PropType<number[]>,
      default: [],
    },
    filtersBackground: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  inject: ['Accordion'],
  setup(props, { emit }) {
    const Accordion = inject<Accordion>('Accordion')!

    const toggleAccordionItem = () => {
      if (props.index === Accordion.active) {
        Accordion.active = null
      } else {
        Accordion.active = props.index
      }
      emit('accordionStatus', props.index)
    }

    return {
      toggleAccordionItem,
      Accordion,
      props,
    }
  },
})
