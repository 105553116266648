<template>
  <div class="calendar-event-attendees-selection-list">
    <div class="list-singletab-wrapper">
      <CalendarEventAttendeesSearchableList
        :searchPhrase="attendeesSearchPhrase"
        :attendees="attendees"
        :userType="userType"
        :selectedAttendee="selectedAttendee"
        @selectAttendee="onAttendeeSelected"
        @searchAttendee="onAttendeeSearch"
        @update:searchPhrase="onSearchPhraseUpdate"
      >
        <template #empty>
          <EmptyState
            :title="t('calendar.events.save_event.attendees.empty_state.title')"
            :description="
              t('calendar.events.save_event.attendees.empty_state.description')
            "
          />
        </template>
      </CalendarEventAttendeesSearchableList>
    </div>
  </div>
</template>

<script lang="ts">
import { ResultPageDto } from '@bd/api'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import { MEETING_ATTENDEES_PAGE_SIZE } from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { getUserRoleByUserType } from '@bd/components/User'
import { LoadableOptional } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { getAttendeesSearchPhrase } from '@bd/store-modules/modules/calendar-event/getters-helpers'
import { MeetingAttendeeSearchPayload } from '@bd/store-modules/types/calendarEvent'
import { computed, ComputedRef, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '../../types'
import CalendarEventAttendeesSearchableList from './CalendarEventAttendeesSearchableList.vue'
import { EventAttendeeAvailableTypes } from './types'

export default defineComponent({
  components: {
    EmptyState,
    CalendarEventAttendeesSearchableList,
  },
  props: {
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
  },
  emits: ['selectAttendee', 'searchAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()

    const attendees: ComputedRef<
      LoadableOptional<ResultPageDto<MeetingAttendeeDto> | undefined>
    > = computed(() => ({
      payload: store.state.calendarEvent?.eventSaveMetaData.attendees,
    }))

    const attendeesSearchPhrase = computed<string>(() =>
      getAttendeesSearchPhrase(store),
    )

    const getUserRole = () => {
      return getUserRoleByUserType(props.userType)
    }

    const fetchMeetingAttendees = async () => {
      const payload: MeetingAttendeeSearchPayload = {
        pageSize: MEETING_ATTENDEES_PAGE_SIZE,
        userRole: getUserRole(),
      }
      await store.dispatch('calendarEvent/getMeetingAttendees', payload)
    }

    const onAttendeeSelected = (
      selectedAttendee: CalendarEventAttendeeItem,
    ) => {
      emit('selectAttendee', selectedAttendee)
    }

    const onAttendeeSearch = (searchPhrase: string) => {
      emit('searchAttendee', searchPhrase)
      fetchMeetingAttendees()
    }

    const onSearchPhraseUpdate = (searchPhrase: string) => {
      store.dispatch('calendarEvent/setAttendeesSearchPhrase', searchPhrase)
    }

    fetchMeetingAttendees()

    return {
      t,
      attendees,
      attendeesSearchPhrase,
      onAttendeeSelected,
      onAttendeeSearch,
      onSearchPhraseUpdate,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';

.calendar-event-attendees-selection-list {
  height: 100%;
}
.list-singletab-wrapper {
  @include results-list-container;
  padding: 1rem;
}
</style>
