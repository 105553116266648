
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import { CalendarEventAttendeeItem, Overlay } from '@bd/components'
import { defineComponent, PropType } from 'vue'
import CalendarEventAttendeeSelectionList from './CalendarEventAttendeeSelectionList.vue'
import { EventAttendeeAvailableTypes } from './types'

export default defineComponent({
  components: {
    Overlay,
    CalendarEventAttendeeSelectionList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
  },
  emits: ['update:visible', 'selectAttendee', 'searchAttendee', 'outsideClick'],
  setup(_props, { emit }) {
    const onAttendeeSelected = (
      selectedAttendee: CalendarEventAttendeeItem,
    ) => {
      emit('selectAttendee', selectedAttendee)
      emit('update:visible', false)
    }

    return {
      onAttendeeSelected,
    }
  },
})
