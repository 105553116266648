
import { CalendarEventAttendeeItem } from '@bd/components/Calendar/types'
import { defineComponent, PropType } from 'vue'
import { EventAttendeeAvailableTypes } from '../types'
import { UserAvatar, Svg, useNameAndLastName } from '@bd/components'

export default defineComponent({
  components: {
    UserAvatar,
    Svg,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    item: {
      type: Object as PropType<CalendarEventAttendeeItem>,
      required: true,
    },
    showId: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const nameAndLastName = useNameAndLastName(
      props.item.firstName,
      props.item.lastName,
    )

    return { nameAndLastName }
  },
})
