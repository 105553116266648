
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import { useRelativeTimeDistance } from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'
import NotificationInitial from './NotificationInitial.vue'

export default defineComponent({
  components: {
    NotificationInitial,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    item: {
      type: Object as PropType<SystemNotificationDto>,
      required: true,
    },
  },
  setup(props) {
    const createdAt = computed(() => new Date(props.item.createdAt))
    const relativeTime = useRelativeTimeDistance(createdAt)

    return {
      relativeTime,
    }
  },
})
