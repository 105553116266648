
import { CustomerMeetingInvitationDto } from '@bd/api/client-api/types'
import { TimeSlotID } from '@bd/api/common/types/time-slot'
import {
  TimeSlotPicker,
  useBreakpoints,
  useLoadableResourceDataState,
  WeekdayPicker,
  WeekdaySelectType,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { ID, LoadableOptional, LocalIsoDate } from '@bd/helpers'
import { TimeSlot } from '@itcraft-bestdeal/api'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
    TimeSlotPicker,
    WeekdayPicker,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    visible: Boolean,
    availableTimeSlots: {
      type: Object as PropType<LoadableOptional<TimeSlot.TimeSlotDto[]>>,
      required: true,
    },
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    submitPending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'update:selectedDay', 'submitAppointment'],
  setup(props, { emit }) {
    const { locale, t } = useI18n()
    const selectedTimeSlots = ref([] as TimeSlotID[])
    const { mobileSize } = useBreakpoints()

    const {
      isLoading,
      isEmpty,
      resource: timeSlots,
    } = useLoadableResourceDataState(
      computed(() => props.availableTimeSlots.payload),
      (res) => !!res?.content.length,
    )

    const deselectAllTimeSlots = () => {
      selectedTimeSlots.value = []
    }

    const selectTimeSlot = (id: ID<'TimeSlot'>) => {
      selectedTimeSlots.value = [id]
    }

    const unselectTimeSlot = () => {
      deselectAllTimeSlots()
    }

    const selectDay = (day: LocalIsoDate, selectType: WeekdaySelectType) => {
      emit('update:selectedDay', day, selectType)
      deselectAllTimeSlots()
    }

    const header = computed(() => {
      const day = props.selectedDay
      const formatter = new Intl.DateTimeFormat(locale.value, {
        month: 'long',
        year: 'numeric',
      })

      const str = formatter.format(new Date(day))
      return str.charAt(0).toUpperCase() + str.slice(1)
    })

    const submitClick = () => {
      const data: CustomerMeetingInvitationDto = {
        timeSlotId: selectedTimeSlots.value[0],
        date: props.selectedDay,
      }

      emit('submitAppointment', data)
    }

    const canSubmit = computed(
      () =>
        !props.submitPending &&
        selectedTimeSlots.value &&
        selectedTimeSlots.value.length &&
        !isLoading.value,
    )

    return {
      t,
      header,
      timeSlots,
      selectDay,
      selectedTimeSlots,
      selectTimeSlot,
      unselectTimeSlot,
      mobileSize,
      submitClick,
      isLoading,
      isEmpty,
      canSubmit,
    }
  },
})
