import { CalendarEventMeetingTypeItem } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import { computed, Ref } from 'vue'

/** Map calendar event meeting types to list entries (with selected' flag set) */
export const useMeetingTypeEntries = (
  meetingTypes: Ref<CalendarEventMeetingType[]>,
  selectedMeetingType?: Ref<CalendarEventMeetingType | undefined>,
) => {
  const store = useAppStore()
  return computed<CalendarEventMeetingTypeItem[]>(() => {
    const selected = selectedMeetingType?.value
    const isEditMode = !!store.state.calendarEvent?.eventSaveData.eventId
    return meetingTypes.value.map((type, index) => {
      return {
        ...type,
        selected:
          !!selected &&
          ((selected.isCustom && type.isCustom) ||
            selected.predefinedId === type.predefinedId),
        tileId: index,
        disabled:
          isEditMode &&
          ((selected?.isCustom && !type.isCustom) ||
            (!selected?.isCustom && type.isCustom)),
      }
    })
  })
}
