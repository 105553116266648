<template>
  <div class="card">
    <div class="card-image-container" @click="$emit('card-click')">
      <div class="draft-chip" v-if="isDraft">{{ t('draft') }}</div>
      <img
        :src="image"
        :class="item?.imageUrl ? 'fit-cover' : 'fit-scale-down'"
      />
    </div>
    <div class="card-wrapper">
      <div class="caption mb-2">{{ item.displayableId }}</div>

      <div class="card-content">
        <div class="card-content-address h-5">
          {{ isReversedAddress ? reversedAddress : address }}
        </div>

        <div class="card-content-price h-2">
          {{ getFormattedPrice(item.price, currency, locale) }}
        </div>
      </div>

      <div
        class="build-year my-2"
        v-if="displayBuildYear && item.constructionYear"
      >
        {{ t('construction_year') }}: {{ item.constructionYear }}
      </div>

      <div class="details my-1" v-if="detailsToDisplay.hasDetails">
        <p class="body-12" v-if="detailsToDisplay.rooms != null">
          <Svg :src="require('@bd/assets/icons/rooms.svg')" />
          {{ t('rooms') }}: {{ detailsToDisplay.rooms }}
        </p>

        <p class="body-12" v-if="detailsToDisplay.area != null">
          <Svg :src="require('@bd/assets/icons/square.svg')" />
          {{ detailsToDisplay.area }} m<sup>2</sup>
        </p>

        <p class="body-12" v-if="detailsToDisplay.floor != null">
          <Svg :src="require('@bd/assets/icons/stairs.svg')" />
          {{ t('floor') }} {{ detailsToDisplay.floor }}/{{
            detailsToDisplay.levels
          }}
        </p>
      </div>
      <div class="card-footer">
        <Button
          v-if="footerButton"
          @click="$emit('card-button-click')"
          :label="t('make_an_appointment')"
          class="p-button-sm p-button-primary p-button-rounded"
        />
        <template v-if="avatar">
          <UserAvatar
            :user="avatar.user"
            :user-type="avatar.userType"
            :override-with-user-type="false"
          />

          <span class="avatar-text">{{ avatarName }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserAvatar, Svg, Avatar } from '@bd/components'
import { LOCALISATION_SETTINGS } from '../../config'
import { getFormattedPrice } from '@bd/helpers'
import { CommonOfferRowDto } from '@bd/api'

export default defineComponent({
  name: 'DashboardCard',
  components: {
    UserAvatar,
    Svg,
  },
  emits: ['card-button-click', 'card-click'],

  props: {
    item: {
      type: Object as PropType<CommonOfferRowDto>,
      required: true,
    },
    displayedIconDetails: {
      type: Array as PropType<string[]>,
      default: [],
      required: false,
    },
    displayBuildYear: {
      type: Boolean,
      default: false,
      required: false,
    },
    footerButton: {
      type: Boolean,
      required: false,
    },
    avatar: {
      type: Object as PropType<Avatar>,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
    isReversedAddress: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS

    const image = computed(() => {
      const imageUrl = props.item?.imageUrl
      return imageUrl ?? require('@bd/assets/images/no-image.svg')
    })

    const street = computed(() =>
      props.item?.street ? `${t('street_prefix')} ${props.item?.street}` : '',
    )
    const city = computed(() => props.item?.cityDto?.cityName ?? '')
    const district = computed(() => props.item?.districtDto?.districtName)

    const address = computed(() => {
      if (!street.value && !city.value) {
        return `(${t('no_information')})`
      }
      if (district.value) {
        return `${street.value}\n${city.value}, ${district.value}`
      }
      return `${street.value}\n${city.value}`
    })

    const reversedAddress = computed(() => {
      if (!street.value && !city.value) {
        return `(${t('no_information')})`
      }
      if (district.value) {
        return `${district.value}, ${street.value}\n ${city.value}`
      }
      return `${street.value}\n${city.value}`
    })

    const avatarName = computed(() => {
      return props.avatar?.user.firstName
        ? `${props.avatar.user.firstName} ${props.avatar.user.lastName}`
        : `(${t('no_information')})`
    })

    const detailsToDisplay = computed(() => {
      const details = props.displayedIconDetails
      const { houseArea, landArea } = props.item || {}

      const rooms = details.includes('rooms') ? props.item.rooms : null
      const area = details.includes('area') ? houseArea || landArea : null
      const floor = details.includes('floor') ? props.item.floor : null

      return {
        hasDetails: rooms != null || area != null || floor != null,
        rooms,
        area,
        floor,
        levels: props.item.levels,
      }
    })

    return {
      t,
      getFormattedPrice,
      currency,
      locale,
      image,
      address,
      reversedAddress,
      avatarName,
      detailsToDisplay,
    }
  },
})
</script>

<style lang="scss" scoped>
.fit {
  &-scale-down {
    object-fit: scale-down;
    background-color: rgba($silver-sand, 0.1);
  }
  &-cover {
    object-fit: cover;
  }
}

.card {
  border-radius: 6px;
  transition: all 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 65px 230px 0 $light-shadow;
  }
}

.card-image-container {
  position: relative;
  text-align: center;
  $image-aspect-ratio: 16/9;
  padding-top: 1 / $image-aspect-ratio * 100%;
  border-radius: 6px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 10px;
}
.caption {
  line-height: 1;
}

.card-content {
  @include flex(space-between, flex-start);
  &-address {
    white-space: pre-line;
    line-height: 17px;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    @supports (-webkit-line-clamp: 2) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &-price {
    white-space: nowrap;
    display: inline-block;
  }
}

.build-year {
  color: $comet;
  font-size: 0.8rem;
}

.details {
  width: 100%;
  p {
    display: inline-block;
    width: 33%;
    padding-bottom: 0;
  }
  i {
    vertical-align: bottom;
    width: 15px;
    height: 15px;
    margin-right: 9px;
    color: $primary-color;
  }
}

.card-footer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.draft-chip {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffff;
  color: $primary-color;
  border-radius: 16px;
  padding: 7px 20px;
  font-weight: 500;
  margin: 10px;
  z-index: 1;
}

.avatar-text {
  color: $port-gore;
  font-size: 0.8125rem;
  font-weight: 500;
}
</style>
