
import { defineComponent, inject } from 'vue'
import { Accordion } from './DashboardAccordion.types'

export default defineComponent({
  emits: ['accordionStatus'],
  props: {
    accordionItemStatus: {
      type: Number,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const Accordion = inject<Accordion>('Accordion')!

    const toggleAccordionItem = () => {
      if (props.index === Accordion.active) {
        Accordion.active = null
        emit('accordionStatus', null)
      } else {
        Accordion.active = props.index
        emit('accordionStatus', props.index)
      }
    }

    return {
      toggleAccordionItem,
      Accordion,
      props,
    }
  },
})
