import { ID, LocalIsoTime, parseLocalIsoTime } from '@bd/helpers'

export enum Weekday {
  SUN = 0,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

export interface TimeSlotDto {
  timeSlotId: ID<'TimeSlot'>
  startTime: LocalIsoTime
  endTime: LocalIsoTime
}

export type TimeSlotID = TimeSlotDto['timeSlotId']

export const minutes = (time: LocalIsoTime): number => {
  const { hours, minutes, seconds } = parseLocalIsoTime(time)
  return hours * 60 + minutes + Math.round(seconds / 60)
}

export interface TimeSlotState {
  selected: boolean
  timeSlotId: TimeSlotID
}
