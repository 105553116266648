<template>
  <main class="container-fluid">
    <div class="row align-items-center">
      <section class="auth-column col-12 col-md-5 col-lg-4 left-col">
        <div
          class="auth-content-container d-flex flex-column align-items-center"
        >
          <div class="navigation-slot w-100"><slot name="navigation" /></div>
          <slot name="logo" />
          <div class="main-slot w-100"><slot name="main" /></div>
          <div class="footer-slot w-100"><slot name="footer" /></div>
        </div>
      </section>
      <section
        class="auth-column bg-image d-none d-md-flex col-md-7 col-lg-8"
        :style="`background-image: url(${bgImage})`"
      ></section>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AuthLayout',
  props: {
    bgImage: String,
  },
})
</script>

<style lang="scss" scoped>
.auth-column {
  &.bg-image {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.left-col {
    @include breakpoint-up(md) {
      overflow-y: auto;
    }
  }

  .auth-content-container {
    height: 100vh;
    @include breakpoint-down(md) {
      height: 90vh;
    }
    padding: 20px 0;
    width: 100%;
  }

  .main-slot {
    margin-top: auto;
    width: 100%;

    :deep(.submit-spacing) {
      margin-top: 30px;
    }
  }

  @include breakpoint-up(md) {
    .main-slot {
      max-width: 335px;
    }
  }

  .footer-slot {
    margin-top: auto;
  }

  @include breakpoint-up(xl) {
    .auth-content-container {
      padding: 20px;
    }
  }
}
</style>
