
import { TemplateRef } from '@bd/helpers'
import { useDebounceFn, VueInstance } from '@vueuse/core'
import InputText from 'primevue/inputtext'
import { computed, defineComponent, onMounted, ref } from 'vue'

const DEFAULT_DEBOUNCE_MS = 250

export default defineComponent({
  components: {
    InputText,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debounceMs: {
      type: Number,
      default: DEFAULT_DEBOUNCE_MS,
    },
    emitSearchOnClear: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'search', 'clear'],
  setup(props, { emit }) {
    const emitSearchEvent = useDebounceFn(
      () => emit('search', props.modelValue),
      props.debounceMs,
    )

    const onUpdate = (value: string) => {
      emit('update:modelValue', value)
      emitSearchEvent()
    }

    const clearInput = () => {
      emit('update:modelValue', '')
      emit('clear')
      if (props.emitSearchOnClear) {
        emit('search', '')
      }
    }

    const searchPhraseNotEmpty = computed(() => props.modelValue.trim().length)
    const inputText = ref<TemplateRef>(null)

    /**
     * Autofocus the input if needed
     */
    onMounted(() => {
      if (!props.autofocus) {
        return
      }
      inputText.value?.focus()
    })

    /**
     * Retrieve native input element from the PrimeVue's InputText
     */
    const inputRefFn = (el: VueInstance) => {
      if (el && el.$el) {
        inputText.value = el.$el
      }
    }

    return {
      onUpdate,
      clearInput,
      inputText,
      inputRefFn,
      searchPhraseNotEmpty,
    }
  },
})
