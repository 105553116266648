
import { computed, defineComponent } from 'vue'
import { CounterProposal, ProposalMessages } from '@bd/components'
import { useAppStore } from '@bd/client/store'
import { AddPurchaseOfferDto } from '@bd/api/common/types/purchase-offers'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: { CounterProposal },
  setup() {
    const store = useAppStore()
    const toast = useToast()
    const errorData = computed(() => store.state.purchaseOffers?.error)
    const proposalMessage = computed(
      () => store.state.purchaseOffers?.purchaseMessage,
    )

    const lastOffer = computed(() => store.state?.purchaseOffers?.purchaseOffer)

    const isCounterProposalModalVisible = computed({
      get: () => store.state.dialog.counterProposalModal,
      set: (value: boolean) => {
        store.dispatch('dialogVisibility', {
          counterProposalModal: value,
        })
      },
    })

    const makeProposal = async (payload: AddPurchaseOfferDto) => {
      if (!payload.offerId || !payload.price) {
        return
      }

      await store.dispatch('purchaseOffers/addPurchaseOffer', payload)

      if (!errorData.value) {
        store.dispatch('dialogVisibility', {
          counterProposalModal: false,
        })
      }
      toast.add(
        !errorData.value
          ? ProposalMessages.counterproposalSuccess
          : ProposalMessages.counterproposalError,
      )
    }

    const acceptOffer = async (offerId: number) => {
      await store.dispatch('purchaseOffers/acceptLastPurchase', offerId)
      store.dispatch('dialogVisibility', {
        counterProposalModal: false,
      })
    }

    const clearProposalData = () => {
      store.dispatch('dialogVisibility', {
        counterProposalModal: false,
      })
      store.commit('purchaseOffers/CLEAR_ERROR')
      store.commit('purchaseOffers/CLEAR_LAST_PURCHASE')
      store.commit('purchaseOffers/CLEAR_PURCHASE_MESSAGE')
    }

    return {
      makeProposal,
      isCounterProposalModalVisible,
      acceptOffer,
      lastOffer,
      errorData,
      clearProposalData,
      proposalMessage,
    }
  },
})
