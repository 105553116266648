import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { ErrorResponse } from '@bd/api'

const t = i18n.global.t

export interface ErrorDataObject {
  [key: string]: string
}

const errorFieldResponses: Record<string, string> = {
  REQUIRED: t('validation.required'),
  INVALID_VALUE: t('validation.invalid_value'),
  INVALID_EMAIL: t('validation.email'),
  INVALID_PHONE_FORMAT: t('validation.invalid_phone_format'),
  INVALID_UNIQUE_PHONE: t('validation.invalid_unique_phone'),
  INVALID_UNIQUE_EMAIL: t('validation.invalid_unique_email'),
  HTML_NOT_ALLOWED: t('validation.html_not_allowed'),
  INVALID_NUMBER_RANGE: t('validation.invalid_number_range'),
  INVALID_TEXT_LENGTH: t('validation.invalid_text_length'),
  INVALID_MIN_NUMBER_VALUE: t('validation.min_max_number'),
  INVALID_MAX_NUMBER_VALUE: t('validation.min_max_number'),
  INVALID_PATTERN: t('validation.invalid_pattern'),
  PASSWORD_TOO_WEAK: t('validation.password_too_weak'),
  INVALID_CURRENT_PASSWORD: t('validation.invalid_current_password'),
}

const errorMessages: Record<string, string> = {
  CITY_NOT_FOUND: t('offer_errors.city_not_found'),
  OFFER_NOT_FOUND: t('offer_errors.offer_not_found'),
  DISTRICT_NOT_FOUND: t('offer_errors.district_not_found'),
  SELLER_NOT_FOUND: t('offer_errors.seller_not_found'),
  SELLER_CANNOT_HAVE_MORE_OFFERS: t(
    'offer_errors.seller_cant_have_more_offers',
  ),
  EDIT_SELLER_IN_PUBLISHED_OFFER_NOT_ALLOWED: t(
    'offer_errors.edit_seller_in_published_offer_not_allowed',
  ),
  PHOTOS_IDS_MUST_BE_UNIQUE: t('offer_errors.photo_ids_not_unique'),
  PHOTO_NOT_EXIST: t('offer_errors.photo_not_exist'),
  EMPTY_LAND_AREA: t('offer_errors.empty_land_area'),
  EMPTY_HOUSE_AREA: t('offer_errors.empty_house_area'),
  NO_FILE_UPLOADED: t('offer_errors.no_file_uploaded'),
  UPLOADED_FILE_IS_NOT_AN_IMAGE: t('offer_errors.uploaded_file_is_not_image'),
  WRONG_BUILDING_TYPE: t('offer_errors.wrong_building_type'),
  EMPTY_PROPERTY_TYPE: t('offer_errors.empty_property_type'),
  FLOOR_GREATER_THAN_LEVELS: t('offer_errors.floor_greater_than_levels'),
}

const getFieldName = (backendName: string) => {
  if (backendName === 'sellerId') return 'offerSellerDto'
  if (backendName === 'cityId') return 'cityDto'
  return backendName
}

export const adminValidationService = {
  handleValidation: function (
    e: ErrorResponse | undefined,
  ): ErrorDataObject | undefined {
    if (e && e.fieldErrors?.length) {
      const result: ErrorDataObject = {}

      e.fieldErrors.forEach((error) => {
        const field = getFieldName(error.field)
        result[field] = errorFieldResponses[error.message]
      })

      return result
    }
  },
  handleMessage: function (e: ErrorResponse | undefined) {
    if (e && e.resultKey) {
      return errorMessages[e.resultKey]
    }
  },
}
