
import { UserCalendarEventDetailsDto } from '@bd/api'
import {
  PageContent,
  useLoadableResourceDataState,
  usePossibleEventActions,
  UserType,
} from '@bd/components'
import { LoadableResource } from '@bd/helpers'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import EventDetails, {
  emits as eventDetailsEmits,
} from './EventDetails/EventDetails.vue'

export default defineComponent({
  components: {
    PageContent,
    EventDetails,
  },
  props: {
    eventDetails: {
      type: Object as PropType<
        LoadableResource<UserCalendarEventDetailsDto | undefined>
      >,
      required: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  emits: [...eventDetailsEmits],
  setup(props) {
    const { t } = useI18n()
    const eventDetails = computed(() => props.eventDetails)
    const eventDetailsContent = computed(() => eventDetails.value?.content)
    const eventDto = computed(() => eventDetailsContent.value?.calendarEventDto)
    const possibleEventActions = usePossibleEventActions(eventDto)
    const isBuyer = computed(() => props.userType === UserType.Buyer)
    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      eventDetails,
    )

    return {
      t,
      isLoading,
      isPresent,
      isEmpty,
      possibleEventActions,
      isBuyer,
      eventDetailsContent,
    }
  },
})
