import { clientApi, commonApi, PageableParams } from '@bd/api'
import { AvailableTimeSlotsDto } from '@bd/api/client-api/types'
import { AppState, ClientOfferState } from '@bd/client/types'
import { logErr } from '@bd/helpers'
import { Filters } from '@bd/store-modules/types'
import { ActionTree } from 'vuex'
import { CustomerMeetingInvitationPayload } from './types'
import {
  allCitiesLoaded,
  getNextPageIndex,
  isLoadingChunk,
} from './gettersHelper'

export const actions: ActionTree<ClientOfferState, AppState> = {
  async cityDistricts({ commit }, payload: number) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await commonApi.districts(payload)
      commit('SET_DISTRICTS', data)
    } catch (error) {
      logErr(error)
    }
    commit('SET_LOADING', false, { root: true })
  },

  async citiesAndProvincesList({ state, commit }, pageSize: number) {
    if (isLoadingChunk(state) || allCitiesLoaded(state)) {
      return
    }
    const params: PageableParams = {
      pageSize: pageSize,
      pageIndex: getNextPageIndex(state),
    }

    commit('SET_CITIES_AND_PROVINCES_LOADING', true)
    try {
      const { data } = await commonApi.citiesAndProvincesUsed(params)
      commit('ADD_CITIES_AND_PROVINCES_CHUNK', data)
    } finally {
      commit('SET_CITIES_AND_PROVINCES_LOADING', false)
    }
  },

  async setFilterAndRefresh({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
    commit('APPLY_FILTERS')
  },

  async setFilters({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
  },

  async fetchOffer({ commit }, payload: number) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await clientApi.offer(payload)
      commit('SET_OFFER', data)
    } catch (error) {
      logErr(error)
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async fetchAvailableTimeSlots({ commit }, payload: AvailableTimeSlotsDto) {
    commit('SET_AVAILABLE_TIME_SLOTS_LOADING', true)
    try {
      const { data } = await clientApi.offerAvailableTimeSlots(payload)
      commit('SET_AVAILABLE_TIME_SLOTS', data)
    } catch (error) {
      logErr(error)
    } finally {
      commit('SET_AVAILABLE_TIME_SLOTS_LOADING', false)
    }
  },

  async submitAppointmentInvitation(
    _,
    payload: CustomerMeetingInvitationPayload,
  ) {
    try {
      await clientApi.offerMeetingInvitation(payload.offerId, payload.body)
    } catch (error) {
      logErr(error)
      throw error
    }
  },
}
