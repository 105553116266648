<template>
  <nav
    class="navigation d-none d-md-flex align-items-center"
    :class="{ sticky: isSticky }"
  >
    <slot name="view-left">
      <img src="@bd/assets/images/immoby-horizontal-logo.svg" />
    </slot>
    <slot name="view-right">
      <ul class="navigation-list d-flex align-items-center ml-auto">
        <TopNavigationItem
          v-for="(item, index) of items"
          :key="index"
          :item="item"
        />
      </ul>
    </slot>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { NavigationItem } from '../types'
import TopNavigationItem from './TopNavigationItem.vue'

export default defineComponent({
  components: {
    TopNavigationItem,
  },
  props: {
    items: {
      type: Object as PropType<NavigationItem[]>,
      required: true,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.navigation {
  padding: 0 15px;
  height: $top-navigation-height;
  background-color: $white;
  box-shadow: 0 4px 10px 2px rgb(30 40 50 / 2%);
  border-bottom: 1px solid $athens-gray;

  &.sticky {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
}
</style>
