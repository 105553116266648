import { CommonAppState } from '@bd/store-modules/types'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import { AxiosResponse, AxiosError } from 'axios'
import { http } from '@bd/api/common'

export const useUnauthorizedInterceptor = (
  store: Store<CommonAppState>,
  router: Router,
) => {
  const redirect = () => router.push({ path: '/login' })

  return http.interceptors.response.use(
    (res: AxiosResponse) => {
      return res
    },
    async (err: AxiosError) => {
      if (err.response?.status !== 401 || err.config.url?.endsWith('/logout')) {
        return err
      }
      try {
        await store.dispatch('auth/authLogout')
      } finally {
        redirect()
      }
      return Promise.reject(err)
    },
  )
}
