
import { TimeSlot } from '@bd/api'
import { TimeSlotPicker, useLoadableResourceDataState } from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { LoadableResource } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    TimeSlotPicker,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    selectedTimeSlotIds: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: true,
    },
    availableTimeSlots: {
      type: Object as PropType<
        LoadableResource<TimeSlot.TimeSlotDto[] | undefined>
      >,
      required: true,
    },
    disabledTimeSlotIds: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: true,
    },
    isCustomMeeting: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:selected'],
  setup(props) {
    const { t } = useI18n()
    const availableTimeSlots = computed(() => props.availableTimeSlots)

    const { isPresent, isLoading, isEmpty } = useLoadableResourceDataState(
      availableTimeSlots,
      (res) => !!(res?.content && res.content.length),
    )

    const emptyStateLabel = computed(() =>
      t('calendar.events.save_event.time.no_available_slots'),
    )

    return {
      t,
      isPresent,
      isLoading,
      isEmpty,
      emptyStateLabel,
    }
  },
})
