<template>
  <Dialog
    class="client-note-add-modal"
    header="&nbsp;"
    v-model:visible="dialogVisible"
    :showHeader="!mobileSize"
    :modal="true"
  >
    <MobileHeader
      :headerLabel="t('clients.note_adding')"
      :shouldNavigate="false"
      @navigateClick="$emit('update:visible', false)"
    />

    <div class="content-wrapper">
      <div class="text-center" v-if="!mobileSize">
        <span class="h-1">{{ t('clients.note_adding') }}</span>
      </div>
      <form class="note-form" novalidate @submit="onSubmit">
        <label class="h-4 mb-2" for="note" v-if="mobileSize">
          {{ t('clients.note') }}
        </label>
        <div class="note-textarea">
          <TextareaInput
            name="note"
            rows="10"
            :whiteBackground="!mobileSize"
            :placeholder="t('clients.note_placeholder')"
          />
        </div>
        <div class="button-container">
          <Button
            type="submit"
            class="p-button-primary w-100 p-button-rounded"
            :label="t('clients.add_note')"
            :disabled="!form.meta.value.valid"
          />
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Dialog from 'primevue/dialog'
import MobileHeader from '../Layout/MobileHeader/MobileHeader.vue'
import { useBreakpoints, ClientNoteMessages } from '@bd/components'
import { useField, useForm } from 'vee-validate'
import { addClientNoteSchema } from '@bd/components/yup'
import { useToast } from 'primevue/usetoast'
import { addNote } from '@bd/api/common/clients-management-api'
import { TextareaInput } from '@bd/components'

export default defineComponent({
  components: { Dialog, MobileHeader, TextareaInput },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:visible', 'noteAdd'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const toast = useToast()
    const dialogVisible = useVModel(props, 'visible', emit)
    const { mobileSize } = useBreakpoints()

    const form = useForm({
      validationSchema: addClientNoteSchema,
    })

    const { value: note } = useField<string>('note')

    const onSubmit = form.handleSubmit(async () => {
      try {
        await addNote({
          value: note.value,
          userId: props.clientId,
        })
        toast.add(ClientNoteMessages.saveSuccess)
        emit('noteAdd')
      } catch (e) {
        toast.add(ClientNoteMessages.saveError)
      }
    })

    return {
      t,
      dialogVisible,
      mobileSize,
      form,
      onSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.client-note-add-modal) {
  min-width: 460px;
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include breakpoint-up(md) {
    @include dialog-border-radius(6px);
  }
  @include mobile-fullscreen-dialog();

  .p-dialog-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .p-dialog-header {
    padding-bottom: 0;
  }

  .p-dialog-header-icons {
    margin-left: auto;
  }
}
.content-wrapper {
  padding: 0 2rem 2rem 2rem;
  height: 100%;
  @include breakpoint-down(md) {
    margin-top: $mobile-header-height;
    padding: 1.5rem;
  }
}
.note-form {
  display: flex;
  flex-direction: column;
  @include breakpoint-down(md) {
    height: 100%;
  }
}
.button-container {
  margin-top: auto;
  @include breakpoint-up(md) {
    margin-top: 2rem;
  }
}
.note-textarea {
  @include breakpoint-up(md) {
    background: $alabaster;
    padding: 0 1rem;
    margin-top: 1rem;
  }
}
</style>
