
import { Svg, useBadgeValue } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { ChildlessNavigationItem } from '../types'
import { useNavigationItemIconStyle } from '../utils'

export default defineComponent({
  components: {
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<ChildlessNavigationItem>,
      required: true,
      validator: (item: ChildlessNavigationItem) => !!(item.icon && item.path),
    },
  },
  setup(props) {
    const router = useRouter()

    const onItemClick = (item: ChildlessNavigationItem) => {
      if (!item.path) {
        return
      }
      router.push({ path: item.path })
    }

    const itemIconStyle = useNavigationItemIconStyle(props.item)

    const badge = computed(() => props.item.badge)
    const badgeValue = useBadgeValue(badge)

    return {
      onItemClick,
      itemIconStyle,
      badgeValue,
    }
  },
})
