import { ref } from 'vue'

const isUpdateEvent = (
  event: Event,
): event is CustomEvent<ServiceWorkerRegistration> => {
  return 'detail' in event
}

export const usePWAUpdate = (onUpdate?: () => void) => {
  const registration = ref<ServiceWorkerRegistration>()
  const updateExists = ref(false)
  const refreshing = ref(false)

  document.addEventListener(
    'swUpdated',
    (event: Event) => {
      if (isUpdateEvent(event)) {
        registration.value = event.detail
        updateExists.value = true
        console.log('🆕 swUpdate event:', event)
        onUpdate?.()
      }
    },
    {
      once: true,
    },
  )

  if (navigator.serviceWorker) {
    const originalController = navigator.serviceWorker.controller
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log('🎛️ controllerchange event:', originalController)
      if (refreshing.value || !originalController) {
        return
      }
      refreshing.value = true
      window.location.reload()
      console.log('🐫 Location reloaded')
    })
  }

  const refreshApp = () => {
    updateExists.value = false
    if (!registration.value?.waiting) {
      return
    }
    console.log('🗨️ refreshApp - sending SKIP_WAITING message')
    registration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  return {
    registration,
    updateExists,
    refreshApp,
  }
}
