<template>
  <div class="user-profile-example">
    <UserProfileBanner :user="mockUser" :userType="UserType.Seller">
      <!-- Prepended (before) slot -->
      <template #prepend>
        <div class="controls-prepend">
          <Button icon="pi pi-search" />
        </div>
      </template>
      <!-- Appended (after) slot -->
      <template #append>
        <div class="controls-append">
          <Button label="After avatar" />
        </div>
      </template>
    </UserProfileBanner>

    <!-- Override the user avatar image and fullname initials to display the user type initial -->
    <UserProfileBanner
      :user="mockUser"
      :userType="UserType.Agent"
      :overrideWithUserType="true"
    />

    <!-- Display user id -->
    <UserProfileBanner
      :user="mockUser"
      :userType="UserType.Buyer"
      :shouldShowUserId="true"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { UserType, UserProfileBanner } from '@bd/components'
import { UserDto } from '@bd/api'

/**
 * Component that demonstrates the use of User profile common components
 * TODO: Delete when no longer needed
 */
export default defineComponent({
  components: {
    UserProfileBanner,
  },
  setup() {
    const mockUser = computed<Partial<UserDto>>(() => ({
      avatarUrl: '', // Substitute with an actual image path for demo purposes
      firstName: 'John',
      lastName: 'Doe',
      id: 213451,
    }))

    return {
      mockUser,
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped>
.user-profile-example {
  .controls {
    &-prepend {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      margin: 0 2rem;

      @include breakpoint-down(md) {
        margin: 0;
        Button {
          top: 20px;
          right: 20px;
        }
      }
    }
    &-append {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 1rem;

      @include breakpoint-down(md) {
        flex-direction: column;
        margin: 0;
        padding: 1rem 0;
      }
    }
  }
}
</style>
