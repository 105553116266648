<template>
  <div class="row single-select-option d-flex justify-content-between">
    <div class="col px-1" v-for="item of items" :key="item.name">
      <Button
        class="single-select-option-item w-100 text-400 p-button-outlined p-button-mobile justify-content-center"
        :class="{ active: activeProperty.includes(item.option) }"
        @click="$emit('filter-data', { value: item.option, prop: value })"
      >
        <span>{{ item.name }}</span>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { AssetData } from '@bd/admin/types'
import { FiltersState } from '@bd/store-modules/types'

export default defineComponent({
  name: 'SingleSelectOption',
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<AssetData[]>,
      required: true,
    },
    filtersObject: {
      type: Object as PropType<FiltersState | undefined>,
      required: true,
    },
  },
  emits: ['filter-data'],
  setup(props) {
    const activeProperty = computed(() => {
      if (props.filtersObject && props.filtersObject.propertyType) {
        return [props.filtersObject.propertyType]
      } else {
        return []
      }
    })
    return { activeProperty }
  },
})
</script>

<style lang="scss" scoped>
.single-select-option {
  padding: 0 0.75rem;
}

.single-select-option-item {
  padding: 13px 5px;
  height: auto;
  border-color: $blue-haze !important;
}

.active {
  background: $primary-color !important;
  border: solid 1px $primary-color !important;
  color: white !important;
}
</style>
