<template>
  <Form
    id="form_registration"
    novalidate
    @submit="onSubmit"
    :validation-schema="registerSchema"
  >
    <AuthLayout :bgImage="require('@bd/assets/images/image-family.jpg')">
      <template #navigation>
        <div class="d-none d-md-flex align-self-start">
          <Button
            @click="$router.push({ name: 'Dashboard' })"
            icon="pi pi-chevron-left"
            class="p-button-secondary p-button-circle"
          />
        </div>
      </template>
      <template #logo>
        <div class="d-md-none mt-md-0 mt-auto text-center">
          <img :src="require('@bd/assets/images/immoby-logo.svg')" />
        </div>
      </template>
      <template #main>
        <div class="d-none d-md-block text-center description-container">
          <p class="h-1">
            {{ t('create_account_1') }}
          </p>
          <p class="body-bg hint">
            {{ t('create_account_2') }}
          </p>
        </div>
        <AuthFormRegister />
      </template>
      <template #footer>
        <div
          class="d-flex justify-content-center align-self-start align-items-end body-sm text-center"
        >
          <div>
            <span class="d-none d-md-block">
              {{ t('sell_apartment') }}
              <br />
              {{ t('contact_helpline') }}
              <br />
              {{ helpline }}
            </span>
          </div>
        </div>
      </template>
    </AuthLayout>
  </Form>
</template>

<script lang="ts">
import { default as common } from '@/config/common.json'
import { SignupRequestDto } from '@bd/api'
import {
  authFormService,
  useBreakpoints,
  useRootData,
  AuthFormMessages,
} from '@bd/components'
import { registerSchema } from '@bd/components/yup'
import { ToType } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { Form, SubmissionContext, SubmissionHandler } from 'vee-validate'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import AuthFormRegister from '../AuthForm/AuthFormRegister.vue'
import AuthLayout from '../AuthLayout.vue'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  name: 'AuthRegister',
  components: { AuthFormRegister, AuthLayout, Form },
  emits: ['submit', 'register'],
  setup(_, { emit }) {
    const i18n = useI18n()
    const store = useAppStore()
    const { helpline } = common
    const { isLoading } = useRootData()
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    const toast = useToast()

    const onSubmit: SubmissionHandler<ToType<SignupRequestDto>> = async (
      values: SignupRequestDto,
      actions: SubmissionContext<ToType<SignupRequestDto>>,
    ) => {
      emit('submit')
      const payload: SignupRequestDto = values
      try {
        await store.dispatch('auth/authSignup', payload)
        emit('register')
        toast.add(AuthFormMessages.registerSuccess)
      } catch (err) {
        const errorsData = authFormService.handleError(
          store.state.auth?.error,
          isDesktopSize.value,
        )
        if (errorsData) {
          actions.setErrors(errorsData)
        }
        toast.add(AuthFormMessages.registerError)
      }
    }

    return {
      ...i18n,
      helpline,
      registerSchema,
      isLoading,
      onSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
.description-container {
  margin-bottom: 3vh;
  .hint {
    margin-top: 2vh;
  }
}
</style>
