import { BadgeOptions, MAX_UNREAD_NOTIFICATIONS } from '@bd/components'
import { computed, Ref } from 'vue'

export const useBadgeValue = (badgeOptions: Ref<BadgeOptions | undefined>) =>
  computed<string | null>(() => {
    const badge = badgeOptions.value
    if (!badge || badge.value <= 0) {
      return null
    }
    return badge?.value > MAX_UNREAD_NOTIFICATIONS
      ? `${MAX_UNREAD_NOTIFICATIONS}+`
      : `${badge.value}`
  })
