import { onUnmounted, toRaw, ref, unref, isRef, watch, Ref } from 'vue'
import type { Store } from 'vuex'
import { useField } from 'vee-validate'

export const IS_DEV = process.env.NODE_ENV === 'development'

/**
 * Creates a branded (a.k.a nominal) type. This allows for type safety when
 * dealing with values that in runtime have the same representation but
 * different semantics. Example: Consider having 2 different entities - File and
 * User. Both have some field that identifies them and in runtime both are
 * numbers. If we declare User and File interfaces so that identifier fields
 * have 'number' type we'll be able to pass User's identifier to any function
 * that accepts File identifier and vice versa. If instead we used ID type like
 * ```
 * interface User { id: ID<'User'> }
 * interface File { id: ID<'File'> }
 * ```
 * then TS will differentiate those identifiers and `user.id = file.id` will
 * raise TS error.
 *
 * https://spin.atomicobject.com/2018/01/15/typescript-flexible-nominal-typing/
 */
export type ID<EntityName, RuntimeType = number> = RuntimeType & {
  __entityName?: EntityName
}

/**
 * This is a hack that is required for casting interfaces to type aliases to
 * prevent 'Index signature is missing in type' TS error.
 * https://github.com/microsoft/TypeScript/issues/15300
 */
export type ToType<T> = { [K in keyof T]: T[K] }

export type TemplateRef<T extends HTMLElement = HTMLElement> = T | null

export interface OptionalPayload<T> {
  payload?: T
}

export interface LoadableResource<T> {
  isLoading: boolean
  content: T
}

export type LoadableOptional<T> = OptionalPayload<LoadableResource<T>>

export const logErr = (e: Error): void => console.error(e)

export const clamp = (val: number, bound1: number, bound2: number): number => {
  const min = Math.min(bound1, bound2)
  const max = Math.max(bound1, bound2)
  if (val < min) return min
  if (val > max) return max
  return val
}

/**
 * Creates a function that will invoke `fn` only once and cache the result. Next
 * invocations will return cached result
 */
export const once = <T>(fn: () => T): (() => T) => {
  let didRun = false
  let result: T
  return () => {
    if (didRun) return result

    result = fn()
    didRun = true
    return result
  }
}

export const times = <T>(n: number, mapFn: (idx: number) => T): T[] => {
  if (n <= 0 || !isFinite(n)) return []
  return Array.from({ length: Math.floor(n) }, (_, idx) => mapFn(idx))
}

export const vuexLogger = (prefix: string) => (store: Store<unknown>): void => {
  store.subscribe((mutation) => {
    console.log(prefix, `mutation`, mutation.type, mutation.payload)
  })
  store.subscribeAction((action) => {
    console.log(prefix, `action`, action.type, action.payload)
  })
}

/**
 * Creates an array with `elem` appended if it wasn't present before. Removes
 * `elem` otherwise.
 *
 * Removing can be prevented by setting `shouldAdd` to true. Adding can be
 * prevented by setting `shouldAdd` to false. Leaving it on undefined preserves
 * original 'toggle' behavior.
 */
export const toggleElem = <T>(arr: T[], elem: T, shouldAdd?: boolean): T[] => {
  const idx = arr.indexOf(elem)
  const has = idx >= 0
  const add = shouldAdd ?? !has
  if (add && !has) {
    return [...arr, elem]
  } else if (!add && has) {
    return [...arr.slice(0, idx), ...arr.slice(idx + 1)]
  }
  return arr
}

export type LocalIsoTime = ID<'LocalIsoTime', string> // hh:mm:ss
export type LocalIsoDate = ID<'LocalIsoDate', string> // yyyy-mm-dd
export const getLocalIsoDate = (date: Date): LocalIsoDate => {
  return [
    `${date.getFullYear()}`,
    `${date.getMonth() + 1}`.padStart(2, '0'),
    `${date.getDate()}`.padStart(2, '0'),
  ].join('-')
}

export type LocalTime = { hours: number; minutes: number; seconds: number }
export const parseLocalIsoTime = (time: LocalIsoTime): LocalTime => {
  if (IS_DEV && !/^\d\d:\d\d(:\d\d)?$/.test(time)) {
    throw Error(`Time format doesn't match hh:mm:ss`)
  }

  const [h, m, s] = time.split(':').map(parseFloat)
  return {
    hours: h,
    minutes: m,
    seconds: s ?? 0,
  }
}

/**
 * Parses LocalIsoDate strings to Date objects. Doing it directly with Date
 * constructor: `new Date(LocalIsoDate)` is *NOT* correct because it's treated
 * as UTC and not local date.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#timestamp_string
 */
export const parseLocalIsoDate = (date: LocalIsoDate) => {
  if (IS_DEV && !/^\d{4}-\d\d-\d\d$/.test(date)) {
    throw Error(`Date format doesn't match yyyy-mm-dd`)
  }
  const [y, m, d] = date.split('-').map(parseFloat)
  return new Date(y, m - 1, d)
}

export const stringifyLocalTime = (
  time: LocalTime,
  includeSeconds = false,
): string => {
  const { hours, minutes, seconds } = time
  return [hours, minutes, includeSeconds ? seconds : null]
    .filter((str) => str !== null)
    .map((str) => str?.toString().padStart(2, '0'))
    .join(':')
}

export const getDatePart = (
  dateParts: Intl.DateTimeFormatPart[],
  partType: Intl.DateTimeFormatPartTypes,
) => {
  return dateParts.find((part) => part.type === partType)?.value ?? ''
}

export const delay = (ms: number) =>
  new Promise<void>((res) => setTimeout(res, ms))

interface BackoffCfg {
  maxDelay?: number
  minDelay?: number
  maxTries?: number
}

/**
 * Creates a function that each time called will return Promise that resolves
 * after increasing time span. After `maxTries` calls it will throw an error.
 */
export const backoff = ({
  maxDelay = Infinity,
  minDelay = 0,
  maxTries = Infinity,
}: BackoffCfg = {}) => {
  let tries = 0
  const timeout = (tryNum: number) => {
    if (tryNum > maxTries) throw Error('Max retry count exceeded')
    const time = 1000 * 1.4 ** tryNum
    const rand = (Math.random() - 0.5) * (time / 3)
    return clamp(time + rand, minDelay, maxDelay)
  }
  return () => delay(timeout(tries++))
}

/**
 * Will call `workerFn` until it succeeds. `delayFn` allows to customize time
 * between subsequent retries
 */
export async function retry<T>(workerFn: () => Promise<T>): Promise<T>
export async function retry<T>(
  delayFn: () => Promise<void>,
  workerFn: () => Promise<T>,
): Promise<T>
export async function retry<T>(
  delayFn: () => Promise<void>,
  workerFn?: () => Promise<T>,
): Promise<T> {
  if (!workerFn) {
    // eslint-disable-next-line no-param-reassign
    workerFn = delayFn as never
    // eslint-disable-next-line no-param-reassign
    delayFn = backoff()
  }

  for (;;) {
    try {
      return await workerFn()
    } catch (e) {
      await delayFn()
    }
  }
}

export const convertCamelCaseToSnakeCase = (word: string) => {
  return word.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
}

export const deferred = <T>() => {
  let resolve: (val: T | Promise<T>) => void
  let reject: (err: Error) => void
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })
  return [promise, resolve!, reject!] as const
}

/**
 * Maps object keys to array of their corresponding values
 * @example
 * const obj = {
 *   name: 'readme',
 *   extension: 'md'
 * }
 * const result = keysToValArray(obj) // Contains: ['readme', 'md']
 * @param obj Input object
 * @returns Array of values
 */
export const keysToValArray = <T>(obj: Record<string, T>): T[] =>
  Object.keys(obj).map((key: string) => obj[key])

export type ComponentSize = 'small' | 'medium' | 'large'

export const getFormattedPrice = (
  price: number,
  currency: string,
  locale: string,
) => {
  if (!price) {
    return price
  }
  return price.toLocaleString(locale, {
    style: 'currency',
    currency,
    maximumSignificantDigits: 6,
  })
}

/**
 * Compare two arrays ignoring elements order
 * @returns true if arr1 and arr2 are the same size and both contain the same elements in any order, false otherwise
 */
export const sameArraysContent = <T>(arr1: T[], arr2: T[]) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((element) => arr2.includes(element)) &&
    arr2.every((element) => arr1.includes(element))
  )
}

export const emptyLoadableResource = <T>(
  overrideEmptyContent?: T,
): LoadableResource<T | undefined> => ({
  isLoading: false,
  content: overrideEmptyContent ?? undefined,
})

/**
 * Use instead of declaring callback inline:
 * ```
 * [0, 1, null, undefined, 4].filter(x => x != null)
 * // vs
 * [0, 1, null, undefined, 4].filter(notNil)
 * ```
 *
 * Main benefit is that by using this helper we're also assuring TS that we got
 * rid of any `null`s and `undefined`s from the array.
 *
 * Name was taken from https://lodash.com/docs/#isNil
 */
export const notNil = <T extends NonNullable<unknown>>(
  obj: T | null | undefined,
): obj is T => obj != null

export const stringOrNull = (text?: string) => {
  return text?.trim().length ? text : null
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export const truncateText = (text: string, max: number, showMore: boolean) => {
  if (text.length >= max) {
    return showMore ? text : text.substring(0, max).concat('...')
  }
  return text
}

/**
 * Returns an array of items such that getId(item) is unique. For example:
 *
 * ```
 * uniqBy([1,2,3,4], num => num % 2) -> [1,2]
 * uniqBy([
 *   {color: 'green', item: 'grass'}
 *   {color: 'blue', item: 'sky'}
 *   {color: 'blue', item: 'water'}
 * ], entry => entry.color) -> [
 *   {color: 'green', item: 'grass'}
 *   {color: 'blue', item: 'sky'}
 * ]
 * ```
 */
export const uniqBy = <T>(arr: T[], getId: (item: T) => unknown): T[] => {
  const seenIds = new Set<unknown>()
  return arr.filter((item) => {
    const id = getId(item)
    if (seenIds.has(id)) return false
    seenIds.add(id)
    return true
  })
}

type Handlers<T> =
  | ((val: T) => void)
  | {
      next: (val: T) => void
      error: (err: unknown) => void
    }
export const subscribe = <T>(
  source: PromiseLike<T>,
  sub: Handlers<T>,
): (() => void) => {
  const next = typeof sub === 'function' ? sub : sub.next
  const error = typeof sub === 'function' ? null : sub.error
  let active = true

  source.then(
    (val) => active && next(val),
    error ? (err) => active && error(err) : null,
  )

  return () => {
    active = false
  }
}

export const useLatest = <Res, Args extends unknown[]>(
  fn: (...args: Args) => Promise<Res>,
  sub: Handlers<Res>,
) => {
  let cancelLast: (() => void) | undefined

  onUnmounted(() => {
    cancelLast?.()
  })

  return (...args: Args) => {
    if (cancelLast) cancelLast()
    cancelLast = subscribe(fn(...args), sub)
  }
}

export const useFieldOf = <Form>() => <
  Name extends keyof Form,
  Init extends Form[Name]
>(
  name: Name,
  init?: Init,
) => {
  return useField<Init | undefined>(name as never, undefined, {
    initialValue: init as never,
  })
}

export const toRawDeep = <T>(value: T): T => {
  const shallow = toRaw(value)
  if (shallow && Array.isArray(shallow)) {
    return (shallow.map(toRawDeep) as never) as T
  }
  if (shallow && typeof shallow === 'object') {
    const res = {} as T
    for (const key in shallow) {
      res[key] = toRawDeep(shallow[key])
    }
    return res
  }
  return shallow
}

export type MaybeRef<T> = T | Ref<T>

export const useBlobUrl = (
  urlOrRef: MaybeRef<string | undefined>,
  fetchBlob: (url: string) => Promise<Blob>,
) => {
  const dataUrl = ref<string>()

  const updateDataUrl = async () => {
    const url = unref(urlOrRef)
    const prevDataUrl = dataUrl.value

    try {
      if (url) {
        const blob = await fetchBlob(url)
        dataUrl.value = URL.createObjectURL(blob)
      }
    } finally {
      if (prevDataUrl) {
        URL.revokeObjectURL(prevDataUrl)
      }
    }
  }

  onUnmounted(() => {
    if (dataUrl.value) {
      URL.revokeObjectURL(dataUrl.value)
    }
  })

  if (isRef(urlOrRef)) {
    watch(urlOrRef, updateDataUrl)
  }

  updateDataUrl()

  return dataUrl
}

export const removeNullValuesFromObject = <T>(obj: T): Partial<T> => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (obj[key] != null) {
      result[key] = obj[key]
    }
  }
  return result
}

type DictOfType<T> = Record<string, T>
export const DictOf = <T>() => <D extends DictOfType<T>>(dict: D): D => dict

export const trimSlash = (str: string) => str.replace(/\/$/, '')
