<template>
  <div class="date-element">
    <p class="day">{{ dayAndMonth.day }}</p>
    <p class="month">{{ dayAndMonth.month }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import { LocalIsoDate } from '@bd/helpers'
import { useFormattedDayAndMonth } from '@bd/components'

export default defineComponent({
  name: 'ProfileCardDate',
  props: {
    date: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const dayAndMonth = useFormattedDayAndMonth(date)
    return { dayAndMonth }
  },
})
</script>

<style lang="scss" scoped>
.date-element {
  width: 50px;
  height: 50px;
  padding: 10px 15px 10px 16px;
  border-radius: 8px;
  background: rgba($athens-gray, 0.5);
  text-align: center;
  .day {
    font-weight: 600;
    font-size: 17px;
    color: $port-gore;
  }
  .month {
    font-size: 13px;
    color: $manatee;
  }
}
</style>
