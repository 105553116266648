import { NotificationType } from '@bd/api/common/types/notifications'
import { Message, onBackgroundMessage } from '@bd/api/firebase-sw'
import { useAppStore } from '@bd/store-modules'
import { useRouter } from 'vue-router'
import { noop } from '@vueuse/shared'

export const usePurchaseOffers = () => {
  const store = useAppStore()
  const router = useRouter()

  const fetchPurchaseOfferData = async (msg: Message) => {
    const buyerId = msg.data.BUYER_ID
    const offerId = msg.data.OFFER_ID

    await store.dispatch('purchaseOffers/lastPurchaseOffer', {
      offerId,
      buyerId,
    })
    store.dispatch('dialogVisibility', {
      counterProposalModal: true,
    })
    router.replace({ query: {} })
  }

  const handleClickMessage = (msg: Message) => {
    if (msg.data.IMMOBY_NOTIFICATION_TYPE === NotificationType.PURCHASE_OFFER) {
      fetchPurchaseOfferData(msg)
    }
  }

  onBackgroundMessage(noop, (msg: Message) => {
    handleClickMessage(msg)
  })
}
