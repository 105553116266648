
import { computed, defineComponent, PropType } from 'vue'
import { ChildlessNavigationItem, NavigationItem } from '../types'
import BottomNavigationItem from './BottomNavigationItem.vue'

/**
 * For bottom navigation overriding the default order of navigation items
 */
const navigationOrderFn = (item1: NavigationItem, item2: NavigationItem) => {
  const a = item1.order ?? 1,
    b = item2.order ?? 0
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  }
  return 0
}

export default defineComponent({
  components: {
    BottomNavigationItem,
  },
  props: {
    items: {
      type: Object as PropType<NavigationItem[]>,
      required: true,
    },
  },
  setup(props) {
    const mappedItems = computed<ChildlessNavigationItem[]>(() => {
      return props.items
        .flatMap((item) => item.children ?? item)
        .filter((item) => item.icon && item.path)
        .sort(navigationOrderFn)
    })

    return {
      mappedItems,
    }
  },
})
