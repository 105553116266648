
import { defineComponent, PropType } from 'vue'
import { NavigationItem } from '../types'
import TopNavigationItem from './TopNavigationItem.vue'

export default defineComponent({
  components: {
    TopNavigationItem,
  },
  props: {
    items: {
      type: Object as PropType<NavigationItem[]>,
      required: true,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
})
