<template>
  <div class="month-calendar-title">
    <div class="date-header" :class="{ full: showFullDate, compact: compact }">
      <slot :currentDate="currentDate">
        <div v-if="!showFullDate">
          <h2
            class="date-label text-capitalize"
            :class="{ 'page-title': !compact, 'h-2': compact }"
          >
            {{ currentDate.month }}
            <span class="year-label">{{ currentDate.year }}</span>
          </h2>
        </div>
        <div v-else>
          <h1 class="day-label">
            {{ currentDate.day }}
          </h1>
          <div class="month-year-label text-capitalize">
            {{ currentDate.month }} {{ currentDate.year }}
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { LocalIsoDate } from '@bd/helpers'
import { defineComponent, PropType, toRefs } from 'vue'
import { useFormattedDate } from '../use'

export default defineComponent({
  props: {
    date: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    showFullDate: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const currentDate = useFormattedDate(date)
    return {
      currentDate,
    }
  },
})
</script>

<style lang="scss" scoped>
.date-header {
  .date-label.page-title {
    @include breakpoint-down(md) {
      font-size: 1.875rem;
    }
    .year-label {
      font-weight: 400;
      color: $manatee;
    }
  }

  &.full {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem 0;

    .day-label {
      font-size: 4.375rem;
      font-weight: normal;
      line-height: normal;
    }
    .month-year-label {
      color: $comet;
    }
  }
}
</style>
