<template>
  <div class="row submit-spacing d-none d-md-block">
    <div class="col">
      <Button
        :disabled="isLoading"
        @click="$router.push({ path: '/login' })"
        :label="t('back_to_login')"
        class="p-button-primary w-100"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'

export default defineComponent({
  name: 'AuthFormConfirmation',
  setup() {
    const i18n = useI18n()
    const { isLoading } = useRootData()

    return {
      ...i18n,
      isLoading,
    }
  },
})
</script>
