
import { computed, defineComponent } from 'vue'
import Dialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'
import { useBreakpoints } from '@bd/components'
import { useRouter } from 'vue-router'
import { useAppStore } from '@bd/client/store'
import { LoginModalDialogData } from '@bd/client/types'

export default defineComponent({
  components: { Dialog },
  props: {
    visible: Boolean,
  },
  emits: ['update:visible'],
  setup() {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const router = useRouter()
    const store = useAppStore()

    const reason = computed(() => {
      const data = store.state.dialog.dashboardLoginModal
      return (data as LoginModalDialogData).reason
    })

    const goToLogin = () => {
      store.dispatch('dialogVisibility', {
        dashboardLoginModal: false,
      })
      router.push({ name: 'Login' })
    }

    return {
      t,
      mobileSize,
      goToLogin,
      reason,
    }
  },
})
