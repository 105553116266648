<template>
  <div
    class="calendar-event-view"
    :class="{ 'is-loading': isLoading, 'is-empty': isEmpty && !isEventAddView }"
  >
    <slot v-if="!showEmptyState && !isLoading" />
    <ProgressSpinner v-if="isLoading" />
    <template v-if="showEmptyState && !isLoading">
      <EmptyState :title="t('calendar.event_details.empty_state.title')">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-primary px-3"
          :label="t('calendar.event_details.empty_state.back')"
          @click="$router.push({ path: emptyStateRoute })"
        />
      </EmptyState>
    </template>
  </div>
</template>
<script lang="ts">
import { UserCalendarEventDetailsDto } from '@bd/api'
import { useLoadableResourceDataState } from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { LoadableResource } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { emits as eventDetailsEmits } from './EventDetails/EventDetails.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ProgressSpinner,
    EmptyState,
  },
  props: {
    eventDetails: {
      type: Object as PropType<
        LoadableResource<UserCalendarEventDetailsDto | undefined>
      >,
      required: true,
    },
    emptyStateRoute: {
      type: String,
      required: true,
    },
  },
  emits: [...eventDetailsEmits],
  setup(props) {
    const { t } = useI18n()
    const route = useRoute()

    const eventDetails = computed(() => props.eventDetails)
    const eventDetailsContent = computed(() => eventDetails.value?.content)
    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      eventDetails,
    )

    const isEventAddView = computed(() => route.name === 'CalendarEventNew')
    const isEventEditView = computed(() => route.name === 'CalendarEventEdit')
    const isEditionPossible = computed(
      () => eventDetailsContent.value?.calendarEventDto.editionPossibility,
    )
    const showEmptyState = computed(
      () =>
        (!isEditionPossible.value && isEventEditView.value) ||
        (isEmpty.value && !isEventAddView.value),
    )

    return {
      t,
      isLoading,
      isPresent,
      isEmpty,
      isEventAddView,
      showEmptyState,
      eventDetailsContent,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-event-view {
  background-color: $default-bg-color;
  height: calc(100vh - #{$top-navigation-height});

  @include breakpoint-down(md) {
    height: 100% !important;
  }

  &.admin {
    height: 100%;
    overflow-y: auto;
  }

  &.is-loading,
  &.is-empty {
    justify-content: center;
    @include breakpoint-down(md) {
      height: 100vh !important;
    }

    &.admin {
      height: 100%;
      overflow-y: auto;
    }
  }
  &.is-loading {
    @include flex;
  }
}
</style>
