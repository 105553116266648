
import Dialog from 'primevue/dialog'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    submitPending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'reject'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
