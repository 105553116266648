import { AppStore } from '@bd/store-modules'
import { OfferSelectionTab } from '@bd/store-modules/types'
import { canFetchTimeSlots } from './utils'

export const getOffersSearchPhrase = (store: AppStore) => {
  return store.state.calendarEvent?.eventSaveMetaData.offersSearchPhrase ?? ''
}

export const getAttendeesSearchPhrase = (store: AppStore) => {
  return store.state.calendarEvent?.eventSaveMetaData.attendeesSearchPhrase ?? ''
}

export const getSelectedOffersTab = (store: AppStore) => {
  return (
    store.state.calendarEvent?.eventSaveMetaData.selectedOffersTab ??
    OfferSelectionTab.Mine
  )
}

export const getEventSaveData = (store: AppStore) => {
  return store.state.calendarEvent?.eventSaveData
}

export const isEventSaveValid = (store: AppStore) => {
  const eventSaveData = store.state.calendarEvent?.eventSaveData

  const canFetchAvailableTimeSlots = canFetchTimeSlots(store.state.calendarEvent)
  const areTimeSlotsSelected = eventSaveData?.timeSlotIds && eventSaveData.timeSlotIds.length

  return canFetchAvailableTimeSlots && areTimeSlotsSelected
}
