
import { defineComponent, computed, PropType } from 'vue'
import {
  extractFullNameInitials,
  extractUserTypeInitial,
  UserAvatarInput,
  Avatar,
  UserType,
  useUserTypeClass,
} from '@bd/components'

export default defineComponent({
  props: {
    userType: {
      type: String as PropType<Avatar['userType']>,
      required: true,
      validator: (value: string) => value in UserType,
    },
    user: {
      type: Object as PropType<UserAvatarInput>,
      required: true,
    },
    overrideWithUserType: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const computedUserType = computed(() => props.userType)
    const isImage = computed(
      () => props.user.avatarUrl && !props.overrideWithUserType,
    )

    const computedStyle = computed(() => {
      return isImage.value
        ? `background: url(${props.user.avatarUrl}) 100% / 100% no-repeat`
        : ''
    })

    const initials = computed(() => {
      const { firstName, lastName } = props.user
      return props.overrideWithUserType || !firstName || !lastName
        ? extractUserTypeInitial(props.userType)
        : extractFullNameInitials(firstName, lastName)
    })

    const userTypeClass = useUserTypeClass(computedUserType)

    return { initials, isImage, computedStyle, UserType, userTypeClass }
  },
})
