import { TransactionHistoryState } from '@bd/store-modules/types/transactionHistory'
import {
  getDefaultTransactionsObject,
  getDefaultTransactionDetails,
  getDefaultTransactionHistory,
} from './utils'

export const state: TransactionHistoryState = {
  transactions: getDefaultTransactionsObject(),
  transactionDetails: getDefaultTransactionDetails(),
  transactionHistory: getDefaultTransactionHistory(),
}
