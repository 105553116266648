<template>
  <canvas
    ref="canvas"
    :width="width"
    :height="height"
    @click="onCanvasClick"
  ></canvas>
</template>

<script lang="ts">
import { watchEffect, PropType, defineComponent, shallowRef } from 'vue'
import {
  Chart,
  ChartType,
  ChartData,
  ChartOptions,
  Plugin,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js'

Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
)

const watchEffectPost: typeof watchEffect = (fn, opts) => {
  return watchEffect(fn, { ...opts, flush: 'post' })
}

// https://www.chartjs.org/docs/latest/getting-started/v3-migration.html#removal-of-public-apis
const getElementAtEvent = (chart: Chart, event: MouseEvent) => {
  return chart.getElementsAtEventForMode(
    event,
    'nearest',
    { intersect: true },
    false,
  )
}

// https://www.chartjs.org/docs/latest/getting-started/v3-migration.html#removal-of-public-apis
const getDatasetAtEvent = (chart: Chart, event: MouseEvent) => {
  return chart.getElementsAtEventForMode(
    event,
    'dataset',
    { intersect: true },
    false,
  )
}

export default defineComponent({
  emits: ['select'],
  props: {
    type: {
      type: String as PropType<ChartType>,
      required: true,
    },
    data: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    options: Object as PropType<ChartOptions>,
    plugins: Array as PropType<Plugin[]>,
    width: { type: Number, default: 300 },
    height: { type: Number, default: 150 },
  },

  setup(props, { emit }) {
    const canvas = shallowRef<HTMLCanvasElement>()
    const chart = shallowRef<Chart>()

    watchEffectPost((onCleanup) => {
      if (!canvas.value) return

      chart.value = new Chart(canvas.value, {
        type: props.type,
        data: props.data,
        options: props.options,
        plugins: props.plugins,
      })

      onCleanup(() => chart.value?.destroy())
    })

    const onCanvasClick = (event: MouseEvent) => {
      if (!chart.value) return
      const element = getElementAtEvent(chart.value, event)
      const dataset = getDatasetAtEvent(chart.value, event)

      if (element && element[0] && dataset) {
        emit('select', {
          originalEvent: event,
          element: element[0],
          dataset: dataset,
        })
      }
    }

    return {
      canvas,
      onCanvasClick,
    }
  },
})
</script>

<style lang="scss" scoped></style>
