import { UserRole } from '@bd/api'
import { CommonAppState } from '@bd/store-modules/types'

export const getUserFullName = (state: CommonAppState) => {
  const { firstName, lastName } = state.userProfile?.user || {}
  return firstName && lastName ? `${firstName ?? ''} ${lastName ?? ''}` : ''
}

export const isUserLogged = (state: CommonAppState): boolean => {
  return !!state.auth?.accessToken
}

export const userHasRoles = (state: CommonAppState, roles: UserRole[]): boolean => {
  return roles.every((role) => state.userProfile?.user.roles.includes(role))
}
