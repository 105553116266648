
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TransactionStatus } from '@bd/api'
import Chip from 'primevue/chip'

export default defineComponent({
  name: 'ProfileCardChip',
  components: { Chip },
  props: {
    status: {
      type: String as PropType<TransactionStatus>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const chipStatus = computed(() => {
      return props.status === 'ACCEPTED' ? 'acceptation' : 'negotiation'
    })
    return { t, chipStatus }
  },
})
