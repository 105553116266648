
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'
import { defineComponent } from 'vue'
import AuthFormConfirmation from '../AuthForm/AuthFormConfirmation.vue'
import AuthLayout from '../AuthLayout.vue'

export default defineComponent({
  name: 'AuthPasswordResetConfirmation',
  components: { AuthLayout, AuthFormConfirmation },
  setup() {
    const i18n = useI18n()
    const { helpline } = common
    return {
      ...i18n,
      helpline,
    }
  },
})
