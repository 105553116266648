
import { defineComponent, PropType, computed, ref, watchEffect } from 'vue'
import { TimeSlot } from '@bd/api'
import { times, toggleElem, getLocalIsoDate, LocalIsoDate } from '@bd/helpers'
import { useI18n } from 'vue-i18n'
import {
  isSameDay,
  addDays,
  subDays,
  isBefore,
  startOfToday,
  startOfDay,
} from 'date-fns'
import { WeekdaySelectType } from './types'

const WEEK_STARTS_AT = TimeSlot.Weekday.MON
const nearestWeekStart = (time = Date.now()) => {
  const date = new Date(time)
  date.setHours(0, 0, 0, 0)
  const offset = date.getDay() < WEEK_STARTS_AT ? 7 : 0
  const day = offset + date.getDay() - WEEK_STARTS_AT
  return subDays(date.getTime(), day).getTime()
}

type EntryID = TimeSlot.Weekday | LocalIsoDate

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<'weekday' | 'date'>,
      default: 'weekday',
    },
    selected: {
      type: Array as PropType<EntryID[]>,
      required: true,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    highlightToday: {
      type: Boolean,
      default: false,
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectConfirmation: {
      type: Boolean,
      default: false,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
    },
  },
  emits: ['update:selected', 'select', 'unselect', 'selectAttempt'],
  setup(props, { emit }) {
    const { locale } = useI18n()

    const selectionConfirmed = ref(false)

    const nearestWeekBySelectedDay = () =>
      nearestWeekStart(new Date(props.selected[0]).getTime())

    const startTime = ref(
      props.mode === 'date' && props.selected.length
        ? nearestWeekBySelectedDay()
        : nearestWeekStart(),
    )

    watchEffect(() => {
      startTime.value = nearestWeekBySelectedDay()
    })

    const isDateBeforeNow = (date: Date) => {
      return isBefore(startOfDay(date), startOfToday())
    }

    type Entry = typeof dayEntries.value[number]
    const dayEntries = computed(() => {
      const formatter = new Intl.DateTimeFormat(locale.value, {
        weekday: 'short',
      })

      return times(7, (day) => {
        const isDateMode = props.mode === 'date'
        const date = addDays(startTime.value, day)
        const dayOfWeek = date.getDay() as TimeSlot.Weekday
        const dateStr = getLocalIsoDate(date)
        const id: EntryID = isDateMode ? dateStr : dayOfWeek
        const now = new Date()
        const before = isDateBeforeNow(date)
        return {
          id,
          dayLetter: formatter.format(date).charAt(0).toUpperCase(),
          dayOfMonth: date.getDate(),
          selected: props.selected.includes(id),
          isToday: isSameDay(date, now),
          disabled: props.disablePastDates && isDateMode && before,
        }
      })
    })

    // Prevent selecting an entry when selection confirmation is required & emit selection attempt event
    const attemptedToSelect = () => {
      if (props.selectConfirmation && !selectionConfirmed.value) {
        emit('selectAttempt')
        selectionConfirmed.value = true
        return true
      }
      return false
    }

    const toggle = (entry: Entry, selectType: WeekdaySelectType) => {
      // Prevent selecting the same entry when multi select is off
      if (!props.multiSelect && props.selected.includes(entry.id)) {
        return
      }
      if (attemptedToSelect()) {
        return
      }
      const newSelected = props.multiSelect
        ? toggleElem(props.selected, entry.id, !entry.selected)
        : [entry.id]
      emit(entry.selected ? 'unselect' : 'select', entry.id, selectType)
      emit('update:selected', newSelected, selectType)
      selectionConfirmed.value = false
    }

    const shiftBy = (days: number) => {
      if (attemptedToSelect()) {
        return
      }
      startTime.value = addDays(startTime.value, days).getTime()
      const firstNonDisabledEntry = dayEntries.value.find(
        (entry) => !entry.disabled,
      )
      if (firstNonDisabledEntry) {
        toggle(firstNonDisabledEntry, 'Navigation')
      }
    }

    const showTodayHighlight = computed(() => {
      return props.mode === 'date' && props.highlightToday
    })

    const canNavigateBack = computed(() => {
      const date = new Date(startTime.value)
      return !isDateBeforeNow(date) && !isSameDay(date, startOfToday())
    })

    return { dayEntries, shiftBy, toggle, showTodayHighlight, canNavigateBack }
  },
})
