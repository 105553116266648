<template>
  <div class="calendar-event-offer">
    <ResponsiveContent>
      <template #mobile>
        <div class="mobile">
          <CalendarEventOfferChooser
            :selectedOffer="selectedOffer"
            @searchOffer="$emit('searchOffer', $event)"
            @clearOffer="$emit('clearOffer')"
          />
        </div>
      </template>
      <template #desktop>
        <div class="desktop d-md-flex">
          <CalendarEventOfferChooser
            :selectedOffer="selectedOffer"
            @searchOffer="$emit('searchOffer', $event)"
            @clearOffer="$emit('clearOffer')"
          />
        </div>
      </template>
    </ResponsiveContent>
  </div>
</template>

<script lang="ts">
import { ResponsiveContent } from '@bd/components'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import { defineComponent, PropType } from 'vue'
import CalendarEventOfferChooser from './CalendarEventOfferChooser.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventOfferChooser,
  },
  props: {
    selectedOffer: {
      type: Object as PropType<MeetingOfferSelectPayload>,
      required: false,
    },
  },
  emits: ['update:selectedOffer', 'searchOffer', 'clearOffer'],
})
</script>

<style lang="scss" scoped>
.calendar-event-offer {
  @include breakpoint-down(md) {
    background-color: $alabaster;
  }
}
</style>
