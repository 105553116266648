
import { defineComponent, computed, PropType } from 'vue'
import { AdditionalFilter } from '@bd/store-modules/types'
import DashboardCarousel from './DashboardCarousel.vue'

export default defineComponent({
  name: 'CarouselButtons',
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AdditionalFilter[]>,
      required: true,
    },
    activeFilters: {
      // TODO add type
      type: Object,
      required: true,
    },
  },
  emits: ['filter-data'],
  components: { DashboardCarousel },
  setup(props, { emit }) {
    const currentActiveFilters = computed(() => {
      if (props.value.includes('cityIds')) {
        return props.activeFilters?.cityIds ?? []
      } else if (props.value.includes('districtIds')) {
        return props.activeFilters?.districtIds ?? []
      } else {
        return []
      }
    })
    const getItemName = (cityObject: AdditionalFilter) => {
      if (cityObject.districtName) {
        return cityObject.districtName
      } else {
        return cityObject.cityName
      }
    }

    const emitFilterData = (item: AdditionalFilter) => {
      emit('filter-data', {
        value: item.districtId || item.cityId,
        prop: props.value,
      })
    }

    const isCityValue = computed(() => {
      return !!props.value.includes('cityIds')
    })

    return { currentActiveFilters, getItemName, emitFilterData, isCityValue }
  },
})
