<template>
  <div class="wrapper" :style="cssVars">
    <Button
      v-for="entry of entries"
      :key="entry.slot.timeSlotId"
      class="time-slot"
      :class="{
        'p-button-primary selected': entry.selected,
        'p-button-secondary is-deselected': !entry.selected,
        'new-selected': entry.newSelected,
        'new-deselected': entry.newDeselected,
        disabled: entry.disabled,
      }"
      @click="toggle(entry)"
    >
      {{ entry.label }}
    </Button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { TimeSlot } from '@bd/api'
import { clamp, toggleElem } from '@bd/helpers'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    slots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    selected: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: true,
    },
    newSelected: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: false,
    },
    newDeselected: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: false,
    },
    disabledSlots: {
      type: Array as PropType<TimeSlot.TimeSlotID[]>,
      required: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    validateContinuity: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selected', 'select', 'unselect'],

  setup(props, { emit }) {
    const columns = computed(() => {
      let n = props.slots.length
      n = Math.sqrt(n)
      n = Math.floor(n)
      n = clamp(n, 2, 5)
      return n
    })

    type Entry = typeof entries.value[number]
    const entries = computed(() => {
      return props.slots.map((slot) => ({
        slot,
        label: slot.startTime.slice(0, -1 * ':00'.length),
        selected: props.selected.includes(slot.timeSlotId),
        newSelected: props.newSelected?.includes(slot.timeSlotId),
        newDeselected: props.newDeselected?.includes(slot.timeSlotId),
        disabled: props.disabledSlots?.includes(slot.timeSlotId),
      }))
    })

    const cssVars = computed(() => {
      return { '--columns': columns.value }
    })

    const getSelectedNeighboursCount = (entry: Entry): number => {
      const slotEntries = entries.value
      const currIdx = slotEntries.findIndex(
        (e) => e.slot.timeSlotId === entry.slot.timeSlotId,
      )
      if (currIdx === -1) {
        return 0
      }
      const prev = currIdx !== 0 ? slotEntries[currIdx - 1] : null
      const next =
        currIdx !== slotEntries.length - 1 ? slotEntries[currIdx + 1] : null

      return +(prev?.selected ?? 0) + +(next?.selected ?? 0)
    }

    const continuityValidator = (entry: Entry) => {
      const selectedNeighboursCount = getSelectedNeighboursCount(entry)
      const selectedEntriesCount = entries.value.filter((e) => e.selected)
        .length
      const noEntriesSelectedOrDeselectingLastEntry =
        !selectedEntriesCount || (entry.selected && selectedEntriesCount === 1)

      return (
        noEntriesSelectedOrDeselectingLastEntry || selectedNeighboursCount === 1
      )
    }

    const toggle = (entry: Entry) => {
      const isContinuityInvalid =
        props.validateContinuity &&
        props.multiSelect &&
        !continuityValidator(entry)
      if (
        (!props.multiSelect &&
          props.selected.includes(entry.slot.timeSlotId)) ||
        isContinuityInvalid
      ) {
        return
      }

      const id = entry.slot.timeSlotId

      const newSelected = props.multiSelect
        ? toggleElem(props.selected, id, !entry.selected)
        : [entry.slot.timeSlotId]
      emit(entry.selected ? 'unselect' : 'select', id)
      emit('update:selected', newSelected)
    }

    return {
      entries,
      cssVars,
      toggle,
      ...useI18n(),
    }
  },
})
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(var(--columns), 1fr);
  width: 100%;

  @include breakpoint-down(xs) {
    grid-template-columns: repeat(min(3, var(--columns)), 1fr);
  }
}

.time-slot {
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  flex-direction: column;

  @include breakpoint-down(md) {
    padding: 1.5rem 0;
  }

  &.is-deselected {
    color: $comet;
  }
  &.new-selected {
    font-weight: 600;
    font-size: 15px;
  }
  &.new-deselected {
    text-decoration: line-through;
  }
  &.disabled:not(.selected) {
    pointer-events: none;
    background-color: $athens-gray;
    text-decoration: line-through;
  }
}
</style>
