<template>
  <Form
    id="form_reset"
    novalidate
    :validation-schema="resetSchema"
    @submit="onSubmit"
    #default="{ meta: formStatus }"
  >
    <AuthLayout :bgImage="require('@bd/assets/images/image-female-phone.jpg')">
      <template #navigation>
        <div class="d-none d-md-flex align-self-start">
          <Button
            @click="$router.back()"
            icon="pi pi-chevron-left"
            class="p-button-secondary p-button-circle"
          />
        </div>
      </template>
      <template #main>
        <div class="text-center">
          <div>
            <p class="h-1">
              {{ t('reset_password') }}
            </p>
            <p class="body-bg mt-4 mb-3 mb-md-5" style="padding: 0 25px 0 25px">
              {{ t('reset_password_email') }}
            </p>
          </div>
          <img src="@bd/assets/images/password-reset.svg" class="reset-image" />
        </div>
        <div class="auth-form">
          <AuthFormReset :showHint="showHint" />
        </div>
      </template>
      <template #footer>
        <div
          class="d-flex justify-content-center align-self-start align-items-end body-sm text-center"
        >
          <div v-if="showHint">
            <span class="d-none d-md-block">
              <p>{{ t('reset_email_question') }}</p>
              <p>{{ `${t('contact_helpline')} ${helpline}` }}</p>
            </span>
          </div>
          <Button
            :disabled="isLoading || !formStatus.valid"
            type="submit"
            :label="t('reset_password')"
            class="p-button-primary p-button-rounded w-100 d-md-none"
          />
        </div>
      </template>
    </AuthLayout>
  </Form>
</template>

<script lang="ts">
import { resetSchema } from '@bd/components/yup'
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'
import { defineComponent, computed } from 'vue'
import { Form, SubmissionHandler } from 'vee-validate'
import AuthFormReset from '../AuthForm/AuthFormReset.vue'
import AuthLayout from '../AuthLayout.vue'
import { authFormService, useBreakpoints } from '@bd/components'
import { PasswordResetRequestDto } from '@bd/api'
import { useAppStore } from '@bd/store-modules'
import { useRootData } from '@bd/components'
import { ToType } from '@bd/helpers'

export default defineComponent({
  name: 'AuthPasswordReset',
  components: { AuthLayout, AuthFormReset, Form },
  props: {
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit', 'reset'],
  setup(_, { emit }) {
    const i18n = useI18n()
    const store = useAppStore()
    const { helpline } = common
    const { isLoading } = useRootData()
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)

    const onSubmit: SubmissionHandler<ToType<PasswordResetRequestDto>> = async (
      values,
      actions,
    ) => {
      const payload: PasswordResetRequestDto = values
      try {
        await store.dispatch('auth/resetPassword', payload)
        emit('reset')
      } catch (err) {
        const errorsData = authFormService.handleError(
          store.state.auth?.error,
          isDesktopSize.value,
        )
        if (errorsData) {
          actions.setErrors(errorsData)
        }
      }
    }
    return {
      ...i18n,
      onSubmit,
      helpline,
      resetSchema,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
.reset-image {
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
