
import { defineComponent, PropType } from 'vue'
import CounterProposalListItem from './CounterProposalListItem.vue'
import { ProposalDto } from '@bd/components'

export default defineComponent({
  name: 'CounterProposalList',
  components: { CounterProposalListItem },
  props: {
    listData: {
      type: Object as PropType<ProposalDto>,
      required: true,
    },
  },
})
