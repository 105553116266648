import { TOAST_CONFIG } from '../../config'
import { i18n } from '@bd/components/i18n'
import { Toasts } from '../../types'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

export const ProposalMessages = Toasts({
  counterproposalSuccess: {
    severity: 'success',
    life: messageDuration.success,
    summary: t('proposal.counterproposal_made_success'),
  },
  counterproposalError: {
    severity: 'error',
    life: messageDuration.error,
    summary: t('proposal.counterproposal_made_error'),
  },
  proposalApproved: {
    severity: 'success',
    life: messageDuration.success,
    summary: t('proposal.proposal_approved'),
  },
})
