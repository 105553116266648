import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

const messages = () => {
  const locales: LocaleMessages<VueMessageType> = {}
  const context = require.context('./locales', true, /[a-z]{2}-[A-Z]{2}\.ts$/)
  context.keys().forEach((fileName) => {
    const [, locale] = fileName.match(/([a-z]{2}-[A-Z]{2})\.ts$/)!
    locales[locale] = context(fileName).default
  })
  return locales
}

export const locales = messages()

export const i18n = createI18n({
  locale: 'pl-PL',
  fallbackLocale: 'pl-PL',
  messages: locales,
})
