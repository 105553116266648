<template>
  <div class="p-field-checkbox">
    <Checkbox
      :id="name"
      v-model="inputValue"
      binary
      class="p-checkbox-primary mr-2"
    />
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import Checkbox from 'primevue/checkbox'

export default defineComponent({
  name: 'CheckboxInput',
  components: { Checkbox },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { value: inputValue, handleChange } = useField(props.name)

    return {
      inputValue,
      handleChange,
    }
  },
})
</script>

<style lang="scss" scoped></style>
