import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { ErrorResponse } from '@bd/api'

const t = i18n.global.t

export interface ErrorDataObject {
  [key: string]: string
}

const errorFieldResponses: Record<string, string> = {
  INVALID_UNIQUE_PHONE: t('validation.invalid_unique_phone'),
  INVALID_UNIQUE_EMAIL: t('validation.invalid_unique_email'),
}

export const clientValidationService = {
  handleValidation: function (
    e: ErrorResponse | undefined,
  ): ErrorDataObject | undefined {
    if (!e || !e.fieldErrors?.length) {
      return
    }

    const result: ErrorDataObject = {}
    e.fieldErrors.forEach((error) => {
      result[error.field] = errorFieldResponses[error.message]
    })
    return result
  },
}
