
import { Avatar, UserType } from '@bd/components'
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserAvatar, Svg } from '@bd/components'
import { ClientDto } from '@bd/api'

export default defineComponent({
  components: {
    UserAvatar,
    Svg,
  },
  props: {
    user: {
      type: Object as PropType<ClientDto>,
      required: true,
    },
    userType: {
      type: String as PropType<Avatar['userType']>,
      required: true,
      validator: (value: string) => value in UserType,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const label = computed(() => t(`userType.${props.userType.toLowerCase()}`))

    return {
      label,
    }
  },
})
