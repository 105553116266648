
import { defineComponent, PropType, computed } from 'vue'
import { NotificationType } from '@bd/api/common/types/notifications'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  props: {
    notificationType: {
      type: String as PropType<NotificationType>,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const isCalendarNotification = computed(() => {
      return props.notificationType === NotificationType.CALENDAR
    })

    const isProposalNotification = computed(() => {
      return props.notificationType === NotificationType.PURCHASE_OFFER
    })

    const initial = computed(() => {
      const translation = t(`notificationType.${props.notificationType}`)
      return translation.charAt(0).toUpperCase()
    })

    return {
      initial,
      isCalendarNotification,
      isProposalNotification,
    }
  },
})
