
import { defineComponent, PropType } from 'vue'
import MultiSelect from 'primevue/multiselect'
import { useI18n } from 'vue-i18n'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'SelectMulti',
  components: { MultiSelect },
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true,
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { value: inputValue, errorMessage } = useField(props.name)
    return { t, errorMessage, inputValue }
  },
})
