import {
  DateFormatConfig,
  RelativeTimeFormatConfig,
  TimeFormatConfig,
} from '../types'
import { i18n } from '@bd/components/../plugins/vue-i18n-next-plugin'
import { FilterData } from '@bd/store-modules/types'
import {
  ApartmentBuildingType,
  HouseBuildingType,
  OfficeBuildingType,
  PlotType,
  PropertyType,
} from '@bd/api'

export { default as InjectTokens } from './inject-tokens'

const { t } = i18n.global

export const ACTIVE_NAV = [
  { path: '/registration', text: 'create_account_1' },
  { path: '/password-reset', text: '' },
  { path: '/password-reset-confirmation', text: '' },
]

export const LOCALISATION_SETTINGS = {
  currency: 'PLN',
  currencyDecimal: 'zł',
  locale: 'pl-PL',
}

export const DATE_FORMAT = DateFormatConfig({
  calendar: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  },
  transaction: {
    month: 'short',
  },
  standardDate: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  offerStatisticsChart: {
    day: '2-digit',
    month: '2-digit',
  },
})

export const TIME_FORMAT = TimeFormatConfig({
  standardTime: {
    hour: '2-digit',
    minute: '2-digit',
  },
})

export const RELATIVE_TIME_FORMAT: RelativeTimeFormatConfig = {
  common: {
    numeric: 'auto',
  },
}

export const SELECTED_DETAILS_IN_OFFER = [
  'constructionYear',
  'propertyMarket',
  'rent',
  'parkingSpot',
  'basement',
  'elevator',
  'balcony',
  'garden',
  'security',
  'buildingType',
  'buildingMaterial',
  'buildingFinishState',
  'kitchenType',
  'heatingType',
  'apartmentLevels',
  'windowsType',
  'internet',
  'propertyOwnership',
] as const

export type ToastMessageSeverities = 'error' | 'success' | 'info' | 'warn'
export interface ToastConfig {
  zIndex: number
  /**
   * Default toast message durations/lifespan in milliseconds
   */
  messageDuration: Partial<{ [key in ToastMessageSeverities]: number }>
}

export const TOAST_CONFIG: ToastConfig = {
  zIndex: 1005,
  messageDuration: {
    error: 8000,
    success: 5000,
  },
}

// Debounce duration for calendar (WeekdayPicker) component navigation event
export const CALENDAR_NAVIGATION_DEBOUNCE = 500

export const CUSTOM_MEETING_NAME = t(
  'calendar.events.save_event.meeting_type.custom_name',
)

export const MEETING_OFFERS_PAGE_SIZE = 10
export const MEETING_ATTENDEES_PAGE_SIZE = 10
export const MEETING_SEARCHABLE_LIST_DEBOUNCE_MS = 500

export const NOTIFICATIONS_PAGE_SIZE = 10

export const MAX_UNREAD_NOTIFICATIONS = 9
export const NAVIGATION_BADGES_REFRESH_INTERVAL_MS = 1000 * 60 * 5

export const CITIES_PAGE_SIZE = 8

export const FILTERS: FilterData[] = [
  {
    name: t('offerDetails.property_type'),
    value: ['propertyType'],
    filterName: 'propertyType',
    options: [
      { name: 'APARTMENT', value: 'APARTMENT' },
      { name: 'HOUSE', value: 'HOUSE' },
      { name: 'PLOT', value: 'PLOT' },
      { name: 'OFFICE', value: 'OFFICE' },
    ],
    assets: [
      {
        name: t('apartment'),
        option: 'APARTMENT',
        icon: require('@bd/assets/icons/sofa.svg'),
      },
      {
        name: t('house'),
        option: 'HOUSE',
        icon: require('@bd/assets/icons/house.svg'),
      },
      {
        name: t('plot'),
        option: 'PLOT',
        icon: require('@bd/assets/icons/leaf.svg'),
      },
      {
        name: t('office'),
        option: 'OFFICE',
        icon: require('@bd/assets/icons/office.svg'),
      },
    ],
    isAdditional: false,
    type: 'list',
    position: 1,
  },
  {
    name: t('price'),
    filterName: 'price',
    value: ['priceFrom', 'priceTo'],
    isAdditional: false,
    type: 'range',
    position: 2,
  },
  {
    name: t('city'),
    filterName: 'city',
    isAdditional: false,
    value: ['cityIds'],
    type: 'list',
    position: 3,
  },
  {
    name: t('district'),
    filterName: 'district',
    isAdditional: false,
    value: ['districtIds'],
    type: 'list',
    position: 4,
  },
  {
    name: t('rooms_number'),
    filterName: 'rooms',
    value: ['roomsFrom', 'roomsTo'],
    isAdditional: true,
    type: 'range',
    position: 5,
  },
  {
    name: t('area'),
    filterName: 'area',
    value: ['houseAreaFrom', 'houseAreaTo'],
    isAdditional: true,
    type: 'range',
    position: 6,
  },
  {
    name: t('plot_area'),
    filterName: 'plotArea',
    value: ['landAreaFrom', 'landAreaTo'],
    isAdditional: true,
    type: 'range',
    position: 7,
  },
  {
    name: t('floor'),
    filterName: 'floor',
    value: ['floorFrom', 'floorTo'],
    isAdditional: true,
    type: 'range',
    position: 8,
  },
  {
    name: t('construction_year'),
    filterName: 'constructionYear',
    value: ['constructionYearFrom', 'constructionYearTo'],
    isAdditional: true,
    type: 'range',
    position: 9,
  },
]

export const BUILDING_TYPES = {
  [PropertyType.APARTMENT]: {
    translationKey: 'apartmentBuildingType',
    enumValues: ApartmentBuildingType,
  },
  [PropertyType.HOUSE]: {
    translationKey: 'houseBuildingType',
    enumValues: HouseBuildingType,
  },
  [PropertyType.PLOT]: {
    translationKey: 'plotType',
    enumValues: PlotType,
  },
  [PropertyType.OFFICE]: {
    translationKey: 'officeBuildingType',
    enumValues: OfficeBuildingType,
  },
}

export const numericPlaceholder = '-'
