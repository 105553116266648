<template>
  <li class="navigation-item" :class="computedClass" @click="onItemClick(item)">
    <div class="item-wrapper">
      <!-- Multi-level dropdown navigation item -->
      <DropdownButton
        v-if="item.children && item.children.length"
        v-model:isOpen="navigationDropdownOpen"
        contentClass="navigation-dropdown-content"
      >
        <button class="navigation-label dropdown-toggle">
          <span class="dropdown-label text-truncate">{{ item.label }}</span>
          <img src="@bd/assets/icons/dropdown-small.svg" />
        </button>

        <template #content>
          <ul class="nested-navigation-list">
            <TopNavigationItem
              v-for="(child, index) of item.children"
              :key="index"
              :item="child"
            />
          </ul>
        </template>
      </DropdownButton>

      <!-- Single-level -->
      <label
        v-if="(item.label || item.icon) && !item.children"
        class="navigation-label"
      >
        <span v-if="item.label" class="text-truncate">{{ item.label }}</span>
        <Svg
          v-if="item.icon && !item.label"
          class="navigation-item-icon"
          :src="item.icon.src"
          :style="itemIconStyle"
        />
        <span class="badge" v-if="badgeValue">
          {{ badgeValue }}
        </span>
      </label>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import { NavigationItem } from '../types'
import { useNavigationItemIconStyle } from '../utils'
import { DropdownButton, useBadgeValue } from '@bd/components'
import { useRouter } from 'vue-router'
import { Svg } from '@bd/components'

export default defineComponent({
  components: {
    DropdownButton,
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
      validator: (item: NavigationItem) => !!(item.label || item.icon),
    },
  },
  setup(props) {
    const router = useRouter()
    const navigationDropdownOpen = ref(false)

    const computedClass = computed(() => {
      const { item } = props
      return {
        'with-badge': item.badge,
        text: item.label,
        icon: !item.label && item.icon,
        'dropdown-active': navigationDropdownOpen.value,
        dropdown: item.children,
        active: item.active || item.children?.some((child) => child.active),
        disabled: item.disabled,
      }
    })

    const itemIconStyle = useNavigationItemIconStyle(props.item)

    const badge = computed(() => props.item.badge)
    const badgeValue = useBadgeValue(badge)

    const onItemClick = (item: NavigationItem) => {
      if (item.action) {
        item.action()
      } else if (item.path) {
        router.push({ path: item.path })
      }
    }

    return {
      navigationDropdownOpen,
      computedClass,
      itemIconStyle,
      onItemClick,
      badgeValue,
    }
  },
})
</script>

<style lang="scss" scoped>
$badge-size: 18px;
$gutter-size: 1rem;
$dropdown-border-radius: 7px;

.navigation-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  height: 30px;
  padding: 0.25rem #{$gutter-size};
  cursor: pointer;
  border-radius: 6px;

  &.with-badge {
    padding-right: calc(#{$gutter-size} + (#{$badge-size} / 2));
  }

  &.dropdown {
    padding: 0;
    .dropdown-label {
      max-width: 140px;
      margin-right: 1rem;
    }
  }

  &.active {
    color: $primary-color;
    .navigation-item-icon {
      color: $primary-color;
    }
    .navigation-item:not(.active) {
      color: $port-gore;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  .badge {
    @include navigation-badge($badge-size);
  }

  &.icon .badge {
    left: calc(100% - 4px);
  }

  .item-wrapper {
    height: 100%;
  }

  .navigation-label {
    color: inherit;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    outline: none;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: inherit;
    background-color: inherit;
  }

  .navigation-item-icon {
    max-width: 24px;
    max-height: 24px;
    color: $blue-haze;
  }

  &:hover,
  &.dropdown-active {
    background-color: $alabaster;
  }
}
.nested-navigation-list {
  width: 100%;
  border-radius: $dropdown-border-radius;
  box-shadow: 0 0 20px 0 $light-shadow;

  .navigation-item {
    border-radius: 0;
    padding: 0 1rem;
    height: 50px;
    background-color: $white;
    min-width: 120px;
    transition: background-color 0.1s ease-in-out;

    &:first-of-type {
      border-top-left-radius: $dropdown-border-radius;
      border-top-right-radius: $dropdown-border-radius;
    }

    &:last-of-type {
      border-bottom-left-radius: $dropdown-border-radius;
      border-bottom-right-radius: $dropdown-border-radius;
    }

    &:not(:last-of-type) .item-wrapper {
      border-bottom: 1px solid rgba($athens-gray, 0.5);
    }

    .item-wrapper {
      padding: 1rem 0;
      margin: 0;
      height: 100%;
      width: 100%;
    }

    .navigation-label {
      font-weight: normal;
      transition: background-color 0.1s ease-in-out;
    }

    &.active .navigation-label {
      font-weight: 600;
    }

    &:hover {
      background-color: $alabaster;
    }
  }
}
.item-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dropdown-toggle {
  padding: 0;
}
:deep(.navigation-dropdown-content) {
  width: auto !important;
  left: 50%;
  transform: translate(-50%);
}
:deep(.dropdown-wrapper) {
  padding: 0.25rem #{$gutter-size};
  height: 100%;
}
</style>
