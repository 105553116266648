
import { UserCalendarEventDto } from '@bd/api'
import { TimeSlotDto } from '@bd/api/common/types/time-slot'
import { LocalIsoDate } from '@bd/helpers'
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserType } from '../index'
import CalendarDateTitle from './CalendarDateTitle.vue'
import CalendarEventsListModal from './EventsList/CalendarEventsListModal.vue'
import MeetingsCalendar from './MeetingsCalendar.vue'
import MonthCalendar from './MonthCalendar.vue'
import { useAppStore } from '@bd/store-modules'

export default defineComponent({
  components: {
    CalendarDateTitle,
    MeetingsCalendar,
    MonthCalendar,
    CalendarEventsListModal,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    navigatedDate: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlotDto[]>,
      required: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: [
    'update:selectedDay',
    'update:navigatedDate',
    'eventTileClick',
    'addEventClick',
    'editAvailabilityClick',
  ],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const eventsListModalVisible = ref(true)
    const overlappingEvents = ref<UserCalendarEventDto[] | null>(null)
    const isAgent = computed(() => props.userType === UserType.Agent)
    const isBuyer = computed(() => props.userType === UserType.Buyer)

    const selectedDayChange = (day: LocalIsoDate) => {
      emit('update:selectedDay', day)
    }

    const navigatedDateChange = (date: LocalIsoDate) => {
      emit('update:navigatedDate', date)
    }

    const addNewEvent = () => {
      emit('addEventClick')
      store.dispatch('calendarEvent/setSelectedDate', {
        date: props.selectedDay,
        keepDate: true,
      })
    }

    const editAgentAvailability = () => {
      emit('editAvailabilityClick')
    }

    const singleEventClicked = (event: UserCalendarEventDto) => {
      emit('eventTileClick', event)
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      if (events.length > 1) {
        overlappingEvents.value = events
        eventsListModalVisible.value = true
        return
      }
      singleEventClicked(events[0])
    }

    return {
      t,
      selectedDayChange,
      navigatedDateChange,
      addNewEvent,
      editAgentAvailability,
      eventTileClicked,
      singleEventClicked,
      eventsListModalVisible,
      overlappingEvents,
      isAgent,
      isBuyer,
    }
  },
})
