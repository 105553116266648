<template>
  <iframe
    width="100%"
    loading="lazy"
    allowfullscreen
    :src="mapUrl"
    class="offer-map-frame"
  />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'

export default defineComponent({
  name: 'DashboardOfferMap',
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
  },
  setup(props) {
    const mapUrl = computed(() => {
      const KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
      const { latitude: lat, longitude: long } = props.offerDetails || {}
      const query = `key=${KEY}&q=${lat},${long}`
      return `https://www.google.com/maps/embed/v1/place?${query}`
    })

    return { mapUrl }
  },
})
</script>

<style lang="scss" scoped>
.offer-map-frame {
  height: 450px;
  border: 0;
  border-radius: 6px;

  @include breakpoint-down(md) {
    height: 140px;
  }
}
</style>
