import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@bd/assets/styles/reset.scss'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@bd/assets/styles/bootstrap-grid.css'
import '@bd/assets/styles/transitions.css'
import '@bd/assets/styles/helpers.scss'
import '@bd/assets/styles/overrides.scss'
import '@bd/assets/styles/typography.scss'
import '@bd/assets/styles/immoby-theme.scss'

import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import AutoComplete from 'primevue/autocomplete'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'

import * as SW from '../../common/api/src/firebase-sw'

import { useUnauthorizedInterceptor } from '@bd/components'

const initFirebaseSw = () => {
  if (!('serviceWorker' in navigator)) {
    console.warn('Service worker is not supported')
    return
  }
  SW.init()
}

initFirebaseSw()
useUnauthorizedInterceptor(store, router)

createApp(App)
  .use(PrimeVue)
  .component('InputText', InputText)
  .component('Button', Button)
  .component('AutoComplete', AutoComplete)
  .directive('tooltip', Tooltip)
  .use(ToastService)
  .use(store)
  .use(i18n)
  .use(router)
  .mount('#app')
