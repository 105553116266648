
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'DashboardCarousel',
  props: ['items'],
  components: {
    Carousel,
    Slide,
  },
  setup(props) {
    return { ...props }
  },
})
