<template>
  <NotificationsList
    :notifications="notifications"
    :isChunkLoading="isLoadingNotificationsChunk"
    :isFirstTimeLoading="isLoadingNotificationsFirstTime"
    @bottomReach="onBottomReach"
    @notificationClick="onNotificationClick"
  />
</template>

<script lang="ts">
import {
  NotificationType,
  SystemNotificationDto,
} from '@bd/api/common/types/notifications'
import { useAppStore } from '@bd/store-modules'
import {
  isLoadingChunk,
  isLoadingFirstTime,
} from '@bd/store-modules/modules/notifications/getters-helpers'
import { useToast } from 'primevue/usetoast'
import { NOTIFICATIONS_PAGE_SIZE } from '@bd/components'
import { computed, defineComponent, onMounted } from 'vue'
import NotificationsList from './NotificationsList/NotificationsList.vue'
import { NotificationToastMessage } from './utils'

export default defineComponent({
  components: { NotificationsList },
  emits: [
    'listBottomReach',
    'notificationClick',
    'calendarNotificationClick',
    'proposalNotificationClick',
  ],
  setup(_, { emit }) {
    const store = useAppStore()
    const toast = useToast()

    const notifications = computed(
      () => store.state.notifications?.notifications,
    )

    const isLoadingNotificationsFirstTime = computed(() =>
      isLoadingFirstTime(store.state.notifications),
    )
    const isLoadingNotificationsChunk = computed(() =>
      isLoadingChunk(store.state.notifications),
    )

    const fetchNotifications = () => {
      store.dispatch(
        'notifications/getSystemNotifications',
        NOTIFICATIONS_PAGE_SIZE,
      )
    }

    const onBottomReach = () => {
      emit('listBottomReach')
      fetchNotifications()
    }

    const markAsSeen = async (notificationId: number) => {
      try {
        await store.dispatch(
          'notifications/markNotificationAsSeen',
          notificationId,
        )
      } catch (err) {
        toast.add(NotificationToastMessage.markSeenError)
      }
    }

    const getUnreadNotificationsCount = async () => {
      await store.dispatch('notifications/getUnreadNotificationsCount')
    }

    const onNotificationClick = async (notification: SystemNotificationDto) => {
      if (!notification.seen) {
        await markAsSeen(notification.id)
        getUnreadNotificationsCount()
      }
      emit('notificationClick', notification)
      if (notification.notificationType === NotificationType.CALENDAR) {
        if (!notification.navigationEnabled) {
          toast.removeAllGroups()
          toast.add(NotificationToastMessage.eventExpired)
        }
        emit('calendarNotificationClick', notification)
      } else {
        emit('proposalNotificationClick', notification)
      }
    }

    onMounted(async () => {
      await store.dispatch('notifications/clearNotifications')
      fetchNotifications()
    })

    return {
      notifications,
      isLoadingNotificationsFirstTime,
      isLoadingNotificationsChunk,
      onBottomReach,
      onNotificationClick,
    }
  },
})
</script>

<style lang="scss" scoped></style>
