import { ApiResponse, http, ResultPageDto, SimpleResponse, TimeSlot } from '@bd/api/common'
import {
  AdminCustomMeetingDto,
  AdminCustomMeetingTypeTimeSlotsParams,
  AdminEditCustomMeetingDto,
  AgentCustomMeetingDto,
  AgentPredefinedMeetingDto,
  CustomMeetingTypeTimeSlotsParams,
  EditCustomMeetingDto,
  EditPredefinedMeetingDto,
  MeetingOfferDto,
  MeetingOfferSearchParams,
  PredefinedMeetingTypeTimeSlotsParams,
} from './types/calendar-event'

const eventEndpoint = '/user-calendar-event'

export const createPredefinedMeeting = (
  payload: AgentPredefinedMeetingDto,
): ApiResponse<SimpleResponse<number>> => {
  return http.post(`${eventEndpoint}/predefined-meeting`, payload)
}
export const createCustomMeeting = (
  payload: AgentCustomMeetingDto | AdminCustomMeetingDto,
): ApiResponse<SimpleResponse<number>> => {
  return http.post(`${eventEndpoint}/custom-meeting`, payload)
}

export const updatePredefinedMeeting = (
  eventId: number,
  payload: EditPredefinedMeetingDto,
): ApiResponse<SimpleResponse<number>> => {
  return http.put(
    `${eventEndpoint}/${eventId}/predefined-meeting/edition`,
    payload,
  )
}

export const updateCustomMeeting = (
  eventId: number,
  payload: EditCustomMeetingDto | AdminEditCustomMeetingDto,
): ApiResponse<SimpleResponse<number>> => {
  return http.put(`${eventEndpoint}/${eventId}/custom-meeting/edition`, payload)
}

export const getPredefinedMeetingTypeTimeSlots = (
  params: PredefinedMeetingTypeTimeSlotsParams,
): ApiResponse<TimeSlot.TimeSlotDto[]> => {
  return http.get(
    `${eventEndpoint}/meeting-type/predefined/available-time-slots`,
    {
      params,
    },
  )
}

export const getCustomMeetingTypeTimeSlots = (
  params: CustomMeetingTypeTimeSlotsParams | AdminCustomMeetingTypeTimeSlotsParams,
): ApiResponse<TimeSlot.TimeSlotDto[]> => {
  return http.get(`${eventEndpoint}/meeting-type/custom/available-time-slots`, {
    params,
  })
}

export const getMeetingOffersByAddress = (
  params: MeetingOfferSearchParams,
): ApiResponse<ResultPageDto<MeetingOfferDto[]>> => {
  return http.get('/offers-by-address', {
    params: {
      ...params,
    },
  })
}
