<template>
  <Dialog
    maximizable
    class="profile-card-modal"
    :class="{ 'p-dialog-maximized': mobileSize }"
    header="&nbsp;"
    :showHeader="true"
    modal
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <h1 class="header h-1">{{ t('negotiation_details') }}</h1>

    <div class="dialog-content">
      <div class="header-content">
        <ProfileCardChip :status="transaction.status" />
        <ProfileCardDate :date="transaction.date" />
      </div>
      <h2 class="h-2 my-1">
        {{ getFormattedPrice(transaction.price, currency, locale) }}
      </h2>
      <div class="inner-content my-2">
        <p>{{ t('street_prefix') }} {{ transaction.offerDto.street }}</p>
        <p>
          {{ cityAndDistrict.city
          }}{{
            cityAndDistrict.district ? `, ${cityAndDistrict.district}` : ''
          }}
        </p>
        <p class="my-2 caption">
          {{ transaction.offerDto.id }}
        </p>
      </div>
    </div>
    <div class="progress-container" v-if="isLoading">
      <ProgressSpinner />
    </div>
    <ProfileCardDetail
      v-for="(detail, index) in computedPurchasedOffers"
      :key="detail.offerId"
      :detail="detail"
      :is-first="computedPurchasedOffers.length === index + 1"
    />
    <div class="text-center">
      <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useBreakpoints, useResourceDataState } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'
import { PurchaseOfferDto, TransactionHistoryDetailsDto } from '@bd/api'
import ProfileCardDetail from './ProfileCardDetail.vue'
import ProfileCardDate from './ProfileCardDate.vue'
import ProfileCardChip from './ProfileCardChip.vue'

export default defineComponent({
  name: 'ProfileCardModal',
  components: {
    Dialog,
    ProfileCardDetail,
    ProfileCardDate,
    ProfileCardChip,
    ProgressSpinner,
  },
  props: {
    visible: Boolean,
    purchasedOffers: {
      type: Array as PropType<PurchaseOfferDto[]>,
      required: true,
    },
    transaction: {
      type: Object as PropType<TransactionHistoryDetailsDto>,
      required: true,
    },
    errorMessage: {
      type: String as PropType<string | undefined>,
    },
  },
  emits: ['update:visible'],
  setup(props) {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { currency, locale } = LOCALISATION_SETTINGS

    const cityAndDistrict = computed(() => {
      const address = props.transaction.offerDto
      return {
        city: address.cityDto.cityName,
        district: address.districtDto?.districtName,
      }
    })
    const computedPurchasedOffers = computed(() => {
      return props.purchasedOffers
    })
    const { isEmpty, isLoading } = useResourceDataState(
      computedPurchasedOffers,
      (res) => !!(res && res.length),
    )

    return {
      t,
      mobileSize,
      cityAndDistrict,
      getFormattedPrice,
      currency,
      locale,
      isEmpty,
      isLoading,
      computedPurchasedOffers,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 53px;
}
.dialog-content {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  border-bottom: $divider-border;
}

:deep(.profile-card-modal) {
  border-radius: 6px;
  width: min(100vw, 488px);
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0 77px 40px;
    @include breakpoint-down(xs) {
      padding: 0 25px 20px;
    }
  }
}

.chip-accept {
  color: $jungle-green;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 14px;
  background: rgba($jungle-green, 0.2);
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 50px;
}
</style>
