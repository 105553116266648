import {
  CalendarEventAddress,
  CalendarEventDateTime,
  CalendarEventDto,
  CalendarEventStatus,
  EventDetailsActionFlags,
  MeetingTypeDto,
  UserDto,
  UserRole,
} from '@bd/api'
import {
  CUSTOM_MEETING_NAME,
  DATE_FORMAT,
  EventDuration,
  TIME_FORMAT,
} from '@bd/components'
import {
  getDatePart,
  getLocalIsoDate,
  LocalIsoDate,
  LocalTime,
  times,
} from '@bd/helpers'
import { addMinutes } from 'date-fns'
import { computed, ComputedRef, ref, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import { useAppStore } from '@bd/store-modules'

export type DatePart = { [key in Intl.DateTimeFormatPartTypes]: string }

export const useFormattedDate = (selectedDay: Ref<LocalIsoDate>) => {
  const { locale } = useI18n()
  return computed<Pick<DatePart, 'day' | 'month' | 'year'>>(() => {
    /* Use 2 separate formatters to avoid certain translation happening:
     * When using collective day, month, year formatting for polish locale -
     * - the output is for example: '8 marca 2021' but the expected result should be
     * '8 marzec 2021'.
     * Using separate formatter for the day part makes sure that the month name is in the expected format
     */
    const calendarDateFormat = DATE_FORMAT.calendar
    const dayFormatter = new Intl.DateTimeFormat(locale.value, {
      day: calendarDateFormat.day,
    })
    const formatter = new Intl.DateTimeFormat(locale.value, {
      month: calendarDateFormat.month,
      year: calendarDateFormat.year,
    })

    const today = new Date(selectedDay.value)
    const formattedDateParts = formatter.formatToParts(today)
    return {
      day: dayFormatter.format(today),
      month: getDatePart(formattedDateParts, 'month'),
      year: getDatePart(formattedDateParts, 'year'),
    }
  })
}

// Generate time steps from start to end of the timeline, to display current time indicator
export const useTimeSteps = (startTime: LocalTime, totalMinutes: number) =>
  computed<Date[]>(() => {
    const now = new Date()
    return times(totalMinutes, (idx) => {
      const { hours, minutes, seconds } = startTime
      now.setHours(hours, minutes, seconds)
      const newTime = addMinutes(now, idx)
      return newTime
    })
  })

export const getCurrentTimeStep = (timeSteps: Date[]) => {
  const now = new Date()
  const foundTimeStep = timeSteps.findIndex(
    (step) =>
      step.getHours() === now.getHours() &&
      step.getMinutes() === now.getMinutes(),
  )
  return foundTimeStep >= 0 ? foundTimeStep : null
}

export const useCalendarLocalState = (
  initialValue: LocalIsoDate = getLocalIsoDate(new Date()),
) => {
  const selectedDay = ref(initialValue)
  const previouslySelectedDay = ref(selectedDay.value)
  const navigatedDate = ref(selectedDay.value)

  const onSelectedDayChange = (day: LocalIsoDate) => {
    if (previouslySelectedDay.value === day) {
      return false
    }
    navigatedDate.value = day
    previouslySelectedDay.value = day
    return true
  }

  return {
    selectedDay,
    navigatedDate,
    onSelectedDayChange,
  }
}

export const useEventDuration = (
  eventDateTime: Ref<CalendarEventDateTime>,
  user: Ref<UserDto | undefined>,
): ComputedRef<EventDuration> => {
  const { locale } = useI18n()
  return computed(() => {
    const { date, startTime, clientEndTime, agentEndTime } = eventDateTime.value
    const timeFormatter = new Intl.DateTimeFormat(
      locale.value,
      TIME_FORMAT.standardTime,
    )

    const startDate = new Date(`${date}T${startTime}`)
    const endTime = user.value?.roles.includes(UserRole.CUSTOMER)
      ? clientEndTime
      : agentEndTime
    const endDate = new Date(`${date}T${endTime}`)
    return {
      start: timeFormatter.format(startDate),
      end: timeFormatter.format(endDate),
    }
  })
}

export const getOfferAddress = (address: CalendarEventAddress) => {
  return {
    ...address,
    houseNumber:
      address.buildingNumber && address.houseNumber
        ? `/${address.houseNumber}`
        : '',
  }
}

export const useEventAddress = (
  eventAddress: Ref<CalendarEventAddress | undefined>,
): ComputedRef<CalendarEventAddress | null> =>
  computed(() =>
    eventAddress.value ? getOfferAddress(eventAddress.value) : null,
  )

export const useEventStatusClass = (
  event: Ref<CalendarEventDto>,
  hasMultipleEvents?: Ref<boolean>,
) =>
  computed(() => {
    const eventStatus = event.value.calendarEventStatus
    // Multiple events class should take precedence before any other event status-related visual classes
    if (hasMultipleEvents?.value) {
      return 'multiple'
    }
    return {
      'pending-agent':
        eventStatus === CalendarEventStatus.WAITING_FOR_AGENT_ACCEPTANCE,
      'pending-seller':
        eventStatus === CalendarEventStatus.WAITING_FOR_SELLER_ACCEPTANCE,
      accepted:
        eventStatus !== CalendarEventStatus.WAITING_FOR_AGENT_ACCEPTANCE &&
        eventStatus !== CalendarEventStatus.WAITING_FOR_SELLER_ACCEPTANCE,
    }
  })

export type EventActionPossibility =
  | 'any'
  | 'anyButEdit'
  | 'edit'
  | 'noneExcludingEdit'
export type EventActionPosibilities = {
  [key in EventActionPossibility]: boolean
}

export const usePossibleEventActions = (
  eventDto: Ref<EventDetailsActionFlags | undefined>,
): ComputedRef<EventActionPosibilities> =>
  computed(() => {
    const value = eventDto.value
    const anyButEdit = !!(
      value?.cancelationPossibility ||
      value?.rejectionPossibility ||
      value?.acceptancePossibility
    )
    return {
      any: anyButEdit || !!value?.editionPossibility,
      anyButEdit,
      noneExcludingEdit: !anyButEdit,
      edit: !!value?.editionPossibility,
    }
  })

export const formatDuration = (durationMinutes: number): string => {
  const hours = Math.floor(durationMinutes / 60)
  const minutes = durationMinutes % 60
  return `${hours}${
    minutes !== 0 ? ':' + minutes.toString().padStart(2, '0') : ''
  }h`
}

const buildCustomMeetingTypeEntry = (): CalendarEventMeetingType => {
  return {
    name: CUSTOM_MEETING_NAME,
    isCustom: true,
  }
}

/**
 * Maps API-fetched predefined meeting types to component & store compatible data structure
 * @param predefinedMeetingTypes API-fetched predefined meeting types
 * @returns Mapped meeting types, including one custom meeting type entry
 */
export const useMappedMeetingTypes = (
  predefinedMeetingTypes: Ref<MeetingTypeDto[] | undefined>,
): ComputedRef<CalendarEventMeetingType[]> => {
  const store = useAppStore()
  const userRoles = store.state.userProfile?.user.roles
  const isAgentOrAdmin = computed(() =>
    (userRoles ?? []).some((role) =>
      [UserRole.ADMIN, UserRole.AGENT].includes(role),
    ),
  )

  return computed(() => {
    const predefined = (predefinedMeetingTypes.value ?? []).map((type) => {
      return {
        predefinedId: type.meetingTypeId,
        name: type.meetingTypeName,
        agentDurationMinutes: type.agentDurationMinutes,
        customerPresenceRequired: type.customerPresenceRequired,
        isCustom: false,
      }
    })
    if (isAgentOrAdmin.value) {
      return [...predefined, buildCustomMeetingTypeEntry()]
    }
    return predefined
  })
}
