<template>
  <Dialog
    maximizable
    class="dialog-appointment"
    header="&nbsp;"
    :class="{ 'p-dialog-maximized': mobileSize }"
    :showHeader="true"
    :modal="true"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="content-wrapper">
      <div class="picker-container">
        <div class="header-container">
          <h1 class="header h-1">{{ header }}</h1>
          <WeekdayPicker
            class="weekday-picker"
            mode="date"
            :selected="[selectedDay]"
            :disablePastDates="true"
            :disabled="submitPending"
            @select="selectDay"
          />
        </div>

        <div class="time-slots-container">
          <TimeSlotPicker
            v-if="!isLoading && timeSlots && !submitPending"
            class="time-slot-picker"
            :slots="timeSlots"
            :selected="selectedTimeSlots"
            @select="selectTimeSlot"
            @unselect="unselectTimeSlot"
          />
          <div
            class="progress-container"
            v-if="isEmpty || isLoading || submitPending"
          >
            <ProgressSpinner v-if="isLoading || submitPending" />
            <EmptyState
              v-if="isEmpty"
              :title="t('appointment.empty_state.title')"
            />
          </div>
        </div>
      </div>

      <div class="submit-button-container flex-centered">
        <Button
          :class="[
            'submit-button p-button-rounded',
            { 'p-button-primary': canSubmit },
          ]"
          :disabled="!canSubmit"
          @click="submitClick"
        >
          {{ t('submit_appointment') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { CustomerMeetingInvitationDto } from '@bd/api/client-api/types'
import { TimeSlotID } from '@bd/api/common/types/time-slot'
import {
  TimeSlotPicker,
  useBreakpoints,
  useLoadableResourceDataState,
  WeekdayPicker,
  WeekdaySelectType,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { ID, LoadableOptional, LocalIsoDate } from '@bd/helpers'
import { TimeSlot } from '@itcraft-bestdeal/api'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
    TimeSlotPicker,
    WeekdayPicker,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    visible: Boolean,
    availableTimeSlots: {
      type: Object as PropType<LoadableOptional<TimeSlot.TimeSlotDto[]>>,
      required: true,
    },
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    submitPending: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'update:selectedDay', 'submitAppointment'],
  setup(props, { emit }) {
    const { locale, t } = useI18n()
    const selectedTimeSlots = ref([] as TimeSlotID[])
    const { mobileSize } = useBreakpoints()

    const {
      isLoading,
      isEmpty,
      resource: timeSlots,
    } = useLoadableResourceDataState(
      computed(() => props.availableTimeSlots.payload),
      (res) => !!res?.content.length,
    )

    const deselectAllTimeSlots = () => {
      selectedTimeSlots.value = []
    }

    const selectTimeSlot = (id: ID<'TimeSlot'>) => {
      selectedTimeSlots.value = [id]
    }

    const unselectTimeSlot = () => {
      deselectAllTimeSlots()
    }

    const selectDay = (day: LocalIsoDate, selectType: WeekdaySelectType) => {
      emit('update:selectedDay', day, selectType)
      deselectAllTimeSlots()
    }

    const header = computed(() => {
      const day = props.selectedDay
      const formatter = new Intl.DateTimeFormat(locale.value, {
        month: 'long',
        year: 'numeric',
      })

      const str = formatter.format(new Date(day))
      return str.charAt(0).toUpperCase() + str.slice(1)
    })

    const submitClick = () => {
      const data: CustomerMeetingInvitationDto = {
        timeSlotId: selectedTimeSlots.value[0],
        date: props.selectedDay,
      }

      emit('submitAppointment', data)
    }

    const canSubmit = computed(
      () =>
        !props.submitPending &&
        selectedTimeSlots.value &&
        selectedTimeSlots.value.length &&
        !isLoading.value,
    )

    return {
      t,
      header,
      timeSlots,
      selectDay,
      selectedTimeSlots,
      selectTimeSlot,
      unselectTimeSlot,
      mobileSize,
      submitClick,
      isLoading,
      isEmpty,
      canSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 2rem;
}

:deep(.dialog-appointment) {
  width: min(100vw, 488px);
  @include breakpoint-up(md) {
    @include dialog-border-radius(6px);
  }
  .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
  .p-dialog-footer {
    @include breakpoint-up(md) {
      padding: 0 77px 40px;
    }
    button {
      width: 100%;
    }
  }
}

.content-wrapper {
  height: 100%;
  display: flex;
  padding: 1rem 2rem 2rem 2rem;
  flex-direction: column;
  overflow-y: auto;
  @include breakpoint-down(md) {
    padding: 0;
  }
}
.picker-container {
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.time-slots-container {
  overflow-y: auto;
  padding: 0.25rem 1rem;
  height: 300px;
  @include breakpoint-down(md) {
    height: 100%;
  }
}

.submit-button-container {
  margin-top: 1rem;
  padding: 0 1rem;
  @include breakpoint-down(md) {
    margin-top: auto;
    padding: 1rem;
  }

  .submit-button {
    height: 50px;
    justify-content: center;
    width: 100%;
  }
}
.header-container {
  padding: 1rem;
}
</style>
