
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import Textarea from 'primevue/textarea'

export default defineComponent({
  components: { Textarea },
  props: {
    rows: {
      type: String,
      default: '5',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    horizontalResize: {
      type: Boolean,
      default: true,
    },
    verticalResize: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleBlur } = useField<string>(
      props.name,
    )

    return {
      handleBlur,
      errorMessage,
      inputValue,
    }
  },
})
