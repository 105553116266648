
import { defineComponent, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import { UserCalendarEventDto } from '@bd/api'
import CalendarEventListItem from './CalendarEventListItem.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventListItem,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
  },
  emits: ['update:visible', 'eventClicked'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
