<template>
  <li
    class="navigation-item flex-centered"
    :class="{ active: item.active }"
    @click="onItemClick(item)"
  >
    <div class="item-wrapper">
      <Svg
        v-if="item.icon"
        class="navigation-item-icon"
        :style="itemIconStyle"
        :src="item.icon.src"
      />
      <span class="badge" v-if="badgeValue">
        {{ badgeValue }}
      </span>
    </div>
  </li>
</template>

<script lang="ts">
import { Svg, useBadgeValue } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { ChildlessNavigationItem } from '../types'
import { useNavigationItemIconStyle } from '../utils'

export default defineComponent({
  components: {
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<ChildlessNavigationItem>,
      required: true,
      validator: (item: ChildlessNavigationItem) => !!(item.icon && item.path),
    },
  },
  setup(props) {
    const router = useRouter()

    const onItemClick = (item: ChildlessNavigationItem) => {
      if (!item.path) {
        return
      }
      router.push({ path: item.path })
    }

    const itemIconStyle = useNavigationItemIconStyle(props.item)

    const badge = computed(() => props.item.badge)
    const badgeValue = useBadgeValue(badge)

    return {
      onItemClick,
      itemIconStyle,
      badgeValue,
    }
  },
})
</script>

<style lang="scss" scoped>
$badge-size: 22px;

.navigation-item {
  flex: 1 0 auto;
  height: 100%;
  cursor: pointer;
  .navigation-item-icon {
    max-width: 24px;
    max-height: 24px;
    color: $blue-haze;
  }
  &.active {
    .navigation-item-icon {
      color: $primary-color;
    }
  }

  .item-wrapper {
    position: relative;
    .badge {
      @include navigation-badge($badge-size);
      left: 90%;
    }
  }
}
</style>
