
import { defineComponent, PropType, computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { EventActionType } from '../types'
import { EventDetailsActionFlags } from '@bd/api/common'
import { useBreakpoints } from '@bd/components'

export default defineComponent({
  components: {},
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    actionFlags: {
      type: Object as PropType<EventDetailsActionFlags>,
      required: true,
    },
  },
  emits: ['action'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { actionFlags } = toRefs(props)

    const { mobileSize } = useBreakpoints()

    const cancelKey = computed(() => {
      return mobileSize.value ? 'cancel_full' : 'cancel.title'
    })

    const rejectOrCancelLabel = computed(() =>
      t(
        `calendar.event_details.${
          actionFlags.value.rejectionPossibility
            ? 'reject.title'
            : cancelKey.value
        }`,
      ),
    )

    const onRejectOrCancel = () => {
      emit(
        'action',
        props.actionFlags.rejectionPossibility
          ? EventActionType.Reject
          : EventActionType.Cancel,
      )
    }

    return {
      t,
      EventActionType,
      cancelKey,
      rejectOrCancelLabel,
      onRejectOrCancel,
    }
  },
})
