<template>
  <div class="meetings-timeline h-100 px-3" ref="meetingsTimelineRef">
    <div
      class="now-indicator"
      v-if="showNowIndicator && nowIndicatorPosTop != null"
      ref="nowIndicatorRef"
      :style="{ '--currentIndicatorPos': nowIndicatorPosTop }"
    ></div>
    <div class="time-slots-list">
      <div
        v-for="timeSlot of timeSlotEntries"
        :key="timeSlot.timeSlotId"
        :style="{ '--time-slot-duration': timeSlot.durationMinutes }"
        class="time-slot"
      >
        <div class="time-col">
          {{ timeSlot.timeLabel }}
        </div>
        <transition name="event-fade">
          <div
            class="event-col"
            v-if="timeSlot.events && timeSlot.events.length"
          >
            <CalendarEventTile
              :events="timeSlot.events"
              :isFirstThisDay="firstEventId === timeSlot.timeSlotId"
              @click="eventTileClicked(timeSlot.events)"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarEventStatus, TimeSlot, UserCalendarEventDto } from '@bd/api'
import { minutes } from '@bd/api/common/types/time-slot'
import { parseLocalIsoTime, stringifyLocalTime } from '@bd/helpers'
import { useIntervalFn } from '@vueuse/core'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { getCurrentTimeStep, useTimeSteps } from '../use'
import CalendarEventTile from './CalendarEventTile.vue'
import { TemplateRef } from '@bd/helpers'

const NOW_INDICATOR_UPDATE_TICK = 60 * 1000

interface TimeSlotEntry extends TimeSlot.TimeSlotDto {
  timeLabel: string
  durationMinutes: number
  events: UserCalendarEventDto[]
}

export default defineComponent({
  components: {
    CalendarEventTile,
  },
  props: {
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    showNowIndicator: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['eventTileClick'],
  setup(props, { emit }) {
    const meetingsTimelineRef = ref<TemplateRef>(null)
    const nowIndicatorRef = ref<TemplateRef>(null)
    const nowIndicatorPosTop = ref<number | null>(
      nowIndicatorRef.value?.clientTop ?? null,
    )

    const totalTimelineMinutes = computed(
      () =>
        minutes(props.timeSlots[props.timeSlots.length - 1].endTime) -
        minutes(props.timeSlots[0].startTime),
    )

    const firstSlotStartTime = computed(() =>
      parseLocalIsoTime(props.timeSlots[0].startTime),
    )
    const timeSteps = useTimeSteps(
      firstSlotStartTime.value,
      totalTimelineMinutes.value,
    )

    const findTimeSlotEvents = (slot: TimeSlot.TimeSlotDto) => {
      return props.todayCalendarEvents.filter(
        (event: UserCalendarEventDto) =>
          event.calendarEventDto.startTime === slot.startTime,
      )
    }

    const timeSlotEntries = computed<TimeSlotEntry[]>(() => {
      return props.timeSlots.map((slot) => ({
        timeSlotId: slot.timeSlotId,
        startTime: slot.startTime,
        endTime: slot.endTime,
        timeLabel: stringifyLocalTime(parseLocalIsoTime(slot.startTime)),
        durationMinutes: minutes(slot.endTime) - minutes(slot.startTime),
        events: findTimeSlotEvents(slot),
      }))
    })

    const updateNowIndicator = () => {
      const currentTimeStep = getCurrentTimeStep(timeSteps.value)
      if (currentTimeStep) {
        nowIndicatorPosTop.value = currentTimeStep
      }
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      emit('eventTileClick', events)
    }

    const firstEventId = computed(() => {
      return timeSlotEntries.value.find((x) => !!x.events.length)?.timeSlotId
    })

    onMounted(() => {
      updateNowIndicator()
      useIntervalFn(updateNowIndicator, NOW_INDICATOR_UPDATE_TICK)
    })

    return {
      timeSlotEntries,
      nowIndicatorRef,
      nowIndicatorPosTop,
      meetingsTimelineRef,
      eventTileClicked,
      CalendarEventStatus,
      firstEventId,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './calendar';

.meetings-timeline {
  --minute-size: 2;
  --event-offset: calc(var(--minute-size) * 2px);

  background-color: $white;
  overflow-y: auto;
  padding-top: $vertical-gutter;
  border-top: $divider-border;

  @include breakpoint-down(md) {
    border-top: none;
    background-color: $alabaster;
    border-top-left-radius: $timeline-border-radius;
    border-top-right-radius: $timeline-border-radius;
  }
}
.time-slot {
  height: calc(1px * (var(--time-slot-duration) * var(--minute-size)));
  display: flex;
  border-top: $divider-border;

  @include breakpoint-up(md) {
    &:first-of-type {
      border-top: none;
    }
  }

  .time-col {
    flex: 0 0 60px;
    padding-top: $vertical-gutter;
    color: $comet;
    font-size: 0.875rem;
  }

  .event-col {
    flex: 1 1 auto;
  }
}
.now-indicator {
  position: relative;
  height: 1px;
  left: $now-indicator-margin;
  width: calc(100% - #{$now-indicator-margin});
  background-color: $heliotrope;
  top: calc(1px * (var(--currentIndicatorPos) * var(--minute-size)));
  z-index: 4;
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: inherit;
    right: 0;
    display: block;
    position: absolute;
    transform: translateY(-5px);
  }
}
</style>
