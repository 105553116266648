import { emptyLoadableResource, LocalIsoDate } from '@bd/helpers'
import { UserCalendarEventDetailsDto, UserCalendarEventDto } from '@bd/api'
import { MutationTree } from 'vuex'
import { CommonCalendarState } from '@bd/store-modules/types/calendar'

export const mutations: MutationTree<CommonCalendarState> = {
  SET_TODAY_EVENTS(state, payload: UserCalendarEventDto[]) {
    state.todayEvents = payload
  },
  SET_EVENT_DETAILS_LOADING(state, payload: boolean) {
    state.eventDetails.isLoading = payload
  },
  SET_EVENT_DETAILS(state, payload: UserCalendarEventDetailsDto) {
    state.eventDetails.content = payload
  },
  SET_SELECTED_DATE(state, payload: LocalIsoDate) {
    state.selectedDate = payload
  },
  CLEAR_EVENT_DETAILS(state) {
    state.eventDetails = emptyLoadableResource()
  },
  SET_SELECTED_AGENT_ID(state, id: number | undefined) {
    state.selectedAgentId = id
  },
  SET_TODAY_EVENTS_COUNT(state, payload: number) {
    state.todayEventsCount = payload
  },
  SET_FUTURE_DATES(state, payload: LocalIsoDate[]) {
    state.upcomingEventDates = payload
  }
}
