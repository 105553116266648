import { emptyLoadableResource } from '@bd/helpers'
import {
  CommonCalendarEventState,
  OfferSelectionTab,
} from '@bd/store-modules/types/calendarEvent'
import { EVENT_SAVE_DATA_INITIAL_STATE } from './utils'

export const state: CommonCalendarEventState = {
  eventSaveData: { ...EVENT_SAVE_DATA_INITIAL_STATE },
  eventSaveMetaData: {
    selectedOffersTab: OfferSelectionTab.Mine,
    offersSearchPhrase: '',
    attendeesSearchPhrase: '',
    offers: emptyLoadableResource(),
    attendees: emptyLoadableResource(),
    availableTimeSlots: emptyLoadableResource([]),
    disabledTimeSlots: []
  },
}
