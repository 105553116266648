
import { UserCalendarEventDto, CalendarEventStatus } from '@bd/api'
import { UserRole } from '@itcraft-bestdeal/api'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEventStatusClass, useUserData } from '@bd/components/use'
import { TemplateRef } from '@bd/helpers'
import { UserAvatar, UserType } from '@bd/components'

export default defineComponent({
  components: { UserAvatar },
  props: {
    events: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    isFirstThisDay: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { user } = useUserData()
    const { CREATED_BY_AGENT } = CalendarEventStatus
    const { Agent } = UserType
    const emptyUser = { firstName: '', lastName: '' }

    const firstCalendarEvent = computed(() => props.events[0].calendarEventDto)
    const hasMultipleEvents = computed(() => props.events.length > 1)
    const eventTileClass = useEventStatusClass(
      firstCalendarEvent,
      hasMultipleEvents,
    )

    const eventDurationStyle = computed(() => {
      const meeting = firstCalendarEvent.value.meetingTypeDto
      return {
        '--event-duration': user.value?.roles.includes(UserRole.CUSTOMER)
          ? meeting.clientDurationMinutes
          : meeting.agentDurationMinutes,
      }
    })

    const eventTile = ref<TemplateRef>()

    onMounted(() => {
      if (props.isFirstThisDay) {
        eventTile.value?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    })

    return {
      t,
      eventTileClass,
      eventDurationStyle,
      firstCalendarEvent,
      hasMultipleEvents,
      CalendarEventStatus,
      eventTile,
      Agent,
      CREATED_BY_AGENT,
      emptyUser,
    }
  },
})
