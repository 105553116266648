
import { CalendarEventMeetingTypeItem } from '@bd/components'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import Dialog from 'primevue/dialog'
import { defineComponent, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventMeetingTypesList from './MeetingTypesList/CalendarEventMeetingTypesList.vue'
import { useMeetingTypeEntries } from './utils'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventMeetingTypesList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    meetingTypes: {
      type: Array as PropType<CalendarEventMeetingType[]>,
      required: true,
    },
    selectedMeetingType: {
      type: Object as PropType<CalendarEventMeetingType>,
      required: false,
    },
  },
  emits: ['update:selectedMeetingType', 'update:visible'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const { meetingTypes, selectedMeetingType } = toRefs(props)
    const meetingTypeEntries = useMeetingTypeEntries(
      meetingTypes,
      selectedMeetingType,
    )
    const onMeetingTypeSelected = (
      meetingType: CalendarEventMeetingTypeItem,
    ) => {
      emit('update:selectedMeetingType', meetingType)
      emit('update:visible', false)
    }
    return {
      t,
      onMeetingTypeSelected,
      meetingTypeEntries,
    }
  },
})
