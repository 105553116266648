import { onMounted, onUnmounted } from 'vue'

// Mobile viewport units workaround
export const useMobileViewportDimensions = () => {
  const setViewportDimensions = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  onMounted(() => {
    setViewportDimensions()
    window.addEventListener('resize', setViewportDimensions)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', setViewportDimensions)
  })
}
