<template>
  <component
    :is="is"
    class="calendar-event-attendees-list-item event-resources-results-list-item"
    :class="{ selected: item.selected }"
  >
    <div class="wrapper">
      <div class="attendee-entry">
        <UserAvatar class="avatar" :userType="userType" :user="item" />
        <div class="attendee-details">
          <span class="text-500 mb-1">
            <div class="name-and-phone">
              <span class="name">
                {{ nameAndLastName }}
              </span>
              <span v-if="item.phoneNumber" class="ml-1 phone-number">
                (<Svg
                  class="mr-1"
                  :src="require('@bd/assets/icons/phone.svg')"
                />
                {{ item.phoneNumber }})
              </span>
            </div>
          </span>
          <span class="attendee-id">{{ item.displayableId }}</span>
        </div>
      </div>

      <div v-if="$slots['item-append']" class="pl-2 item-append">
        <slot name="item-append" :item="item" />
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { CalendarEventAttendeeItem } from '@bd/components/Calendar/types'
import { defineComponent, PropType } from 'vue'
import { EventAttendeeAvailableTypes } from '../types'
import { UserAvatar, Svg, useNameAndLastName } from '@bd/components'

export default defineComponent({
  components: {
    UserAvatar,
    Svg,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    item: {
      type: Object as PropType<CalendarEventAttendeeItem>,
      required: true,
    },
    showId: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const nameAndLastName = useNameAndLastName(
      props.item.firstName,
      props.item.lastName,
    )

    return { nameAndLastName }
  },
})
</script>

<style lang="scss" scoped>
@import '../../event-save';
.calendar-event-attendees-list-item {
  &.selected .attendee-details {
    color: $primary-color;
  }
}
.attendee-entry {
  display: flex;
  align-items: center;
  width: 100%;
  .avatar {
    @include uniform-size(50px);
    font-size: 1rem;
    border-radius: 14px;
    margin-right: 1rem;
    flex: 0 0 auto;
  }
  .attendee-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    color: $port-gore;
    .attendee-id {
      color: $manatee;
      font-size: 0.75rem;
    }
    .name-and-phone {
      margin-bottom: 0.25rem;
      line-height: normal;
      .phone-number {
        display: inline-flex;
        align-items: center;
        color: $comet;
        font-weight: normal;
        i {
          color: $comet;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
