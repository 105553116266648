export default {
  hello: 'Witaj',
  navigation: {
    calendar: 'Kalendarz',
    offers: 'Oferty',
    clients: 'Klienci',
    employees: 'Pracownicy',
  },
  filters: 'Filtry',
  offers: {
    add: 'Dodaj ofertę',
    removeOffer: 'Usuń',
    allOffer: 'Wszystkie oferty',
    addTitle: 'Dodawanie oferty',
    updateTitle: 'Szczegóły oferty',
    saveDraft: 'Zapisz draft',
    addAsDraft: 'Dodaj jako draft',
    publish: 'Opublikuj',
    unpublish: 'Wycofaj ofertę',
    exportToAsari: 'Exportuj do Asari',
    propertyType: 'Typ nieruchomości',
    location: 'Lokalizacja',
    city: 'Miejscowość',
    cityPlaceholder: 'np. Warszawa',
    district: 'Dzielnica',
    districtPlaceholder: 'np. Praga-Południe',
    street: 'Ulica',
    streetPlaceholder: 'np. ul. Niepodległości 32',
    buildingNumber: 'Numer budynku',
    buildingNumberPlaceholder: 'np. 32',
    houseNumber: 'Numer mieszkania',
    houseNumberPlaceholder: 'np. 2',
    seller: 'Sprzedający',
    sellerPlaceholder: 'np. Jan Kowalski',
    createSeller: 'Utwórz sprzedającego',
    locationOnMap: 'Położenie na mapie',
    basicInformation: 'Informacje podstawowe',
    price: 'Cena (PLN)',
    pricePlaceholder: 'np. 499000 PLN ',
    pricePerMeter: 'Cena za m2',
    pricePerMeterMessage: 'Obliczana z ceny całkowitej i powierzchni',
    rooms: 'Liczba pokoi',
    apartmentLevels: 'Liczba poziomów',
    floor: 'Piętro',
    levels: 'Liczba pięter',
    floorPlaceholder: 'np. 2',
    constructionYear: 'Rok budowy',
    constructionYearPlaceholder: 'np. 1992',
    market: 'Rynek',
    houseArea: 'Powierzchnia (m2)',
    houseAreaPlaceholder: 'np. 38m2',
    landArea: 'Powierzchnia działki (m2)',
    landAreaPlaceholder: 'np. 1000m2',
    descriptionTitleSection: 'Opis',
    title: 'Tytuł',
    titlePlaceholder: 'Nadaj tytuł ogłoszeniu',
    descriptionInput: 'Opisz swoją nieruchomość',
    descriptionInputPlaceholder:
      'Dodaj opis, który zachęci kupującego do zakupu nieruchomości',
    additionalInformation: 'Informacje dodatkowe',
    rent: 'Czynsz (PLN)',
    rentPlaceholder: 'np. 399 PLN',
    garage: 'Garaż',
    elevator: 'Winda',
    garden: 'Ogródek',
    security: 'Ochrona',
    propertyOwnership: 'Forma własności',
    basement: 'Piwnica / Komórka',
    balcony: 'Balkon',
    parkingSpot: 'Miejsce parkingowe',
    buildingType: 'Typ budynku',
    plotType: 'Przeznaczenie działki',
    buildingMaterial: 'Materiał budowy',
    kitchenType: 'Typ kuchni',
    heatingType: 'Rodzaj ogrzewania',
    windowsType: 'Typ okien',
    agentHasKeys: 'Posiadam klucze do nieruchomości',
    buildingFinishState: 'Stan wykończenia',
    bathrooms: 'Liczba łazienek',
    internet: 'Internet',
    showOnLandingPage: 'Udostępnij na landing page’u',
    images: 'Zdjęcia',
    landAndMortgageRegisterNumber: 'Numer księgi wieczystej',
    landAndMortgageRegisterNumberPlaceholder: 'np. SZ1S/00012345/0',
    statistics: 'Statystyki',
    fromData: 'Data od',
    fromDataPlaceholder: '5.10.2020r.',
    toData: 'Data do',
    toDataPlaceholder: '12.10.2020r.',
    visits: 'Liczba odsłon',
    visitsPlaceholder: '2900',
    requests: 'Liczba zapytań',
    requestsPlaceholder: '190',
    addStatistics: 'Dodaj statystyki',
    createCity: 'Utwórz miejscowość',
    createCityPlaceholder: 'Np. Warszawa',
    selectProvince: 'Województwo',
    sellerDetails: 'Szczegóły sprzedającego',
  },
  employees: {
    add: 'Dodaj pracownika',
    allEmployees: 'Wszyscy',
    allAgents: 'Wszyscy agenci',
    details: 'Szczegóły pracownika',
    updateTitle: 'Edytowanie pracownika',
    addTitle: 'Dodawanie pracownika',
    remove: 'Usuń pracownika',
    profilPhoto: 'Zdjęcie profilowe',
    information: 'Informacje ogólne',
    firstNamePlaceholder: 'np. Jan',
    lastNamePlaceholder: 'np. Kowalski',
    email: 'E-mail',
    phone: 'Telefon',
    phonePlaceholder: 'np. 609230460',
    role: 'Rola',
  },
  clients: {
    updateTitle: 'Edytowanie klienta',
    addTitle: 'Dodawanie klienta',
    details: 'Szczegóły klienta',
    information: 'Informacje ogólne',
    firstNamePlaceholder: 'np. Jan',
    lastNamePlaceholder: 'np. Kowalski',
    email: 'E-mail (opcjonalnie)',
    emailPlaceholder: 'E-mail',
    phone: 'Telefon',
    phonePlaceholder: 'np. 609230460',
    description: 'Opis',
    descriptionPlaceholder: 'np. Pan Jan szuka mieszkania na Pradze-Północ',
    agent: 'Opiekun klienta',
    agentPlaceholder: 'Wybierz opiekuna',
    developer: 'Developer',
    markDeveloper: 'Oznacz jako developer',
    clientType: 'Typ klienta',
    city: 'Poszukiwane mieszkanie',
    cityPlaceholder: 'np. Aleja Niepodległości',
    areaMin: 'Powierzchnia od',
    areaMinPlaceholder: 'np. 24m2',
    areaMax: 'Powierzchnia do',
    areaMaxPlaceholder: 'np. 48m2',
    priceMin: 'Cena od',
    priceMinPlaceholder: 'np. 199000 PLN',
    priceMax: 'Cena do',
    priceMaxPlaceholder: 'np. 499000 PLN',
    marketPlaceholder: 'Wybierz rynek',
    employee: 'Opiekun',
    notes: 'Notatki',
    go_to_offer: 'Przejdź do oferty',
    seller_role_change_hint:
      'Żeby zmienić rolę sprzedającego najpierw trzeba odłączyć jego ofertę',
  },
  search: 'Wyszukaj',
  property_type: 'Rodzaj nieruchomości',
  price: 'Cena',
  area: 'Powierzchnia',
  city: 'Miasto',
  rooms_number: 'Liczba pokoi',
  plot_area: 'Powierzchnia działki',
  any: 'Dowolny',
  district: 'Dzielnica',
  apartment: 'Mieszkania',
  house: 'Domy',
  plot: 'Działki',
  office: 'Biura',
  construction_year: 'Rok budowy',
  floor: 'Piętro',
  price_from: 'Od',
  price_to: 'Do',
  house_area_from: 'Od',
  house_area_to: 'Do',
  land_area_from: 'Od',
  land_area_to: 'Do',
  floor_from: 'Od',
  floor_to: 'Do',
  rooms_from: 'Od',
  rooms_to: 'Do',
  construction_year_from: 'Od',
  construction_year_to: 'Do',
  select: 'wybierz',
  block: 'Zablokuj',
  unblock: 'Odblokuj',
  confirmDialog: {
    employees: {
      BLOCK: {
        title: 'Czy na pewno chcesz zablokować pracownika?',
      },
      UNBLOCK: {
        title: 'Czy na pewno chcesz odblokować pracownika?',
      },
      REMOVE: {
        title: 'Czy na pewno chcesz usunąć pracownika?',
      },
    },
    offers: {
      sellerWarning:
        'Zmiana klienta sprzedającego spowoduje anulowanie z kalendarza wszystkich powiązanych z nim wydarzeń. Czy chcesz kontynuować?',
      REMOVE: {
        title: 'Czy na pewno chcesz usunąć ofertę?',
      },
    },
  },
  toastMessage: {
    offers: {
      successExportToAsari: 'Eksport do Asari zakończony sukcesem',
      errorExportToAsari: 'Eksport do Asari nie powiódł się',
      succesAddStatistics: 'Dodawanie statystyk zakończone sukcesem',
      errorAddStatistics: 'Dodawanie statystyk nie powiodło się',
      succesRemove: 'Usuwanie zakończone sukcesem',
      errorRemove: 'Usuwanie nie powiodło się',
      succesPublish: 'Publikowanie zakończone sukcesem',
      errorPublish: 'Publikowanie nie powiodło się',
      succesSave: 'Dodawanie oferty zakończone sukcesem',
      errorSave: 'Dodawanie oferty nie powiodło się',
      succesUpdate: 'Zapisywanie oferty zakończone sukcesem',
      errorUpdate: 'Zapisywanie oferty nie powiodło się',
      succesUnpublish: 'Wycofanie oferty zakończone sukcesem',
      errorUnpublish: 'Wycofanie oferty nie powiodło się',
      succesAddCity: 'Dodawanie miasta zakończone sukcesem',
      errorAddCity: 'Dodawanie miasta nie powiodło się',
    },
    employees: {
      successRemove: 'Usuwanie pracownika zakończone sukcesem',
      errorRemove: 'Usuwanie pracownika nie powiodło się',
      successBlock: 'Blokowanie pracownika zakończone sukcesem',
      errorBlock: 'Blokowanie pracownika nie powiodło się',
      successUnblock: 'Odblokowywanie pracownika zakończone sukcesem',
      errorUnblock: 'Odblokowywanie pracownika nie powiodło się',
      successAdd: 'Dodawanie pracownika zakończone sukcesem',
      errorAdd: 'Dodawanie pracownika nie powiodło się',
      successUpdate: 'Aktualizacja pracownika zakończona sukcesem',
      errorUpdate: 'Aktualizacja pracownika nie powiodła się',
    },
    clients: {
      successAdd: 'Dodawanie klienta zakończone sukcesem',
      errorAdd: 'Dodawanie klienta nie powiodło się',
      successUpdate: 'Aktualizacja klienta zakończona sukcesem',
      errorUpdate: 'Aktualizacja klienta nie powiodła się',
      successBlock: 'Blokowanie klienta zakończone sukcesem',
      errorBlock: 'Blokowanie klienta nie powiodło się',
      successUnblock: 'Odblokowywanie klienta zakończone sukcesem',
      errorUnblock: 'Odblokowywanie klienta nie powiodło się',
      successRemove: 'Usuwanie klienta zakończone sukcesem',
      errorRemove: 'Usuwanie klienta nie powiodło się',
    },
  },
}
