<template>
  <div class="client-note" :class="{ 'show-more': showMore }">
    <div class="content-wrapper">
      <div class="content">
        {{ content }}
      </div>
      <label
        class="show-more-btn mt-auto"
        @click="showMore = !showMore"
        v-if="isContentLengthOverLimit"
      >
        {{ t('show') }} {{ showMore ? t('less') : t('more') }}
        <span :class="`ml-1 pi pi-chevron-${showMore ? 'up' : 'down'}`" />
      </label>
    </div>
    <div class="footer">
      <div class="note-author">
        <UserAvatar
          class="note-author-avatar"
          :userType="UserType.Agent"
          :user="{
            firstName: note.author.firstName,
            lastName: note.author.lastName,
          }"
        />
        <div class="note-author-details">
          <span class="h-4 text-wrap-1"
            >{{ note.author.firstName }} {{ note.author.lastName }}</span
          >
          <span class="caption">{{ createdAt }}</span>
        </div>
      </div>
      <div class="button-container">
        <Button
          class="p-button-rounded p-button-text p-button-danger delete-btn"
          icon="pi pi-trash"
          @click="$emit('delete', note.noteId)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { UserAvatar, DATE_FORMAT, UserType } from '@bd/components'
import { ClientNoteDto } from '@bd/api'
import { truncateText } from '@bd/helpers'
import { useI18n } from 'vue-i18n'

export const CLIENT_NOTE_MAX_CHARS = 200

export default defineComponent({
  components: {
    UserAvatar,
  },
  props: {
    note: {
      type: Object as PropType<ClientNoteDto>,
      required: true,
    },
    maxContentCharacters: {
      type: Number,
      default: CLIENT_NOTE_MAX_CHARS,
    },
  },
  emits: ['delete'],
  setup(props) {
    const { t, locale } = useI18n()
    const showMore = ref(false)
    const content = computed(() =>
      truncateText(
        props.note.content,
        props.maxContentCharacters,
        showMore.value,
      ),
    )

    const isContentLengthOverLimit = computed(
      () => props.note.content.length > props.maxContentCharacters,
    )

    const createdAt = computed(() => {
      const formatter = new Intl.DateTimeFormat(locale.value, {
        ...DATE_FORMAT.standardDate,
      })
      return formatter.format(props.note.createdAt)
    })

    return {
      t,
      UserType,
      showMore,
      content,
      createdAt,
      isContentLengthOverLimit,
    }
  },
})
</script>

<style lang="scss" scoped>
.client-note {
  height: 260px;
  @include breakpoint-down(md) {
    min-height: 0;
    height: auto;
  }
  &.show-more {
    height: auto;
    max-height: 400px;
    .content-wrapper {
      overflow-y: hidden;
    }
  }
  padding: 25px 25px;
  border-radius: 6px;
  box-shadow: $default-shadow;
  background-color: $white;
  @include flex($direction: column, $align: flex-start);
}
.content-wrapper {
  padding-bottom: 1rem;
  height: 100%;
  flex: 1;
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
}
.content {
  font-size: 0.9375rem;
  color: $comet;
  line-height: 1.25;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.show-more-btn {
  color: $jungle-green;
  cursor: pointer;
  transition: all 0.15s ease-in;
  &:hover {
    color: darken($jungle-green, 5%);
    font-weight: 500;
  }
}
.footer {
  width: 100%;
  margin-top: auto;
  border-top: $divider-border;
  padding-top: 1rem;
  @include flex($justify: space-between);
}
.note-author {
  @include flex;
}
.note-author-details {
  @include flex(
    $direction: column,
    $align: flex-start,
    $justify: space-between
  );
}
.note-author-avatar {
  font-size: 1rem;
  border-radius: 12px;
  $avatar-size: 40px;
  @include uniform-size($avatar-size);
  min-width: $avatar-size;
}
</style>
