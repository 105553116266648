
import { getOfferAddress, ResponsiveContent, Svg } from '@bd/components'
import { TemplateRef } from '@bd/helpers'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import { computed, defineComponent, PropType, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventOfferAddress from './CalendarEventOfferAddress.vue'
import CalendarEventOfferSelectionModal from './CalendarEventOfferSelectionModal.vue'
import CalendarEventOfferSelectionOverlay from './CalendarEventOfferSelectionOverlay.vue'

export default defineComponent({
  components: {
    Svg,
    ResponsiveContent,
    CalendarEventOfferSelectionModal,
    CalendarEventOfferSelectionOverlay,
    CalendarEventOfferAddress,
  },
  props: {
    selectedOffer: {
      type: Object as PropType<MeetingOfferSelectPayload>,
      required: false,
    },
  },
  emits: ['update:selectedOffer', 'searchOffer', 'clearOffer'],
  setup(props) {
    const { t } = useI18n()
    const offerSelectionModalVisible = ref(false)
    const isOfferChosen = computed(() => !!props.selectedOffer?.offer)
    const offer = computed(() => props.selectedOffer?.offer)
    const address = computed(() => {
      return offer.value?.address
        ? getOfferAddress(offer.value.address)
        : undefined
    })
    const seller = computed(() => props.selectedOffer?.seller)

    const offerSelectionOverlayVisible = ref(false)
    const editIcon = shallowRef<TemplateRef>()
    const offerPickerTrigger = shallowRef<TemplateRef>()

    const isOfferPickerTriggerClicked = ($event: PointerEvent) => {
      return (
        $event.target === offerPickerTrigger.value ||
        $event.composedPath().includes(offerPickerTrigger.value!)
      )
    }

    const onOfferPickerTriggerClick = ($event: PointerEvent) => {
      /* For the 'offer chosen' state -> prevent event handler from executing when the event target isn't the edit icon */
      if (
        !offerSelectionOverlayVisible.value &&
        isOfferChosen.value &&
        $event.target !== editIcon.value
      ) {
        return
      }

      if (isOfferPickerTriggerClicked($event)) {
        offerSelectionOverlayVisible.value = !offerSelectionOverlayVisible.value
      }
    }

    const onOfferChooseClick = () => {
      offerSelectionModalVisible.value = true
    }

    const onOfferSelectionOutsideClick = ($event: PointerEvent) => {
      if (!isOfferPickerTriggerClicked($event)) {
        offerSelectionOverlayVisible.value = false
      }
    }

    return {
      t,
      editIcon,
      isOfferChosen,
      address,
      seller,
      offerSelectionModalVisible,
      offerSelectionOverlayVisible,
      offerPickerTrigger,
      onOfferPickerTriggerClick,
      onOfferChooseClick,
      onOfferSelectionOutsideClick,
    }
  },
})
