import { NavigationEnabledRoute } from '@bd/components'
import { Router } from 'vue-router'
import { i18n } from '@bd/admin/plugins/vue-i18n-next-plugin'
import { NavigationItem } from '@bd/admin/components/Navigation/types'
import {
  AdditionalInformationOffer,
  FilterData,
  InputType,
  PropertyTypeData,
  SelectData,
} from '@bd/admin/types'
import {
  ApartmentBuildingType,
  ApartmentLevel,
  BuildingFinishedState,
  BuildingMaterial,
  BuildingType,
  HeatingType,
  KitchenType,
  PropertyOwnership,
  UserRole,
  WindowsType,
} from '@bd/api'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import { AppStore } from '../store'
import { userHasRoles } from '@bd/store-modules/modules/auth/getters-helpers'

const { t } = i18n.global

export const AUTH_NAVIGATION_ROUTES: NavigationEnabledRoute[] = [
  { path: '/password-reset', text: '' },
  {
    path: '/password-reset-confirmation',
    text: '',
    navigationStrategy: 'SpecificPath',
    goTo: '/login',
  },
]

const NAVIGATION_PATHS = {
  CALENDAR: '/calendar',
  OFFERS: '/offers',
  CLIENTS: '/clients',
  EMPLOYEES: '/employees',
} as const

export const buildNavigation = (
  router: Router,
  store: AppStore,
): NavigationItem[] => {
  const currentPath = router.currentRoute.value.path

  return [
    {
      path: NAVIGATION_PATHS.CALENDAR,
      label: t('navigation.calendar'),
      active: currentPath.startsWith(NAVIGATION_PATHS.CALENDAR),
      icon: require('@bd/assets/icons/navigation/calendar.svg'),
    },
    {
      path: NAVIGATION_PATHS.OFFERS,
      label: t('navigation.offers'),
      active: currentPath.startsWith(NAVIGATION_PATHS.OFFERS),
      icon: require('@bd/assets/icons/navigation/dashboard.svg'),
    },
    {
      path: NAVIGATION_PATHS.CLIENTS,
      label: t('navigation.clients'),
      active: currentPath.startsWith(NAVIGATION_PATHS.CLIENTS),
      icon: require('@bd/admin/assets/icons/navigation/clients.svg'),
    },
    {
      path: NAVIGATION_PATHS.EMPLOYEES,
      label: t('navigation.employees'),
      active: currentPath.startsWith(NAVIGATION_PATHS.EMPLOYEES),
      icon: require('@bd/admin/assets/icons/navigation/employees.svg'),
      visible: userHasRoles(store.state, [UserRole.ADMIN]),
    },
  ]
}

export const LOCALISATION_SETTINGS = {
  currency: 'PLN',
  currencyDecimal: 'zł',
  locale: 'pl-PL',
}

export const PROPERTY_TYPES: PropertyTypeData[] = [
  { name: 'APARTMENT', value: 'APARTMENT' },
  { name: 'HOUSE', value: 'HOUSE' },
  { name: 'PLOT', value: 'PLOT' },
  { name: 'OFFICE', value: 'OFFICE' },
]

export const BOOLEAN_DATA: SelectData[] = [
  { name: 'Tak', value: true },
  { name: 'Nie', value: false },
]

export const additionalInformationOfferForm: AdditionalInformationOffer[][] = [
  [
    {
      type: InputType.NUMBER_INPUT,
      name: 'rent',
      label: 'offers.rent',
      placeholder: 'offers.rentPlaceholder',
      unit: 'currency',
    },
    {
      type: InputType.SELECT,
      name: 'garage',
      label: 'offers.garage',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'elevator',
      label: 'offers.elevator',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'garden',
      label: 'offers.garden',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'security',
      label: 'offers.security',
      options: BOOLEAN_DATA,
    },
  ],
  [
    {
      type: InputType.SELECT,
      name: 'propertyOwnership',
      label: 'offers.propertyOwnership',
      options: enumConvertToValue(PropertyOwnership, 'propertyOwnership'),
    },
    {
      type: InputType.SELECT,
      name: 'basement',
      label: 'offers.basement',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'balcony',
      label: 'offers.balcony',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'parkingSpot',
      label: 'offers.parkingSpot',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'buildingType',
      label: 'offers.buildingType',
      options: enumConvertToValue(
        ApartmentBuildingType,
        'apartmentBuildingType',
      ),
    },
  ],
  [
    {
      type: InputType.SELECT,
      name: 'buildingMaterial',
      label: 'offers.buildingMaterial',
      options: enumConvertToValue(BuildingMaterial, 'buildingMaterial'),
    },
    {
      type: InputType.SELECT,
      name: 'kitchenType',
      label: 'offers.kitchenType',
      options: enumConvertToValue(KitchenType, 'kitchenType'),
    },
    {
      type: InputType.SELECT,
      name: 'heatingType',
      label: 'offers.heatingType',
      options: enumConvertToValue(HeatingType, 'heatingType'),
    },
    {
      type: InputType.SELECT,
      name: 'windowsType',
      label: 'offers.windowsType',
      options: enumConvertToValue(WindowsType, 'windowsType'),
    },
    {
      type: InputType.SELECT,
      name: 'agentHasKeys',
      label: 'offers.agentHasKeys',
      options: BOOLEAN_DATA,
    },
  ],
  [
    {
      type: InputType.SELECT,
      name: 'buildingFinishState',
      label: 'offers.buildingFinishState',
      options: enumConvertToValue(BuildingFinishedState, 'buildingFinishState'),
    },
    {
      type: InputType.NUMBER_INPUT,
      name: 'bathrooms',
      label: 'offers.bathrooms',
      placeholder: 'offers.floorPlaceholder',
      unit: 'decimal',
    },
    {
      type: InputType.SELECT,
      name: 'apartmentLevels',
      label: 'offers.apartmentLevels',
      options: enumConvertToValue(ApartmentLevel, 'apartmentLevels'),
    },
    {
      type: InputType.SELECT,
      name: 'internet',
      label: 'offers.internet',
      options: BOOLEAN_DATA,
    },
    {
      type: InputType.SELECT,
      name: 'showOnLandingPage',
      label: 'offers.showOnLandingPage',
      options: BOOLEAN_DATA,
    },
  ],
]

export enum EmployeeEvent {
  REMOVE = 'REMOVE',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
}

export const APP_SETTINGS = {
  clientNoteMaxChars: 50,
}
