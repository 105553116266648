<template>
  <div class="meeting-type-selection-grid">
    <CalendarEventMeetingTypeSelectionGridTile
      v-for="meetingType of meetingTypeEntries"
      :key="meetingType.tileId"
      :meetingType="meetingType"
      @click="select($event, meetingType)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import CalendarEventMeetingTypeSelectionGridTile from './CalendarEventMeetingTypeSelectionGridTile.vue'
import { useMeetingTypeEntries } from './utils'
import { CalendarEventMeetingTypeItem } from '@bd/components'

export default defineComponent({
  components: {
    CalendarEventMeetingTypeSelectionGridTile,
  },
  props: {
    meetingTypes: {
      type: Array as PropType<CalendarEventMeetingType[]>,
      required: true,
    },
    selectedMeetingType: {
      type: Object as PropType<CalendarEventMeetingType>,
      required: false,
    },
  },
  emits: ['update:selectedMeetingType'],
  setup(props, { emit }) {
    const { meetingTypes, selectedMeetingType } = toRefs(props)
    const meetingTypeEntries = useMeetingTypeEntries(
      meetingTypes,
      selectedMeetingType,
    )

    const select = (_: Event, meetingType: CalendarEventMeetingTypeItem) => {
      emit('update:selectedMeetingType', meetingType)
    }

    return {
      select,
      meetingTypeEntries,
    }
  },
})
</script>

<style lang="scss" scoped>
.meeting-type-selection-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}
</style>
