
import { LocalIsoDate } from '@bd/helpers'
import { defineComponent, PropType, toRefs } from 'vue'
import { useFormattedDate } from '../use'

export default defineComponent({
  props: {
    date: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    showFullDate: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const currentDate = useFormattedDate(date)
    return {
      currentDate,
    }
  },
})
