
import { computed, defineComponent, ref, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import Sidebar from 'primevue/sidebar'
import FilterAccordion from '@bd/components/FilterAccordion/FilterAccordion.vue'
import FilterAccordionItem from '@bd/components/FilterAccordion/FilterAccordionItem.vue'
import { NumberRange } from '@bd/components/FilterElements'
import SingleSelectOption from './SingleSelectOption.vue'
import MultipleSelectOptions from './MultipleSelectOptions.vue'
import { FiltersState, FilterData } from '@bd/store-modules/types'
import { DistrictDto, CityWithProvinceDto } from '@bd/api'

export default defineComponent({
  name: 'FiltersSidebar',
  components: {
    Sidebar,
    FilterAccordion,
    FilterAccordionItem,
    SingleSelectOption,
    MultipleSelectOptions,
    NumberRange,
  },
  props: {
    filtersState: {
      type: Object as PropType<FiltersState>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array as PropType<FilterData[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
    districts: {
      type: Array as PropType<DistrictDto[]>,
      required: true,
    },
  },
  emits: ['update:visible', 'add-to-queue', 'filter-results'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const sidebar = computed({
      get: () => props.visible,
      set: (value: boolean) => {
        emit('update:visible', value)
      },
    })

    const openedFilter = ref<number[]>([])

    const statusChange = (val: number) => {
      if (openedFilter.value.includes(val)) {
        openedFilter.value = openedFilter.value.filter(
          (item: number) => item !== val,
        )
      } else {
        openedFilter.value.push(val)
      }
    }

    const hasDistricts = computed(() => {
      return (
        Array.isArray(props.filtersState?.['cityIds']) &&
        props.filtersState?.['cityIds'].length === 1
      )
    })

    const filterResults = () => {
      sidebar.value = false
      emit('filter-results')
    }

    return {
      t,
      sidebar,
      statusChange,
      openedFilter,
      hasDistricts,
      filterResults,
    }
  },
})
