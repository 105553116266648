<template>
  <CalendarEventView
    :eventDetails="event"
    empty-state-route="/calendar"
    :class="{ admin: userType === UserType.Admin }"
  >
    <router-view />
  </CalendarEventView>
</template>

<script lang="ts">
import { useAppStore } from '@bd/store-modules'
import CalendarEventView from './CalendarEventView.vue'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { UserType } from '@bd/components'

export default defineComponent({
  components: {
    CalendarEventView,
  },
  props: {
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const event = computed(() => store.state.calendar?.eventDetails)
    const eventId = computed(() => +route.params.id)

    const fetchEvent = async (overrideEventId?: number) => {
      await store.dispatch('calendar/clearCalendarEventDetails')
      if (!overrideEventId && !eventId.value) {
        return
      }
      await store.dispatch(
        'calendar/userCalendarEventDetails',
        overrideEventId ?? eventId.value,
      )
    }

    onBeforeRouteUpdate(async (to, from, next) => {
      const fromId = +from.params.id
      const toId = +to.params.id
      if (fromId !== toId) {
        await fetchEvent(toId)
      }
      next()
    })

    fetchEvent()

    return {
      t,
      event,
      eventId,
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped></style>
