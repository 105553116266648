
import { ResponsiveContent, useLoadableResourceDataState } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { canFetchTimeSlots } from '@bd/store-modules/modules/calendar-event/utils'
import { getPredefinedMeetingTypes } from '@bd/store-modules/modules/static-data/getters-helpers'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendees from './CalendarEventAttendees/CalendarEventAttendees.vue'
import CalendarEventDate from './CalendarEventDate/CalendarEventDate.vue'
import CalendarEventMeetingType from './CalendarEventMeetingType/CalendarEventMeetingType.vue'
import CalendarEventOffer from './CalendarEventOffer/CalendarEventOffer.vue'
import CalendarEventTime from './CalendarEventTime/CalendarEventTime.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    ProgressSpinner,
    CalendarEventOffer,
    CalendarEventMeetingType,
    CalendarEventAttendees,
    CalendarEventDate,
    CalendarEventTime,
  },
  emits: ['searchOffer'],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const isOfferChosen = ref(false)

    const predefinedMeetingTypes = computed(() =>
      getPredefinedMeetingTypes(store),
    )
    const { isLoading: meetingTypesLoading } = useLoadableResourceDataState(
      predefinedMeetingTypes,
      (res) => !!(res?.content && res.content.length),
    )

    const canFetchAvailableTimeSlots = computed(() =>
      canFetchTimeSlots(store.state.calendarEvent),
    )

    const showSecondaryMobileSections = computed(() => {
      const saveData = store.state.calendarEvent?.eventSaveData
      return !!(
        saveData?.meetingType &&
        (saveData.offer || saveData?.meetingType?.isCustom)
      )
    })

    const selectedOffer = computed<MeetingOfferSelectPayload | undefined>(
      () => {
        if (!store.state.calendarEvent) {
          return undefined
        }
        const { offer, seller } = store.state.calendarEvent.eventSaveData
        return offer && seller
          ? {
              offer,
              seller,
            }
          : undefined
      },
    )

    const onSelectedOfferClear = () => {
      store.commit('calendarEvent/SET_SELECTED_OFFER', undefined)
    }

    return {
      t,
      isOfferChosen,
      meetingTypesLoading,
      showSecondaryMobileSections,
      selectedOffer,
      canFetchAvailableTimeSlots,
      onSelectedOfferClear,
    }
  },
})
