import { MutationTree } from 'vuex'
import { AppState } from '@bd/client/types'

export const mutations: MutationTree<AppState> = {
  SET_LOADING(state, payload: boolean) {
    state.isLoading = payload
  },
  SET_DIALOG(state, payload: Partial<AppState['dialog']>) {
    Object.assign(state.dialog, payload)
  },
  SET_BOTTOM_NAVIGATION_VISIBLE(state, visible: boolean) {
    state.bottomNavigationVisible = visible
  },
}
