
import { OfferDetailsDto } from '@bd/store-modules/types'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { OfferFooterButtons } from './types'

export default defineComponent({
  name: 'DashboardOfferFooter',
  props: {
    displayedButtons: {
      type: Array as PropType<OfferFooterButtons[]>,
      required: true,
    },
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
  },
  emits: ['openAppointment', 'openProposal'],
  setup(props) {
    const { t } = useI18n()
    const displayedButtonsIncludes = props.displayedButtons.includes.bind(
      props.displayedButtons,
    )
    const footerButtons = computed(() => {
      return {
        appointment: displayedButtonsIncludes('openAppointment'),
        proposal: displayedButtonsIncludes('openProposal'),
      }
    })

    const onlyProposal = computed(
      () =>
        props.displayedButtons.length === 1 &&
        props.displayedButtons[0] === 'openProposal',
    )

    const draftHint = computed(() =>
      props.offerDetails?.draft ? t('offerDetails.draft_proposal_hint') : null,
    )

    return {
      t,
      footerButtons,
      draftHint,
      onlyProposal,
    }
  },
})
