<template>
  <Dialog
    id="dialogFilters"
    class="p-dialog-maximized dialog-filters-wrapper"
    :header="t('filters')"
    :showHeader="true"
    v-model:visible="isDialogVisible"
  >
    <DashboardAccordion class="filters-accordion">
      <DashboardAccordionItem
        @accordionStatus="statusChange"
        :accordionItemStatus="openedFilter"
        v-for="(filter, index) in filters"
        :key="filter.name"
        :index="index"
      >
        <template #accordion-trigger>
          <div class="accordion-item">
            <div>
              <h3 class="h-3">{{ filter.name }}</h3>
            </div>
            <div>
              <span
                :class="`pi chevron-color pi-chevron-${
                  openedFilter === index ? 'up' : 'down'
                }`"
              />
            </div>
          </div>
        </template>
        <template #accordion-content>
          <div class="filter-wrapper">
            <CarouselTiles
              v-if="filter.filterName === 'propertyType'"
              :items="filter.assets"
              :value="filter.value[0]"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'price'"
              unit="currency"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <CarouselButtons
              v-if="filter.filterName === 'city'"
              :items="citiesAndProvinces"
              :value="filter.value"
              :activeFilters="filtersState"
              @filter-data="addToQueue"
            />
            <CarouselButtons
              v-if="filter.filterName === 'district' && hasDistricts"
              :items="districts"
              :value="filter.value"
              :activeFilters="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'rooms'"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'area'"
              unit="area"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'plotArea'"
              unit="area"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'floor'"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
            <NumberRange
              v-if="filter.filterName === 'constructionYear'"
              :items="filter.value"
              :filtersObject="filtersState"
              @filter-data="addToQueue"
            />
          </div>
        </template>
      </DashboardAccordionItem>
      <div class="bottom-wrapper">
        <Button
          @click="filterResults"
          :label="t('search')"
          class="p-button-primary w-100 p-button-rounded"
        />
      </div>
    </DashboardAccordion>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'
import { FilterProp } from '@bd/client/types'
import DashboardAccordion from '../DashboardAccordion/DashboardAccordion.vue'
import DashboardAccordionItem from '../DashboardAccordion/DashboardAccordionItem.vue'
import { CarouselTiles, NumberRange, CarouselButtons } from '../FilterElements'
import { FiltersState, FilterData } from '@bd/store-modules/types'
import { CityWithProvinceDto, DistrictDto } from '@bd/api/common'

export default defineComponent({
  name: 'FiltersDialog',
  components: {
    Dialog,
    DashboardAccordion,
    DashboardAccordionItem,
    CarouselTiles,
    CarouselButtons,
    NumberRange,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array as PropType<FilterData[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
    districts: {
      type: Array as PropType<DistrictDto[]>,
      required: true,
    },
    filtersState: {
      type: Object as PropType<FiltersState>,
      required: true,
    },
  },
  emits: ['update:visible', 'filter-results', 'add-to-queue'],
  setup(props, { emit }) {
    const i18n = useI18n()

    const hasDistricts = computed(() => {
      return (
        Array.isArray(props.filtersState?.['cityIds']) &&
        props.filtersState?.['cityIds'].length === 1
      )
    })
    const isDialogVisible = computed({
      get: () => props.visible,
      set: (value: boolean) => {
        emit('update:visible', value)
      },
    })

    const openedFilter = ref<number | null>(null)
    const statusChange = (val: number | null) => {
      openedFilter.value = val
    }

    const filterResults = async () => {
      emit('filter-results')
    }
    const addToQueue = (queueObj: FilterProp) => {
      emit('add-to-queue', queueObj)
    }

    return {
      ...i18n,
      statusChange,
      openedFilter,
      isDialogVisible,
      addToQueue,
      filterResults,
      hasDistricts,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.dialog-filters-wrapper) {
  .p-dialog-header {
    border-bottom: 0 none;
    background: $whisper;
    color: $trout;
    padding: 1.5rem;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .p-dialog-content {
    padding: 0;
  }
  .p-dialog-title {
    font-weight: 600;
    font-size: 25px !important;
  }
}

:deep(.dialog-filters-wrapper .accordion) {
  .filter-wrapper {
    padding: 1.5rem;
  }
  .bottom-wrapper {
    margin: 10px 24px;
  }
  .accordion-item {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    .chevron-color {
      color: $primary-color;
    }
  }
}
</style>
