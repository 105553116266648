<template>
  <Chip class="chip" :class="`chip-${chipStatus}`" :label="t(chipStatus)" />
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TransactionStatus } from '@bd/api'
import Chip from 'primevue/chip'

export default defineComponent({
  name: 'ProfileCardChip',
  components: { Chip },
  props: {
    status: {
      type: String as PropType<TransactionStatus>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const chipStatus = computed(() => {
      return props.status === 'ACCEPTED' ? 'acceptation' : 'negotiation'
    })
    return { t, chipStatus }
  },
})
</script>

<style lang="scss" scoped>
.chip {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 14px;
  &-acceptation {
    color: $jungle-green;
    background: rgba($jungle-green, 0.2);
  }
  &-negotiation {
    color: $zest;
    background: rgba($zest, 0.2);
  }
}
</style>
