import { Router } from 'vue-router'
import { getUserFullName } from '@bd/store-modules/modules/auth/getters-helpers'
import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { NavigationEnabledRoute, NavigationItem } from '@bd/components'
import { AppStore } from '@bd/store-modules'
import { ComputedRef } from 'vue'
import { TileItem } from '@bd/components/TileList/types'

const t = i18n.global.t

export const AUTH_NAVIGATION_ROUTES: NavigationEnabledRoute[] = [
  { path: '/registration', text: 'create_account_1' },
  { path: '/password-reset', text: '' },
  {
    path: '/password-reset-confirmation',
    text: '',
    navigationStrategy: 'SpecificPath',
    goTo: '/login',
  },
]

export const LOCALISATION_SETTINGS = {
  currency: 'PLN',
  currencyDecimal: 'zł',
  locale: 'pl-PL',
}

export const APP_SETTINGS = {
  characterLimitMobile: 180,
  characterLimitDesktop: 360,
}

const NAVIGATION_PATHS = {
  DASHBOARD: '/dashboard',
  CALENDAR: '/calendar',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications',
} as const

export const buildNavigation = (
  store: AppStore,
  router: Router,
  isMobileSize: ComputedRef<boolean>,
): NavigationItem[] => {
  const currentPath = router.currentRoute.value.path

  return [
    {
      path: NAVIGATION_PATHS.DASHBOARD,
      label: t('navigation.dashboard'),
      active: currentPath.startsWith(NAVIGATION_PATHS.DASHBOARD),
      icon: {
        src: require('@bd/assets/icons/navigation/dashboard.svg'),
        width: 20,
        height: 20,
      },
    },
    {
      path: NAVIGATION_PATHS.CALENDAR,
      label: t('navigation.calendar'),
      active: currentPath.startsWith(NAVIGATION_PATHS.CALENDAR),
      icon: {
        src: require('@bd/assets/icons/navigation/calendar.svg'),
        width: 19,
        height: 20,
      },
      badge: {
        value: store.state.calendar?.todayEventsCount ?? 0,
      },
    },
    {
      label: getUserFullName(store.state) || t('options'),
      children: [
        {
          path: NAVIGATION_PATHS.PROFILE,
          label: t('navigation.profile'),
          active: currentPath.startsWith(NAVIGATION_PATHS.PROFILE),
          icon: {
            src: require('@bd/assets/icons/navigation/profile.svg'),
            width: 20,
            height: 20,
          },
          order: 2,
        },
        {
          label: t('logout'),
          action: async () => {
            await Promise.all([
              await store.dispatch('auth/authLogout'),
              await store.dispatch('transactionHistory/clearTransactions'),
              await store.dispatch('userProfile/clearProfile'),
            ])
            router.push({ path: '/login' })
          },
        },
      ],
    },
    {
      path: isMobileSize.value ? NAVIGATION_PATHS.NOTIFICATIONS : undefined,
      action: async () => {
        await store.dispatch('notifications/setNotificationsVisible', true)
      },
      icon: {
        src: require('@bd/assets/icons/navigation/notifications.svg'),
        width: 19,
        height: 21,
      },
      active:
        currentPath.startsWith(NAVIGATION_PATHS.NOTIFICATIONS) ||
        store.state.notifications?.notificationsVisible,
      badge: {
        value: store.state.notifications?.unreadNotificationsCount ?? 0,
      },
      disabled: !!store.state.notifications?.notificationsVisible,
    },
  ]
}

const domainURL = process.env.VUE_APP_DOMAIN_URL

export const PROFILE_BOTTOM_ITEMS: TileItem[] = [
  {
    name: t('transaction_history'),
    icon: require('@bd/assets/icons/history.svg'),
    routerLink: '/profile/transactions',
  },
  {
    name: t('change_password'),
    icon: require('@bd/assets/icons/padlock.svg'),
    action: 'reset-password',
  },
  {
    name: t('terms_and_conditions'),
    icon: require('@bd/assets/icons/rules.svg'),
    externalLink: `${domainURL}biuro/regulamin/`,
  },
  {
    name: t('privacy_policy'),
    icon: require('@bd/assets/icons/privacy.svg'),
    externalLink: `${domainURL}biuro/polityka-prywatnosci/`,
  },
  {
    name: t('logout'),
    icon: require('@bd/assets/icons/logout.svg'),
    action: 'logout',
    containerClass: 'd-md-none',
  },
]
