<template>
  <div class="field-spacing text-input" :class="computedClass">
    <label
      v-if="label && !floating"
      class="mb-2 body-12 text-500"
      :class="errorMessage ? 'label-error' : 'label-valid'"
      :for="name"
    >
      {{ label }}
    </label>
    <i
      v-if="leftIcon !== false"
      :class="[leftIcon, errorMessage ? 'icon-space' : '']"
    />
    <InputText
      :name="name"
      :type="type"
      v-model.trim="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @blur="handleBlur"
      :class="{ 'p-invalid': errorMessage }"
    />
    <i
      @click="$emit('right-icon-click')"
      v-if="rightIcon !== false"
      :class="[rightIcon, errorMessage ? 'icon-space' : '']"
    />
    <label
      v-if="floating"
      :class="errorMessage ? 'label-error' : 'label-valid'"
      :for="name"
    >
      {{ label }}
    </label>
    <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    <small v-if="message" class="body-12 mt-2">{{ message }}</small>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useField } from 'vee-validate'
import InputText from 'primevue/inputtext'

export default defineComponent({
  components: { InputText },
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      default: '',
    },
    rightIcon: {
      required: false,
      type: String,
    },
    leftIcon: {
      required: false,
      type: String,
    },
    floating: {
      type: Boolean,
      default: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['right-icon-click', 'change'],
  setup(props, { emit }) {
    const { value: inputValue, errorMessage, handleBlur } = useField(props.name)
    watch(inputValue, (value) => emit('change', value))

    const computedClass = computed(() => ({
      'p-input-icon-right': !!props.rightIcon,
      'p-input-icon-left': !!props.leftIcon,
      'p-float-label': props.floating,
      'd-flex flex-column': !props.floating,
      'white-bg': props.whiteBackground,
      rounded: props.rounded,
      'adjust-icon': props.label,
    }))

    watch(
      () => props.value,
      (val) => val && (inputValue.value = val),
    )

    return {
      handleBlur,
      errorMessage,
      inputValue,
      computedClass,
    }
  },
})
</script>

<style lang="scss" scoped>
.adjust-icon i {
  margin-top: 2px;
}

.icon-space {
  top: 30px;
}

.rounded i {
  margin-right: 10px !important;
}
input {
  padding-right: 38px;
  background: $alabaster;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: $gray;
  border-color: transparent;
  padding: 10px 14px;
  height: auto;
}
</style>
