<template>
  <div
    :class="[
      'user-avatar',
      'user-type-' + userTypeClass,
      isImage ? 'user-avatar-type-image' : 'user-avatar-type-initials',
    ]"
    :style="computedStyle"
  >
    <span v-if="!isImage" class="initials">{{ initials }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import {
  extractFullNameInitials,
  extractUserTypeInitial,
  UserAvatarInput,
  Avatar,
  UserType,
  useUserTypeClass,
} from '@bd/components'

export default defineComponent({
  props: {
    userType: {
      type: String as PropType<Avatar['userType']>,
      required: true,
      validator: (value: string) => value in UserType,
    },
    user: {
      type: Object as PropType<UserAvatarInput>,
      required: true,
    },
    overrideWithUserType: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const computedUserType = computed(() => props.userType)
    const isImage = computed(
      () => props.user.avatarUrl && !props.overrideWithUserType,
    )

    const computedStyle = computed(() => {
      return isImage.value
        ? `background: url(${props.user.avatarUrl}) 100% / 100% no-repeat`
        : ''
    })

    const initials = computed(() => {
      const { firstName, lastName } = props.user
      return props.overrideWithUserType || !firstName || !lastName
        ? extractUserTypeInitial(props.userType)
        : extractFullNameInitials(firstName, lastName)
    })

    const userTypeClass = useUserTypeClass(computedUserType)

    return { initials, isImage, computedStyle, UserType, userTypeClass }
  },
})
</script>

<style lang="scss" scoped>
.user-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8.75px;
  margin-right: 0.5rem;

  width: 30px;
  height: 30px;
  font-size: 12px;

  &.user-type {
    &-seller {
      color: $dodger-blue;
      background-color: rgba($dodger-blue, 0.1);
    }
    &-buyer {
      color: $zest;
      background-color: rgba($zest, 0.1);
    }
    &-agent,
    &-admin,
    &-callcenter {
      color: $cerulean;
      background-color: rgba($cerulean, 0.1);
    }

    &-developer {
      color: $jungle-green;
      background-color: rgba($jungle-green, 0.1);
    }
  }
}
</style>
