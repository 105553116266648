<template>
  <div
    class="autocomplete field-spacing d-flex flex-column"
    :class="{ 'white-bg': whiteBackground }"
  >
    <label
      class="mb-2 body-12 text-500"
      :class="errorMessage ? 'label-error' : 'label-valid'"
    >
      {{ label }}
    </label>
    <AutoComplete
      v-model="selectionOrQuery"
      :suggestions="filteredDistricts"
      :placeholder="placeholder"
      :delay="200"
      field="districtName"
      @clear="onClear"
      @complete="onComplete($event.query)"
      @blur="onBlur"
      @item-select="value = $event.value"
    />
    <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowRef, watch } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { DistrictDto } from '@bd/api'
import { useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'DistrictAutocomplete',
  components: { AutoComplete },
  props: {
    items: {
      type: Array as PropType<DistrictDto[]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { errorMessage, value } = useOfferField('districtDto')
    const selectionOrQuery = shallowRef<typeof value.value | string>(
      value.value || '',
    )
    const filteredDistricts = shallowRef<DistrictDto[]>([])

    const onClear = () => {
      value.value = undefined
      filteredDistricts.value = props.items
    }

    const onBlur = () => {
      if (filteredDistricts.value.length === 1) {
        selectionOrQuery.value = filteredDistricts.value[0]
        return
      }

      // clear selection value if no district was found
      if (typeof selectionOrQuery.value !== 'object') {
        selectionOrQuery.value = undefined
      }
    }

    const onComplete = (origQuery: string) => {
      const query = origQuery.toLowerCase()
      filteredDistricts.value = props.items.filter((item: DistrictDto) => {
        return item.districtName.toLowerCase().startsWith(query)
      })
    }

    watch(value, (city) => {
      if (city) selectionOrQuery.value = city
    })

    return {
      value,
      selectionOrQuery,
      onComplete,
      onClear,
      filteredDistricts,
      errorMessage,
      onBlur,
    }
  },
})
</script>

<style lang="scss" scoped></style>
