
import Dialog from 'primevue/dialog'
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '../../types'
import CalendarEventAttendeeSelectionList from './CalendarEventAttendeeSelectionList.vue'
import { EventAttendeeAvailableTypes } from './types'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventAttendeeSelectionList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
  },
  emits: ['update:visible', 'selectAttendee', 'searchAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const title = computed(() => t(`userType.${props.userType.toLowerCase()}`))

    const onAttendeeSelected = (
      selectedAttendee: CalendarEventAttendeeItem,
    ) => {
      emit('selectAttendee', selectedAttendee)
      emit('update:visible', false)
    }

    return {
      t,
      title,
      onAttendeeSelected,
    }
  },
})
