
import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['outsideClick', 'update:visible'],
  setup(_props, { emit }) {
    const container = ref<HTMLElement>()

    onClickOutside(container, (event) => {
      emit('outsideClick', event)
    })

    return {
      container,
    }
  },
})
