<template>
  <PageContent
    class="event-details-content"
    :class="{
      'no-actions': possibleEventActions.noneExcludingEdit,
    }"
    :showMobileHeader="true"
    :headerLabel="t('calendar.event_details.page_title')"
    :responsive="true"
    backRoute="/calendar"
  >
    <EventDetails
      v-if="isPresent && !isLoading"
      :event="eventDetailsContent"
      :showEditIcon="!isBuyer"
      @eventAccept="$emit('eventAccept')"
      @eventCancel="$emit('eventCancel')"
      @eventEdit="$emit('eventEdit')"
      @eventReject="$emit('eventReject')"
    />
  </PageContent>
</template>
<script lang="ts">
import { UserCalendarEventDetailsDto } from '@bd/api'
import {
  PageContent,
  useLoadableResourceDataState,
  usePossibleEventActions,
  UserType,
} from '@bd/components'
import { LoadableResource } from '@bd/helpers'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import EventDetails, {
  emits as eventDetailsEmits,
} from './EventDetails/EventDetails.vue'

export default defineComponent({
  components: {
    PageContent,
    EventDetails,
  },
  props: {
    eventDetails: {
      type: Object as PropType<
        LoadableResource<UserCalendarEventDetailsDto | undefined>
      >,
      required: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  emits: [...eventDetailsEmits],
  setup(props) {
    const { t } = useI18n()
    const eventDetails = computed(() => props.eventDetails)
    const eventDetailsContent = computed(() => eventDetails.value?.content)
    const eventDto = computed(() => eventDetailsContent.value?.calendarEventDto)
    const possibleEventActions = usePossibleEventActions(eventDto)
    const isBuyer = computed(() => props.userType === UserType.Buyer)
    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      eventDetails,
    )

    return {
      t,
      isLoading,
      isPresent,
      isEmpty,
      possibleEventActions,
      isBuyer,
      eventDetailsContent,
    }
  },
})
</script>

<style lang="scss" scoped>
@mixin mobile-height() {
  @include breakpoint-down(md) {
    height: calc((var(--vh) * 100) - #{$mobile-header-height});
  }
}
:deep(.event-details-content) {
  background-color: $alabaster;
  @include breakpoint-down(md) {
    height: calc(
      (var(--vh) * 100) + #{$mobile-header-height} + #{$mobile-actions-row-height}
    );
  }
  &.no-actions {
    @include mobile-height();
  }
}
</style>
