import { UserRole } from '@bd/api'

export enum UserType {
  Seller = 'Seller',
  Buyer = 'Buyer',
  Agent = 'Agent',
  Admin = 'Admin',
  CallCenter = 'CallCenter',
  Developer = 'Developer',
}

export interface UserAvatarInput {
  firstName: string
  lastName: string
  avatarUrl?: string
}

export interface Avatar {
  userType: UserType
  user: UserAvatarInput
  overrideWithUserType?: boolean
}

export const userTypeToRoleMap: { [key in UserType]: UserRole } = {
  Admin: UserRole.ADMIN,
  Agent: UserRole.AGENT,
  CallCenter: UserRole.CALL_CENTER,
  Seller: UserRole.SELLER,
  Buyer: UserRole.CUSTOMER,
  Developer: UserRole.SELLER,
}

export const getUserRoleByUserType = (userType: UserType) => {
  return userTypeToRoleMap[userType]
}
