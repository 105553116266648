<template>
  <div class="field-spacing number-input" :class="computedClass">
    <label
      v-if="!floating"
      class="mb-2 body-12 text-500"
      :class="errorMessage ? 'label-error' : 'label-valid'"
      :for="name"
    >
      {{ label }}
    </label>
    <InputNumber
      v-model="inputValue"
      :minFractionDigits="minFractionDigits"
      :mode="mode"
      :currency="currency"
      :suffix="suffix"
      :locale="locale"
      :placeholder="placeholder"
      :disabled="disabled"
      :min="0"
      :max="9999999999"
      :class="{ 'p-invalid': errorMessage }"
    />
    <label
      v-if="floating"
      :class="errorMessage ? 'label-error' : 'label-valid'"
      :for="name"
    >
      {{ label }}
    </label>
    <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    <small v-if="message" class="body-12 mt-2">{{ message }}</small>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useField } from 'vee-validate'
import InputNumber from 'primevue/inputnumber'
import { LOCALISATION_SETTINGS } from '@bd/admin/config'

export default defineComponent({
  components: { InputNumber },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    floating: {
      type: Boolean,
      default: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: 'decimal',
    },
    minFractionDigits: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(props.name)
    const { currency, locale } = LOCALISATION_SETTINGS

    const mode = computed(() => {
      const units = ['currency', 'decimal']
      return units.includes(props.unit) ? props.unit : 'decimal'
    })

    const suffix = computed(() => {
      return props.unit === 'area' ? ' m2' : ''
    })

    const computedClass = computed(
      (): Record<string, boolean> => {
        return {
          'p-float-label': props.floating,
          'd-flex flex-column': !props.floating,
          'white-bg': props.whiteBackground,
        }
      },
    )

    return {
      inputValue,
      errorMessage,
      currency,
      locale,
      mode,
      suffix,
      computedClass,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon-space {
  top: 30px;
}
.number-input {
  &:not(.p-float-label) :deep(.p-inputtext) {
    padding: 15px 0 15px 15px !important;
  }
}
</style>
