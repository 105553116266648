<template>
  <div class="page-content-wrapper">
    <slot name="mobile-header">
      <MobileHeader
        class="d-md-none"
        v-if="showMobileHeader && headerLabel"
        :headerLabel="headerLabel"
        :backRoute="backRoute"
      />
    </slot>
    <div
      class="page-content"
      :class="[
        $attrs.class,
        {
          'bottom-nav-visible': bottomNavigationVisible,
          'mobile-header-visible': showMobileHeader,
          'container responsive': responsive,
        },
      ]"
    >
      <slot name="default" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useAppStore } from '@bd/store-modules'
import MobileHeader from '../MobileHeader/MobileHeader.vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'PageContent',
  components: {
    MobileHeader,
  },
  inheritAttrs: false,
  props: {
    showMobileHeader: {
      type: Boolean,
      default: false,
    },
    headerLabel: {
      type: String,
      required: false,
    },
    backRoute: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useAppStore()
    const bottomNavigationVisible = computed(
      () => store.state.bottomNavigationVisible,
    )

    return {
      bottomNavigationVisible,
    }
  },
})
</script>

<style lang="scss" scoped>
.page-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$top-navigation-height});

  &.responsive {
    padding: 1rem;
  }

  @include breakpoint-down(md) {
    &.mobile-header-visible {
      margin-top: $mobile-header-height;

      &.bottom-nav-visible {
        height: calc(100vh - #{$mobile-header-height});
      }
    }

    &.bottom-nav-visible {
      height: calc(100vh - #{$mobile-navigation-height});
    }
  }
}
</style>
