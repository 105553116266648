
import {
  defineComponent,
  onMounted,
  watchEffect,
  watch,
  computed,
  ref,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAppStore } from './store'
import { AUTH_NAVIGATION_ROUTES } from './config'
import {
  MobileAuthNavigation,
  useToastConfig,
  useMobileViewportDimensions,
  usePWAUpdate,
  ConfirmDialog,
  CITIES_PAGE_SIZE,
} from '@bd/components'
import { shouldShowAuthNavigation } from '@bd/components/Layout'
import { useUserData } from '@bd/components/use'
import { useI18n } from 'vue-i18n'
import { usePrimeVue } from 'primevue/config'
import { I18nValue, translatePrimeVue } from '@bd/components/i18n'
import { isUserLogged } from '@bd/store-modules/modules/auth/getters-helpers'
import Toast from 'primevue/toast'
import * as SW from '@bd/api/firebase-sw'

export default defineComponent({
  components: {
    MobileAuthNavigation,
    Toast,
    ConfirmDialog,
  },
  setup() {
    const router = useRouter()
    const i18n = useI18n()
    const route = useRoute()
    const store = useAppStore()
    const { isLogged } = useUserData()
    const messages = i18n.messages.value as I18nValue
    const primeVue = usePrimeVue()
    const { toastPosition, toastZIndex } = useToastConfig()

    translatePrimeVue(messages, i18n.locale.value, primeVue)

    const showAuthNavigation = shouldShowAuthNavigation(
      route,
      AUTH_NAVIGATION_ROUTES,
    )

    const fetchInitialData = async () => {
      await Promise.all([
        store.dispatch('userProfile/userProfile'),
        store.dispatch('staticData/loadData'),
        store.dispatch('offers/citiesAndProvincesList', CITIES_PAGE_SIZE),
      ])
      if (isUserLogged(store.state)) {
        const fcmToken = await SW.getFcmToken()
        console.log('📋 User was logged in, FCM token:', fcmToken) // TODO: Remove me when the time has come
      }
    }

    watchEffect(() => {
      const bottomNavVisible =
        isLogged.value &&
        ![
          'DashboardOffer',
          'ProfileTransactions',
          'CalendarEventDetails',
        ].includes(router.currentRoute.value.name as string)
      store.dispatch('setBottomNavigationVisibility', bottomNavVisible)
    })

    useMobileViewportDimensions()

    onMounted(() => {
      fetchInitialData()
    })

    const lastOffer = computed(() => store.state?.purchaseOffers?.purchaseOffer)

    watch(lastOffer, (value) => {
      if (value) {
        store.commit('purchaseOffers/SET_PURCHASE_MESSAGE', value)
      }
    })

    const showPWAUpdateDialog = ref(false)
    const { refreshApp } = usePWAUpdate(() => {
      showPWAUpdateDialog.value = true
    })

    const onUpdateConfirm = () => {
      refreshApp()
      showPWAUpdateDialog.value = false
    }

    return {
      t: i18n.t,
      router,
      showAuthNavigation,
      AUTH_NAVIGATION_ROUTES,
      isLogged,
      toastPosition,
      toastZIndex,
      showPWAUpdateDialog,
      onUpdateConfirm,
    }
  },
})
