<template>
  <div class="event-date-chooser">
    <ResponsiveContent>
      <template #mobile>
        <div class="mobile-event-date-chooser mobile-section">
          <h4 class="header h-4">
            {{ t('calendar.events.save_event.date.title') }}
          </h4>
          <div class="date-container">
            <CalendarDateTitle
              class="date-title"
              :date="selectedDate"
              :compact="true"
            />
            <div class="weekday-picker">
              <WeekdayPicker
                mode="date"
                :selected="[selectedDate]"
                :highlightToday="true"
                :multiSelect="false"
                :disablePastDates="true"
                @update:selected="onSelectedDayChange"
              />
            </div>
          </div>
        </div>
      </template>
      <template #desktop>
        <div class="event-save-desktop-section">
          <h4 class="header h-4">
            {{ t('calendar.events.save_event.date.title') }}
          </h4>
          <div class="date-container">
            <MonthCalendar
              :selectedDay="selectedDate"
              :showDateHeader="true"
              :disablePastDates="true"
              size="small"
              @update:selectedDay="onSelectedDayChange"
            />
          </div>
        </div>
      </template>
    </ResponsiveContent>
  </div>
</template>
<script lang="ts">
import { ResponsiveContent, WeekdayPicker } from '@bd/components'
import MonthCalendar from '@bd/components/Calendar/MonthCalendar.vue'
import CalendarDateTitle from '@bd/components/Calendar/CalendarDateTitle.vue'
import { LocalIsoDate } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { canFetchTimeSlots } from '@bd/store-modules/modules/calendar-event/utils'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarDateTitle,
    WeekdayPicker,
    MonthCalendar,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const selectedDate = computed(
      () => store.state.calendarEvent?.eventSaveData.date,
    )

    const canFetchAvailableTimeSlots = computed(() =>
      canFetchTimeSlots(store.state.calendarEvent),
    )

    const onSelectedDayChange = async (day: LocalIsoDate | LocalIsoDate[]) => {
      await store.dispatch('calendarEvent/setSelectedDate', {
        date: Array.isArray(day) ? day[0] : day,
      })
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    return {
      t,
      selectedDate,
      canFetchAvailableTimeSlots,
      onSelectedDayChange,
    }
  },
})
</script>
<style lang="scss" scoped>
@import '../../calendar';
@import '../event-save';

.mobile-event-date-chooser {
  .header {
    margin-bottom: 1rem;
  }
  .date-title {
    margin-bottom: 1rem;
  }
  .date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .weekday-picker {
    width: 100%;
    padding: 0 0.5rem;
  }
}
</style>
