import { toggleElem } from '@bd/helpers'
import { FiltersState, Filters } from '@bd/store-modules/types'

export const rangeValues = [
  'priceFrom',
  'priceTo',
  'roomsFrom',
  'roomsTo',
  'houseAreaFrom',
  'houseAreaTo',
  'landAreaFrom',
  'landAreaTo',
  'floorFrom',
  'floorTo',
  'constructionYearFrom',
  'constructionYearTo',
]
export const singleValues = ['propertyType', 'address']
export const multiValues = ['cityIds', 'districtIds']

export const addOrRemoveFilter = (filters: FiltersState, payload: Filters) => {
  const prop = Object.keys(payload)[0] as keyof Filters
  const value = Object.values(payload)[0] as string | number

  if (payload.pageIndex !== undefined) {
    filters.pageIndex = payload.pageIndex
  }
  if (payload.showAll !== undefined) {
    filters.showAll = payload.showAll
  }
  if (payload.address === undefined) {
    filters.address = ''
  }
  // Clears the district filter when the city filter changes
  if (
    filters.cityIds &&
    payload.cityIds &&
    filters.cityIds !== payload.cityIds
  ) {
    filters.districtIds = []
  }
  const emptyCityIds =
    Array.isArray(payload.cityIds) && payload.cityIds.length === 0
  const emptyDistrictIds =
    Array.isArray(payload.districtIds) && payload.districtIds.length === 0
  if (emptyCityIds || emptyDistrictIds) {
    filters.cityIds = []
    filters.districtIds = []
  }

  const isOneOf = <T extends readonly string[]>(
    prop: string,
    opts: T,
  ): prop is T[number] => opts.includes(prop)

  if (isOneOf(prop, rangeValues)) {
    if (!value) {
      const { [prop]: _value, ...rest } = filters
      return rest
    } else {
      const updatedFilter = { ...filters, [prop]: value }
      return updatedFilter
    }
  }

  if (isOneOf(prop, singleValues)) {
    if (filters[prop] === value) {
      const { [prop]: _value, ...rest } = filters
      return rest
    } else {
      const updatedFilter = { ...filters, [prop]: value }
      return updatedFilter
    }
  }

  if (isOneOf(prop, multiValues)) {
    const current = filters[prop] || []
    const values = new Set([value].flat(Infinity) as number[])

    let next = current as number[]
    values.forEach((value) => {
      next = toggleElem(next, value)
    })
    return { ...filters, [prop]: next }
  }

  return filters
}
