
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserAvatar, Svg, Avatar } from '@bd/components'
import { LOCALISATION_SETTINGS } from '../../config'
import { getFormattedPrice } from '@bd/helpers'
import { CommonOfferRowDto } from '@bd/api'

export default defineComponent({
  name: 'DashboardCard',
  components: {
    UserAvatar,
    Svg,
  },
  emits: ['card-button-click', 'card-click'],

  props: {
    item: {
      type: Object as PropType<CommonOfferRowDto>,
      required: true,
    },
    displayedIconDetails: {
      type: Array as PropType<string[]>,
      default: [],
      required: false,
    },
    displayBuildYear: {
      type: Boolean,
      default: false,
      required: false,
    },
    footerButton: {
      type: Boolean,
      required: false,
    },
    avatar: {
      type: Object as PropType<Avatar>,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
    isReversedAddress: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS

    const image = computed(() => {
      const imageUrl = props.item?.imageUrl
      return imageUrl ?? require('@bd/assets/images/no-image.svg')
    })

    const street = computed(() =>
      props.item?.street ? `${t('street_prefix')} ${props.item?.street}` : '',
    )
    const city = computed(() => props.item?.cityDto?.cityName ?? '')
    const district = computed(() => props.item?.districtDto?.districtName)

    const address = computed(() => {
      if (!street.value && !city.value) {
        return `(${t('no_information')})`
      }
      if (district.value) {
        return `${street.value}\n${city.value}, ${district.value}`
      }
      return `${street.value}\n${city.value}`
    })

    const reversedAddress = computed(() => {
      if (!street.value && !city.value) {
        return `(${t('no_information')})`
      }
      if (district.value) {
        return `${district.value}, ${street.value}\n ${city.value}`
      }
      return `${street.value}\n${city.value}`
    })

    const avatarName = computed(() => {
      return props.avatar?.user.firstName
        ? `${props.avatar.user.firstName} ${props.avatar.user.lastName}`
        : `(${t('no_information')})`
    })

    const detailsToDisplay = computed(() => {
      const details = props.displayedIconDetails
      const { houseArea, landArea } = props.item || {}

      const rooms = details.includes('rooms') ? props.item.rooms : null
      const area = details.includes('area') ? houseArea || landArea : null
      const floor = details.includes('floor') ? props.item.floor : null

      return {
        hasDetails: rooms != null || area != null || floor != null,
        rooms,
        area,
        floor,
        levels: props.item.levels,
      }
    })

    return {
      t,
      getFormattedPrice,
      currency,
      locale,
      image,
      address,
      reversedAddress,
      avatarName,
      detailsToDisplay,
    }
  },
})
