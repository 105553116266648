import { PropertyMarket, PropertyType } from '@bd/api'
import * as Yup from 'yup'
import { i18n } from '../vue-i18n-next-plugin'

// * nullable()            == accept null
// * defined()             == prohibit undefined
// * required()            == prohibit lack of value
// * nullable().required() == prohibit lack of value but treat null as lack of
//                            value instead of type error

const t = i18n.global.t

const maxNumberAllowed = Number.MAX_SAFE_INTEGER

const levelsValidation = () => {
  return Yup.number()
    .nullable()
    .min(0)
    .max(maxNumberAllowed)
    .test(
      'levels',
      t('validation.levels_cant_be_lower_than_floor'),
      (value, ctx) => {
        if (value == null) {
          return true
        }
        return value >= ctx.parent.floor
      },
    )
}

Yup.setLocale({
  mixed: {
    default: t('validation.invalid'),
    defined: t('validation.required'),
    required: t('validation.required'),
  },
  string: {
    email: t('validation.email'),
    min: t('validation.min'),
    max: t('validation.max'),
  },
  number: {
    min: t('validation.min_max_number'),
    max: t('validation.min_max_number'),
  },
})

const phoneNumber = Yup.string().test(
  'phone',
  t('validation.must_contain__min_9_characters'),
  (value: string | undefined) => {
    const phoneRegex = /^[0-9]{9,15}$/
    const isValidPhone = typeof value === 'string' && phoneRegex.test(value)
    return isValidPhone
  },
)

const email = Yup.string().email().max(200)
const password = Yup.string()
  .min(8)
  .max(50)
  .matches(/\d/, t('validation.must_contain_number'))
  .matches(/[A-Z]/, t('validation.must_contain_capital_letter'))

export const loginSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
})

export const resetSchema = Yup.object().shape({
  email: email.required(),
})

export const registerSchema = Yup.object().shape({
  email: email.required(),
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).required(),
  phoneNumber: phoneNumber.required(),
  password: password.required(),
  passwordRepeat: password
    .test('passwords-match', t('validation.password'), function (value) {
      return this.parent.password === value
    })
    .required(),
})

export const addClientSchema = Yup.object().shape({
  phoneNumber: phoneNumber.required(),
  description: Yup.string().required(),
  city: Yup.string().max(10000).required(),
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).nullable(),
  note: Yup.string().max(10000).nullable(),
  district: Yup.string().max(10000).nullable(),
  areaMin: Yup.number().min(0).nullable(),
  areaMax: Yup.number().min(0).nullable(),
  priceMin: Yup.number().min(0).nullable(),
  priceMax: Yup.number().min(0).nullable(),
  market: Yup.string().nullable(),
})

export const addClientCommonSchema = {
  email: Yup.string().email().max(200).nullable(), // Email is not required by design
  phoneNumber: phoneNumber.required(),
  description: Yup.string().max(10000).required(),
  note: Yup.string().max(10000).nullable(),
  firstName: Yup.string().max(255).required(),
}

export const addBuyerSchema = Yup.object().shape({
  ...addClientCommonSchema,
  lastName: Yup.string().max(255).nullable(),
  customerCity: Yup.string().max(1000).nullable().required(),
  customerAreaMin: Yup.number().min(0).nullable(),
  customerAreaMax: Yup.number().min(0).nullable(),
  customerPriceMin: Yup.number().min(0).nullable(),
  customerPriceMax: Yup.number().min(0).nullable(),
  customerMarket: Yup.string()
    .oneOf([null, ...Object.values(PropertyMarket)])
    .nullable(),
  agent: Yup.object().nullable(),
})

export const addSellerSchema = Yup.object().shape({
  ...addClientCommonSchema,
  lastName: Yup.string().max(255).required(),
  sellerDeveloper: Yup.boolean().required(),
  agent: Yup.object().defined().nullable(),
})

const isApartmentOrOfficeOrHouse = (propertyType: PropertyType) =>
  propertyType === PropertyType.APARTMENT ||
  propertyType === PropertyType.OFFICE ||
  propertyType === PropertyType.HOUSE

const isOfficeOrPlot = (propertyType: PropertyType) =>
  propertyType === PropertyType.OFFICE || propertyType === PropertyType.PLOT

const isPlot = (propertyType: PropertyType) =>
  propertyType === PropertyType.PLOT

export const offerSchema: Yup.AnyObjectSchema = Yup.object().shape({
  propertyType: Yup.string().nullable().required(),
  cityDto: Yup.object().nullable().required(),
  agentHasKeys: Yup.boolean().nullable().required(),
  offerSellerDto: Yup.object().nullable().required(),
  showOnLandingPage: Yup.boolean().nullable().required(),
  street: Yup.string().nullable().required(),
  title: Yup.string().nullable().required(),
  description: Yup.string().nullable().required(),
  draft: Yup.boolean().required(),
  longitude: Yup.number().nullable().required(),
  latitude: Yup.number().nullable().required(),
  price: Yup.number().min(0).max(maxNumberAllowed).nullable().required(),
  buildingNumber: Yup.string().when('propertyType', {
    is: isOfficeOrPlot,
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable().required(),
  }),
  houseArea: Yup.number().when('propertyType', {
    is: isPlot,
    then: Yup.number().min(0).max(maxNumberAllowed).nullable(),
    otherwise: Yup.number().min(0).max(maxNumberAllowed).nullable().required(),
  }),
  rooms: Yup.number().when('propertyType', {
    is: isOfficeOrPlot,
    then: Yup.number().min(1).max(maxNumberAllowed).nullable(),
    otherwise: Yup.number().min(1).max(maxNumberAllowed).nullable().required(),
  }),
  propertyMarket: Yup.string().when('propertyType', {
    is: isOfficeOrPlot,
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable().required(),
  }),
  landArea: Yup.number().when('propertyType', {
    is: isApartmentOrOfficeOrHouse,
    then: Yup.number().min(0).max(maxNumberAllowed).nullable(),
    otherwise: Yup.number().min(0).max(maxNumberAllowed).nullable().required(),
  }),
  internet: Yup.boolean().nullable(),
  windowsType: Yup.string().nullable(),
  apartmentLevels: Yup.string().nullable(),
  heatingType: Yup.string().nullable(),
  bathrooms: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  kitchenType: Yup.string().nullable(),
  buildingFinishState: Yup.string().nullable(),
  buildingMaterial: Yup.string().nullable(),
  buildingType: Yup.string().nullable(),
  security: Yup.boolean().nullable(),
  parkingSpot: Yup.boolean().nullable(),
  garden: Yup.boolean().nullable(),
  balcony: Yup.boolean().nullable(),
  elevator: Yup.boolean().nullable(),
  basement: Yup.boolean().nullable(),
  garage: Yup.boolean().nullable(),
  propertyOwnership: Yup.string().nullable(),
  rent: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  constructionYear: Yup.number().min(1).max(maxNumberAllowed).nullable(),
  levels: levelsValidation(),
  floor: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  districtDto: Yup.object().nullable(),
  houseNumber: Yup.string().nullable(),
})

export const draftOfferSchema: Yup.AnyObjectSchema = Yup.object().shape({
  cityDto: Yup.object().nullable().required(),
  agentHasKeys: Yup.boolean().nullable().required(),
  apartmentLevels: Yup.string().nullable(),
  balcony: Yup.boolean().nullable(),
  basement: Yup.boolean().nullable(),
  bathrooms: Yup.number().max(maxNumberAllowed).nullable(),
  buildingFinishState: Yup.string().nullable(),
  buildingMaterial: Yup.string().nullable(),
  buildingNumber: Yup.string().nullable(),
  buildingType: Yup.string().nullable(),
  constructionYear: Yup.number().max(maxNumberAllowed).nullable(),
  description: Yup.string().nullable(),
  districtDto: Yup.object().nullable(),
  draft: Yup.boolean().required(),
  elevator: Yup.boolean().nullable(),
  floor: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  garage: Yup.boolean().nullable(),
  garden: Yup.boolean().nullable(),
  heatingType: Yup.string().nullable(),
  houseArea: Yup.number().min(1).max(maxNumberAllowed).nullable(),
  houseNumber: Yup.string().nullable(),
  internet: Yup.boolean().nullable(),
  kitchenType: Yup.string().nullable(),
  landArea: Yup.number().min(1).max(maxNumberAllowed).nullable(),
  latitude: Yup.number().required(),
  levels: levelsValidation(),
  longitude: Yup.number().nullable(),
  parkingSpot: Yup.boolean().nullable(),
  price: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  propertyMarket: Yup.string().nullable(),
  propertyOwnership: Yup.string().nullable(),
  propertyType: Yup.string().nullable().required(),
  rent: Yup.number().min(0).max(maxNumberAllowed).nullable(),
  rooms: Yup.number().min(1).max(maxNumberAllowed).nullable(),
  security: Yup.boolean().nullable(),
  offerSellerDto: Yup.object().nullable(),
  showOnLandingPage: Yup.boolean().nullable(),
  street: Yup.string().nullable().required(),
  title: Yup.string().nullable(),
  windowsType: Yup.string().nullable(),
})

export const addOrEditEmployeeSchema = Yup.object().shape({
  userId: Yup.number(),
  avatar: Yup.object(),
  email: Yup.string().defined().nullable(),
  firstName: Yup.string().max(255).defined().nullable(),
  lastName: Yup.string().max(255).defined().nullable(),
  roles: Yup.array().of(Yup.string()).defined().nullable(),
  phoneNumber: Yup.string(),
})

export const addClientNoteSchema: Yup.AnyObjectSchema = Yup.object().shape({
  note: Yup.string().max(10000).required(),
})

export const statisticsSchema = Yup.object().shape({
  offerId: Yup.number().required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
  numberOfViews: Yup.number().min(0).required().nullable(),
  numberOfInquiries: Yup.number().min(0).required().nullable(),
})
