<template>
  <div class="actions-group" :class="{ mobile: isMobile }">
    <Button
      v-if="actionFlags.editionPossibility"
      class="action-btn p-button-secondary d-none d-md-block"
      :label="t('calendar.event_details.edit')"
      @click="$emit('action', EventActionType.Edit)"
    />
    <Button
      v-if="
        actionFlags.cancelationPossibility || actionFlags.rejectionPossibility
      "
      class="action-btn p-button-secondary"
      :label="rejectOrCancelLabel"
      @click="onRejectOrCancel"
    />
    <Button
      v-if="actionFlags.acceptancePossibility"
      class="action-btn p-button-primary"
      :label="t('calendar.event_details.accept.title')"
      @click="$emit('action', EventActionType.Accept)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { EventActionType } from '../types'
import { EventDetailsActionFlags } from '@bd/api/common'
import { useBreakpoints } from '@bd/components'

export default defineComponent({
  components: {},
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    actionFlags: {
      type: Object as PropType<EventDetailsActionFlags>,
      required: true,
    },
  },
  emits: ['action'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { actionFlags } = toRefs(props)

    const { mobileSize } = useBreakpoints()

    const cancelKey = computed(() => {
      return mobileSize.value ? 'cancel_full' : 'cancel.title'
    })

    const rejectOrCancelLabel = computed(() =>
      t(
        `calendar.event_details.${
          actionFlags.value.rejectionPossibility
            ? 'reject.title'
            : cancelKey.value
        }`,
      ),
    )

    const onRejectOrCancel = () => {
      emit(
        'action',
        props.actionFlags.rejectionPossibility
          ? EventActionType.Reject
          : EventActionType.Cancel,
      )
    }

    return {
      t,
      EventActionType,
      cancelKey,
      rejectOrCancelLabel,
      onRejectOrCancel,
    }
  },
})
</script>

<style lang="scss" scoped>
.actions-group {
  display: flex;
  margin: 10px 0;

  .action-btn {
    width: 162px;
    &:not(:first-of-type) {
      margin-left: 0.25rem;
    }

    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
  }

  &.mobile {
    width: 100%;
    .action-btn {
      flex: 1 1 auto;
    }
  }
}
</style>
