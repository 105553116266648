<template>
  <component :is="is" class="icon" ref="contents" />
</template>
<script lang="ts">
import { defineComponent, nextTick, onUnmounted, ref, watchEffect } from 'vue'
/**
 * This function should return a version of nextTick that will only call the
 * callback when component is still mounted. This is needed in cases where the
 * callback function is the same one that schedules waiting for render because
 * without checking if the component is still mounted we may end up in infinite
 * loop
 */
export const getWaitForRender = () => {
  let willRender = true
  onUnmounted(() => {
    willRender = false
  })
  const wrapCallback = (cb: () => void) => () => {
    if (willRender) cb()
  }
  return (cb: () => void) => {
    return willRender && nextTick(wrapCallback(cb))
  }
}
const CACHE: Partial<{ [src: string]: SVGSVGElement | Promise<void> }> = {}
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
const loadSvg = (src: string): Promise<void> => {
  const loadLoop = async (tries: number): Promise<void> => {
    try {
      const response = await fetch(src)
      const parser = new DOMParser()
      const result = parser.parseFromString(await response.text(), 'text/xml')
      const svg = result.getElementsByTagName('svg')[0]
      svg.setAttribute('width', '100%')
      svg.setAttribute('height', '100%')
      CACHE[src] = svg
    } catch {
      // exponential backoff
      await delay(1000 * 1.4 ** tries)
      return loadLoop(tries + 1)
    }
  }
  return (CACHE[src] = loadLoop(0))
}
/**
 * This component allows to embed svg inline instead of using opaque <img> tag.
 * This in turn allows svg content to be styled via CSS
 */
export default defineComponent({
  props: {
    src: String,
    is: { type: String, default: 'i' },
  },
  setup(props) {
    const contents = ref<HTMLElement>()
    const unmountSvg = () => {
      while (contents.value?.firstChild) {
        contents.value.firstChild.remove()
      }
    }
    const mountSvg = (source: SVGSVGElement) => {
      if (!contents.value) return
      unmountSvg()
      contents.value.appendChild(source.cloneNode(true))
    }
    const waitForRender = getWaitForRender()
    watchEffect(
      function refresh() {
        if (!props.src) {
          unmountSvg()
          return
        }
        const entry = CACHE[props.src]
        if (!entry) {
          unmountSvg()
          loadSvg(props.src).then(refresh)
        } else if ('then' in entry) {
          unmountSvg()
          entry.then(refresh)
        } else if (!contents.value) {
          waitForRender(refresh)
        } else {
          mountSvg(entry)
        }
      },
      { flush: 'post' },
    )
    return { contents }
  },
})
</script>
<style lang="scss" scoped>
.icon {
  box-sizing: content-box;
  display: inline-block;
  fill: currentColor;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  line-height: 1;
}
</style>
