import { BaseAddress, CalendarEventOfferDetailsDto, ClientDto } from '@bd/api'
import { LocalIsoDate } from '@bd/helpers'
import {
  CalendarEventAttendee,
  CalendarEventMeetingType,
} from '@bd/store-modules/types'

export interface CalendarNavigationEvent {
  month: number
  year: number
}

export interface EventDuration {
  start: LocalIsoDate | string
  end: LocalIsoDate | string
}

export enum EventActionType {
  Accept = 'Accept',
  Cancel = 'Cancel',
  Reject = 'Reject',
  Edit = 'Edit',
}

export interface CalendarEventSelectedOffer {
  offer?: CalendarEventOfferDetailsDto
  seller?: Partial<ClientDto>
}

export interface SelectableListItem {
  selected?: boolean
}
export interface CalendarEventOfferItem extends SelectableListItem {
  id: number
  displayableId: string
  thumbnailUrl: string
  address: BaseAddress
  seller: CalendarEventAttendee
  draft: boolean
}

export interface CalendarEventAttendeeItem
  extends CalendarEventAttendee,
    SelectableListItem {}

export interface CalendarEventMeetingTypeItem extends CalendarEventMeetingType {
  selected: boolean
  tileId: number
  disabled?: boolean
}
