
import { ResponsiveContent, UserType } from '@bd/components'
import { defineComponent, computed, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendeeChooser from './CalendarEventAttendeeChooser.vue'
import { useAppStore } from '@bd/store-modules'
import CalendarEventAttendeeSelectionOverlay from './CalendarEventAttendeeSelectionOverlay.vue'
import CalendarEventAttendeeSelectionModal from './CalendarEventAttendeeSelectionModal.vue'
import { TemplateRef } from '@bd/helpers'
import { CalendarEventAttendeeItem } from '../../types'
import { CalendarEventAttendee } from '@bd/store-modules/types'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventAttendeeChooser,
    CalendarEventAttendeeSelectionOverlay,
    CalendarEventAttendeeSelectionModal,
  },
  props: {},
  emits: ['searchAttendee'],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()

    const buyerChooserWrapper = shallowRef<TemplateRef>()
    const selectedMeetingType = computed(
      () => store.state.calendarEvent?.eventSaveData.meetingType,
    )

    const attendeeSelectionModalVisible = ref(false)

    const selectedSeller = computed(
      () => store.state.calendarEvent?.eventSaveData.seller,
    )
    const selectedBuyer = computed(
      () => store.state.calendarEvent?.eventSaveData.buyer,
    )

    const isBuyerRequired = computed(
      () =>
        store.state.calendarEvent?.eventSaveData.meetingType
          ?.customerPresenceRequired,
    )

    const attendeeOverlayVisible = ref(false)

    const isBuyerChooserClicked = ($event: PointerEvent) => {
      return (
        $event.target === buyerChooserWrapper.value ||
        $event.composedPath().includes(buyerChooserWrapper.value!)
      )
    }

    const onAttendeeChooserClick = ($event: PointerEvent) => {
      if (isBuyerChooserClicked($event)) {
        attendeeOverlayVisible.value = !attendeeOverlayVisible.value
      }
    }

    const onBuyerSelect = async (attendee: CalendarEventAttendeeItem) => {
      const { selected: _, ...rest } = attendee
      const selectedBuyerPayload: CalendarEventAttendee = rest
      await store.dispatch('calendarEvent/setSelectedBuyer', {
        buyer: selectedBuyerPayload,
      })
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    const onBuyerClear = async () => {
      const shouldClearBuyerRequirement =
        selectedMeetingType.value?.isCustom &&
        selectedMeetingType.value.customerPresenceRequired
      await store.dispatch('calendarEvent/setSelectedBuyer', {
        buyer: undefined,
      })
      if (shouldClearBuyerRequirement) {
        store.dispatch('calendarEvent/clearBuyerRequired')
      }
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }
    const onAttendeeChooserOutsideClick = ($event: PointerEvent) => {
      if (!isBuyerChooserClicked($event)) {
        attendeeOverlayVisible.value = false
      }
    }

    return {
      t,
      UserType,
      attendeeSelectionModalVisible,
      selectedSeller,
      selectedBuyer,
      buyerChooserWrapper,
      isBuyerRequired,
      attendeeOverlayVisible,
      onAttendeeChooserClick,
      onBuyerSelect,
      onBuyerClear,
      onAttendeeChooserOutsideClick,
    }
  },
})
