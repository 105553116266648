<template>
  <div class="offline-screen">
    <img class="offline-img" :src="offlineImg.src" :alt="t('offline.title')" />
    <h1 class="title h-1 mb-4 mt-5 text-center">{{ t('offline.title') }}</h1>
    <div class="description body-bg mb-4 text-center">
      <div>{{ t('offline.description_1') }}</div>
      <div>{{ t('offline.description_2') }}</div>
    </div>
    <Button
      class="p-button-primary refresh-btn"
      :label="t('offline.refresh')"
      @click="refresh"
    />
  </div>
</template>

<script lang="ts">
import { useOfflineImg } from '@bd/components'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  setup() {
    const { t } = useI18n()

    const refresh = () => {
      window.location.reload()
    }

    const offlineImg = useOfflineImg()

    return {
      t,
      refresh,
      offlineImg,
    }
  },
})
</script>

<style lang="scss" scoped>
.offline-screen {
  @include flex($direction: column);
  @include uniform-size(100%);
}

.refresh-btn {
  $btn-padding: 2rem;
  padding-left: $btn-padding;
  padding-right: $btn-padding;
}

.description {
  max-width: 260px;
}
</style>
