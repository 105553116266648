import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { ErrorResponse } from '@bd/api'

const t = i18n.global.t

const errorResponseKeys: Record<string, string> = {
  OFFER_NOT_FOUND: t('validation.offer_not_found'),
  CUSTOMER_NOT_FOUND: t('validation.customer_not_found'),
  PURCHASE_OFFER_NOT_FOUND: t('validation.purchase_offer_not_found'),
}

export const transactionHistoryService = {
  handleError: function (e: ErrorResponse | undefined): string | undefined {
    if (e && e.resultKey) {
      return errorResponseKeys[e.resultKey]
    } else if (e && e.code) {
      return t('validation.failed')
    }
  },
}
