<template>
  <div
    class="autocomplete field-spacing d-flex flex-column"
    :class="{ 'white-bg': whiteBackground }"
  >
    <label
      class="mb-2 body-12 text-500"
      :class="errorMessage ? 'label-error' : 'label-valid'"
    >
      {{ label }}
    </label>
    <AutoComplete
      v-model="selectionOrQuery"
      :suggestions="items"
      :placeholder="placeholder"
      :delay="200"
      :class="{ 'autocomplete-error': errorMessage }"
      field="cityName"
      @clear="onClear"
      @complete="$emit('search', $event.query)"
      @item-select="value = $event.value"
    />
    <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowRef, watch } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { CityDto } from '@bd/api'
import { useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'CityAutocomplete',
  components: { AutoComplete },
  emits: ['search'],
  props: {
    items: {
      type: Array as PropType<CityDto[]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { value, errorMessage } = useOfferField('cityDto')
    const selectionOrQuery = shallowRef<typeof value.value | string>(
      value.value || '',
    )

    const onClear = () => {
      value.value = undefined
      emit('search', '')
    }

    watch(value, (city) => {
      if (city) selectionOrQuery.value = city
    })

    return {
      value,
      selectionOrQuery,
      onClear,
      errorMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
.autocomplete-error {
  border: $error-border;
  border-radius: 4px;
}
</style>
