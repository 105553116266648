import { useRootData } from '@bd/components'
import { computed, ComputedRef } from 'vue'
import { LoadableResource } from '@bd/helpers'

export interface ResourceDataState<T> {
  isLoading: boolean // Resource is loading
  isPresent: boolean // Resource is present (ignores loading)
  isEmpty: boolean // Resource is empty & not loading
  resource: T // Original resource
}

export type ComputedRefWrapper<T> = {
  [P in keyof T]: ComputedRef<T[P]>
}

/**
 * Composable function used to reactively describe given resource's state.
 * It is useful to be able to differentiate between following states:
 * Resource is loading, resource is present or empty.
 * It also returns the original resource
 * @param resource Resource to describe
 * @param isPresentPredicate (Optional) Custom predicate to modify default 'isPresent' descriptor.
 * Could for example be used to customize 'isPresent' to return true only when the array has length
 * @returns @see ResourceDataState
 *
 * @example
 * // #1 For object resource
 * const { isPresent } = useResourceDataState(store.state.profile)
 *
 * // #2 For array resource (By default 'isPresent' would return true even if the array was empty)
 * const { isPresent } = useResourceDataState(store.state.users, (users) => !!users.length)
 */
export const useResourceDataState = <T>(
  resource: ComputedRef<T>,
  isPresentPredicate?: (res: T) => boolean,
): ComputedRefWrapper<ResourceDataState<T>> => {
  const { isLoading } = useRootData()
  const isPresent = computed(
    () => isPresentPredicate?.call(null, resource.value) ?? !!resource.value,
  )
  return {
    isLoading: isLoading,
    isPresent: isPresent,
    isEmpty: computed(() => !isPresent.value && !isLoading.value),
    resource: resource,
  }
}

export type LoadableResourceDataState<T> = Omit<
  ResourceDataState<T>,
  'resource'
> & { resource: T | undefined }

/**
 * Variant of useResourceDataState that supports LoadableResource type
 */
export const useLoadableResourceDataState = <T>(
  resource: ComputedRef<LoadableResource<T> | undefined>,
  isPresentPredicate?: (res: LoadableResource<T> | undefined) => boolean,
): ComputedRefWrapper<LoadableResourceDataState<T>> => {
  const isPresent = computed(
    () =>
      isPresentPredicate?.call(null, resource.value) ??
      !!resource.value?.content,
  )
  const isLoading = computed(() => !!resource.value?.isLoading)
  return {
    isLoading: isLoading,
    isPresent: isPresent,
    isEmpty: computed(() => !isPresent.value && !isLoading.value),
    resource: computed(() => resource.value?.content),
  }
}
