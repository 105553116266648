<template>
  <div class="calendar-event-offers-selection-list">
    <TabView
      class="uniform"
      v-if="isAgent"
      :activeIndex="activeTabIndex"
      @tab-change="onTabChange"
    >
      <TabPanel
        :header="t('calendar.events.save_event.offers.mine')"
        v-if="isAgent"
      >
        <CalendarEventOffersSearchableList
          :searchPhrase="offersSearchPhrase"
          :offers="offers"
          @selectOffer="onOfferSelected"
          @searchOffer="onOfferSearch"
          @update:searchPhrase="onSearchPhraseUpdate"
        >
          <template #empty="{ source }">
            <EmptyState
              :title="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.title
                  : emptyStates.NoSearchResults.title
              "
              :description="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.description
                  : emptyStates.NoSearchResults.description
              "
            />
          </template>
        </CalendarEventOffersSearchableList>
      </TabPanel>
      <TabPanel :header="t('calendar.events.save_event.offers.all')">
        <CalendarEventOffersSearchableList
          :searchPhrase="offersSearchPhrase"
          :offers="offers"
          @selectOffer="onOfferSelected"
          @searchOffer="onOfferSearch"
          @update:searchPhrase="onSearchPhraseUpdate"
        >
          <template #empty="{ source }">
            <EmptyState
              :title="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.title
                  : emptyStates.NoSearchResults.title
              "
              :description="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.description
                  : emptyStates.NoSearchResults.description
              "
            />
          </template>
        </CalendarEventOffersSearchableList>
      </TabPanel>
    </TabView>
    <template v-else>
      <div class="list-singletab-wrapper">
        <CalendarEventOffersSearchableList
          :searchPhrase="offersSearchPhrase"
          :offers="offers"
          @selectOffer="onOfferSelected"
          @searchOffer="onOfferSearch"
          @update:searchPhrase="onSearchPhraseUpdate"
        >
          <template #empty="{ source }">
            <EmptyState
              :title="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.title
                  : emptyStates.NoSearchResults.title
              "
              :description="
                source === EmptyStateSource.NoOffersAdded
                  ? emptyStates.NoOffersAdded.description
                  : emptyStates.NoSearchResults.description
              "
            />
          </template>
        </CalendarEventOffersSearchableList>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { ResultPageDto, UserRole } from '@bd/api'
import { MeetingOfferDto } from '@bd/api/common/types/calendar-event'
import {
  InjectTokens,
  MEETING_OFFERS_PAGE_SIZE,
  UserType,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { LoadableOptional } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import {
  getOffersSearchPhrase,
  getSelectedOffersTab,
} from '@bd/store-modules/modules/calendar-event/getters-helpers'
import {
  MeetingOfferSearchPayload,
  OfferSelectionTab,
} from '@bd/store-modules/types/calendarEvent'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import { computed, ComputedRef, defineComponent, inject, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventOfferItem } from '../../types'
import CalendarEventOffersSearchableList from './CalendarEventOffersSearchableList.vue'
import { EmptyStateSource } from './types'

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    EmptyState,
    CalendarEventOffersSearchableList,
  },
  emits: ['selectOffer', 'searchOffer', 'tabChange'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()

    const userType = inject<Ref<UserType | undefined>>(
      InjectTokens.eventSaveUserType,
    )

    const isAgent = computed(
      () =>
        userType?.value === UserType.Agent ||
        !!store.state.userProfile?.user.roles.includes(UserRole.AGENT),
    )

    const emptyStates = {
      [EmptyStateSource.NoOffersAdded]: {
        title: t(
          'calendar.events.save_event.offers.empty_state.no_offers_added.title',
        ),
        description: t(
          'calendar.events.save_event.offers.empty_state.no_offers_added.description',
        ),
      },
      [EmptyStateSource.NoSearchResults]: {
        title: t(
          'calendar.events.save_event.offers.empty_state.no_search_results.title',
        ),
        description: t(
          'calendar.events.save_event.offers.empty_state.no_search_results.description',
        ),
      },
    }

    const offers: ComputedRef<
      LoadableOptional<ResultPageDto<MeetingOfferDto> | undefined>
    > = computed(() => ({
      payload: store.state.calendarEvent?.eventSaveMetaData.offers,
    }))

    const activeTabIndex = computed<OfferSelectionTab | undefined>(() =>
      getSelectedOffersTab(store),
    )

    const offersSearchPhrase = computed<string>(() =>
      getOffersSearchPhrase(store),
    )

    const fetchMeetingOffers = async () => {
      const payload: MeetingOfferSearchPayload = {
        isAgent: isAgent.value,
        pageSize: MEETING_OFFERS_PAGE_SIZE,
      }
      await store.dispatch('calendarEvent/getMeetingOffers', payload)
    }

    const onOfferSelected = (selectedOffer: CalendarEventOfferItem) => {
      emit('selectOffer', selectedOffer)
    }

    const onOfferSearch = (searchPhrase: string) => {
      emit('searchOffer', searchPhrase)
      fetchMeetingOffers()
    }

    const onSearchPhraseUpdate = (searchPhrase: string) => {
      store.dispatch('calendarEvent/setOffersSearchPhrase', searchPhrase)
    }

    const onTabChange = async ($event: {
      originalEvent: Event
      index: number
    }) => {
      const tab = OfferSelectionTab[$event.index]
      emit('tabChange', tab)
      await store.dispatch('calendarEvent/setOfferSelectedTab', $event.index)
      await fetchMeetingOffers()
    }

    fetchMeetingOffers()

    return {
      t,
      offers,
      emptyStates,
      offersSearchPhrase,
      isAgent,
      activeTabIndex,
      onOfferSelected,
      onOfferSearch,
      onSearchPhraseUpdate,
      onTabChange,
      EmptyStateSource,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';

:deep(.p-tabview) {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 0.25rem;
  .p-tabview-title {
    font-size: 0.875rem;
  }
  .p-tabview-nav {
    @include breakpoint-up(md) {
      padding: 0 1.5rem;
    }
  }
  .p-tabview-panels {
    height: 100%;
    overflow-y: hidden;

    .p-tabview-panel {
      @include results-list-container;
    }
  }
}
.calendar-event-offers-selection-list {
  height: 100%;
}
.list-singletab-wrapper {
  @include results-list-container;
  padding: 1rem;
}
</style>
