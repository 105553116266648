
import {
  CalendarEventMeetingTypeItem,
  ResponsiveContent,
  useMappedMeetingTypes,
} from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { getPredefinedMeetingTypes } from '@bd/store-modules/modules/static-data/getters-helpers'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventMeetingTypeSelectionGrid from './CalendarEventMeetingTypeSelectionGrid.vue'
import CalendarEventMeetingTypeSelectionModal from './CalendarEventMeetingTypeSelectionModal.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventMeetingTypeSelectionGrid,
    CalendarEventMeetingTypeSelectionModal,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const selectedMeetingType = computed(
      () => store.state.calendarEvent?.eventSaveData.meetingType,
    )

    const meetingTypeSelectionModalVisible = ref(false)

    const predefinedMeetingTypes = computed(
      () => getPredefinedMeetingTypes(store)?.content,
    )
    const meetingTypes = useMappedMeetingTypes(predefinedMeetingTypes)

    const isMeetingTypeChosen = computed(() => !!selectedMeetingType.value)

    const onMeetingTypeSelect = async (
      selectedMeetingType: CalendarEventMeetingTypeItem,
    ) => {
      const { selected: _, tileId: __, ...rest } = selectedMeetingType
      const meetingType: CalendarEventMeetingType = {
        ...rest,
      }
      await store.dispatch('calendarEvent/setSelectedMeetingType', meetingType)
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    return {
      t,
      meetingTypeSelectionModalVisible,
      isMeetingTypeChosen,
      meetingTypes,
      selectedMeetingType,
      onMeetingTypeSelect,
    }
  },
})
