<template>
  <component :is="is" class="event-list-item" :class="eventClass">
    <div class="wrapper">
      <div class="event-icon responsive">
        <Svg :src="require('@bd/assets/icons/navigation/calendar.svg')" />
      </div>
      <div class="data-group-col">
        <div>
          <span>{{ eventTime.start }} - {{ eventTime.end }}</span>
          <h3 class="event-title text-truncate h-3">
            {{ event.calendarEventDto.meetingTypeDto.meetingTypeName }}
          </h3>
        </div>

        <div v-if="showAddress && address" class="event-address">
          <span class="city"> {{ address.cityDto.cityName }}, </span>
          <span v-if="address.districtDto">
            {{ address.districtDto?.districtName }},
          </span>
          {{ address.street }}
          {{ address.buildingNumber }}{{ address.houseNumber }}
        </div>
      </div>
      <div class="navigate-icon">
        <i class="pi pi-angle-right" />
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { UserCalendarEventDto } from '@bd/api'
import { Svg } from '@bd/components'
import {
  useEventAddress,
  useEventDuration,
  useEventStatusClass,
  useFormattedDate,
  useUserData,
} from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: { Svg },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDto>,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    is: {
      type: String,
      default: 'li',
    },
  },
  setup(props) {
    const calendarEvent = computed(() => props.event.calendarEventDto)
    const offer = computed(() => calendarEvent.value.calendarEventOfferDto)
    const date = computed(() => calendarEvent.value.date)
    const { user } = useUserData()

    const address = useEventAddress(offer)
    const eventDate = useFormattedDate(date)
    const eventTime = useEventDuration(calendarEvent, user)
    const eventClass = useEventStatusClass(calendarEvent)

    return {
      eventDate,
      eventTime,
      address,
      eventClass,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../calendar';

.event-list-item {
  padding: 0 0.25rem;
  transition: background-color 0.15s ease-in;
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-of-type) {
    .wrapper {
      border-bottom: $divider-border;
    }
  }

  @include breakpoint-down(md) {
    max-width: 450px;
    width: 100%;
  }

  &:hover {
    background-color: $alabaster;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 0;
  }
}
.data-group-col {
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.event-title {
  @include breakpoint-down(md) {
    max-width: 50vw;
  }
}

.event-address {
  color: $manatee;
}

.event-icon {
  margin-right: 1rem;
}

.navigate-icon {
  margin-left: auto;
  color: $jungle-green;
  i {
    font-size: 1.5rem;
  }
}
.city {
  color: $comet;
  font-weight: 500;
}
</style>
