
import { computed, defineComponent, watch } from 'vue'
import { useField } from 'vee-validate'
import InputText from 'primevue/inputtext'

export default defineComponent({
  components: { InputText },
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      default: '',
    },
    rightIcon: {
      required: false,
      type: String,
    },
    leftIcon: {
      required: false,
      type: String,
    },
    floating: {
      type: Boolean,
      default: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['right-icon-click', 'change'],
  setup(props, { emit }) {
    const { value: inputValue, errorMessage, handleBlur } = useField(props.name)
    watch(inputValue, (value) => emit('change', value))

    const computedClass = computed(() => ({
      'p-input-icon-right': !!props.rightIcon,
      'p-input-icon-left': !!props.leftIcon,
      'p-float-label': props.floating,
      'd-flex flex-column': !props.floating,
      'white-bg': props.whiteBackground,
      rounded: props.rounded,
      'adjust-icon': props.label,
    }))

    watch(
      () => props.value,
      (val) => val && (inputValue.value = val),
    )

    return {
      handleBlur,
      errorMessage,
      inputValue,
      computedClass,
    }
  },
})
