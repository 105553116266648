import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const useNameAndLastName = (firstName?: string, lastName?: string) => {
  const { t } = useI18n()

  return computed(() => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else if (firstName) {
      return firstName
    } else if (lastName) {
      return lastName
    }
    return t('client')
  })
}
