<template>
  <slot name="mobile" v-if="!isDesktopSize" />
  <slot name="desktop" v-else />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useBreakpoints } from '..'

export default defineComponent({
  components: {},
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
</script>

<style lang="scss" scoped></style>
