<template>
  <div class="contractor-wrapper">
    <div>
      <UserAvatar
        class="custom-user-avatar"
        :user="{
          firstName: contractor.firstName,
          lastName: contractor.lastName,
        }"
        :user-type="userType"
      />
    </div>
    <div class="contractor">
      <p class="contractor-type">
        {{ t(`userType.${userType.toLowerCase()}`) }}
      </p>
      <p class="contractor-name">
        {{ contractor.firstName }} {{ contractor.lastName }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { UserAvatar } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { TransactionHistoryUserDto } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ProfileCardContractor',
  components: { UserAvatar },
  emits: ['remove-note'],
  props: {
    contractor: {
      type: Object as PropType<TransactionHistoryUserDto>,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
})
</script>

<style lang="scss" scoped>
.avatar-content {
  @include flex(center, flex-start, column);
}

.divider-border {
  border-top: $divider-border;
  margin: 20px;
  width: 100%;
}

.custom-user-avatar {
  width: 40px;
  height: 40px;
  font-size: 100%;
  margin-right: 15px;
}

.contractor {
  &-type {
    font-size: 0.75rem;
    font-weight: 500;
    color: $comet;
    padding-bottom: 5px;
  }
  &-name {
    font-size: 1rem;
    color: $port-gore;
  }
}

.contractor-wrapper {
  @include flex(flex-start, center, row);
  background: $white;
  width: 100%;
  min-height: 70px;
  border: solid 1px $white;
  border-radius: 6px;
}
</style>
