import { computed } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import {
  ChildlessNavigationItem,
  NavigationEnabledRoute,
  NavigationItem,
} from './types'

export const shouldShowAuthNavigation = (
  route: RouteLocationNormalizedLoaded,
  enabledRoutes: NavigationEnabledRoute[],
) => {
  return computed(() => {
    const currentPath = route.path
    return enabledRoutes.map((x) => x.path).includes(currentPath)
  })
}

export const useNavigationItemIconStyle = (
  item: NavigationItem | ChildlessNavigationItem,
) =>
  computed(() => {
    return item.icon?.width && item.icon.height
      ? {
          width: `${item.icon.width}px`,
          height: `${item.icon.height}px`,
        }
      : null
  })
