<template>
  <div class="search-row">
    <SearchInput
      :modelValue="searchPhrase"
      :placeholder="t('calendar.events.save_event.offers.search_placeholder')"
      :debounceMs="MEETING_SEARCHABLE_LIST_DEBOUNCE_MS"
      :autofocus="true"
      @search="$emit('searchOffer', $event)"
      @update:modelValue="$emit('update:searchPhrase', $event)"
    />
  </div>
  <div class="results-container" v-if="offerItems && !isLoading && isPresent">
    <div class="results-stats body-sm-medium">
      {{ t('search_result.found') }} {{ resultStats.returned }}
      {{ t('search_result.from') }} {{ resultStats.total }}
      {{ t('search_result.results') }}
    </div>
    <CalendarEventOffersList
      :items="offerItems"
      @selectOffer="onOfferSelected"
    />
  </div>
  <slot v-if="isEmpty && !isLoading" name="empty" :source="emptyStateSource" />
  <div v-if="isLoading" class="progress-container">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { BaseAddress, ResultPageDto } from '@bd/api'
import { MeetingOfferDto } from '@bd/api/common/types/calendar-event'
import {
  CalendarEventOfferItem,
  SearchInput,
  useLoadableResourceDataState,
  MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
  useResultListStats,
} from '@bd/components'
import { LoadableOptional } from '@bd/helpers'
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventOffersList from './OffersList/CalendarEventOffersList.vue'
import ProgressSpinner from 'primevue/progressspinner'
import { EmptyStateSource } from './types'
import { useAppStore } from '@bd/store-modules'
import {
  CalendarEventAttendee,
  MeetingOfferSelectPayload,
} from '@bd/store-modules/types'

export default defineComponent({
  components: {
    SearchInput,
    CalendarEventOffersList,
    ProgressSpinner,
  },
  props: {
    searchPhrase: {
      type: String,
      required: true,
    },
    offers: {
      type: Object as PropType<
        LoadableOptional<ResultPageDto<MeetingOfferDto> | undefined>
      >,
      required: true,
    },
  },
  emits: ['update:searchPhrase', 'searchOffer', 'selectOffer'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const { offers } = toRefs(props)
    const offersPayload = computed(() => offers.value.payload)
    const offersPayloadContent = computed<
      ResultPageDto<MeetingOfferDto> | undefined
    >(() => offersPayload.value?.content)
    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      offersPayload,
      (res) => !!(res?.content?.content && res.content.content.length),
    )

    const selectedOffferId = computed(
      () => store.state.calendarEvent?.eventSaveData.offer?.id,
    )

    const offerItems = computed<CalendarEventOfferItem[] | undefined>(() => {
      const offersContent = offersPayloadContent.value?.content
      const mappedOffers:
        | CalendarEventOfferItem[]
        | undefined = offersContent?.map(
        ({
          id,
          displayableId,
          imageUrl: thumbnailUrl,
          seller,
          draft,
          ...rest
        }) => {
          return {
            id,
            displayableId,
            thumbnailUrl,
            draft,
            address: rest as BaseAddress,
            seller: seller as CalendarEventAttendee,
            selected: id === selectedOffferId.value,
          }
        },
      )
      return mappedOffers
    })

    const resultStats = useResultListStats(offersPayloadContent)

    const emptyStateSource = computed(() =>
      props.searchPhrase.trim().length
        ? EmptyStateSource.NoSearchResults
        : EmptyStateSource.NoOffersAdded,
    )

    const onOfferSelected = async (offer: CalendarEventOfferItem) => {
      if (offer.id === selectedOffferId.value) {
        return
      }
      const { seller, ...rest } = offer
      const selectedOfferPayload: MeetingOfferSelectPayload = {
        offer: rest,
        seller,
      }
      await store.dispatch(
        'calendarEvent/setSelectedOfferAndSeller',
        selectedOfferPayload,
      )
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
      emit('selectOffer', offer)
    }

    return {
      t,
      offerItems,
      isLoading,
      isEmpty,
      isPresent,
      emptyStateSource,
      resultStats,
      onOfferSelected,
      MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';

.search-row {
  margin-bottom: 0.5rem;
}
.results-container {
  @include results-list-container;
}
</style>
