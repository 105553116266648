<template>
  <div class="break" v-if="isRowBreak">
    <p class="transaction-year" v-if="yearFromDate">{{ yearFromDate }}</p>
  </div>

  <Card class="transaction-card">
    <template #header>
      <div class="header-content">
        <ProfileCardChip :status="transactionHistory.status" />
        <ProfileCardDate :date="transactionHistory.date" />
      </div>
    </template>
    <template #title>
      <h2 class="h-2">
        {{ getFormattedPrice(transactionHistory.price, currency, locale) }}
      </h2>
    </template>
    <template #content>
      <div class="inner-content">
        <div class="address">
          {{ t('street_prefix') }} {{ transactionHistory.offerDto.street }}
          <br />
          {{ cityAndDistrict.city
          }}{{
            cityAndDistrict.district ? `, ${cityAndDistrict.district}` : ''
          }}
        </div>
        <p v-if="transactionHistoryAgent" class="offer-id caption">
          {{ transactionHistory.offerDto.id }}
        </p>
      </div>
      <div v-if="transactionHistoryAgent" class="buyer-and-seller-wrapper">
        <ProfileCardContractor
          :contractor="contractors.customer"
          userType="Buyer"
        />
        <ProfileCardContractor
          :contractor="contractors.seller"
          userType="Seller"
        />
      </div>

      <Accordion class="accordion-custom" v-model:activeIndex="detailsStatus">
        <AccordionTab>
          <div class="progress-container" v-if="isEmpty">
            <ProgressSpinner />
          </div>
          <ProfileCardDetail
            v-for="(detail, index) in purchasedOffers"
            :key="detail.offerId"
            :detail="detail"
            :is-first="purchasedOffers.length === index + 1"
          />
        </AccordionTab>
      </Accordion>
    </template>
    <template #footer>
      <p @click="showDetails" class="link">
        <span v-if="detailsStatus">{{ t('show_details') }}</span>
        <span v-else>
          <i class="pi pi-chevron-up mr-2" />
          {{ t('collapse') }}
        </span>
      </p>
    </template>
  </Card>
  <ProfileCardModal
    :transaction="transactionHistoryAgent || transactionHistoryClient"
    :purchasedOffers="purchasedOffers"
    :errorMessage="errorMessage"
    v-model:visible="showProfileCardModal"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ProgressSpinner from 'primevue/progressspinner'
import ProfileCardDate from './ProfileCardDate.vue'
import ProfileCardDetail from './ProfileCardDetail.vue'
import ProfileCardModal from './ProfileCardModal.vue'
import ProfileCardChip from './ProfileCardChip.vue'
import ProfileCardContractor from './ProfileCardContractor.vue'
import { useBreakpoints, useResourceDataState } from '@bd/components'
import {
  TransactionHistoryDto,
  TransactionHistoryDetailsDtoAgent,
  PurchaseOfferDto,
  TransactionHistoryDetailsDto,
} from '@bd/api'
import { LOCALISATION_SETTINGS } from '../../config'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'ProfileCard',
  components: {
    Card,
    ProfileCardDate,
    ProfileCardDetail,
    ProfileCardModal,
    ProfileCardChip,
    ProfileCardContractor,
    Accordion,
    AccordionTab,
    ProgressSpinner,
  },
  props: {
    transactionHistoryClient: {
      type: Object as PropType<TransactionHistoryDto>,
    },
    transactionHistoryAgent: {
      type: Object as PropType<TransactionHistoryDetailsDtoAgent>,
    },
    transactionDetails: {
      type: Array as PropType<TransactionHistoryDetailsDto[]>,
      required: true,
    },
    isRowBreak: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String as PropType<string | undefined>,
    },
  },
  emits: ['fetch-details'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { currency, locale } = LOCALISATION_SETTINGS
    const transactionHistory = computed(() => {
      if (props.transactionHistoryClient) {
        return props.transactionHistoryClient
      } else {
        return props.transactionHistoryAgent
      }
    })

    const detailsStatus = ref(1)
    const showProfileCardModal = ref(false)

    const purchasedOffers = computed((): PurchaseOfferDto[] => {
      const offerId = transactionHistory.value?.offerDto.id

      return (
        props.transactionDetails?.find((x) => x.offerDto.id === offerId)
          ?.purchaseOffers || []
      )
    })

    const yearFromDate = computed(() => {
      if (!transactionHistory.value) {
        return false
      }
      const date = new Date(transactionHistory.value.date)
      return date.getFullYear()
    })

    const cityAndDistrict = computed(() => {
      const offerDto = transactionHistory.value?.offerDto
      return {
        city: offerDto?.cityDto.cityName,
        district: offerDto?.districtDto?.districtName,
      }
    })

    const showDetails = () => {
      const offerId = transactionHistory.value?.offerDto.id

      const payload = { offerId, customerId: 0 }
      if (props.transactionHistoryAgent?.customer.id) {
        payload.customerId = props.transactionHistoryAgent.customer.id
      }

      if (mobileSize.value && detailsStatus.value === 0) {
        detailsStatus.value = 1
        return
      }
      if (mobileSize.value && detailsStatus.value === 1) {
        emit('fetch-details', payload)
        detailsStatus.value = 0
        return
      }

      if (!showProfileCardModal.value) {
        emit('fetch-details', payload)
        showProfileCardModal.value = true
        return
      }
    }

    const { isEmpty } = useResourceDataState(
      purchasedOffers,
      (res) => !!(res && res.length),
    )

    const contractors = computed(() => {
      if (props.transactionHistoryAgent === undefined) {
        return false
      }
      return {
        customer: props.transactionHistoryAgent.customer,
        seller: props.transactionHistoryAgent.seller,
      }
    })

    watchEffect(() => {
      !mobileSize.value && (detailsStatus.value = 1)
      mobileSize.value && (showProfileCardModal.value = false)
    })

    return {
      t,
      detailsStatus,
      showProfileCardModal,
      showDetails,
      cityAndDistrict,
      purchasedOffers,
      currency,
      locale,
      getFormattedPrice,
      yearFromDate,
      isEmpty,
      transactionHistory,
      contractors,
    }
  },
})
</script>

<style lang="scss" scoped>
$padding-size: 1rem;

.break {
  display: flex;
  white-space: 100%;
  flex-basis: 100%;
}

.transaction-year {
  color: $manatee;
  width: 60%;
  padding: 10px 0 10px 10px;
  font-size: 18px;
  font-weight: 500;
  @include breakpoint-down(md) {
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
  }
}

.transaction-card :deep {
  min-width: 260px;
  margin: 0.5rem;
  box-shadow: $default-shadow;
  border-radius: 6px;
  @include breakpoint-up(md) {
    min-width: 360px;
  }
  @include breakpoint-down(xl) {
    width: 100%;
  }
  .p-card {
    &-header {
      padding: $padding-size;
    }
    &-body {
      padding: 0;
    }
    &-content {
      padding: 0;
    }
    &-title {
      padding: 0 $padding-size;
    }
    &-footer {
      border-top: $divider-border;
      padding: 1.5rem;
      text-align: center;
    }
  }

  .inner-content {
    padding: $padding-size;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 50px;
  }
}

.accordion-custom {
  :deep(.p-accordion-header) {
    display: none;
  }
  :deep(.p-accordion-content) {
    padding: 0 10px;
    border: none;
  }
}
.link:hover {
  cursor: pointer;
}

.address {
  font-size: 0.875rem;
  line-height: 1.35;
  color: $comet;
}

.offer-id {
  padding: 1rem 0 0 0;
}
.buyer-and-seller-wrapper {
  border-top: $divider-border;
  padding: 0 25px;
}
</style>
