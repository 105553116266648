<template>
  <div class="event-attendee-card">
    <div class="avatar-container">
      <UserAvatar :user="user" :userType="userType" class="attendee-avatar" />
    </div>
    <div class="details-group">
      <div class="labels-group mt-md-3">
        <label class="body-12 py-1">{{ label }}</label>
        <label class="name py-1"
          >{{ user.firstName }} {{ user.lastName }}</label
        >
      </div>
      <div class="phone-container" v-if="user.phoneNumber">
        <a :href="`tel:${user.phoneNumber}`" class="d-md-none">
          <Button class="p-button-secondary p-button-circle">
            <Svg
              :src="require('@bd/assets/icons/call-phone.svg')"
              class="phone-icon"
            />
          </Button>
        </a>
        <div class="phone-number d-none d-md-block">
          {{ user.phoneNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Avatar, UserType } from '@bd/components'
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserAvatar, Svg } from '@bd/components'
import { ClientDto } from '@bd/api'

export default defineComponent({
  components: {
    UserAvatar,
    Svg,
  },
  props: {
    user: {
      type: Object as PropType<ClientDto>,
      required: true,
    },
    userType: {
      type: String as PropType<Avatar['userType']>,
      required: true,
      validator: (value: string) => value in UserType,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const label = computed(() => t(`userType.${props.userType.toLowerCase()}`))

    return {
      label,
    }
  },
})
</script>

<style lang="scss" scoped>
.event-attendee-card {
  color: $port-gore;
  background-color: $white;
  box-shadow: $default-shadow;
  border-radius: 10px;
  max-width: 360px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 200px;
  min-height: 230px;
  max-height: 300px;
  overflow: hidden;
  @include breakpoint-down(md) {
    padding: 20px;
    min-height: unset;
    max-width: unset;
    flex-direction: row;
    align-items: center;
    @include uniform-size(auto);
  }
}
.avatar-container {
  .attendee-avatar {
    @include uniform-size(50px);
    font-size: 0.938rem;
    border-radius: 13px;
  }
}
.details-group {
  display: flex;
  flex-direction: column;
  @include uniform-size(100%);
  @include breakpoint-down(md) {
    flex-direction: row;
    align-items: center;
    margin-left: 0.5rem;
  }
}
.name {
  line-height: normal;
}
.labels-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;
}
.phone-container {
  margin-left: auto;
  padding-left: 0.5rem;
  @include breakpoint-up(md) {
    margin-left: 0;
    margin-top: auto;
    padding: 1rem 0 0;
  }
}
.phone-icon {
  @include uniform-size(40px);
  color: $primary-color;
}
</style>
