<template>
  <div class="transaction-item">
    <div>
      <p class="mb-2 transaction-type">
        {{ t(`transaction_type.${label}`) }}
      </p>
      <p class="price-text">
        {{ getFormattedPrice(detail.price, currency, locale) }}
      </p>
    </div>
    <ProfileCardDate :date="detail.date" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import ProfileCardDate from './ProfileCardDate.vue'
import { useI18n } from 'vue-i18n'
import { PurchaseOfferDto, TransactionType } from '@bd/api'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'ProfileCardDetail',
  components: { ProfileCardDate },
  props: {
    detail: {
      type: Object as PropType<PurchaseOfferDto>,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS
    const label = computed(() => {
      if (props.isFirst) {
        return props.detail.type === TransactionType.BUYER_TO_SELLER
          ? 'buyer_to_seller_proposal'
          : 'seller_to_buyer_proposal'
      }
      return props.detail.type.toLowerCase()
    })
    return { t, currency, locale, getFormattedPrice, label }
  },
})
</script>

<style lang="scss" scoped>
.transaction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  color: $comet;
  border-bottom: $divider-border;
  padding-bottom: 24px;

  &:last-child {
    border-bottom: none;
  }

  @include breakpoint-down(md) {
    padding: 5px;
  }
}

.transaction-type {
  font-size: 14px;
}

.price-text {
  font-weight: 600;
  font-size: 17px;
  color: $port-gore;
}
</style>
