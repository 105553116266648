
import { defineComponent, computed, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import { useBreakpoints, useResourceDataState } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'
import { PurchaseOfferDto, TransactionHistoryDetailsDto } from '@bd/api'
import ProfileCardDetail from './ProfileCardDetail.vue'
import ProfileCardDate from './ProfileCardDate.vue'
import ProfileCardChip from './ProfileCardChip.vue'

export default defineComponent({
  name: 'ProfileCardModal',
  components: {
    Dialog,
    ProfileCardDetail,
    ProfileCardDate,
    ProfileCardChip,
    ProgressSpinner,
  },
  props: {
    visible: Boolean,
    purchasedOffers: {
      type: Array as PropType<PurchaseOfferDto[]>,
      required: true,
    },
    transaction: {
      type: Object as PropType<TransactionHistoryDetailsDto>,
      required: true,
    },
    errorMessage: {
      type: String as PropType<string | undefined>,
    },
  },
  emits: ['update:visible'],
  setup(props) {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { currency, locale } = LOCALISATION_SETTINGS

    const cityAndDistrict = computed(() => {
      const address = props.transaction.offerDto
      return {
        city: address.cityDto.cityName,
        district: address.districtDto?.districtName,
      }
    })
    const computedPurchasedOffers = computed(() => {
      return props.purchasedOffers
    })
    const { isEmpty, isLoading } = useResourceDataState(
      computedPurchasedOffers,
      (res) => !!(res && res.length),
    )

    return {
      t,
      mobileSize,
      cityAndDistrict,
      getFormattedPrice,
      currency,
      locale,
      isEmpty,
      isLoading,
      computedPurchasedOffers,
    }
  },
})
