
import { computed, defineComponent, PropType, watch } from 'vue'
import { useBreakpoints } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { useRoute, useRouter } from 'vue-router'
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import NotificationsCenter from './NotificationsCenter.vue'
import NotificationsDropdown from './NotificationsDropdown.vue'
import { NotificationsViewType } from './utils'

export default defineComponent({
  components: {
    NotificationsCenter,
    NotificationsDropdown,
  },
  props: {
    viewType: {
      type: String as PropType<NotificationsViewType>,
      required: true,
    },
  },
  emits: ['onNewProposal'],
  setup(_, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useAppStore()
    const { mobileSize } = useBreakpoints()

    const notificationsVisible = computed(
      () => store.state.notifications?.notificationsVisible ?? false,
    )

    const closeNotifications = () => {
      if (notificationsVisible.value) {
        store.dispatch('notifications/setNotificationsVisible', false)
      }
    }

    const onNotificationsOutsideClick = () => {
      closeNotifications()
    }

    /* React to screen size:
     * If mobile screen: close notifications dropdown
     * If desktop screen: navigate back from notifications path
     */
    const handleNotificationsVisibility = () => {
      if (mobileSize.value) {
        closeNotifications()
        return
      }
      if (route.name === 'Notifications') {
        router.back()
      }
    }

    const onCalendarNotificationClick = (
      notification: SystemNotificationDto,
    ) => {
      if (!notification.navigationEnabled) {
        return
      }
      router.push({
        name: 'CalendarEventDetails',
        params: { id: notification.userCalendarEventDto!.userCalendarEventId },
      })
      closeNotifications()
    }

    const onProposalNotificationClick = (
      notification: SystemNotificationDto,
    ) => {
      const { offerId, buyerId } = notification.purchaseOfferDto
      store.dispatch('purchaseOffers/lastPurchaseOffer', { offerId, buyerId })
      store.dispatch('dialogVisibility', {
        counterProposalModal: true,
      })
    }

    watch(mobileSize, () => {
      handleNotificationsVisibility()
    })
    return {
      notificationsVisible,
      onNotificationsOutsideClick,
      onCalendarNotificationClick,
      onProposalNotificationClick,
    }
  },
})
