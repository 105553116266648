<template>
  <div class="autocomplete">
    <div class="fake-wrapper" ref="fakeWrapper"></div>
    <div class="wrapper" ref="wrapper">
      <div class="header">
        <DashboardAutocompleteInput
          class="input"
          :query="query"
          v-model:isOpen="isOpenComputed"
          :placeholder="placeholder"
          :showCloseButton="isOpenComputed && mobileSize"
          @arrow-up="onArrowUp"
          @arrow-down="onArrowDown"
          @enter="onEnter"
          @input="$emit('input', $event)"
          search-icon
        />
        <Button
          v-if="showSearchButton"
          @click="$emit('search-click')"
          icon="pi pi-search"
          class="p-button-primary search-btn"
        />
      </div>
      <div v-if="isOpenComputed" class="autocomplete-results">
        <div class="loading" v-if="isLoading">
          {{ t('loading_results') }}
        </div>
        <div v-else-if="query.length < minQueryLength">
          {{ t('min_characters_needed', minQueryLength) }}
        </div>
        <div v-else-if="!items.length">
          {{ t('no_results') }}
        </div>
        <template v-else>
          <slot name="dropdown-header" />
          <template v-for="(item, i) in items" :key="getItemKey(item)">
            <slot
              name="item"
              :refElem="(elem) => setItemElement(i, elem)"
              :item="item"
              :select="setResult"
              :focused="i === activeIdx"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  PropType,
  watchEffect,
  watch,
  shallowRef,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside, useVModel } from '@vueuse/core'
import DashboardAutocompleteInput from './DashboardAutocompleteInput.vue'
import { useBreakpoints } from '../../use/useBreakpoints'

const UNBOUND_TOKEN = {} as never

export default defineComponent({
  name: 'DashboardAutocomplete',
  components: { DashboardAutocompleteInput },
  props: {
    query: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: (null as never) as PropType<boolean>,
      default: UNBOUND_TOKEN,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    minQueryLength: {
      type: Number,
      default: 0,
    },
    getItemKey: {
      type: Function as PropType<(item: unknown) => unknown>,
      required: true,
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:isOpen', 'search-click', 'item-select', 'input'],
  setup(props, { emit }) {
    const i18n = useI18n()
    const wrapper = shallowRef<HTMLElement>()
    const fakeWrapper = shallowRef<HTMLElement>()
    const activeIdx = ref(0)
    const { mobileSize } = useBreakpoints()
    const itemElements: Record<number, HTMLElement> = {}
    const isOpenComputed =
      props.isOpen === UNBOUND_TOKEN
        ? ref(false)
        : useVModel(props, 'isOpen', emit)

    const setResult = (result: unknown) => {
      isOpenComputed.value = false
      emit('item-select', result)
    }

    const scrollToIfNeeded = (item: HTMLElement | undefined) => {
      const parent = item?.offsetParent
      if (!item || !parent) return

      if (item.offsetTop < parent.scrollTop) {
        item.scrollIntoView({ block: 'start' })
      } else {
        const parentBottom = parent.scrollTop + parent.clientHeight
        const itemBottom = item.offsetTop + item.clientHeight
        if (itemBottom > parentBottom) {
          item.scrollIntoView({ block: 'end' })
        }
      }
    }

    const onArrowDown = () => {
      if (activeIdx.value < props.items.length - 1) {
        activeIdx.value = activeIdx.value + 1
        scrollToIfNeeded(itemElements[activeIdx.value])
      }
    }
    const onArrowUp = () => {
      if (activeIdx.value > 0) {
        activeIdx.value = activeIdx.value - 1
        scrollToIfNeeded(itemElements[activeIdx.value])
      }
    }

    const onEnter = () => {
      const index = activeIdx.value
      if (0 <= index && index < props.items.length) {
        activeIdx.value = 0
        setResult(props.items[index])
      }
    }

    watch([isOpenComputed, wrapper], async ([isOpen, wrapper]) => {
      if (!wrapper) return

      if (isOpen) {
        fakeWrapper.value!.style.height = wrapper.clientHeight + 'px'
        fakeWrapper.value!.style.width = wrapper.clientWidth + 'px'
        fakeWrapper.value!.style.display = 'block'

        wrapper.classList.add('before-open')
        requestAnimationFrame(() => {
          wrapper.classList.add('is-open')
          wrapper.classList.remove('before-open')
        })
      } else {
        fakeWrapper.value!.style.display = ''
        wrapper.classList.remove('is-open')
      }
    })

    watchEffect(() => {
      const isOverlay = mobileSize.value && isOpenComputed.value
      document.body.style.overflow = isOverlay ? 'hidden' : ''
    })

    onClickOutside(wrapper, () => {
      isOpenComputed.value = false
      activeIdx.value = 0
    })

    const setItemElement = (idx: number, elem: HTMLElement) => {
      itemElements[idx] = elem
    }

    return {
      t: i18n.t,
      mobileSize,
      setResult,
      wrapper,
      fakeWrapper,
      isOpenComputed,
      activeIdx,
      onArrowDown,
      onArrowUp,
      onEnter,
      setItemElement,
    }
  },
})
</script>
<style lang="scss" scoped>
.fake-wrapper {
  display: none;

  @include breakpoint-up(md) {
    display: none !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 1;
  filter: opacity(1);

  @include breakpoint-down(md) {
    &.before-open,
    &.is-open {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .bottom-nav-visible & {
        bottom: $mobile-navigation-height;
      }

      .header {
        padding: 20px 20px 0;
      }
    }

    &.before-open {
      transform: translateY(20px);
      filter: opacity(0);
    }

    &.is-open {
      transition: all 0.3s ease;
      transform: translateY(0);
      filter: opacity(1);
    }
  }
}

.header {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;

  @include breakpoint-up(md) {
    background: $alabaster;
  }
}

.autocomplete-results {
  background: #fff;
  flex: 1;
  padding: 0 20px 20px;
  overflow: auto;

  @include breakpoint-up(md) {
    position: absolute;
    top: calc(100% + 9px);
    width: 100%;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 25px 190px 0 rgb(23 25 51 / 1%),
      0 18px 9px 0 rgb(182 207 231 / 12%);
    z-index: 1;
    max-height: 400px;
  }
}

.search-btn.search-btn {
  border-radius: 6px;
  width: 44px;
  height: 44px;
  margin: 3px;

  @include breakpoint-down(md) {
    display: none;
  }
}
</style>
