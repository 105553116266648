<template>
  <AuthLayout :bgImage="require('@bd/assets/images/image-female-phone.jpg')">
    <template #navigation>
      <div class="d-none d-md-flex align-self-start left-container-box">
        <Button
          @click="$router.push({ path: '/login' })"
          icon="pi pi-chevron-left"
          class="p-button-rounded p-button-outlined"
        />
      </div>
    </template>
    <template #main>
      <div class="text-center">
        <div>
          <p class="h-1">
            {{ t('email_sent') }}
          </p>
          <p
            class="body-bg mt-4 d-none d-md-block"
            style="padding: 0 25px 0 25px"
          >
            {{ t('email_instruction') }}
          </p>
        </div>
        <img
          src="@bd/assets/images/password-reset-confirmation.svg"
          class="reset-image"
        />
        <div class="d-md-none text-center">
          <p class="body-bg mt-4" style="padding: 0 25px 0 25px">
            {{ t('email_instruction') }}
          </p>
        </div>
      </div>
      <AuthFormConfirmation />
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { default as common } from '@/config/common.json'
import { defineComponent } from 'vue'
import AuthFormConfirmation from '../AuthForm/AuthFormConfirmation.vue'
import AuthLayout from '../AuthLayout.vue'

export default defineComponent({
  name: 'AuthPasswordResetConfirmation',
  components: { AuthLayout, AuthFormConfirmation },
  setup() {
    const i18n = useI18n()
    const { helpline } = common
    return {
      ...i18n,
      helpline,
    }
  },
})
</script>

<style lang="scss" scoped>
@include breakpoint-down(xl) {
  .left-container-box {
    height: 0;
  }
}

.left-container-box {
  width: 100%;
}
.reset-image {
  margin-top: 50px;
  margin-bottom: 70px;
}
</style>
