
import { CalendarEventOfferItem, Overlay } from '@bd/components'
import { defineComponent } from 'vue'
import CalendarEventOfferSelectionList from './CalendarEventOfferSelectionList.vue'

export default defineComponent({
  components: {
    Overlay,
    CalendarEventOfferSelectionList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'selectOffer', 'searchOffer', 'outsideClick'],
  setup(_props, { emit }) {
    const onOfferSelected = (selectedOffer: CalendarEventOfferItem) => {
      emit('selectOffer', selectedOffer)
      emit('update:visible', false)
    }
    return {
      onOfferSelected,
    }
  },
})
