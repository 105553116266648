import { AppState, ClientPurchaseState } from '@bd/client/types'
import { ActionTree } from 'vuex'
import { commonApi } from '@bd/api'
import { logErr } from '@bd/helpers'
import { AddPurchaseOfferDto } from '@bd/api/common/types/purchase-offers'

export const actions: ActionTree<ClientPurchaseState, AppState> = {
  async lastPurchaseOffer({ commit }, payload: { offerId: number }) {
    try {
      const { data } = await commonApi.purchaseOfferCustomer(payload)
      commit('SET_PURCHASE', data)
    } catch (error) {
      logErr(error)
    }
  },
  async addPurchaseOffer({ commit }, payload: AddPurchaseOfferDto) {
    commit('CLEAR_ERROR')
    try {
      await commonApi.addPurchaseBuyer(payload)
    } catch (error) {
      commit('SET_ERROR', error)
      logErr(error)
    }
  },
  async acceptLastPurchase(_, offerId: number) {
    try {
      await commonApi.acceptPurchaseBuyer(offerId)
    } catch (error) {
      logErr(error)
    }
  },
}
