
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ProposalModal from './ProposalModal/ProposalModal.vue'
import CounterProposalList from './ProposalModal/CounterProposalList.vue'
import {
  AgentPurchaseOfferDto,
  ProposalMessage,
} from '@bd/api/common/types/purchase-offers'
import { ErrorResponse } from '@bd/api'
import { purchaseOfferService } from '@bd/components'

export default defineComponent({
  name: 'CounterProposal',
  components: { ProposalModal, CounterProposalList },
  emits: ['hide', 'accept', 'counterproposal'],
  props: {
    purchaseOffer: {
      type: Object as PropType<AgentPurchaseOfferDto | undefined>,
    },
    isBuyerView: {
      type: Boolean,
    },
    proposalMessage: {
      type: Object as PropType<ProposalMessage>,
    },
    errorData: {
      type: Object as PropType<ErrorResponse | undefined>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const lastOffer = computed(() => props.purchaseOffer)
    const priceInputValue = ref<number>()
    const errorMessage = computed(() => {
      return purchaseOfferService.handleError(props.errorData)
    })

    const isNextStep = ref(false)

    const priceTitle = computed(() => {
      if (isNextStep.value) {
        return t('proposal.your_proposed_price')
      }
      return lastOffer.value?.type === 'SELLER_TO_BUYER'
        ? t('proposal.seller_price_proposal')
        : t('proposal.buyer_price_proposal')
    })
    const isOfferAccepted = computed(() => {
      return !lastOffer.value?.accepted
    })
    const labelAccept = computed(() =>
      lastOffer.value?.type === 'SELLER_TO_BUYER'
        ? t('proposal.accept_on_behalf_of_the_buyer')
        : t('proposal.accept_on_behalf_of_the_seller'),
    )
    const labelCounterproposal = computed(() =>
      lastOffer.value?.type === 'SELLER_TO_BUYER'
        ? t('proposal.make_a_counterproposal_on_behalf_of_the_buyer')
        : t('proposal.make_a_counterproposal_on_behalf_of_the_seller'),
    )

    const computedProposalMessage = computed(() => {
      return (
        props.proposalMessage?.message &&
        t(`proposal.${props.proposalMessage?.message.toLowerCase()}`)
      )
    })

    const labelHeader = computed(() => {
      if (props.isBuyerView && computedProposalMessage.value) {
        return t('proposal.your_proposal')
      }

      return isNextStep.value
        ? t('proposal.price_proposal_question')
        : t('proposal.price_proposal_accept')
    })

    const displayedMessage = computed(() => {
      if (errorMessage.value) {
        return errorMessage.value
      }
      if (computedProposalMessage.value) {
        return computedProposalMessage.value
      }
      return false
    })

    const makeCounterproposal = () => {
      if (!isNextStep.value) {
        isNextStep.value = true
        return
      }
      if (props.isBuyerView) {
        emit('counterproposal', {
          price: priceInputValue.value,
          offerId: lastOffer.value?.offerId,
        })
        return
      }

      emit('counterproposal', {
        price: priceInputValue.value,
        offerId: lastOffer.value?.offerId,
        buyerId: lastOffer.value?.buyer.id,
        type:
          lastOffer.value?.type === 'BUYER_TO_SELLER'
            ? 'SELLER_TO_BUYER'
            : 'BUYER_TO_SELLER',
      })
    }

    const isInputDisabled = computed(() => {
      return !isNextStep.value || (!!props.proposalMessage && props.isBuyerView)
    })

    const onHide = () => {
      emit('hide')
      isNextStep.value = false
    }

    const cancel = () => {
      lastOffer.value?.accepted === undefined
        ? emit('hide')
        : (isNextStep.value = false)
    }

    watch(lastOffer, (offer) => {
      isNextStep.value = offer?.accepted === undefined
      if (offer?.price) priceInputValue.value = offer.price
    })

    return {
      t,
      lastOffer,
      priceTitle,
      isOfferAccepted,
      labelAccept,
      labelCounterproposal,
      priceInputValue,
      displayedMessage,
      makeCounterproposal,
      isNextStep,
      onHide,
      isInputDisabled,
      cancel,
      labelHeader,
    }
  },
})
