<template>
  <div
    class="offer-avatar"
    :class="desktopVersion ? 'offer-avatar-desktop' : 'offer-avatar-mobile'"
    v-if="userData"
  >
    <div class="flex-centered">
      <UserAvatar
        :user="{
          firstName: userData.firstName,
          lastName: userData.lastName,
        }"
        :userType="typeOfUser"
      />
      <div
        :class="{ pointer: typeOfUser === UserType.Seller }"
        @click="goToSeller"
      >
        <p class="avatar-keeper body-12" v-text="label" />
        <p :class="desktopVersion ? 'avatar-name' : 'avatar-name-mobile'">
          {{ `${userData.firstName} ${userData.lastName}` }}
        </p>
        <p
          v-if="!desktopVersion"
          class="caption avatar-phone"
          v-text="userData.phoneNumber"
        />
      </div>
    </div>

    <div :class="{ 'flex-centered': desktopVersion }">
      <p class="mr-4 link-dark" v-if="desktopVersion">
        {{ userData.phoneNumber }}
      </p>
      <a :href="`tel:${userData.phoneNumber}`">
        <Button class="p-button-secondary p-button-circle">
          <Svg
            :src="require('@bd/assets/icons/call-phone.svg')"
            class="phone-icon"
          />
        </Button>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { UserAvatar, UserType, Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'DashboardOfferKeeper',
  components: { UserAvatar, Svg },
  props: {
    userData: {
      type: Object as PropType<
        OfferDetailsDto['offerAgentDto'] | OfferDetailsDto['offerSellerDto']
      >,
      required: true,
    },
    typeOfUser: {
      type: String as PropType<UserType>,
      required: true,
    },
    desktopVersion: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const router = useRouter()

    const label = computed(() => {
      return props.typeOfUser === UserType.Seller
        ? t('userType.seller')
        : t('userType.agent')
    })

    const goToSeller = () => {
      if (props.typeOfUser !== UserType.Seller) {
        return
      }
      router.push({
        path: `/client/${props.userData?.id}`,
      })
    }
    return { t, UserType, label, goToSeller }
  },
})
</script>

<style lang="scss" scoped>
.offer-avatar {
  img,
  .avatar-keeper {
    font-weight: 500;
    margin-bottom: 4px;
    @include breakpoint-up(md) {
      display: none;
    }
  }
}

.avatar-name {
  color: $port-gore;
  font-size: 1.1rem;
  line-height: 1.5;
  &-mobile {
    @include breakpoint-up(md) {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}

.avatar-phone {
  @include breakpoint-down(md) {
    display: none;
  }
}

.offer-avatar {
  @include flex(space-between, center, row);

  &-desktop {
    :deep(.user-avatar) {
      font-size: 15px;
      width: 50px;
      height: 50px;
      margin-right: 1rem;
    }
    border-radius: 10px;
    box-shadow: 0 5px 73px 0 $light-shadow;
    padding: 20px;
    background: $white;
    justify-content: space-between;
  }
  &-mobile {
    :deep(.user-avatar) {
      font-size: 15px;
      width: 50px;
      height: 50px;
      margin-right: 1rem;
      @include breakpoint-up(md) {
        width: 35px;
        height: 35px;
        font-size: 12px;
      }
    }
    @include breakpoint-down(md) {
      border-radius: 10px;
      box-shadow: 0 5px 73px 0 $light-shadow;
      padding: 20px;
      background: #ffff;
      justify-content: space-between;
    }
  }
}

.phone-icon {
  color: $primary-color;
  @include uniform-size(40px);
}
</style>
