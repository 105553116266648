import { TOAST_CONFIG } from '.'
import { i18n } from '@bd/components/i18n'
import { Toasts } from '../types'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

export const ClientNoteMessages = Toasts({
  saveSuccess: {
    severity: 'success',
    summary: t('clients.note_add_messages.successSave'),
    life: messageDuration.success,
  },
  saveError: {
    severity: 'error',
    summary: t('clients.note_add_messages.errorSave'),
    life: messageDuration.error,
  },
  deleteSuccess: {
    severity: 'success',
    summary: t('clients.note_remove_messages.success'),
    life: messageDuration.success,
  },
  deleteError: {
    severity: 'error',
    summary: t('clients.note_remove_messages.error'),
    life: messageDuration.error,
  },
})

export const AuthFormMessages = Toasts({
  registerSuccess: {
    severity: 'success',
    summary: t('register.success'),
    life: messageDuration.success,
  },
  registerError: {
    severity: 'error',
    summary: t('register.error'),
    life: messageDuration.error,
  },
})
