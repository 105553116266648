<template>
  <div class="p-fluid">
    <TextInput
      name="username"
      type="text"
      :label="loginFieldLabel"
      :floating="false"
      rounded
    />

    <TextInput
      :right-icon="eyeIcon"
      name="password"
      :label="t('password')"
      :type="passwordFieldType"
      :rightIcon="eyeIcon"
      @right-icon-click="switchPasswordVisibility"
      :floating="false"
      rounded
    />
  </div>
  <div class="row submit-spacing">
    <div class="col-4 col-md-12 order-2 order-md-1 d-flex justify-content-end">
      <Button
        type="submit"
        :disabled="isLoading"
        :label="t('login')"
        class="p-button-primary p-button-rounded w-100 d-none d-md-block"
      />
      <Button
        type="submit"
        :disabled="isLoading"
        class="p-button-lg p-button-rounded p-button-icon-only p-button-primary d-md-none arrow-btn"
      >
        <i class="pi pi-arrow-right" />
      </Button>
    </div>
    <ForgotPasswordLink v-if="showPasswordResetLink">
      {{ t('password_forgot') }}
    </ForgotPasswordLink>
  </div>
</template>

<script lang="ts">
import { TextInput, useRootData, UserType } from '@bd/components'
import { useIsFormValid } from 'vee-validate'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ForgotPasswordLink from './AuthForgotPasswordLink.vue'

export default defineComponent({
  components: { ForgotPasswordLink, TextInput },
  name: 'AuthFormLogin',
  props: {
    showPasswordResetLink: {
      type: Boolean,
      default: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const passwordFieldType = ref('password')
    const { isLoading } = useRootData()
    const eyeIcon = computed(() => {
      return `pi ${
        passwordFieldType.value === 'password' ? 'pi-eye-slash' : 'pi-eye'
      }`
    })

    const switchPasswordVisibility = () => {
      passwordFieldType.value === 'password'
        ? (passwordFieldType.value = 'text')
        : (passwordFieldType.value = 'password')
    }

    const isFormValid = useIsFormValid()

    const loginFieldLabel = computed(() =>
      props.userType === UserType.Buyer ? t('email_or_phone') : t('email'),
    )

    return {
      t,
      isLoading,
      loginFieldLabel,
      passwordFieldType,
      switchPasswordVisibility,
      eyeIcon,
      isFormValid,
    }
  },
})
</script>

<style lang="scss" scoped>
.arrow-btn {
  padding: 30px !important;
  display: flex;
  flex-direction: column;
  .pi {
    font-size: 1.25rem;
  }
}
</style>
