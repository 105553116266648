<template>
  <div class="field-spacing" :class="computedClass">
    <label v-if="!floating" class="mb-2 body-12 text-500" :for="name">
      {{ label }}
    </label>
    <Calendar
      v-model="date"
      :id="name"
      :dateFormat="datePickerFormat"
      :disabled="disabled"
      :disabledDays="disabledDays"
      :selectOtherMonths="true"
      :manualInput="false"
    />
    <label v-if="floating" :for="name">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Calendar from 'primevue/calendar'
import { default as common } from '@bd/admin/config/common.json'

export default defineComponent({
  name: 'DatePicker',
  components: { Calendar },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
      default: () => new Date(),
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    floating: {
      type: Boolean,
      default: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDays: {
      type: Array as PropType<number[]>,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const date = computed({
      get: () => props.modelValue,
      set: (value: Date) => {
        emit('update:modelValue', value)
      },
    })

    const computedClass = computed(
      (): Record<string, boolean> => {
        return {
          'p-float-label': props.floating,
          'd-flex flex-column': !props.floating,
          'white-bg': props.whiteBackground,
        }
      },
    )

    return {
      date,
      computedClass,
      datePickerFormat: common.datePickerFormat,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.p-datepicker) {
  .p-highlight {
    background-color: $primary-color;
    color: $white;
    box-shadow: 0 10px 20px 0 rgba(0, 201, 183, 0.58);
  }
  .p-datepicker-today > span {
    background-color: transparent;
    border: 2px solid $primary-color;
    &.p-highlight {
      background-color: $primary-color;
      color: $white;
    }
  }
  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background-color: $clickable-hover-bg;
  }

  table td > span {
    border-radius: 16px;
    font-size: 1rem;
  }
}
:deep {
  .p-datepicker-next,
  .p-datepicker-prev {
    &:enabled:hover {
      background-color: $clickable-hover-bg !important;
    }
    > span {
      color: $primary-color;
    }
  }
}
:deep(.p-calendar) {
  .p-inputtext {
    &:disabled {
      background: $alabaster;
      color: $comet;
      opacity: 0.8;
    }
  }
}
</style>
