
import { defineComponent, PropType, computed } from 'vue'
import { CalendarEventOfferItem } from '@bd/components/Calendar/types'
import CalendarEventOfferAddress from '../CalendarEventOfferAddress.vue'
import { useEventAddress } from '@bd/components/use'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    CalendarEventOfferAddress,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    item: {
      type: Object as PropType<CalendarEventOfferItem>,
      required: true,
    },
    showId: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const regularAddress = computed(() => props.item.address)
    const formattedAddress = useEventAddress(regularAddress)
    const image = computed(() => {
      const imageUrl = props.item?.thumbnailUrl
      return imageUrl ?? require('@bd/assets/images/no-image.svg')
    })
    return {
      t,
      formattedAddress,
      image,
    }
  },
})
