
import { defineComponent, computed, ref, PropType } from 'vue'
import Galleria from 'primevue/galleria'
import { useI18n } from 'vue-i18n'
import {
  useRootData,
  useBreakpoints,
  useTextOrPlaceholder,
  Svg,
} from '@bd/components'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'
import { useUserData } from '@bd/components/use'
import { UserRole } from '@bd/api'
import { useAppStore } from '@bd/store-modules'

export default defineComponent({
  name: 'DashboardOfferGallery',
  components: { Galleria, Svg },
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
    showStatistics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleFullscreen'],
  setup(props, { emit }) {
    const store = useAppStore()
    const { isLoading } = useRootData()
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const { user, isLogged } = useUserData()
    const isBuyer = computed(
      () => user.value?.roles.includes(UserRole.CUSTOMER) || false,
    )

    const district = computed(
      () => props.offerDetails?.districtDto?.districtName,
    )
    const districtName = useTextOrPlaceholder(district)

    const street = computed(() => props.offerDetails?.street)
    const streetName = useTextOrPlaceholder(street, {
      transformFn: (val) => `${t('street_prefix')} ${val}`,
    })

    const city = computed(() => props.offerDetails?.cityDto.cityName)
    const cityName = useTextOrPlaceholder(city)

    const imagesList = computed(() => {
      const galleryImages = props.offerDetails?.galleryImages
      const galleryImagesUrls = props.offerDetails?.galleryImagesUrls

      const galleryImagesMapper = (photoUrl: string) => {
        return {
          itemImageSrc: photoUrl,
          thumbnailImageSrc: photoUrl,
          alt: props.offerDetails?.title,
          title: props.offerDetails?.title,
        }
      }

      if (galleryImages && galleryImages.length) {
        return galleryImages.map(({ photoUrl }) =>
          galleryImagesMapper(photoUrl),
        )
      }
      if (galleryImagesUrls && galleryImagesUrls.length) {
        return galleryImagesUrls.map((imgUrl) => galleryImagesMapper(imgUrl))
      }

      return [
        {
          itemImageSrc: require('@bd/assets/images/no-image.svg'),
          thumbnailImageSrc: require('@bd/assets/images/no-image.svg'),
          alt: t('no_image_available'),
          title: t('no_image_available'),
        },
      ]
    })
    const isEmptyState = computed(() => {
      const galleryUrls =
        props.offerDetails?.galleryImagesUrls ||
        props.offerDetails?.galleryImages
      return galleryUrls?.length === 0
    })
    const desktopSize = computed(() => width.value >= sizes.md)
    const responsiveOptions = [
      {
        breakpoint: `${sizes.lg}px`,
        numVisible: 3,
      },
      {
        breakpoint: `${sizes.md}px`,
        numVisible: 1,
      },
    ]
    const opts = ref({
      activeIndex: 0,
      showThumbnailNavigators: false,
    })

    const isFullscreen = ref(false)
    const showThumbnails = computed(() => {
      if (isEmptyState.value) {
        return false
      } else if (!isFullscreen.value && width.value >= sizes.md) {
        return true
      } else if (isFullscreen.value && width.value <= sizes.md) {
        return true
      } else {
        return false
      }
    })
    const next = () => {
      opts.value.activeIndex =
        opts.value.activeIndex === imagesList.value.length - 1
          ? 0
          : (opts.value.activeIndex += 1)
    }
    const prev = () => {
      opts.value.activeIndex =
        opts.value.activeIndex === 0
          ? imagesList.value.length - 1
          : opts.value.activeIndex - 1
    }
    // hack that removes unnecessary overflow-hidden class from the body
    const removeOverflowHidden = () => {
      document.body.classList.remove('p-overflow-hidden')
    }
    const isFullscreenComputed = computed({
      get: () => {
        return isFullscreen.value
      },
      set: (value) => {
        isFullscreen.value = value
        if (!isFullscreenComputed.value) {
          opts.value.activeIndex = 0
        }
        removeOverflowHidden()
        emit('toggleFullscreen', value)
      },
    })
    const galleryContainerClass = computed(() => {
      const containerClass = [
        {
          value: isFullscreenComputed.value,
          name: 'fullscreen-gallery',
        },
        {
          value: !isFullscreenComputed.value,
          name: 'main-gallery',
        },
        {
          value: isEmptyState.value,
          name: 'empty-state',
        },
      ]
      return containerClass
        .filter((item) => item.value)
        .map(({ name }) => name)
        .join(' ')
    })

    const switchFullscreenMode = () => {
      if (isEmptyState.value) {
        return
      }
      isFullscreenComputed.value = !isFullscreenComputed.value
    }

    const openAppointmentModal = () => {
      if (isLogged.value && isBuyer.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId: props.offerDetails?.id },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    return {
      t,
      opts,
      isFullscreenComputed,
      next,
      prev,
      showThumbnails,
      desktopSize,
      responsiveOptions,
      width,
      sizes,
      imagesList,
      isEmptyState,
      switchFullscreenMode,
      galleryContainerClass,
      isLoading,
      isLogged,
      isBuyer,
      district,
      districtName,
      streetName,
      cityName,
      openAppointmentModal,
    }
  },
})
