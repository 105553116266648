import { MutationTree } from 'vuex'
import { ClientPurchaseState } from '@bd/client/types'
import {
  PurchaseOfferRowDto,
  ProposalOfferMessages,
} from '@bd/api/common/types/purchase-offers'
import { TransactionType } from '@bd/api/common/types/transaction'
import { ErrorResponse } from '@bd/api'

export const mutations: MutationTree<ClientPurchaseState> = {
  SET_PURCHASE_MESSAGE(state, payload: PurchaseOfferRowDto) {
    const isPurchaseAccepted = payload?.accepted && payload.offerId
    const isPurchaseOngoing =
      payload?.type === TransactionType.BUYER_TO_SELLER && payload.offerId

    const message = {
      message: isPurchaseAccepted
        ? ProposalOfferMessages.PROPOSAL_ACCEPTED
        : ProposalOfferMessages.PROPOSAL_MADE,
      id: payload.offerId,
    }

    if (isPurchaseAccepted || isPurchaseOngoing) {
      state.purchaseMessage = message
    }
  },
  CLEAR_PURCHASE_MESSAGE(state) {
    state.purchaseMessage = undefined
  },
  SET_PURCHASE(state, payload: PurchaseOfferRowDto) {
    state.purchaseOffer = payload
  },
  CLEAR_LAST_PURCHASE(state) {
    state.purchaseOffer = undefined
  },
  SET_ERROR(state, payload: ErrorResponse) {
    state.error = payload
  },
  CLEAR_ERROR(state) {
    state.error = undefined
  },
}
