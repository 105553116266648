
import { defineComponent, computed, PropType } from 'vue'
import { AdditionalFilter } from '@bd/admin/types'

export default defineComponent({
  name: 'MultipleSelectOptions',
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AdditionalFilter[]>,
      required: true,
    },
    activeFilters: {
      // TODO add type
      type: Object,
      required: true,
    },
  },
  emits: ['filter-data'],
  setup(props) {
    const currentActiveFilters = computed(() => {
      if (props.value.includes('cityIds')) {
        return props.activeFilters?.cityIds ?? []
      } else if (props.value.includes('districtIds')) {
        return props.activeFilters?.districtIds ?? []
      } else {
        return []
      }
    })

    return { currentActiveFilters }
  },
})
