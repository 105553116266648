<template>
  <div class="notifications-list-container">
    <div
      v-if="isPresent && !isFirstTimeLoading"
      class="notifications-list"
      @scroll="onScroll"
    >
      <NotificationsListItem
        v-for="notification of notificationItems"
        :key="notification.id"
        :item="notification"
        @click="$emit('notificationClick', notification)"
      />
      <div class="chunk-loading-spinner-container" v-if="isChunkLoading">
        <div class="spinner">
          <ProgressSpinner class="chunk-spinner" strokeWidth="3" />
        </div>
      </div>
    </div>
    <div v-if="isFirstTimeLoading" class="progress-container">
      <ProgressSpinner />
    </div>
    <EmptyState
      v-if="isEmpty && !isFirstTimeLoading"
      :title="t('notifications.empty_state.title')"
      :description="t('notifications.empty_state.description')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import NotificationsListItem from './NotificationsListItem.vue'
import { useInfiniteScroll, useLoadableResourceDataState } from '@bd/components'
import { ResultPageDto } from '@bd/api'
import { LoadableResource } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    NotificationsListItem,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    notifications: {
      type: Object as PropType<
        LoadableResource<ResultPageDto<SystemNotificationDto> | undefined>
      >,
      required: true,
    },
    isChunkLoading: {
      type: Boolean,
      required: true,
    },
    isFirstTimeLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['bottomReach', 'notificationClick'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const notifications = computed(() => props.notifications)
    const { isEmpty, isPresent } = useLoadableResourceDataState(
      notifications,
      (res) => !!(res?.content?.content && res.content.content.length),
    )

    const notificationItems = computed(
      () => notifications.value.content?.content ?? [],
    )

    const onScroll = useInfiniteScroll(() => {
      emit('bottomReach')
    })

    return {
      t,
      onScroll,
      isEmpty,
      isPresent,
      notificationItems,
    }
  },
})
</script>

<style lang="scss" scoped>
.notifications-list-container {
  display: flex;
  flex-direction: column;
  @include uniform-size(100%);
  overflow-y: hidden;
}
.notifications-list {
  overflow-y: auto;
}
.chunk-loading-spinner-container {
  padding: 0.5rem 0;
  width: 100%;
  @include flex;
  .spinner {
    overflow: hidden;
  }
  .chunk-spinner {
    @include uniform-size(64px);
  }
}
</style>
