import { AxiosError } from 'axios'
import http from './api-setup'
import { getToken } from './auth'

http.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject(error),
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      return Promise.reject(err)
    }
    throw Promise.reject({
      code: err.response?.data.code,
      resultKey: err.response?.data.resultKey,
      fieldErrors: err.response?.data.fieldErrors,
      globalErrors: err.response?.data.globalErrors,
      message: err.response?.data.message,
    })
  },
)

export default http
