<template>
  <div class="register-form-container">
    <div class="form-fields p-fluid">
      <TextInput
        name="email"
        type="email"
        :label="t('email')"
        :floating="false"
      />
      <TextInput
        name="phoneNumber"
        type="number"
        :label="t('phone')"
        :floating="false"
      />
      <TextInput
        name="firstName"
        type="text"
        :label="t('first_name')"
        :floating="false"
      />
      <TextInput
        name="lastName"
        type="text"
        :label="t('last_name')"
        :floating="false"
      />
      <TextInput
        name="password"
        type="password"
        :label="t('password')"
        :floating="false"
      />
      <TextInput
        name="passwordRepeat"
        type="password"
        :label="t('password_repeat')"
        :floating="false"
      />
    </div>
    <div class="bottom-container">
      <div class="consent-row">
        <Checkbox
          class="p-checkbox-primary mr-2"
          name="consent"
          v-model="consent"
          :binary="true"
        />
        <label class="consent-label" for="consent">
          {{ t('consent_1') }}
          <a class="terms-link" :href="`${domainURL}biuro/regulamin/`">
            {{ t('consent_2') }} </a
          >,
          {{ t('consent_3') }}
        </label>
      </div>
      <div class="register-btn">
        <Button
          :disabled="isLoading || !isFormValid || !consent"
          type="submit"
          :label="t('create_account')"
          class="p-button-primary w-100"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TextInput } from '@bd/components'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'
import { useIsFormValid } from 'vee-validate'
import Checkbox from 'primevue/checkbox'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  components: { TextInput, Checkbox },
  name: 'AuthFormRegister',
  setup() {
    const i18n = useI18n()
    const passwordFieldType = ref('password')
    const { isLoading } = useRootData()
    const isFormValid = useIsFormValid()

    const consent = ref(false)

    return {
      ...i18n,
      consent,
      isLoading,
      passwordFieldType,
      isFormValid,
      domainURL,
    }
  },
})
</script>
<style lang="scss" scoped>
.consent-row {
  margin: 1.5rem 0;
}
.register-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bottom-container {
  margin-bottom: 1rem;
}
.terms-link {
  color: $primary-color;
  text-decoration: none;
  font-weight: 500;
}
</style>
