import { computed, ComputedRef } from 'vue'
import { FilterData } from '@bd/store-modules/types'
import { FILTERS } from '@itcraft-bestdeal/components'
import { FiltersState } from '@bd/store-modules/types'
import { DistrictDto } from '@bd/api'

export const useComputedFilters = (
  stateFilters: ComputedRef<FiltersState | undefined>,
  districts: ComputedRef<DistrictDto[] | undefined>,
) => {
  const filters = computed(() => {
    const baseFilterList = [...FILTERS.filter((x) => !x.isAdditional)]
    const additionalList = [...FILTERS.filter((x) => x.isAdditional)]

    const hasDistricts =
      Array.isArray(stateFilters.value?.cityIds) &&
      stateFilters.value?.cityIds?.length === 1 &&
      districts.value?.length
    const hasDistrictsFilter = (filter: FilterData) =>
      hasDistricts ? true : filter.filterName !== 'district'

    const propertyTypeIncludes = (type: string) => {
      if (stateFilters.value?.propertyType) {
        return stateFilters.value.propertyType.includes(type)
      }
    }

    const isApartmentHouseOrOffice =
      propertyTypeIncludes('APARTMENT') ||
      propertyTypeIncludes('HOUSE') ||
      propertyTypeIncludes('OFFICE')
    const isApartmentOrOffice =
      propertyTypeIncludes('APARTMENT') || propertyTypeIncludes('OFFICE')
    const isPlot = propertyTypeIncludes('PLOT')

    const getApartmentOrHouseOrOfficeArray = () => {
      const rooms = additionalList.filter((x) => x.filterName === 'rooms')
      const constructionYear = additionalList.filter(
        (x) => x.filterName === 'constructionYear',
      )
      const area = additionalList.filter((x) => x.filterName === 'area')
      return [...rooms, ...constructionYear, ...area]
    }

    const getApartmentOrOfficeArray = () => {
      const floor = additionalList.filter((x) => x.filterName === 'floor')
      return floor
    }

    const apartmentHouseOrOfficeArray = isApartmentHouseOrOffice
      ? getApartmentOrHouseOrOfficeArray()
      : []
    const plotArray = isPlot
      ? additionalList.filter((x) => x.filterName === 'plotArea')
      : []
    const apartmentOrOfficeArray = isApartmentOrOffice
      ? getApartmentOrOfficeArray()
      : []

    return [
      ...baseFilterList.filter(hasDistrictsFilter),
      ...apartmentHouseOrOfficeArray,
      ...apartmentOrOfficeArray,
      ...plotArray,
    ].sort(
      (filter1: FilterData, filter2: FilterData) =>
        filter1.position - filter2.position,
    )
  })
  return {
    filters,
  }
}
