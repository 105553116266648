<template>
  <div class="row">
    <div class="col-6" v-for="key in items" :key="key">
      <label class="h-5 d-block">
        {{ t(convertCamelCaseToSnakeCase(key)) }}
      </label>
      <InputNumber
        :modelValue="filtersObject?.[key] ?? null"
        @update:modelValue="onChange($event, key)"
        class="number-range mt-2"
        :minFractionDigits="0"
        :mode="mode"
        :currency="currency"
        :suffix="suffix"
        :locale="locale"
        @keydown.enter="$emit('number-range-enter')"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import InputNumber from 'primevue/inputnumber'
import { LOCALISATION_SETTINGS } from '../../config'
import { FiltersState } from '@bd/store-modules/types'
import { convertCamelCaseToSnakeCase } from '@bd/helpers'

export default defineComponent({
  name: 'NumberRange',
  components: { InputNumber },
  emits: ['filter-data', 'number-range-enter'],
  props: {
    filtersObject: {
      type: Object as PropType<FiltersState | undefined>,
    },
    items: {
      type: Array as PropType<(keyof FiltersState)[]>,
      required: true,
    },
    unit: { type: String, default: 'decimal' },
  },
  setup(props, { emit }) {
    const i18n = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS

    const mode = computed(() => {
      return props.unit === 'currency' ? props.unit : 'decimal'
    })

    const suffix = computed(() => {
      return props.unit === 'area' ? ' m2' : ''
    })

    const onChange = (value: null | number, prop: string) => {
      emit('filter-data', { value: value || 0, prop })
      emit('number-range-enter')
    }

    return {
      ...i18n,
      props,
      currency,
      locale,
      mode,
      suffix,
      convertCamelCaseToSnakeCase,
      onChange,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.number-range .p-inputnumber-input) {
  width: 150px;
}
</style>
