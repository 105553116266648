<template>
  <div class="gallery-container">
    <Button
      v-if="showStatistics && offerDetails && !offerDetails.draft"
      class="p-button-secondary p-button-rounded stats-button"
      @click="
        $router.push({
          name: 'Statistics',
          params: { offerId: offerDetails.id },
        })
      "
    >
      <Svg :src="require('@bd/assets/icons/stats.svg')" class="c-primary" />
    </Button>
    <div class="btn btn-left" v-if="!isEmptyState">
      <Button
        @click="prev"
        icon="pi pi-chevron-left"
        class="p-button-secondary p-button-circle"
      />
    </div>
    <Galleria
      :containerClass="galleryContainerClass"
      :value="imagesList"
      v-model:activeIndex="opts.activeIndex"
      v-model:visible="isFullscreenComputed"
      :fullScreen="isFullscreenComputed"
      :showItemNavigators="false"
      :responsiveOptions="responsiveOptions"
      :showThumbnails="showThumbnails"
      :showThumbnailNavigators="opts.showThumbnailNavigators"
      circular
      maskClass="custom-gallery-mask"
    >
      <template #header>
        <div
          class="fullscreen fullscreen-header"
          v-if="isFullscreenComputed && desktopSize"
        >
          <div>
            <p class="caption">{{ offerDetails?.displayableId }}</p>
            <p class="fullscreen-header-name">
              <span v-if="district">{{ districtName }},</span>
              {{ streetName }}
            </p>
            <p>{{ cityName }}</p>
          </div>
          <div v-if="!isLogged || isBuyer">
            <Button
              :label="t('submit_appointment')"
              class="p-button-primary appointment-btn"
              @click="openAppointmentModal"
            />
          </div>
        </div>
        <div
          class="fullscreen fullscreen-header-mobile"
          v-if="isFullscreenComputed && !desktopSize"
        >
          <div class="text-center h-4">
            {{ opts.activeIndex + 1 }} {{ t('of') }} {{ imagesList.length }}
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <div v-if="isFullscreenComputed" class="navigator navigator-left">
          <Button
            @click="prev"
            icon="pi pi-chevron-left"
            class="p-button-secondary p-button-circle"
          />
          <p class="caption py-2">{{ t('previous') }}</p>
        </div>
        <div
          v-if="!isEmptyState && !isFullscreenComputed"
          class="count-images-element"
        >
          <img src="@bd/assets/icons/images.svg" class="d-inline" />
          <span class="h-4">{{ imagesList.length }}</span>
        </div>
        <img
          v-if="!isFullscreenComputed || width >= sizes.md"
          @click="switchFullscreenMode"
          :src="item.itemImageSrc"
          :alt="item.alt"
          class="w-100 d-block"
          :class="isEmptyState ? 'fit-scale-down' : 'fit-contain pointer'"
        />
        <div v-if="isFullscreenComputed" class="navigator navigator-right">
          <Button
            @click="next"
            icon="pi pi-chevron-right"
            class="p-button-secondary p-button-circle"
          />
          <p class="caption py-2">{{ t('next') }}</p>
        </div>
      </template>

      <template #thumbnail="{ item }">
        <img :src="item.thumbnailImageSrc" :alt="item.alt" />
      </template>

      <template #footer>
        <div
          class="fullscreen fullscreen-footer-mobile"
          v-if="isFullscreenComputed && !desktopSize"
        >
          <div class="btn-container" v-if="!isLogged || isBuyer">
            <Button
              :label="t('submit_appointment')"
              class="p-button-primary w-100"
              @click="openAppointmentModal"
            />
          </div>
        </div>
        <div
          class="fullscreen fullscreen-footer-desktop"
          v-if="isFullscreenComputed && desktopSize"
        >
          <div class="center h-4">
            {{ opts.activeIndex + 1 }} {{ t('of') }} {{ imagesList.length }}
          </div>
        </div>
      </template>
    </Galleria>

    <div class="btn btn-right" v-if="!isEmptyState">
      <Button
        @click="next"
        icon="pi pi-chevron-right"
        class="p-button-secondary p-button-circle"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'
import Galleria from 'primevue/galleria'
import { useI18n } from 'vue-i18n'
import {
  useRootData,
  useBreakpoints,
  useTextOrPlaceholder,
  Svg,
} from '@bd/components'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'
import { useUserData } from '@bd/components/use'
import { UserRole } from '@bd/api'
import { useAppStore } from '@bd/store-modules'

export default defineComponent({
  name: 'DashboardOfferGallery',
  components: { Galleria, Svg },
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
    showStatistics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleFullscreen'],
  setup(props, { emit }) {
    const store = useAppStore()
    const { isLoading } = useRootData()
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const { user, isLogged } = useUserData()
    const isBuyer = computed(
      () => user.value?.roles.includes(UserRole.CUSTOMER) || false,
    )

    const district = computed(
      () => props.offerDetails?.districtDto?.districtName,
    )
    const districtName = useTextOrPlaceholder(district)

    const street = computed(() => props.offerDetails?.street)
    const streetName = useTextOrPlaceholder(street, {
      transformFn: (val) => `${t('street_prefix')} ${val}`,
    })

    const city = computed(() => props.offerDetails?.cityDto.cityName)
    const cityName = useTextOrPlaceholder(city)

    const imagesList = computed(() => {
      const galleryImages = props.offerDetails?.galleryImages
      const galleryImagesUrls = props.offerDetails?.galleryImagesUrls

      const galleryImagesMapper = (photoUrl: string) => {
        return {
          itemImageSrc: photoUrl,
          thumbnailImageSrc: photoUrl,
          alt: props.offerDetails?.title,
          title: props.offerDetails?.title,
        }
      }

      if (galleryImages && galleryImages.length) {
        return galleryImages.map(({ photoUrl }) =>
          galleryImagesMapper(photoUrl),
        )
      }
      if (galleryImagesUrls && galleryImagesUrls.length) {
        return galleryImagesUrls.map((imgUrl) => galleryImagesMapper(imgUrl))
      }

      return [
        {
          itemImageSrc: require('@bd/assets/images/no-image.svg'),
          thumbnailImageSrc: require('@bd/assets/images/no-image.svg'),
          alt: t('no_image_available'),
          title: t('no_image_available'),
        },
      ]
    })
    const isEmptyState = computed(() => {
      const galleryUrls =
        props.offerDetails?.galleryImagesUrls ||
        props.offerDetails?.galleryImages
      return galleryUrls?.length === 0
    })
    const desktopSize = computed(() => width.value >= sizes.md)
    const responsiveOptions = [
      {
        breakpoint: `${sizes.lg}px`,
        numVisible: 3,
      },
      {
        breakpoint: `${sizes.md}px`,
        numVisible: 1,
      },
    ]
    const opts = ref({
      activeIndex: 0,
      showThumbnailNavigators: false,
    })

    const isFullscreen = ref(false)
    const showThumbnails = computed(() => {
      if (isEmptyState.value) {
        return false
      } else if (!isFullscreen.value && width.value >= sizes.md) {
        return true
      } else if (isFullscreen.value && width.value <= sizes.md) {
        return true
      } else {
        return false
      }
    })
    const next = () => {
      opts.value.activeIndex =
        opts.value.activeIndex === imagesList.value.length - 1
          ? 0
          : (opts.value.activeIndex += 1)
    }
    const prev = () => {
      opts.value.activeIndex =
        opts.value.activeIndex === 0
          ? imagesList.value.length - 1
          : opts.value.activeIndex - 1
    }
    // hack that removes unnecessary overflow-hidden class from the body
    const removeOverflowHidden = () => {
      document.body.classList.remove('p-overflow-hidden')
    }
    const isFullscreenComputed = computed({
      get: () => {
        return isFullscreen.value
      },
      set: (value) => {
        isFullscreen.value = value
        if (!isFullscreenComputed.value) {
          opts.value.activeIndex = 0
        }
        removeOverflowHidden()
        emit('toggleFullscreen', value)
      },
    })
    const galleryContainerClass = computed(() => {
      const containerClass = [
        {
          value: isFullscreenComputed.value,
          name: 'fullscreen-gallery',
        },
        {
          value: !isFullscreenComputed.value,
          name: 'main-gallery',
        },
        {
          value: isEmptyState.value,
          name: 'empty-state',
        },
      ]
      return containerClass
        .filter((item) => item.value)
        .map(({ name }) => name)
        .join(' ')
    })

    const switchFullscreenMode = () => {
      if (isEmptyState.value) {
        return
      }
      isFullscreenComputed.value = !isFullscreenComputed.value
    }

    const openAppointmentModal = () => {
      if (isLogged.value && isBuyer.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId: props.offerDetails?.id },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    return {
      t,
      opts,
      isFullscreenComputed,
      next,
      prev,
      showThumbnails,
      desktopSize,
      responsiveOptions,
      width,
      sizes,
      imagesList,
      isEmptyState,
      switchFullscreenMode,
      galleryContainerClass,
      isLoading,
      isLogged,
      isBuyer,
      district,
      districtName,
      streetName,
      cityName,
      openAppointmentModal,
    }
  },
})
</script>

<style lang="scss" scoped>
.appointment-btn {
  padding: 1rem 2.5rem;
  font-size: 14px;
}

img {
  @include breakpoint-up(md) {
    border-radius: 6px;
  }
}

.fit {
  &-scale-down {
    object-fit: scale-down;
    width: 100%;
  }
  &-contain {
    width: 100%;
    object-fit: contain;
    max-height: 80vh;
  }
}

:deep(.fullscreen-gallery) {
  @include breakpoint-up(md) {
    width: 60%;
  }
  .fullscreen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    &-name {
      font-size: 1.3rem;
      padding: 0.5rem 0;
      color: $port-gore;
      font-weight: 600;
    }
  }
  .p-galleria {
    &-thumbnail {
      &-item-content img {
        width: 100%;
        display: block;
      }
      &-container {
        background: none;
        padding: 0;
      }
    }
    &-close {
      background: $athens-gray;
      width: 2.5rem;
      height: 2.5rem;
      &:hover {
        background: $athens-gray;
      }
      &-icon {
        color: $comet;
        font-size: 1rem !important;
      }
    }
    &-item-container {
      position: relative;
    }
  }
}

:deep(.empty-state) {
  width: 100%;
  .p-galleria {
    &-item {
      background-color: rgba($silver-sand, 0.1);
    }
  }
}

:deep(.main-gallery) {
  .p-galleria {
    &-item {
      img {
        max-height: 480px;
      }
      @include breakpoint-up(md) {
        height: 480px;
      }
      @include breakpoint-down(md) {
        img {
          max-height: 310px;
        }
      }
    }
    &-thumbnail {
      &-container {
        background: transparent;
        padding: 0;
        display: flex;
        justify-content: space-between;
      }
      &-item-content {
        img {
          max-width: 100%;
          height: 150px;
          display: block;
        }
        &:focus {
          box-shadow: none;
        }
      }
      &-item-start {
        padding: 15px 15px 15px 0;
      }
      &-item-end {
        padding: 15px 0 15px 15px;
      }
    }
  }
}

.gallery-container {
  position: relative;

  .btn {
    position: absolute;
    top: 30%;
    &-left {
      left: -4rem;
    }
    &-right {
      right: -4rem;
    }
    @include breakpoint-down(md) {
      display: none;
    }
  }
}

.fullscreen {
  @include breakpoint-down(md) {
    position: absolute;
  }
  display: flex;
  width: 100%;
  &-header-mobile {
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 3.125rem;
    border-bottom: 1px solid rgba($athens-gray, 0.25);
  }
  &-footer-mobile {
    bottom: 0;
    font-size: 1.1rem;
    .btn-container {
      width: 100%;
      padding: 0 15px 20px 15px;
    }
  }
  &-footer-desktop {
    margin-top: 1rem;
    justify-content: center;
  }
}

.count-images-element {
  background: #ffff;
  border-radius: 19px;
  min-width: 70px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 22px;
  text-align: center;
  img {
    margin-right: 10px;
  }
}

.navigator {
  position: absolute;
  text-align: center;

  @include breakpoint-down(md) {
    display: none;
  }
  &-left {
    right: 105%;
  }
  &-right {
    left: 105%;
  }
}

.custom-gallery-mask {
  background: $white !important;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.stats-button {
  position: absolute;
  top: 20px;
  right: 22px;
  z-index: 1;
}
</style>
