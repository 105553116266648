<template>
  <div class="event-meeting-type-chooser">
    <ResponsiveContent>
      <template #mobile>
        <div
          class="mobile-meeting-type-chooser mobile-section mobile-section-simple"
        >
          <div class="meeting-type-details">
            <label
              class="section-label"
              :class="{
                'section-label-sm mb-1': isMeetingTypeChosen,
                'text-500': !isMeetingTypeChosen,
              }"
            >
              {{ t('calendar.events.save_event.meeting_type.title') }}
            </label>
            <span v-if="isMeetingTypeChosen">
              {{ selectedMeetingType?.name }}
            </span>
          </div>
          <Button
            :label="t(isMeetingTypeChosen ? 'change' : 'add')"
            class="btn-select-choose-type btn-mobile-sm p-button-text"
            @click="meetingTypeSelectionModalVisible = true"
          />
        </div>
      </template>
      <template #desktop>
        <div class="event-save-desktop-section">
          <h4 class="header h-4">
            {{ t('calendar.events.save_event.meeting_type.title_desktop') }}
          </h4>
          <CalendarEventMeetingTypeSelectionGrid
            :meetingTypes="meetingTypes"
            :selectedMeetingType="selectedMeetingType"
            @update:selectedMeetingType="onMeetingTypeSelect"
          />
        </div>
      </template>
    </ResponsiveContent>
    <CalendarEventMeetingTypeSelectionModal
      v-model:visible="meetingTypeSelectionModalVisible"
      :meetingTypes="meetingTypes"
      :selectedMeetingType="selectedMeetingType"
      @update:selectedMeetingType="onMeetingTypeSelect"
    />
  </div>
</template>

<script lang="ts">
import {
  CalendarEventMeetingTypeItem,
  ResponsiveContent,
  useMappedMeetingTypes,
} from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { getPredefinedMeetingTypes } from '@bd/store-modules/modules/static-data/getters-helpers'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventMeetingTypeSelectionGrid from './CalendarEventMeetingTypeSelectionGrid.vue'
import CalendarEventMeetingTypeSelectionModal from './CalendarEventMeetingTypeSelectionModal.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventMeetingTypeSelectionGrid,
    CalendarEventMeetingTypeSelectionModal,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const selectedMeetingType = computed(
      () => store.state.calendarEvent?.eventSaveData.meetingType,
    )

    const meetingTypeSelectionModalVisible = ref(false)

    const predefinedMeetingTypes = computed(
      () => getPredefinedMeetingTypes(store)?.content,
    )
    const meetingTypes = useMappedMeetingTypes(predefinedMeetingTypes)

    const isMeetingTypeChosen = computed(() => !!selectedMeetingType.value)

    const onMeetingTypeSelect = async (
      selectedMeetingType: CalendarEventMeetingTypeItem,
    ) => {
      const { selected: _, tileId: __, ...rest } = selectedMeetingType
      const meetingType: CalendarEventMeetingType = {
        ...rest,
      }
      await store.dispatch('calendarEvent/setSelectedMeetingType', meetingType)
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    return {
      t,
      meetingTypeSelectionModalVisible,
      isMeetingTypeChosen,
      meetingTypes,
      selectedMeetingType,
      onMeetingTypeSelect,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../calendar';
@import '../event-save';

.mobile-meeting-type-chooser {
  border-bottom: $divider-border;
}

.meeting-type-details {
  display: flex;
  flex-direction: column;
}

.meeting-types-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.event-save-card {
  .content {
    @include uniform-size(140px);
    padding: 1rem;
    cursor: pointer;
    &.selected {
      background-color: $port-gore;
      color: $white;
    }
  }
}
</style>
