import { MutationTree } from 'vuex'
import { TransactionHistoryState } from '@bd/store-modules/types/transactionHistory'
import {
  TransactionList,
  TransactionHistoryDetailsDto,
} from '@bd/api/common/types/transaction'
import {
  getDefaultTransactionsObject,
  getDefaultTransactionDetails,
  getDefaultTransactionHistory,
} from './utils'

export const mutations: MutationTree<TransactionHistoryState> = {
  SET_TRANSACTIONS(state, payload: TransactionList) {
    state.transactions = payload
  },
  SET_TRANSACTION_DETAILS(state, payload: TransactionHistoryDetailsDto) {
    const updatedItems = state.transactionDetails.filter(
      (detail) => detail.offerDto.id !== payload.offerDto.id,
    )
    state.transactionDetails = [...updatedItems, payload]
  },
  CLEAR_TRANSACTIONS(state) {
    state.transactions = getDefaultTransactionsObject()
    state.transactionDetails = getDefaultTransactionDetails()
    state.transactionHistory = getDefaultTransactionHistory()
  },
  SET_TRANSACTION_HISTORY(state, payload ) {
    state.transactionHistory = payload.content
  },
}
