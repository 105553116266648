
import {
  defineComponent,
  ref,
  PropType,
  watchEffect,
  watch,
  shallowRef,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside, useVModel } from '@vueuse/core'
import DashboardAutocompleteInput from './DashboardAutocompleteInput.vue'
import { useBreakpoints } from '../../use/useBreakpoints'

const UNBOUND_TOKEN = {} as never

export default defineComponent({
  name: 'DashboardAutocomplete',
  components: { DashboardAutocompleteInput },
  props: {
    query: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: (null as never) as PropType<boolean>,
      default: UNBOUND_TOKEN,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    minQueryLength: {
      type: Number,
      default: 0,
    },
    getItemKey: {
      type: Function as PropType<(item: unknown) => unknown>,
      required: true,
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:isOpen', 'search-click', 'item-select', 'input'],
  setup(props, { emit }) {
    const i18n = useI18n()
    const wrapper = shallowRef<HTMLElement>()
    const fakeWrapper = shallowRef<HTMLElement>()
    const activeIdx = ref(0)
    const { mobileSize } = useBreakpoints()
    const itemElements: Record<number, HTMLElement> = {}
    const isOpenComputed =
      props.isOpen === UNBOUND_TOKEN
        ? ref(false)
        : useVModel(props, 'isOpen', emit)

    const setResult = (result: unknown) => {
      isOpenComputed.value = false
      emit('item-select', result)
    }

    const scrollToIfNeeded = (item: HTMLElement | undefined) => {
      const parent = item?.offsetParent
      if (!item || !parent) return

      if (item.offsetTop < parent.scrollTop) {
        item.scrollIntoView({ block: 'start' })
      } else {
        const parentBottom = parent.scrollTop + parent.clientHeight
        const itemBottom = item.offsetTop + item.clientHeight
        if (itemBottom > parentBottom) {
          item.scrollIntoView({ block: 'end' })
        }
      }
    }

    const onArrowDown = () => {
      if (activeIdx.value < props.items.length - 1) {
        activeIdx.value = activeIdx.value + 1
        scrollToIfNeeded(itemElements[activeIdx.value])
      }
    }
    const onArrowUp = () => {
      if (activeIdx.value > 0) {
        activeIdx.value = activeIdx.value - 1
        scrollToIfNeeded(itemElements[activeIdx.value])
      }
    }

    const onEnter = () => {
      const index = activeIdx.value
      if (0 <= index && index < props.items.length) {
        activeIdx.value = 0
        setResult(props.items[index])
      }
    }

    watch([isOpenComputed, wrapper], async ([isOpen, wrapper]) => {
      if (!wrapper) return

      if (isOpen) {
        fakeWrapper.value!.style.height = wrapper.clientHeight + 'px'
        fakeWrapper.value!.style.width = wrapper.clientWidth + 'px'
        fakeWrapper.value!.style.display = 'block'

        wrapper.classList.add('before-open')
        requestAnimationFrame(() => {
          wrapper.classList.add('is-open')
          wrapper.classList.remove('before-open')
        })
      } else {
        fakeWrapper.value!.style.display = ''
        wrapper.classList.remove('is-open')
      }
    })

    watchEffect(() => {
      const isOverlay = mobileSize.value && isOpenComputed.value
      document.body.style.overflow = isOverlay ? 'hidden' : ''
    })

    onClickOutside(wrapper, () => {
      isOpenComputed.value = false
      activeIdx.value = 0
    })

    const setItemElement = (idx: number, elem: HTMLElement) => {
      itemElements[idx] = elem
    }

    return {
      t: i18n.t,
      mobileSize,
      setResult,
      wrapper,
      fakeWrapper,
      isOpenComputed,
      activeIdx,
      onArrowDown,
      onArrowUp,
      onEnter,
      setItemElement,
    }
  },
})
