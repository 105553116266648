
import { default as common } from '@/config/common.json'
import { SignupRequestDto } from '@bd/api'
import {
  authFormService,
  useBreakpoints,
  useRootData,
  AuthFormMessages,
} from '@bd/components'
import { registerSchema } from '@bd/components/yup'
import { ToType } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { Form, SubmissionContext, SubmissionHandler } from 'vee-validate'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import AuthFormRegister from '../AuthForm/AuthFormRegister.vue'
import AuthLayout from '../AuthLayout.vue'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  name: 'AuthRegister',
  components: { AuthFormRegister, AuthLayout, Form },
  emits: ['submit', 'register'],
  setup(_, { emit }) {
    const i18n = useI18n()
    const store = useAppStore()
    const { helpline } = common
    const { isLoading } = useRootData()
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    const toast = useToast()

    const onSubmit: SubmissionHandler<ToType<SignupRequestDto>> = async (
      values: SignupRequestDto,
      actions: SubmissionContext<ToType<SignupRequestDto>>,
    ) => {
      emit('submit')
      const payload: SignupRequestDto = values
      try {
        await store.dispatch('auth/authSignup', payload)
        emit('register')
        toast.add(AuthFormMessages.registerSuccess)
      } catch (err) {
        const errorsData = authFormService.handleError(
          store.state.auth?.error,
          isDesktopSize.value,
        )
        if (errorsData) {
          actions.setErrors(errorsData)
        }
        toast.add(AuthFormMessages.registerError)
      }
    }

    return {
      ...i18n,
      helpline,
      registerSchema,
      isLoading,
      onSubmit,
    }
  },
})
