<template>
  <div class="offer-footer">
    <Button
      v-if="footerButtons.appointment"
      @click="$emit('openAppointment')"
      :label="t('submit_appointment')"
      class="p-button-primary p-button-sm w-100 mb-2 appointment-btn p-button-rounded"
    />
    <label
      class="w-100"
      :class="{ 'mr-md-2': !onlyProposal }"
      v-tooltip="draftHint"
    >
      <Button
        v-if="footerButtons.proposal"
        class="p-button-secondary p-button-sm w-100 p-button-rounded"
        :label="t('make_a_proposal')"
        :disabled="offerDetails?.draft"
        @click="$emit('openProposal')"
      />
    </label>
  </div>
</template>

<script lang="ts">
import { OfferDetailsDto } from '@bd/store-modules/types'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { OfferFooterButtons } from './types'

export default defineComponent({
  name: 'DashboardOfferFooter',
  props: {
    displayedButtons: {
      type: Array as PropType<OfferFooterButtons[]>,
      required: true,
    },
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
  },
  emits: ['openAppointment', 'openProposal'],
  setup(props) {
    const { t } = useI18n()
    const displayedButtonsIncludes = props.displayedButtons.includes.bind(
      props.displayedButtons,
    )
    const footerButtons = computed(() => {
      return {
        appointment: displayedButtonsIncludes('openAppointment'),
        proposal: displayedButtonsIncludes('openProposal'),
      }
    })

    const onlyProposal = computed(
      () =>
        props.displayedButtons.length === 1 &&
        props.displayedButtons[0] === 'openProposal',
    )

    const draftHint = computed(() =>
      props.offerDetails?.draft ? t('offerDetails.draft_proposal_hint') : null,
    )

    return {
      t,
      footerButtons,
      draftHint,
      onlyProposal,
    }
  },
})
</script>

<style lang="scss" scoped>
.offer-footer {
  display: flex;
  flex-direction: column;
  font-size: 1rem;

  @include breakpoint-down(md) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $white;
    @include flex($direction: row-reverse);
    height: $dashboard-offer-footer-height;
    padding: 0.625rem;
    > * {
      font-size: 0.875rem;
      margin: 0.25rem;
    }

    .proposal-btn {
      margin-right: 0.75rem !important;
    }
  }
}
.mr-md-2 {
  @include breakpoint-down(md) {
    margin-right: 0.5rem !important;
  }
}
</style>
