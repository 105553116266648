import {
  http,
  ApiResponse,
  ResultPageDto,
  UserCalendarEventDto,
  TimeSlot,
} from '@bd/api/common'
import {
  OfferDetailsDto,
  OffersParams,
} from '@bd/store-modules/types'

import { ClientOfferRowDto } from './types'
import { stringify } from 'qs'
import { LocalIsoDate } from '@bd/helpers'
import { AvailableTimeSlotsDto, CustomerMeetingInvitationDto } from './types'

export const offer = (id: number): ApiResponse<OfferDetailsDto> => {
  return http.get(`/offer/${id}`)
}

export const offers = (
  params: OffersParams,
): ApiResponse<ResultPageDto<ClientOfferRowDto>> => {
  return http.get('/offers', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const userCalendarEvents = (
  date: LocalIsoDate,
): ApiResponse<UserCalendarEventDto[]> => {
  return http.get(`/user-calendar-events`, {
    params: {
      date,
    },
  })
}

export const offerAvailableTimeSlots = (
  params: AvailableTimeSlotsDto,
): ApiResponse<TimeSlot.TimeSlotDto[]> => {
  return http.get(`/offer/${params.id}/available-time-slots`, {
    params,
  })
}

export const offerMeetingInvitation = (
  offerId: number,
  payload: CustomerMeetingInvitationDto,
): ApiResponse<void> => {
  return http.post(`/offer/${offerId}/meeting-invitation`, payload)
}
