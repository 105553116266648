
import { CalendarEventMeetingTypeItem } from '@bd/components'
import { defineComponent, PropType } from 'vue'
import CalendarEventMeetingTypesListItem from './CalendarEventMeetingTypesListItem.vue'

export default defineComponent({
  components: {
    CalendarEventMeetingTypesListItem,
  },
  props: {
    meetingTypes: {
      type: Array as PropType<CalendarEventMeetingTypeItem[]>,
      required: true,
    },
  },
  emits: ['selectMeetingType'],
})
