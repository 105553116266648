
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Dialog from 'primevue/dialog'
import MobileHeader from '../Layout/MobileHeader/MobileHeader.vue'
import { useBreakpoints, ClientNoteMessages } from '@bd/components'
import { useField, useForm } from 'vee-validate'
import { addClientNoteSchema } from '@bd/components/yup'
import { useToast } from 'primevue/usetoast'
import { addNote } from '@bd/api/common/clients-management-api'
import { TextareaInput } from '@bd/components'

export default defineComponent({
  components: { Dialog, MobileHeader, TextareaInput },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:visible', 'noteAdd'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const toast = useToast()
    const dialogVisible = useVModel(props, 'visible', emit)
    const { mobileSize } = useBreakpoints()

    const form = useForm({
      validationSchema: addClientNoteSchema,
    })

    const { value: note } = useField<string>('note')

    const onSubmit = form.handleSubmit(async () => {
      try {
        await addNote({
          value: note.value,
          userId: props.clientId,
        })
        toast.add(ClientNoteMessages.saveSuccess)
        emit('noteAdd')
      } catch (e) {
        toast.add(ClientNoteMessages.saveError)
      }
    })

    return {
      t,
      dialogVisible,
      mobileSize,
      form,
      onSubmit,
    }
  },
})
