
import { ResponsiveContent, Svg, UserAvatar } from '@bd/components'
import { CalendarEventAttendee } from '@bd/store-modules/types'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendeeDetails from './CalendarEventAttendeeDetails.vue'
import { EventAttendeeAvailableTypes } from './types'
import { TemplateRef } from '@bd/helpers'

export default defineComponent({
  components: {
    ResponsiveContent,
    Svg,
    UserAvatar,
    CalendarEventAttendeeDetails,
  },
  props: {
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<CalendarEventAttendee>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAttendeeRequired: {
      type: Boolean,
      default: false,
    },
    attendeeRequiredLabel: {
      type: String,
      required: false,
    },
  },
  emits: ['attendeeChooserClick', 'clearAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const isAttendeeChosen = computed(() => !!props.selectedAttendee)
    const showControlButtons = computed(
      () => !props.disabled && isAttendeeChosen.value,
    )

    const editButton = ref<TemplateRef>(null)
    const onAttendeeChooserClick = ($event: PointerEvent) => {
      if (isAttendeeChosen.value && $event.target !== editButton.value) {
        return
      }
      emit('attendeeChooserClick', $event)
    }

    return {
      t,
      isAttendeeChosen,
      editButton,
      showControlButtons,
      onAttendeeChooserClick,
    }
  },
})
