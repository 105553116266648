<template>
  <div class="calendar-event-offers-list modal-list-container">
    <CalendarEventOffersListItem
      v-for="offer of items"
      :key="offer.id"
      :item="offer"
      @click="$emit('selectOffer', offer)"
    >
      <template #item-append="{ item }" v-if="isDesktopSize">
        <Svg
          v-if="!item.selected"
          :src="require('@bd/assets/icons/plus.svg')"
          class="plus-icon c-primary"
        />
      </template>
    </CalendarEventOffersListItem>
  </div>
</template>

<script lang="ts">
import { CalendarEventOfferItem, Svg, useBreakpoints } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import CalendarEventOffersListItem from './CalendarEventOffersListItem.vue'

export default defineComponent({
  components: {
    CalendarEventOffersListItem,
    Svg,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<CalendarEventOfferItem[]>,
      required: true,
    },
  },
  emits: ['selectOffer'],
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../event-save';

.plus-icon {
  @include uniform-size(35px);
}
:deep(.item-append) {
  align-self: center;
  cursor: pointer;
  margin-left: 0.5rem;
}
</style>
