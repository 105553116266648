<template>
  <span
    class="search-input"
    :class="{
      'p-input-icon-right': searchPhraseNotEmpty,
      'p-input-icon-left': showSearchIcon,
    }"
  >
    <i v-if="showSearchIcon" class="pi pi-search" />
    <InputText
      v-bind="$attrs"
      type="text"
      :ref="inputRefFn"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @update:modelValue="onUpdate"
    />
    <Button
      icon="pi pi-times"
      class="p-button-rounded p-button-text"
      v-if="searchPhraseNotEmpty"
      @click="clearInput"
    />
  </span>
</template>

<script lang="ts">
import { TemplateRef } from '@bd/helpers'
import { useDebounceFn, VueInstance } from '@vueuse/core'
import InputText from 'primevue/inputtext'
import { computed, defineComponent, onMounted, ref } from 'vue'

const DEFAULT_DEBOUNCE_MS = 250

export default defineComponent({
  components: {
    InputText,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debounceMs: {
      type: Number,
      default: DEFAULT_DEBOUNCE_MS,
    },
    emitSearchOnClear: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'search', 'clear'],
  setup(props, { emit }) {
    const emitSearchEvent = useDebounceFn(
      () => emit('search', props.modelValue),
      props.debounceMs,
    )

    const onUpdate = (value: string) => {
      emit('update:modelValue', value)
      emitSearchEvent()
    }

    const clearInput = () => {
      emit('update:modelValue', '')
      emit('clear')
      if (props.emitSearchOnClear) {
        emit('search', '')
      }
    }

    const searchPhraseNotEmpty = computed(() => props.modelValue.trim().length)
    const inputText = ref<TemplateRef>(null)

    /**
     * Autofocus the input if needed
     */
    onMounted(() => {
      if (!props.autofocus) {
        return
      }
      inputText.value?.focus()
    })

    /**
     * Retrieve native input element from the PrimeVue's InputText
     */
    const inputRefFn = (el: VueInstance) => {
      if (el && el.$el) {
        inputText.value = el.$el
      }
    }

    return {
      onUpdate,
      clearInput,
      inputText,
      inputRefFn,
      searchPhraseNotEmpty,
    }
  },
})
</script>

<style lang="scss" scoped>
.search-input {
  width: 100%;
}
.p-inputtext {
  width: 100%;
  height: 50px;
  font-size: 0.875rem;
  color: $comet;
  padding: 0 1rem;
  border-radius: 4px;
}

.p-input-icon-right {
  > i:last-of-type {
    right: unset;
  }
  > .p-inputtext {
    padding-right: 3rem;
  }
  > .p-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
  }
}
</style>
