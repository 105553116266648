
import { defineComponent, ref, computed, PropType } from 'vue'
import {
  DashboardOfferFooter,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferDetails,
  DashboardOfferDescription,
  DashboardOfferUser,
  Svg,
  useTextOrPlaceholder,
  numericPlaceholder,
  UserType,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'
import { DisplayedOfferUsers, OfferFooterButtons } from './types'

export default defineComponent({
  name: 'DashboardOfferSidebar',
  components: {
    DashboardOfferMap,
    DashboardOfferUser,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferGallery,
    DashboardOfferFooter,
    Svg,
  },
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
    displayedButtons: {
      type: Array as PropType<OfferFooterButtons[]>,
      required: true,
    },
    displayedUsers: {
      type: Array as PropType<DisplayedOfferUsers[]>,
      required: true,
    },
    showStatistics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openAppointment', 'openProposal', 'toggleGalleryFullscreen'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const showMore = ref(false)
    const offer = computed(() => props.offerDetails)
    const { currency, locale } = LOCALISATION_SETTINGS

    const area = computed(() => {
      return props.offerDetails?.houseArea
        ? props.offerDetails?.houseArea
        : props.offerDetails?.landArea
    })

    const numericPlaceholderOpts = { placeholder: numericPlaceholder }

    const street = computed(() => offer.value?.street)
    const city = computed(() => offer.value?.cityDto?.cityName)
    const streetName = useTextOrPlaceholder(street, {
      transformFn: (val) => `${t('street_prefix')} ${val}`,
    })
    const cityName = useTextOrPlaceholder(city)

    const rooms = computed(() => offer.value?.rooms)
    const roomsCount = useTextOrPlaceholder(rooms, numericPlaceholderOpts)

    const floor = computed(() => offer.value?.floor)
    const levels = computed(() => offer.value?.levels)

    const floorNumber = useTextOrPlaceholder(floor, numericPlaceholderOpts)
    const levelsCount = useTextOrPlaceholder(levels, numericPlaceholderOpts)

    const totalPrice = computed(() => offer.value?.price)
    const priceValue = useTextOrPlaceholder(totalPrice, {
      transformFn: (val) => {
        return getFormattedPrice(val as number, currency, locale) as string
      },
      ...numericPlaceholderOpts,
    })

    const pricePerM2 = computed(() => {
      if (area.value && totalPrice.value) {
        return getFormattedPrice(
          totalPrice.value / area.value,
          currency,
          locale,
        )
      }
      return null
    })

    const draftHint = computed(() =>
      offer.value?.draft ? t('offerDetails.draft_proposal_hint') : null,
    )

    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      emit('toggleGalleryFullscreen', isFullscreen)
    }

    return {
      t,
      showMore,
      offer,
      currency,
      locale,
      getFormattedPrice,
      area,
      streetName,
      cityName,
      roomsCount,
      numericPlaceholder,
      floorNumber,
      levelsCount,
      priceValue,
      pricePerM2,
      draftHint,
      onGalleryFullscreenToggle,
      UserType,
    }
  },
})
