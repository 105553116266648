
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  props: {
    headerLabel: {
      type: String,
      required: true,
    },
    backRoute: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
    },
    shouldNavigate: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['navigateClick'],
  setup(props, { emit }) {
    const router = useRouter()

    const navigate = () => {
      emit('navigateClick')
      if (!props.shouldNavigate) {
        return
      }

      if (props.backRoute) {
        router.push(
          typeof props.backRoute === 'object'
            ? props.backRoute
            : { path: props.backRoute },
        )
      } else {
        router.back()
      }
    }

    return {
      navigate,
    }
  },
})
