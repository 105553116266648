import { computed, onMounted, onUnmounted, ref } from 'vue'
import { sizes, windowType } from '@bd/components'

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth)

  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const type = computed(() => windowType(windowWidth.value))

  const width = computed(() => windowWidth.value)

  const mobileSize = computed(() => width.value <= sizes.md)

  const desktopSize = computed(() => !mobileSize.value)

  return { width, type, sizes, mobileSize, desktopSize }
}
