import { NotificationsState } from '@bd/store-modules/types'

export const isLoadingChunk = (state?: NotificationsState): boolean => {
  const isContentPresentBeforehand = !!state?.notifications.content
  return (state?.notifications.isLoading ?? false) && isContentPresentBeforehand
}

export const isLoadingFirstTime = (
  state?: NotificationsState,
): boolean => {
  const isContentPresentBeforehand = !!state?.notifications.content
  return (
    (state?.notifications.isLoading ?? false) && !isContentPresentBeforehand
  )
}

export const allNotificationsLoaded = (
  state?: NotificationsState,
): boolean => {
  const notificationsContent = state?.notifications.content
  if (!notificationsContent) {
    return false
  }
  return notificationsContent.content.length >= notificationsContent.totalCount
}

export const getNextPageIndex = (state?: NotificationsState) => {
  const notificationsContent = state?.notifications.content
  return notificationsContent != null ? notificationsContent.pageNumber + 1 : 0
}
