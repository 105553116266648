<template>
  <div class="p-fluid">
    <div class="field-spacing">
      <TextInput
        name="email"
        type="email"
        :label="t('email')"
        :floating="false"
      />
    </div>
  </div>
  <div class="row submit-spacing d-none d-md-block">
    <div class="col">
      <Button
        :disabled="isLoading || !isFormValid"
        type="submit"
        :label="t('reset_password')"
        class="p-button-primary p-button-rounded w-100"
      />
    </div>
  </div>
  <div class="row submit-spacing d-md-none" v-if="showHint">
    <div class="col body-sm text-center">
      <p>
        {{ `${t('reset_email_question')}  ${t('contact_helpline')}` }}
      </p>
      <p>{{ helpline }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { TextInput } from '@bd/components'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'
import { default as common } from '@/config/common.json'
import { useIsFormValid } from 'vee-validate'

export default defineComponent({
  components: { TextInput },
  name: 'AuthFormReset',
  props: {
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const i18n = useI18n()
    const { isLoading } = useRootData()
    const { helpline } = common
    const isFormValid = useIsFormValid()

    return {
      ...i18n,
      isLoading,
      helpline,
      isFormValid,
    }
  },
})
</script>
