import { AppState } from '../types'

export const state: AppState = {
  isLoading: false,
  dialog: {
    dashboardFilters: false,
    proposalModal: false,
    counterProposalModal: false,
    dashboardLoginModal: false,
    appointmentModal: false,
  },
  bottomNavigationVisible: false,
}
