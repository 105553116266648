<template>
  <div class="mobile-header">
    <slot name="header">
      <div class="content-wrapper d-md-none">
        <div class="row justify-content-between align-items-center no-gutters">
          <div class="col-3 text-left">
            <Button
              @click="navigate"
              icon="pi pi-chevron-left"
              class="p-button-secondary p-button-circle"
            />
          </div>
          <div class="col-6 h-4 text-600 text-center">{{ headerLabel }}</div>
          <div class="col-3"></div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  props: {
    headerLabel: {
      type: String,
      required: true,
    },
    backRoute: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
    },
    shouldNavigate: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['navigateClick'],
  setup(props, { emit }) {
    const router = useRouter()

    const navigate = () => {
      emit('navigateClick')
      if (!props.shouldNavigate) {
        return
      }

      if (props.backRoute) {
        router.push(
          typeof props.backRoute === 'object'
            ? props.backRoute
            : { path: props.backRoute },
        )
      } else {
        router.back()
      }
    }

    return {
      navigate,
    }
  },
})
</script>

<style lang="scss" scoped>
.content-wrapper {
  position: fixed;
  padding: 5px;
  top: 0;
  width: 100%;
  z-index: 10;
  height: $mobile-header-height;
  background-color: $white;
  box-shadow: 0 2px 6px 2px $subtle-shadow;
}
</style>
