import { AxiosResponse } from 'axios'
import { ID } from '@bd/helpers'
import * as TimeSlot from './time-slot'
export { TimeSlot }

export * from './calendar'
export * from './transaction'
export * from './client'

export type ApiResponse<T> = Promise<AxiosResponse<T>>

export enum UserRole {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  CALL_CENTER = 'CALL_CENTER',
  CUSTOMER = 'CUSTOMER',
  SELLER = 'SELLER',
}

export enum EmployeeRole {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  CALL_CENTER = 'CALL_CENTER',
}

export type ClientRole = UserRole.SELLER | UserRole.CUSTOMER

export interface UserDto extends UserEditDto {
  id: ID<'User'>
  userId?: ID<'User'>
  displayableId: string
  avatarUrl: string
  roles: UserRole[]
}

export interface UserEditDto {
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
}

export interface ClientDto extends Omit<UserDto, 'avatarUrl' | 'roles'> {
  userRole: UserRole[]
}

export interface ClientAdminDto extends Omit<ClientDto, 'id'> {
  userId: number
}

export interface LoginRequestDto {
  username: string
  password: string
  // Firebase Cloud Messaging token to receive push notifications as a logged in user. Optional
  fcmToken?: string
}

export interface LoginResponseDto {
  accessToken: string
  user: UserDto
}

export interface SignupRequestDto {
  email: string
  phoneNumber: string
  password: string
  firstName: string
  lastName: string
}

export interface PasswordResetRequestDto {
  email: string
}

export interface FilterType {
  propertyType: string
}

export interface SimpleResponse<T> {
  value: T
}

export interface SimpleResultListDto<T> {
  content: T[]
}

export interface ResultPageDto<T> extends SimpleResultListDto<T> {
  pageNumber: number
  pageSize: number
  totalCount: number
}

export interface ResultListDto<T>
  extends SimpleResultListDto<T>,
    ResultPageDto<T> {
  pageIndex: number
}

export interface ResultSliceDto<T> extends SimpleResultListDto<T> {
  sliceNumber: number
  sliceSize: number
  hasNext: boolean
}

export interface TransactionParams {
  customerId: number
  pageIndex: number
  pageSize: number
}

export enum PropertyType {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  PLOT = 'PLOT',
  OFFICE = 'OFFICE',
}

export enum ApartmentLevel {
  ONE_LEVEL = 'ONE_LEVEL',
  TWO_LEVEL = 'TWO_LEVEL',
  MULTILEVEL = 'MULTILEVEL',
}

export enum BuildingFinishedState {
  PERFECT = 'PERFECT',
  VERY_GOOD = 'VERY_GOOD',
  GOOD = 'GOOD',
  NEEDS_SMALL_RENOVATION = 'NEEDS_SMALL_RENOVATION',
  NEEDS_TOTAL_RENOVATION = 'NEEDS_TOTAL_RENOVATION',
  NEEDS_CONVERSION = 'NEEDS_CONVERSION',
  NEEDS_FINISHING = 'NEEDS_FINISHING',
  DEVELOPMENT_STATE = 'DEVELOPMENT_STATE',
  OPEN_RAW_STATE = 'OPEN_RAW_STATE',
  CLOSE_RAW_STATE = 'CLOSE_RAW_STATE',
  FOR_DEMOLITION = 'FOR_DEMOLITION',
  ABANDONED = 'ABANDONED',
  TO_RENOVATE = 'TO_RENOVATE',
  TO_REFRESH = 'TO_REFRESH',
  AFTER_RENOVATION = 'AFTER_RENOVATION',
  RENOVATED = 'RENOVATED',
  HIGH_STANDARD = 'HIGH_STANDARD',
  WITHOUT_WHITE_MONTAGE = 'WITHOUT_WHITE_MONTAGE',
  TO_LIVE = 'TO_LIVE',
  ABOVE_CONSTRUCTION = 'ABOVE_CONSTRUCTION',
  WITH_TENANTS = 'WITH_TENANTS',
  NEGOTIATED = 'NEGOTIATED',
  TO_RENEW = 'TO_RENEW',
}

export enum BuildingMaterial {
  PRECAST_CONCRETE_SLABS = 'PRECAST_CONCRETE_SLABS',
  BRICK = 'BRICK',
  YTONG = 'YTONG',
  H_FRAME = 'H_FRAME',
  CONCRETE = 'CONCRETE',
  WOOD = 'WOOD',
  AIR_BRICK = 'AIR_BRICK',
  CERAMICS = 'CERAMICS',
  OTHER = 'OTHER',
  MONOLITH = 'MONOLITH',
  MIXED = 'MIXED',
  BLOCKS = 'BLOCKS',
  STEEL = 'STEEL',
  GLASS = 'GLASS',
  AERATED = 'AERATED',
  SAND_LIME_BRICK = 'SAND_LIME_BRICK',
}

export enum BuildingType {}

export enum ApartmentBuildingType {
  APARTMENT_OFFICE_BUILDING = 'APARTMENT_OFFICE_BUILDING',
  OFFICE_BUILDING = 'OFFICE_BUILDING',
  HOUSE = 'HOUSE',
  TENEMENT_HOUSE = 'TENEMENT_HOUSE',
  APARTMENT_RETAIL_BUILDING = 'APARTMENT_RETAIL_BUILDING',
  OFFICE_RETAIL_BUILDING = 'OFFICE_RETAIL_BUILDING',
  APARTMENT_BUILDING = 'APARTMENT_BUILDING',
  STORAGE_AND_OFFICE_CENTER = 'STORAGE_AND_OFFICE_CENTER',
  SEAL = 'SEAL',
  LOW_BLOCK = 'LOW_BLOCK',
  HIGH_BLOCK = 'HIGH_BLOCK',
  VILLA = 'VILLA',
  LOFT = 'LOFT',
  HOUSE_MULTI_PLACES = 'HOUSE_MULTI_PLACES',
  APARTMENT_BLOCK = 'APARTMENT_BLOCK',
  SECTION_HOUSE = 'SECTION_HOUSE',
  SHOPPING_CENTER = 'SHOPPING_CENTER',
  GARAGE = 'GARAGE',
  PAVILION = 'PAVILION',
  HOTEL = 'HOTEL',
  COURT = 'COURT',
  PALACE = 'PALACE',
  PENSION = 'PENSION',
  HISTORICAL = 'HISTORICAL',
  OTHER = 'OTHER',
  RESTAURANT = 'RESTAURANT',
  COTTAGE = 'COTTAGE',
  PART_OF_HOUSE = 'PART_OF_HOUSE',
  WAVY_BLOCK = 'WAVY_BLOCK',
  OUT_BUILDING = 'OUT_BUILDING',
  REPAIR_SHOP = 'REPAIR_SHOP',
  HALL = 'HALL',
  SKY_SCRAPER = 'SKY_SCRAPER',
  MOTEL = 'MOTEL',
  WAREHOUSE = 'WAREHOUSE',
  MILL = 'MILL',
  SHOP = 'SHOP',
  PETROL_STATION = 'PETROL_STATION',
  GRANARY = 'GRANARY',
  PUB = 'PUB',
  NEWSSTAND = 'NEWSSTAND',
  SALOON = 'SALOON',
  INN = 'INN',
  CAFE = 'CAFE',
  CABINET = 'CABINET',
  DISTRIBUTION_CENTRE = 'DISTRIBUTION_CENTRE',
  GRANGE = 'GRANGE',
  AGRITURISMO = 'AGRITURISMO',
  FARM = 'FARM',
  PRODUCTION_HALL = 'PRODUCTION_HALL',
  OFFICE_SPACE = 'OFFICE_SPACE',
}

export enum OfficeBuildingType {
  SERVICES = 'SERVICES',
  MEDICAL = 'MEDICAL',
  RECREATION = 'RECREATION',
  CATERING = 'CATERING',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
}

export enum HouseBuildingType {
  TERRACED = 'TERRACED',
  SEMI_DETACHED = 'SEMI_DETACHED',
  DETACHED = 'DETACHED',
  COUNTRY = 'COUNTRY',
  PERIOD = 'PERIOD',
  CENTER_SEGMENT = 'CENTER_SEGMENT',
  EXTERNAL_SEGMENT = 'EXTERNAL_SEGMENT',
  HALF_OF_SEMI_DETACHED = 'HALF_OF_SEMI_DETACHED',
  TENEMENT = 'TENEMENT',
  VILLA = 'VILLA',
  CITY_VILLA = 'CITY_VILLA',
  RESIDENCE = 'RESIDENCE',
  MANOR_HOUSE = 'MANOR_HOUSE',
  HOUSE_FLOOR = 'HOUSE_FLOOR',
  HOLIDAY = 'HOLIDAY',
  PALACE = 'PALACE',
  SEGMENT = 'SEGMENT',
  OTHER = 'OTHER',
  COTTAGE = 'COTTAGE',
  PART_OF_HOUSE = 'PART_OF_HOUSE',
}

export enum PlotType {
  RESIDENTAL_BUILDING = 'RESIDENTAL_BUILDING',
  COMMERCIAL = 'COMMERCIAL',
  RECREATIONAL = 'RECREATIONAL',
  FOREST = 'FOREST',
  HABITAT = 'HABITAT',
  AGRICULTURAL = 'AGRICULTURAL',
  INVESTMENT = 'INVESTMENT',
  INDUSTRIAL_LAND = 'INDUSTRIAL_LAND',
  OTHER = 'OTHER',
  CRAFTS = 'CRAFTS',
  SERVICES = 'SERVICES',
  AGRI_BUILDING = 'AGRI_BUILDING',
  FARM = 'FARM',
  FOREST_BUILDING = 'FOREST_BUILDING',
}

export enum HeatingType {
  COAL = 'COAL',
  FIREPLACE = 'FIREPLACE',
  CITY = 'CITY',
  STOVE = 'STOVE',
  GAS = 'GAS',
  OTHER = 'OTHER',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  OWN = 'OWN',
  OIL = 'OIL',
  ELECTRIC = 'ELECTRIC',
  HEAT_PUMP = 'HEAT_PUMP',
  HOT_AIR_BLOWER_USING_WATER = 'HOT_AIR_BLOWER_USING_WATER',
  FLOOR = 'FLOOR',
  HOT_AIR_BLOWER_USING_GAS = 'HOT_AIR_BLOWER_USING_GAS',
  INFRARED_HEATER = 'INFRARED_HEATER',
  SOLID_FUEL = 'SOLID_FUEL',
  BIOMASS = 'BIOMASS',
}

export enum KitchenType {
  WITH_WINDOW = 'WITH_WINDOW',
  WITHOUT_WINDOW = 'WITHOUT_WINDOW',
  WITH_GAP = 'WITH_GAP',
  ADJOINING = 'ADJOINING',
  OPEN = 'OPEN',
  SEPARATE = 'SEPARATE',
  OTHER = 'OTHER',
  NO_KITCHEN = 'NO_KITCHEN',
  WITH_EQUIPMENT = 'WITH_EQUIPMENT',
  BUILT = 'BUILT',
}

export enum PropertyMarket {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum PropertyOwnership {
  MORTGAGE = 'MORTGAGE',
  HOUSING_COOPERATIVE = 'HOUSING_COOPERATIVE',
  SHARED = 'SHARED',
  OTHER = 'OTHER',
  HOUSING_COOPERATIVE_KW = 'HOUSING_COOPERATIVE_KW',
  TENANT_COOPERATIVE = 'TENANT_COOPERATIVE',
}

export enum WindowsType {
  ALUMINIUM = 'ALUMINIUM',
  PCV = 'PCV',
  WOODEN_OLD_TYPE = 'WOODEN_OLD_TYPE',
  WOODEN_NEW_TYPE = 'WOODEN_NEW_TYPE',
  STEEL = 'STEEL',
  OLD = 'OLD',
  BURGLAR = 'BURGLAR',
  SHADES = 'SHADES',
  PORTCULLIS = 'PORTCULLIS',
  ROOF = 'ROOF',
}

export interface DistrictDto {
  districtId: ID<'District'>
  districtName: string
}

export interface ProvinceDto {
  provinceId: ID<'Province'>
  provinceName: string
}

export interface CityDto {
  cityId: ID<'City'>
  cityName: string
}

export interface CityWithProvinceDto extends CityDto, ProvinceDto {}

export interface BaseClientDto {
  firstName: string
  id: number
  lastName: string
  phoneNumber: string
}

export interface ErrorObject {
  field: string
  message: string
  parameters: string[]
}

export interface ErrorResponse {
  code: number
  resultKey?: string
  fieldErrors?: ErrorObject[] | undefined
  globalErrors?: ErrorObject[] | undefined
  message?: string
}

export interface LoginData {
  username: string
  password: string
}

export interface AuthErrorResult {
  username?: string
  password?: string
  passwordRepeat?: string
  email?: string
  phoneNumber?: string
  mobile?: LoginData
  desktop?: LoginData
}

export interface RoomItem {
  name: string
  value: string
}

export interface ProvincesCitiesDistrictsDto {
  cityWithDistrictsDtos: {
    cityId: number
    cityName: string
    districtDtos: DistrictDto[]
  }[]
  provinceId: number
  provinceName: string
}

export interface BaseAddress {
  buildingNumber: string
  cityDto: CityDto
  districtDto?: DistrictDto
  houseNumber: string
  street: string
}

export interface BaseCustomerAndSeller {
  phoneNumber: string
  firstName: string
  description: string
}

export interface CommonClientSaveDto {
  userId?: number // For edit
  firstName: string
  phoneNumber: string
  description: string
  note?: string
  email?: string
  agentId?: number
}

export interface SellerSaveDto extends CommonClientSaveDto {
  lastName: string
  developer: boolean
}

export interface BuyerSaveDto extends CommonClientSaveDto {
  lastName?: string
  city: string
  district?: string
  areaMin?: number
  areaMax?: number
  priceMin?: number
  priceMax?: number
  market?: PropertyMarket
}

export type AdminClientSaveDto = SellerSaveDto & BuyerSaveDto

type Optional<T> = T | null | undefined

type MarketOptional = Optional<PropertyMarket>
type StringOptional = Optional<string>
type NumberOptional = Optional<number>

export interface CustomerDto extends BaseCustomerAndSeller {
  city: string
  note: StringOptional
  priceMin: NumberOptional
  priceMax: NumberOptional
  district: StringOptional
  areaMin: NumberOptional
  areaMax: NumberOptional
  market: MarketOptional
  lastName: StringOptional
}

export interface PageableParams {
  pageIndex?: number
  pageSize?: number
}

export interface CommonOfferRowDto {
  buildingNumber?: string
  cityDto: CityDto
  displayableId: string
  districtDto?: DistrictDto
  draft?: boolean
  houseArea: number
  landArea: number
  houseNumber?: string
  id: number
  imageUrl: string
  price: number
  rooms: number
  sellerFirstName?: string
  sellerLastName?: string
  street: string
  uploaded?: boolean
  constructionYear?: number
  floor: number
  levels: number
}

export interface BooleanDto {
  value: boolean
}
