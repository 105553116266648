
import { UserCalendarEventDetailsDto } from '@bd/api'
import { UserType } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import EventAttendeeCard from './EventAttendeeCard.vue'

export default defineComponent({
  components: { EventAttendeeCard },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
  },
  setup(props) {
    const attendees = computed(() => ({
      seller: props.event.calendarEventDto.seller,
      buyer: props.event.calendarEventDto.customer,
    }))

    return {
      UserType,
      attendees,
    }
  },
})
