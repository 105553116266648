import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
import { locales as commonLocales } from '@bd/components/i18n'
import deepmerge from 'deepmerge'

const messages = () => {
  const locales: LocaleMessages<VueMessageType> = {}
  const context = require.context('./locales', true, /[a-z]{2}-[A-Z]{2}\.ts$/)
  context.keys().forEach((fileName) => {
    const [, locale] = fileName.match(/([a-z]{2}-[A-Z]{2})\.ts$/)!
    const currentCommonLocale = commonLocales[locale]
    locales[locale] = deepmerge(currentCommonLocale, context(fileName).default)
  })
  return locales
}

export const i18n = createI18n({
  locale: 'pl-PL',
  fallbackLocale: 'pl-PL',
  messages: messages(),
})
