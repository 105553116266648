import { MutationTree } from 'vuex'
import { StaticDataState } from '../../types'
import { MeetingTypeDto, TimeSlot } from '@bd/api'

export const mutations: MutationTree<StaticDataState> = {
  SET_TIME_SLOTS(state, timeSlots: TimeSlot.TimeSlotDto[]) {
    state.timeSlots = timeSlots
  },
  SET_PREDEFINED_MEETING_TYPES(state, meetingTypes: MeetingTypeDto[]) {
    state.predefinedMeetingTypes.content = meetingTypes
  },
  SET_PREDEFINED_MEETING_TYPES_LOADING(state, isLoading: boolean) {
    state.predefinedMeetingTypes.isLoading = isLoading
  },
}
