
import {
  defineComponent,
  PropType,
  shallowRef,
  watch,
  ref,
  onUnmounted,
} from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { useFieldOf } from '@bd/helpers'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'SellerAutocomplete',
  components: { AutoComplete },
  emits: ['search', 'show-warning-modal'],
  props: {
    items: {
      type: Array as PropType<OfferDetailsDto['offerSellerDto'][]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { errorMessage, value: sellerDto } = useOfferField('offerSellerDto')
    const { value: isDraft } = useOfferField('draft')
    const selectionOrQuery = shallowRef<typeof sellerDto.value | string>(
      sellerDto.value || '',
    )

    const onClear = () => {
      sellerDto.value = undefined
      emit('search', '')
    }

    const renderItem = (item: OfferDetailsDto['offerSellerDto']) => {
      const optionalEmail = item.email ? ` (${item.email})` : ''
      return `${item.firstName} ${item.lastName}${optionalEmail}`
    }

    const shouldNotDisplayModal = ref(false)

    watch(sellerDto, (seller, oldSeller) => {
      if (
        oldSeller?.id &&
        seller?.id !== oldSeller?.id &&
        !shouldNotDisplayModal.value &&
        isDraft.value
      ) {
        shouldNotDisplayModal.value = true
        emit('show-warning-modal', oldSeller)
      }
      if (seller) selectionOrQuery.value = seller
    })

    if (!selectionOrQuery.value) {
      shouldNotDisplayModal.value = true
    }

    onUnmounted(() => (shouldNotDisplayModal.value = false))

    return {
      sellerDto,
      selectionOrQuery,
      onClear,
      errorMessage,
      renderItem,
      shouldNotDisplayModal,
    }
  },
})
