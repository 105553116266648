<template>
  <div class="event-time-chooser">
    <ResponsiveContent>
      <template #mobile>
        <div class="mobile-event-time-chooser mobile-section">
          <h4 class="header h-4">
            {{ t('calendar.events.save_event.time.title') }}
          </h4>
          <div class="time-container">
            <CalendarEventTimeSlotsPicker
              :timeSlots="timeSlots"
              :selectedTimeSlotIds="selectedTimeSlotIds"
              :availableTimeSlots="availableTimeSlots"
              :isCustomMeeting="isCustomMeeting"
              :disabledTimeSlotIds="disabledTimeSlotIds"
              @update:selected="onSelectedTimeSlotsChange"
            />
          </div>
        </div>
      </template>
      <template #desktop>
        <div class="event-save-desktop-section">
          <h4 class="header h-4">
            {{ t('calendar.events.save_event.time.title') }}
          </h4>
          <div class="time-slots-card event-save-card">
            <div class="time-container">
              <CalendarEventTimeSlotsPicker
                :timeSlots="timeSlots"
                :selectedTimeSlotIds="selectedTimeSlotIds"
                :availableTimeSlots="availableTimeSlots"
                :isCustomMeeting="isCustomMeeting"
                :disabledTimeSlotIds="disabledTimeSlotIds"
                @update:selected="onSelectedTimeSlotsChange"
              />
            </div>
          </div>
        </div>
      </template>
    </ResponsiveContent>
  </div>
</template>

<script lang="ts">
import { TimeSlotID } from '@bd/api/common/types/time-slot'
import { ResponsiveContent } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventTimeSlotsPicker from './CalendarEventTimeSlotsPicker.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventTimeSlotsPicker,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const isCustomMeeting = computed(
      () =>
        store.state.calendarEvent?.eventSaveData.meetingType?.isCustom ?? false,
    )

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])

    const availableTimeSlots = computed(
      () => store.state.calendarEvent?.eventSaveMetaData.availableTimeSlots,
    )

    const onSelectedTimeSlotsChange = (timeSlotIds: TimeSlotID[]) => {
      store.dispatch('calendarEvent/setSelectedTimeSlots', timeSlotIds)
    }

    const disabledTimeSlotIds = computed(
      () =>
        store.state.calendarEvent?.eventSaveMetaData.disabledTimeSlots ?? [],
    )

    const selectedTimeSlotIds = computed(() =>
      (store.state.calendarEvent?.eventSaveData.timeSlotIds ?? []).filter(
        (timeSlot) => !disabledTimeSlotIds.value.includes(timeSlot),
      ),
    )

    return {
      t,
      timeSlots,
      availableTimeSlots,
      selectedTimeSlotIds,
      isCustomMeeting,
      disabledTimeSlotIds,
      onSelectedTimeSlotsChange,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../calendar';
@import '../event-save';

$time-slots-max-height: 8.75rem;

.mobile-event-time-chooser {
  .header {
    margin-bottom: 1rem;
  }
  .time-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}
.event-save-desktop-section {
  .time-container {
    max-height: $time-slots-max-height;
  }
}
.time-container {
  overflow-y: auto;
  padding: 0.25rem;
}
.time-slots-card {
  padding: 1.5rem 2rem;
}
</style>
