
import { defineComponent, ref, computed, PropType, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ProgressSpinner from 'primevue/progressspinner'
import ProfileCardDate from './ProfileCardDate.vue'
import ProfileCardDetail from './ProfileCardDetail.vue'
import ProfileCardModal from './ProfileCardModal.vue'
import ProfileCardChip from './ProfileCardChip.vue'
import ProfileCardContractor from './ProfileCardContractor.vue'
import { useBreakpoints, useResourceDataState } from '@bd/components'
import {
  TransactionHistoryDto,
  TransactionHistoryDetailsDtoAgent,
  PurchaseOfferDto,
  TransactionHistoryDetailsDto,
} from '@bd/api'
import { LOCALISATION_SETTINGS } from '../../config'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'ProfileCard',
  components: {
    Card,
    ProfileCardDate,
    ProfileCardDetail,
    ProfileCardModal,
    ProfileCardChip,
    ProfileCardContractor,
    Accordion,
    AccordionTab,
    ProgressSpinner,
  },
  props: {
    transactionHistoryClient: {
      type: Object as PropType<TransactionHistoryDto>,
    },
    transactionHistoryAgent: {
      type: Object as PropType<TransactionHistoryDetailsDtoAgent>,
    },
    transactionDetails: {
      type: Array as PropType<TransactionHistoryDetailsDto[]>,
      required: true,
    },
    isRowBreak: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String as PropType<string | undefined>,
    },
  },
  emits: ['fetch-details'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const { currency, locale } = LOCALISATION_SETTINGS
    const transactionHistory = computed(() => {
      if (props.transactionHistoryClient) {
        return props.transactionHistoryClient
      } else {
        return props.transactionHistoryAgent
      }
    })

    const detailsStatus = ref(1)
    const showProfileCardModal = ref(false)

    const purchasedOffers = computed((): PurchaseOfferDto[] => {
      const offerId = transactionHistory.value?.offerDto.id

      return (
        props.transactionDetails?.find((x) => x.offerDto.id === offerId)
          ?.purchaseOffers || []
      )
    })

    const yearFromDate = computed(() => {
      if (!transactionHistory.value) {
        return false
      }
      const date = new Date(transactionHistory.value.date)
      return date.getFullYear()
    })

    const cityAndDistrict = computed(() => {
      const offerDto = transactionHistory.value?.offerDto
      return {
        city: offerDto?.cityDto.cityName,
        district: offerDto?.districtDto?.districtName,
      }
    })

    const showDetails = () => {
      const offerId = transactionHistory.value?.offerDto.id

      const payload = { offerId, customerId: 0 }
      if (props.transactionHistoryAgent?.customer.id) {
        payload.customerId = props.transactionHistoryAgent.customer.id
      }

      if (mobileSize.value && detailsStatus.value === 0) {
        detailsStatus.value = 1
        return
      }
      if (mobileSize.value && detailsStatus.value === 1) {
        emit('fetch-details', payload)
        detailsStatus.value = 0
        return
      }

      if (!showProfileCardModal.value) {
        emit('fetch-details', payload)
        showProfileCardModal.value = true
        return
      }
    }

    const { isEmpty } = useResourceDataState(
      purchasedOffers,
      (res) => !!(res && res.length),
    )

    const contractors = computed(() => {
      if (props.transactionHistoryAgent === undefined) {
        return false
      }
      return {
        customer: props.transactionHistoryAgent.customer,
        seller: props.transactionHistoryAgent.seller,
      }
    })

    watchEffect(() => {
      !mobileSize.value && (detailsStatus.value = 1)
      mobileSize.value && (showProfileCardModal.value = false)
    })

    return {
      t,
      detailsStatus,
      showProfileCardModal,
      showDetails,
      cityAndDistrict,
      purchasedOffers,
      currency,
      locale,
      getFormattedPrice,
      yearFromDate,
      isEmpty,
      transactionHistory,
      contractors,
    }
  },
})
