import { computed, ComputedRef, Ref } from 'vue'
import { i18n } from '../i18n'
import { UserType } from './types'

const t = i18n.global.t

/**
 * Extracts user fullname initials
 */
export const extractFullNameInitials = (
  firstName?: string,
  lastName?: string,
): string => {
  return (firstName ?? '')
    .charAt(0)
    .toUpperCase()
    .concat((lastName ?? '').charAt(0).toUpperCase())
}

/**
 * Extracts initial (first letter) of the translated user type
 */
export const extractUserTypeInitial = (userType: UserType): string => {
  return t(`userType.${[userType.toLowerCase()]}`)[0]
}

export const useUserTypeClass = (
  userType: Ref<UserType>,
): ComputedRef<string> => computed(() => userType.value.toLowerCase())

export const useUserNameOrPlaceholder = (
  name: Ref<{ firstName?: string; lastName?: string }>,
  placeholder?: string,
) =>
  computed(() => {
    const { firstName, lastName } = name.value
    return !firstName && !lastName
      ? placeholder ?? `(${t('no_information')})`
      : `${firstName ?? ''} ${lastName ?? ''}`
  })
