<template>
  <div :class="['user-profile-banner', 'user-type-' + userTypeClass]">
    <div class="layout-container">
      <div class="prepend-slot">
        <slot name="prepend"></slot>
      </div>

      <div class="content">
        <UserAvatar
          :user="user"
          :userType="userType"
          :overrideWithUserType="overrideWithUserType"
        />

        <div class="labels">
          <h1 class="h-1 username text-wrap-3 mr-1">
            {{ user.firstName }} {{ user.lastName }}
          </h1>
          <label v-if="shouldShowUserId" class="label-15 id">
            {{ t('user.id') }}:
            {{ user.displayableId ?? user.id ?? user.userId }}
          </label>
        </div>
      </div>

      <div class="append-slot">
        <slot name="append"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { UserType, useUserTypeClass, UserAvatar } from '@bd/components'
import { UserDto } from '@bd/api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    UserAvatar,
  },
  props: {
    userType: {
      type: String as PropType<UserType>,
      validator: (value: string) => value in UserType,
      required: true,
    },
    user: {
      type: Object as PropType<UserDto>,
      required: true,
    },
    overrideWithUserType: {
      type: Boolean,
      default: false,
    },
    shouldShowUserId: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedUserType = computed(() => props.userType)
    const userTypeClass = useUserTypeClass(computedUserType)

    return {
      ...useI18n(),
      UserType,
      userTypeClass,
    }
  },
})
</script>

<style lang="scss" scoped>
$agent-bg: rgba($white-ice, 0.3);
$buyer-bg: $vista-white;
$seller-bg: $alabaster;
$developer-bg: $alabaster;

.user-profile-banner {
  .layout-container {
    display: flex;

    @include breakpoint-up(md) {
      max-width: 60%;
      margin: 0 auto;
    }
    @include breakpoint-down(md) {
      flex-direction: column;
      padding-bottom: 1.5rem;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 2.5rem 0;
    @include breakpoint-down(md) {
      width: 100%;
      padding: 3rem 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .labels {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    @include breakpoint-down(md) {
      align-items: center;
    }

    .username {
      margin: 0.5rem 0;
      @include breakpoint-down(md) {
        margin: 0.5rem 1rem;
        text-align: center;
      }
    }
  }

  &.user-type-buyer {
    background-color: $buyer-bg;
    .id {
      color: $zest;
    }
  }
  &.user-type-agent {
    background-color: $agent-bg;
    .id {
      color: $cerulean;
    }
  }
  &.user-type-seller {
    background-color: $seller-bg;
    .id {
      color: $dodger-blue;
    }
  }
  &.user-type-developer {
    background-color: $developer-bg;
    .id {
      color: $jungle-green;
    }
  }

  .user-avatar {
    flex: 1 0 auto;
    width: 80px;
    max-width: 80px;
    height: 80px;
    font-size: 2rem;
    border-radius: 22px;

    @include breakpoint-up(md) {
      margin-right: 1.5rem;
    }
  }
}
</style>
