import { CommonUserCalendarEventsParams } from './types/calendar'
import http from './http-client'
import {
  ApiResponse,
  SimpleResponse,
  SimpleResultListDto,
  UserCalendarEventDetailsDto,
  UserCalendarEventDto,
  ResultListDtoOfLocalDate,
} from './types'

export const userCalendarEvents = <T extends CommonUserCalendarEventsParams>(
  params: T,
): ApiResponse<
  UserCalendarEventDto[] & SimpleResultListDto<UserCalendarEventDto>
> => {
  return http.get(`/user-calendar-events`, {
    params,
  })
}

export const userCalendarEventDetails = (
  eventId: UserCalendarEventDto['userCalendarEventId'],
): ApiResponse<UserCalendarEventDetailsDto> => {
  return http.get(`/user-calendar-event/${eventId}`)
}

export const cancelCalendarEvent = (
  eventId: UserCalendarEventDto['userCalendarEventId'],
): ApiResponse<void> => {
  return http.post(`/user-calendar-event/${eventId}/cancellation`)
}

export const acceptCalendarEvent = (
  eventId: UserCalendarEventDto['userCalendarEventId'],
): ApiResponse<void> => {
  return http.post(`/user-calendar-event/${eventId}/acceptance`)
}

export const rejectCalendarEvent = (
  eventId: UserCalendarEventDto['userCalendarEventId'],
): ApiResponse<void> => {
  return http.post(`/user-calendar-event/${eventId}/rejection`)
}

export const getTodayEventsCount = (): ApiResponse<SimpleResponse<number>> => {
  return http.get('/user-calendar-events/amount')
}

export const getUpcomingEventDates = (): ApiResponse<ResultListDtoOfLocalDate> => {
  return http.get('/user-calendar-events/dates')
}
