import { CommonAppState, NotificationsState } from '@bd/store-modules/types'
import { ActionContext } from 'vuex'

export const updateNotificationsAfterSeen = (
  ctx: ActionContext<NotificationsState, CommonAppState>,
  notificationId: number,
) => {
  const { state, commit } = ctx
  const notifications = state.notifications.content?.content

  const seenNotification = notifications?.find((n) => n.id === notificationId)
  if (!seenNotification) {
    return
  }

  const newNotifications = notifications!.map((notification) => ({
    ...notification,
    seen: seenNotification.id === notification.id || notification.seen,
  }))

  commit('SET_NOTIFICATIONS_CONTENT', newNotifications)
}
