
import { computed, defineComponent, PropType, ref } from 'vue'
import { UserAvatar, DATE_FORMAT, UserType } from '@bd/components'
import { ClientNoteDto } from '@bd/api'
import { truncateText } from '@bd/helpers'
import { useI18n } from 'vue-i18n'

export const CLIENT_NOTE_MAX_CHARS = 200

export default defineComponent({
  components: {
    UserAvatar,
  },
  props: {
    note: {
      type: Object as PropType<ClientNoteDto>,
      required: true,
    },
    maxContentCharacters: {
      type: Number,
      default: CLIENT_NOTE_MAX_CHARS,
    },
  },
  emits: ['delete'],
  setup(props) {
    const { t, locale } = useI18n()
    const showMore = ref(false)
    const content = computed(() =>
      truncateText(
        props.note.content,
        props.maxContentCharacters,
        showMore.value,
      ),
    )

    const isContentLengthOverLimit = computed(
      () => props.note.content.length > props.maxContentCharacters,
    )

    const createdAt = computed(() => {
      const formatter = new Intl.DateTimeFormat(locale.value, {
        ...DATE_FORMAT.standardDate,
      })
      return formatter.format(props.note.createdAt)
    })

    return {
      t,
      UserType,
      showMore,
      content,
      createdAt,
      isContentLengthOverLimit,
    }
  },
})
