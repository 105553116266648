
import { defineComponent, PropType, computed } from 'vue'
import { UserAvatar, UserType, Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'DashboardOfferKeeper',
  components: { UserAvatar, Svg },
  props: {
    userData: {
      type: Object as PropType<
        OfferDetailsDto['offerAgentDto'] | OfferDetailsDto['offerSellerDto']
      >,
      required: true,
    },
    typeOfUser: {
      type: String as PropType<UserType>,
      required: true,
    },
    desktopVersion: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const router = useRouter()

    const label = computed(() => {
      return props.typeOfUser === UserType.Seller
        ? t('userType.seller')
        : t('userType.agent')
    })

    const goToSeller = () => {
      if (props.typeOfUser !== UserType.Seller) {
        return
      }
      router.push({
        path: `/client/${props.userData?.id}`,
      })
    }
    return { t, UserType, label, goToSeller }
  },
})
