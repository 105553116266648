import { ToastAddParams } from '../utils'
import { i18n } from '@bd/components/i18n'
import { TOAST_CONFIG } from '../../config'
import { EventActionType } from './types'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

type ToastMessages =
  | 'acceptSuccess'
  | 'acceptError'
  | 'cancelSuccess'
  | 'cancelError'
  | 'rejectSuccess'
  | 'rejectError'
export const CalendarEventMessages: {
  [key in ToastMessages]: ToastAddParams
} = {
  acceptSuccess: {
    severity: 'success',
    summary: t('calendar.event_details.accept.success'),
    life: messageDuration.success,
  },
  acceptError: {
    severity: 'error',
    summary: t('calendar.event_details.accept.error'),
    life: messageDuration.error,
  },
  cancelSuccess: {
    severity: 'success',
    summary: t('calendar.event_details.cancel.success'),
    life: messageDuration.success,
  },
  cancelError: {
    severity: 'error',
    summary: t('calendar.event_details.cancel.error'),
    life: messageDuration.error,
  },
  rejectSuccess: {
    severity: 'success',
    summary: t('calendar.event_details.cancel.success'),
    life: messageDuration.success,
  },
  rejectError: {
    severity: 'error',
    summary: t('calendar.event_details.cancel.error'),
    life: messageDuration.error,
  },
}

export interface DialogOptions {
  type: EventActionType
  title: string
  description?: string
}

export const ConfirmationDialogOptions: Partial<
  { [key in EventActionType]: DialogOptions }
> = {
  Cancel: {
    type: EventActionType.Cancel,
    title: t('calendar.event_details.cancel.confirm_title'),
  },
  Reject: {
    type: EventActionType.Reject,
    title: t('calendar.event_details.reject.confirm_title'),
  },
  Accept: {
    type: EventActionType.Accept,
    title: t('calendar.event_details.accept.confirm_title'),
    description: t('calendar.event_details.accept.confirm_description'),
  },
}
