
import { computed, defineComponent, PropType } from 'vue'
import Calendar from 'primevue/calendar'
import { default as common } from '@bd/admin/config/common.json'

export default defineComponent({
  name: 'DatePicker',
  components: { Calendar },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
      default: () => new Date(),
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    floating: {
      type: Boolean,
      default: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDays: {
      type: Array as PropType<number[]>,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const date = computed({
      get: () => props.modelValue,
      set: (value: Date) => {
        emit('update:modelValue', value)
      },
    })

    const computedClass = computed(
      (): Record<string, boolean> => {
        return {
          'p-float-label': props.floating,
          'd-flex flex-column': !props.floating,
          'white-bg': props.whiteBackground,
        }
      },
    )

    return {
      date,
      computedClass,
      datePickerFormat: common.datePickerFormat,
    }
  },
})
