<template>
  <DashboardCarousel :items="items" class="carousel-tiles">
    <template v-slot:slide-item="{ item }">
      <button
        @click="$emit('filter-data', { value: item.option, prop: value })"
        :class="{ active: activeProperty.includes(item.option) }"
      >
        <img :src="item.icon" />
        <p class="body-bg">{{ item.name }}</p>
      </button>
    </template>
  </DashboardCarousel>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { AssetData } from '@bd/admin/types'
import DashboardCarousel from './DashboardCarousel.vue'
import { FiltersState } from '@bd/store-modules/types'

export default defineComponent({
  name: 'CarouselTiles',
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<AssetData[]>,
      required: true,
    },
    filtersObject: {
      type: Object as PropType<FiltersState | undefined>,
      required: true,
    },
  },
  emits: ['filter-data'],
  components: { DashboardCarousel },
  setup(props) {
    const activeProperty = computed(() => {
      if (props.filtersObject && props.filtersObject.propertyType) {
        return [props.filtersObject.propertyType]
      } else {
        return []
      }
    })
    return { ...props, activeProperty }
  },
})
</script>

<style lang="scss" scoped>
.carousel-tiles {
  .carousel__slide {
    button {
      width: 125px;
      padding: 25px 0;
      height: auto;
      background: #ffff;
      border: 2px solid $athens-gray;
      border-radius: 6px;
      &:active {
        border: 2px solid $heliotrope;
        outline: none;
      }
      &:hover {
        outline: none;
      }
    }
    .active {
      border: 2px solid $heliotrope;
    }
    p {
      margin-top: 20px;
    }
  }
}
</style>
