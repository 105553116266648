import { Filters, ComputedFilter } from '@bd/store-modules/types'
import { FILTERS } from '../config'

export const useComputedActiveFilters = (
  searchedFilters: Filters,
): ComputedFilter[] => {
  const arrayOfRangeValues = FILTERS.filter(
    (filter) => filter.type === 'range',
  ).flatMap((x) => x.value)

  const mappedFilters = (Object.keys(searchedFilters) as (keyof Filters)[])
    .map(
      (key): ComputedFilter => {
        const filterData = FILTERS.find((x) => x.value.includes(key))

        return {
          isRange: arrayOfRangeValues.includes(key),
          filterName: FILTERS.find((x) => x.value.includes(key))?.filterName,
          passedData: [{ name: key, value: searchedFilters[key] as string }],
          children: [],
          filterData,
        }
      },
    )
    .filter((x) => x.filterName)
  const reducedFilters = mappedFilters.reduce(
    (total: ComputedFilter[], currentValue: ComputedFilter) => {
      if (!total.find((el) => el.filterName === currentValue.filterName)) {
        return [...total, currentValue]
      }
      return total
    },
    [],
  )

  const mappedFiltersWithChildren = reducedFilters.map((x) => {
    const children = mappedFilters
      .filter((filter) => filter.filterName === x.filterName)
      .flatMap((x) => x.passedData)
    return { ...x, children }
  })
  return mappedFiltersWithChildren
}
