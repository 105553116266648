
import { defineComponent, PropType, computed } from 'vue'
import { UserType, useUserTypeClass, UserAvatar } from '@bd/components'
import { UserDto } from '@bd/api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    UserAvatar,
  },
  props: {
    userType: {
      type: String as PropType<UserType>,
      validator: (value: string) => value in UserType,
      required: true,
    },
    user: {
      type: Object as PropType<UserDto>,
      required: true,
    },
    overrideWithUserType: {
      type: Boolean,
      default: false,
    },
    shouldShowUserId: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedUserType = computed(() => props.userType)
    const userTypeClass = useUserTypeClass(computedUserType)

    return {
      ...useI18n(),
      UserType,
      userTypeClass,
    }
  },
})
