import { Module } from 'vuex'
import { AuthState, CommonAppState } from '@bd/store-modules/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const auth: Module<AuthState, CommonAppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default auth
