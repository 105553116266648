export default {
  welcome: 'Witaj',
  todays_meetings_count: 'Dzisiejsza ilość spotkań',
  loading: 'Loading',
  make_an_appointment: 'Make an appointment',
  filters: 'Filtry',
  offers: {
    title: 'Oferty',
  },
  clients: {
    adding: 'Dodawanie klienta',
    add: 'Dodaj klienta',
    title: 'Klienci',
    create_meeting: 'Utwórz spotkanie z tym klientem',
    wanted_location: 'Poszukiwana lokalizacja',
    history: 'Historia klienta',
    description: 'Opis',
    notes: 'Notatki',
    details: 'Szczegóły klienta',
    from: 'Od',
    to: 'Do',
    form: {
      personal_data: 'Dane osobowe',
      client_description: 'Opis klienta',
      wanted_apartment: 'Poszukiwane mieszkanie',
      area: 'Powierzchnia',
      price: 'Cena',
      market: 'Rynek',
      description: 'Opis klienta',
      additional_data: 'Opis klienta',
      describe_client: 'Opisz klienta',
      primary: 'Pierwotny',
      secondary: 'Wtórny',
    },
    messages: {
      successSave: 'Dodawanie klienta zakończone sukcesem',
      errorSave: 'Dodawanie klienta nie powiodło się',
    },
  },
  dashboard_content_list_type: {
    offers: {
      All: 'Wszystkie',
      OnlyMine: 'Moje',
    },
    clients: {
      All: 'Wszyscy',
      OnlyMine: 'Moi',
    },
  },
  calendar: {
    edit_availability: 'Edytuj dostępność',
    empty_state: {
      description:
        'Aby poprawnie ustawić kalendarz, wprowadź godziny, w których nie będziesz dostępny.',
      action: 'Określ dostępność',
    },
    availability: {
      page_title: 'Twoja dostępność',
      add_exception: 'Dodaj wyjątek',
      exception_adding: 'Dodawanie wyjątku',
      save_for_entire_week: 'Kopiuj na tydzień',
      save_day: 'Zapisz dzień',
      save_week: 'Zapisz na cały tydzień',
      changes_unsaved: {
        title: 'Nie zapisano zmian.',
        description:
          'Naciśnij ponownie na dzień, aby go zmienić i odrzucić zmiany.',
      },
      submit: {
        error: 'Nie można zapisać dostępności',
        success: 'Twoja dostępność została zapisana',
      },
      exception: {
        submit: {
          error: 'Nie można zapisać wyjątku',
          success: 'Zapisano wyjątek',
        },
      },
    },
  },
  options: 'Opcje',
  statistics: {
    offer_interest: 'Zainteresowanie Twoją ofertą',
    similar_offers_statistics: 'Oferty w Twojej okolicy',
    periodic_statistics: 'Wyświetlenia twojej oferty',
    last_week_views: 'Wyświetlenia z ostatniego tygodnia',
    total_views: 'Wszystkie wyświetlenia',
    last_week_inquiries: 'Zgłoszenia z ostatniego tygodnia',
    total_inquiries: 'Wszystkie zgłoszenia',
    periods: {
      months_1: 'Miesiąc',
      months_3: '3 Miesiące',
      months_6: '6 Miesięcy',
      months_12: 'Rok',
      months_all: 'Od początku',
    },
  },
  price_proposal_was_made: 'złożył propozycję cenową użytkownikowi',
  customer_history_is_empty: 'Historia klienta jest pusta',
}
