import { ResultPageDto } from '@bd/api'
import { computed, Ref } from 'vue'

export const useResultListStats = <T>(
  list: Ref<ResultPageDto<T> | undefined>,
) => {
  return computed(() => ({
    total: list.value?.totalCount,
    returned: list.value?.content.length,
  }))
}
