
import { CalendarEventOfferItem, Svg, useBreakpoints } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import CalendarEventOffersListItem from './CalendarEventOffersListItem.vue'

export default defineComponent({
  components: {
    CalendarEventOffersListItem,
    Svg,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<CalendarEventOfferItem[]>,
      required: true,
    },
  },
  emits: ['selectOffer'],
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
