<template>
  <Carousel :items-to-show="2.5">
    <Slide v-for="item in items" :key="item">
      <slot :item="item" name="slide-item"></slot>
    </Slide>
  </Carousel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'DashboardCarousel',
  props: ['items'],
  components: {
    Carousel,
    Slide,
  },
  setup(props) {
    return { ...props }
  },
})
</script>
