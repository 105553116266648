
import { defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { TileItem, TileOptions } from './types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TileList',
  components: { Svg },
  emits: ['item-click'],
  props: {
    item: {
      type: Object as PropType<TileItem>,
      required: true,
    },
    opts: {
      type: Object as PropType<TileOptions>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isBreak: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()

    const handler = () => {
      const { routerLink, externalLink, action } = props.item

      if (routerLink) router.push({ path: routerLink })
      if (externalLink) window.open(externalLink, '_self')
      if (action) emit('item-click', action)
    }
    return { handler }
  },
})
