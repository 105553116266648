<template>
  <Dialog
    class="dialog-offer-selection"
    :header="t('calendar.events.save_event.offer')"
    :showHeader="true"
    :modal="true"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <CalendarEventOfferSelectionList
      @selectOffer="onOfferSelected"
      @searchOffer="$emit('searchOffer', $event)"
    />
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventOfferSelectionList from './CalendarEventOfferSelectionList.vue'
import Dialog from 'primevue/dialog'
import { CalendarEventOfferItem } from '@bd/components'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventOfferSelectionList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'selectOffer', 'searchOffer'],
  setup(_, { emit }) {
    const { t } = useI18n()

    const onOfferSelected = (selectedOffer: CalendarEventOfferItem) => {
      emit('selectOffer', selectedOffer)
      emit('update:visible', false)
    }

    return {
      t,
      onOfferSelected,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.dialog-offer-selection) {
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include mobile-fullscreen-dialog();
  .p-dialog-header {
    padding-bottom: 0;
  }
  .p-dialog-content {
    padding: 1rem 0 0 0;
  }
}
</style>
