<template>
  <Dialog
    class="dialog-attendee-selection"
    :header="title"
    :showHeader="true"
    :modal="true"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <CalendarEventAttendeeSelectionList
      :userType="userType"
      :selectedAttendee="selectedAttendee"
      @selectAttendee="onAttendeeSelected"
      @searchAttendee="$emit('searchAttendee', $event)"
    />
  </Dialog>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog'
import { defineComponent, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '../../types'
import CalendarEventAttendeeSelectionList from './CalendarEventAttendeeSelectionList.vue'
import { EventAttendeeAvailableTypes } from './types'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventAttendeeSelectionList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
  },
  emits: ['update:visible', 'selectAttendee', 'searchAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const title = computed(() => t(`userType.${props.userType.toLowerCase()}`))

    const onAttendeeSelected = (
      selectedAttendee: CalendarEventAttendeeItem,
    ) => {
      emit('selectAttendee', selectedAttendee)
      emit('update:visible', false)
    }

    return {
      t,
      title,
      onAttendeeSelected,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.dialog-attendee-selection) {
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include mobile-fullscreen-dialog();
  .p-dialog-header {
    padding-bottom: 0;
  }
  .p-dialog-content {
    padding: 1rem 0 0 0;
  }
}
</style>
