
import { Svg, useBreakpoints, CalendarEventAttendeeItem } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import CalendarEventAttendeesListItem from './CalendarEventAttendeesListItem.vue'
import { EventAttendeeAvailableTypes } from '../types'

export default defineComponent({
  components: {
    CalendarEventAttendeesListItem,
    Svg,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    items: {
      type: Array as PropType<CalendarEventAttendeeItem[]>,
      required: true,
    },
  },
  emits: ['selectAttendee'],
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
