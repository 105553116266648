<template>
  <div class="event-attendees-card-group">
    <EventAttendeeCard
      v-if="attendees.buyer"
      class="mr-md-3 my-md-3"
      :user="attendees.buyer"
      :userType="UserType.Buyer"
    />
    <EventAttendeeCard
      v-if="attendees.seller"
      class="my-md-3 mt-3"
      :user="attendees.seller"
      :userType="UserType.Seller"
    />
  </div>
</template>

<script lang="ts">
import { UserCalendarEventDetailsDto } from '@bd/api'
import { UserType } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import EventAttendeeCard from './EventAttendeeCard.vue'

export default defineComponent({
  components: { EventAttendeeCard },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
  },
  setup(props) {
    const attendees = computed(() => ({
      seller: props.event.calendarEventDto.seller,
      buyer: props.event.calendarEventDto.customer,
    }))

    return {
      UserType,
      attendees,
    }
  },
})
</script>

<style lang="scss" scoped>
.event-attendees-card-group {
  width: 100%;
  display: flex;
  @include breakpoint-down(md) {
    flex-direction: column;
  }
}
</style>
