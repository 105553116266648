import { CommonAppState } from './types'
import { Store, useStore } from 'vuex'

export { default as auth } from './modules/auth'
export { default as staticData } from './modules/static-data'
export { default as userProfile } from './modules/user-profile'
export { default as transactionHistory } from './modules/transaction-history'
export { default as calendarEvent } from './modules/calendar-event'
export { default as notifications } from './modules/notifications'
export { default as calendar } from './modules/calendar'
export type AppStore = Store<CommonAppState>
export const useAppStore = (): AppStore => useStore<CommonAppState>()
