
import { TimeSlot, UserCalendarEventDto } from '@bd/api'
import { LocalIsoDate } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import { defineComponent, PropType, computed } from 'vue'
import Svg from '../Svg/Svg.vue'
import WeekdayPicker from '../WeekdayPicker/WeekdayPicker.vue'
import CalendarDateTitle from './CalendarDateTitle.vue'
import MeetingsTimeline from './MeetingsTimeline.vue'
import { isSameDay } from 'date-fns'

export default defineComponent({
  components: {
    ProgressSpinner,
    WeekdayPicker,
    CalendarDateTitle,
    MeetingsTimeline,
    Svg,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    showTopControls: {
      type: Boolean,
      default: false,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: ['update:selectedDay', 'eventTileClick', 'availabilityEdit'],
  setup(props, { emit }) {
    const selectedDayUpdated = (value: LocalIsoDate[]) => {
      emit('update:selectedDay', value[0])
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      emit('eventTileClick', events)
    }

    const editAvailability = () => {
      emit('availabilityEdit')
    }

    const showNowIndicator = computed(() => {
      const selectedDate = new Date(`${props.selectedDay}`)
      return isSameDay(new Date(), selectedDate)
    })

    return {
      selectedDayUpdated,
      eventTileClicked,
      editAvailability,
      showNowIndicator,
    }
  },
})
