import { http } from '@bd/api/common'
import {
  ApiResponse,
  PageableParams,
  ResultPageDto,
  SimpleResponse,
} from './types'
import { SystemNotificationDto } from './types/notifications'

export const getSystemNotifications = (
  params: PageableParams,
): ApiResponse<ResultPageDto<SystemNotificationDto>> => {
  return http.get('/system-notifications', {
    params,
  })
}

export const markNotificationAsSeen = (id: number): ApiResponse<void> => {
  return http.post(`/system-notification/${id}/seen`)
}

export const getUnreadNotificationsCount = (): ApiResponse<
  SimpleResponse<number>
> => {
  return http.get('/system-notifications/new')
}
