import { ActionTree } from 'vuex'
import { CommonAppState } from '@bd/store-modules/types'
import { TransactionHistoryState } from '@bd/store-modules/types/transactionHistory'
import { commonApi, TransactionParams } from '@bd/api'
import { logErr } from '@bd/helpers'
import { TransactionDetailsPayload } from '@bd/api/common/types/transaction'

export const actions: ActionTree<TransactionHistoryState, CommonAppState> = {
  async transactionsList({ commit }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await commonApi.transactionHistory()
      commit('SET_TRANSACTIONS', data)
    } catch (e) {
      logErr(e)
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },
  async transactionDetails({ commit }, offerId: number) {
    try {
      const { data } = await commonApi.transactionHistoryDetail(offerId)
      commit('SET_TRANSACTION_DETAILS', data)
    } catch (e) {
      logErr(e)
      throw e
    }
  },
  async transactionDetailsAgent(
    { commit },
    payload: TransactionDetailsPayload,
  ) {
    try {
      const { data } = await commonApi.transactionHistoryDetailAgent(payload)
      commit('SET_TRANSACTION_DETAILS', data)
    } catch (e) {
      logErr(e)
      throw e
    }
  },
  async clearTransactions({ commit }) {
    try {
      commit('CLEAR_TRANSACTIONS')
    } catch (e) {
      logErr(e)
    }
  },
  async transactionHistoryCustomer({ commit }, payload: TransactionParams) {
    try {
      const { data } = await commonApi.transactionHistoryCustomer(payload)
      commit('SET_TRANSACTION_HISTORY', data)
    } catch (e) {
      logErr(e)
    }
  },
}
