
import { defineComponent, computed } from 'vue'
import { useBreakpoints } from '..'

export default defineComponent({
  components: {},
  setup() {
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    return {
      isDesktopSize,
    }
  },
})
