
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { labelWrapper } from '@bd/components'
import { ComputedFilter } from '@bd/store-modules/types'
import { CityWithProvinceDto, DistrictDto } from '@bd/api'

export default defineComponent({
  name: 'FiltersChips',
  props: {
    activeFilters: {
      type: Array as PropType<ComputedFilter[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
    districts: {
      type: Array as PropType<DistrictDto[]>,
    },
  },
  emits: ['clear-filter'],
  setup() {
    const i18n = useI18n()

    const isChipDisplayed = (element: ComputedFilter) => {
      if (!element.children.length) return false
      const first = element.children[0].value

      if (first.length || typeof first === 'number') return true
      return false
    }

    return {
      ...i18n,
      labelWrapper,
      isChipDisplayed,
    }
  },
})
