import {
  acceptCalendarEvent,
  CommonUserCalendarEventsParams,
  getTodayEventsCount,
  rejectCalendarEvent,
  UserCalendarEventDto,
  cancelCalendarEvent,
  userCalendarEventDetails,
  userCalendarEvents,
  getUpcomingEventDates,
} from '@bd/api/common'
import { logErr } from '@bd/helpers'
import { CommonAppState } from '@bd/store-modules/types'
import { CommonCalendarState } from '@bd/store-modules/types/calendar'
import { ActionContext, ActionTree } from 'vuex'
import { isUserLogged } from '../auth/getters-helpers'

export const actions: ActionTree<CommonCalendarState, CommonAppState> = {
  async userCalendarEvents<T extends CommonUserCalendarEventsParams>(
    ctx: ActionContext<CommonCalendarState, CommonAppState>,
    payload: T,
  ) {
    const { commit } = ctx
    commit('SET_TODAY_EVENTS', [])
    const { data } = await userCalendarEvents(payload)

    const events: UserCalendarEventDto[] = data.content ?? data
    commit('SET_TODAY_EVENTS', events)
  },
  async userCalendarEventDetails({ commit }, eventId: number) {
    commit('SET_EVENT_DETAILS_LOADING', true)
    try {
      const { data } = await userCalendarEventDetails(eventId)
      commit('SET_EVENT_DETAILS', data)
    } catch (e) {
      logErr(e)
      throw e
    } finally {
      commit('SET_EVENT_DETAILS_LOADING', false)
    }
  },

  clearCalendarEventDetails({ commit }) {
    commit('CLEAR_EVENT_DETAILS')
  },

  async acceptCalendarEvent(_, payload: number) {
    try {
      await acceptCalendarEvent(payload)
    } catch (e) {
      logErr(e)
      throw e
    }
  },

  async cancelCalendarEvent(_, payload: number) {
    try {
      await cancelCalendarEvent(payload)
    } catch (e) {
      logErr(e)
      throw e
    }
  },

  async rejectCalendarEvent(_, payload: number) {
    try {
      await rejectCalendarEvent(payload)
    } catch (e) {
      logErr(e)
      throw e
    }
  },

  setSelectedAgentId({ commit }, id: number | undefined) {
    commit('SET_SELECTED_AGENT_ID', id)
  },

  async getTodayEventsCount({ rootState, commit }) {
    if (!isUserLogged(rootState)) {
      return
    }
    try {
      const {
        data: { value },
      } = await getTodayEventsCount()
      commit('SET_TODAY_EVENTS_COUNT', value)
    } catch (e) {
      logErr(e)
      throw e
    }
  },

  async getUpcomingEventDates({ rootState, commit }) {
    if (!isUserLogged(rootState)) {
      return
    }
    try {
      const { data } = await getUpcomingEventDates()
      commit('SET_FUTURE_DATES', data.content)
    } catch (e) {
      logErr(e)
      throw e
    }
  },
}
