<template>
  <AppointmentModal
    :visible="modalVisible"
    :selectedDay="selectedAppointmentDate"
    :availableTimeSlots="availableTimeSlots"
    :submitPending="submitPending"
    @update:visible="$emit('update:modalVisible', $event)"
    @update:selectedDay="onAppointmentSelectedDayChange"
    @submitAppointment="onAppointmentSubmit"
  />
</template>

<script lang="ts">
import { TimeSlot } from '@bd/api'
import {
  AvailableTimeSlotsDto,
  CustomerMeetingInvitationDto,
} from '@bd/api/client-api/types'
import { useAppStore } from '@bd/client/store'
import { CustomerMeetingInvitationPayload } from '@bd/client/store/modules/offers/types'
import {
  AppointmentModal,
  TOAST_CONFIG,
  WeekdaySelectType,
  CALENDAR_NAVIGATION_DEBOUNCE,
} from '@bd/components'
import { getLocalIsoDate, LoadableOptional, LocalIsoDate } from '@bd/helpers'
import { useDebounceFn } from '@vueuse/core'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    AppointmentModal,
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true,
    },
    offerId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modalVisible'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const toast = useToast()
    const selectedAppointmentDate = ref(getLocalIsoDate(new Date()))
    const timeSlots = computed(() => store.state.offers?.availableTimeSlots)
    const availableTimeSlots = computed<
      LoadableOptional<TimeSlot.TimeSlotDto[]>
    >(() => ({
      payload: timeSlots.value,
    }))
    const submitPending = ref(false)
    const toastDuration = TOAST_CONFIG.messageDuration

    const fetchAvailableTimeSlots = async () => {
      const availableTimeSlotsParams: AvailableTimeSlotsDto = {
        id: props.offerId,
        date: selectedAppointmentDate.value,
      }
      await store.dispatch(
        'offers/fetchAvailableTimeSlots',
        availableTimeSlotsParams,
      )
    }

    const debounceFetchAvailableTimeSlots = useDebounceFn(
      fetchAvailableTimeSlots,
      CALENDAR_NAVIGATION_DEBOUNCE,
    )

    const onAppointmentSelectedDayChange = (
      selectedDay: LocalIsoDate,
      selectType: WeekdaySelectType,
    ) => {
      selectedAppointmentDate.value = selectedDay

      if (selectType === 'Direct') {
        fetchAvailableTimeSlots()
        return
      }
      debounceFetchAvailableTimeSlots()
    }

    const submitAppointmentInvitation = async (
      data: CustomerMeetingInvitationDto,
    ) => {
      submitPending.value = true
      const payload: CustomerMeetingInvitationPayload = {
        offerId: props.offerId,
        body: data,
      }
      try {
        await store.dispatch('offers/submitAppointmentInvitation', payload)
        emit('update:modalVisible', false)
        toast.add({
          severity: 'success',
          summary: t('appointment.invitation.success.title'),
          detail: t('appointment.invitation.success.description'),
          life: toastDuration.success,
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: t('appointment.invitation.error'),
          life: toastDuration.error,
        })
      } finally {
        submitPending.value = false
      }
    }

    const onAppointmentSubmit = ($event: CustomerMeetingInvitationDto) => {
      submitAppointmentInvitation($event)
    }

    fetchAvailableTimeSlots()

    return {
      selectedAppointmentDate,
      availableTimeSlots,
      onAppointmentSelectedDayChange,
      onAppointmentSubmit,
      submitPending,
    }
  },
})
</script>

<style lang="scss" scoped></style>
