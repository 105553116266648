import { isNotLoggedGuard, isLoggedGuard } from '@bd/components'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'
import AppLayout from '../components/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/welcome',
    component: AppLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '../views/Dashboard/Dashboard.vue'
          ),
      },
      {
        path: '/offer/:id',
        name: 'DashboardOffer',
        component: () =>
          import(
            /* webpackChunkName: "DashboardOffer" */ '../views/Dashboard/DashboardOffer.vue'
          ),
      },
      {
        path: '/calendar',
        beforeEnter: isLoggedGuard(store),
        name: 'Calendar',
        component: () =>
          import(
            /* webpackChunkName: "Calendar" */ '../views/Calendar/Calendar.vue'
          ),
      },
      {
        path: '/calendar/event',
        name: 'CalendarEvent',
        beforeEnter: isLoggedGuard(store),
        redirect: '/calendar',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEvent" */ '../views/Calendar/CalendarEventRouterView.vue'
          ),
        children: [
          {
            path: ':id',
            name: 'CalendarEventDetails',
            component: () =>
              import(
                /* webpackChunkName: "CalendarEventDetails" */ '../views/Calendar/CalendarEventDetails.vue'
              ),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        beforeEnter: isLoggedGuard(store),
        component: () =>
          import(
            /* webpackChunkName: "Notifications" */ '../views/Notifications/Notifications.vue'
          ),
      },
      {
        path: '/profile',
        name: 'Profile',
        beforeEnter: isLoggedGuard(store),
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ '../views/Profile/Profile.vue'
          ),
      },
      {
        path: '/profile/transactions',
        name: 'ProfileTransactions',
        beforeEnter: isLoggedGuard(store),
        component: () =>
          import(
            /* webpackChunkName: "ProfileTransactions" */ '../views/Profile/ProfileTransactions.vue'
          ),
      },
      {
        path: '/profile/edit',
        name: 'ProfileEdit',
        beforeEnter: isLoggedGuard(store),
        component: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ '../views/Profile/ProfileEdit.vue'
          ),
      },
    ],
  },
  {
    path: '/registration',
    name: 'Registration',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ '../views/Registration.vue'
      ),
  },
  {
    path: '/welcome',
    name: 'Welcome',
    beforeEnter: isNotLoggedGuard(store, { name: 'Dashboard' }),
    component: () =>
      import(/* webpackChunkName: "Welcome" */ '../views/Welcome.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue'
      ),
  },
  {
    path: '/password-reset-confirmation',
    name: 'PasswordResetConfirmation',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordResetConfirmation" */ '../views/PasswordResetConfirmation.vue'
      ),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROOT_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const dialog = store.state.dialog

  if (dialog.appointmentModal || dialog.counterProposalModal) {
    store.dispatch('dialogVisibility', {
      appointmentModal: false,
      counterProposalModal: false,
    })
  } else {
    next()
  }
})

export default router
