import { isUserLogged } from '../auth/getters-helpers'
import { ActionTree } from 'vuex'
import { CommonAppState, StaticDataState } from '@bd/store-modules/types'
import { retry } from '@bd/helpers'
import { commonApi, MeetingOrganizer } from '@bd/api'
import { getPredefinedMeetingTypes } from '@bd/api/common'

export const actions: ActionTree<StaticDataState, CommonAppState> = {
  async loadData({ commit, rootState }) {
    if (!isUserLogged(rootState)) {
      return
    }
    const timeSlots = await retry(commonApi.getTimeSlots)
    commit('SET_TIME_SLOTS', timeSlots.data)
  },
  async predefinedMeetingTypes({ commit }, meetingOrganizer: MeetingOrganizer) {
    commit('SET_PREDEFINED_MEETING_TYPES_LOADING', true)
    try {
      const meetingTypes = await getPredefinedMeetingTypes(meetingOrganizer)
      commit('SET_PREDEFINED_MEETING_TYPES', meetingTypes.data)
    } finally {
      commit('SET_PREDEFINED_MEETING_TYPES_LOADING', false)
    }
  },
}
