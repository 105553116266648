import { computed, ComputedRef, Ref } from 'vue'
import { i18n } from '../plugins/vue-i18n-next-plugin'

const t = i18n.global.t

type Input = string | number | undefined | null

export const useTextOrPlaceholder = (
  input: Ref<Input>,
  options?: { transformFn?: (val: Input) => string; placeholder?: string },
): ComputedRef<string> =>
  computed(() => {
    const isNumber = typeof input.value === 'number'
    if (
      (isNumber && input.value == null) ||
      (!isNumber && (!input.value || !input.value.toString().trim().length))
    ) {
      return options?.placeholder ?? t('no_information')
    }

    return options?.transformFn
      ? options.transformFn(input.value)
      : input.value!.toString()
  })
