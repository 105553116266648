
import { computed, defineComponent } from 'vue'
import { UserType, UserProfileBanner } from '@bd/components'
import { UserDto } from '@bd/api'

/**
 * Component that demonstrates the use of User profile common components
 * TODO: Delete when no longer needed
 */
export default defineComponent({
  components: {
    UserProfileBanner,
  },
  setup() {
    const mockUser = computed<Partial<UserDto>>(() => ({
      avatarUrl: '', // Substitute with an actual image path for demo purposes
      firstName: 'John',
      lastName: 'Doe',
      id: 213451,
    }))

    return {
      mockUser,
      UserType,
    }
  },
})
