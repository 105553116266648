import { LoginResponseDto, UserDto } from '@bd/api'
import { MutationTree } from 'vuex'
import { AuthState } from '../../types'
import { ErrorResponse } from '@bd/api'
import { removeItem, setItem } from '@bd/api/common/local-storage'

export const mutations: MutationTree<AuthState> = {
  SET_AUTH(state, payload: LoginResponseDto) {
    setItem('accessToken', payload.accessToken)
    state.accessToken = payload.accessToken
    state.user = payload.user
  },
  REMOVE_AUTH(state) {
    removeItem('accessToken')
    state.accessToken = ''
    state.user = undefined

  },
  SET_USER(state, payload: UserDto) {
    state.user = payload
  },
  SET_ERROR(state, payload: ErrorResponse) {
    state.error = payload
  },
  CLEAR_ERROR(state) {
    state.error = undefined
  },
}
