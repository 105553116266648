
import { CalendarEventMeetingTypeItem, Svg } from '@bd/components'
import { formatDuration } from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<CalendarEventMeetingTypeItem>,
      required: true,
    },
  },
  setup(props) {
    const formattedDuration = computed<string | null>(() => {
      const type = props.item
      return type.agentDurationMinutes && !type.isCustom
        ? formatDuration(type.agentDurationMinutes)
        : null
    })

    return {
      formattedDuration,
    }
  },
})
