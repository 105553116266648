<template>
  <div ref="self">
    <slot v-if="$slots.empty && !items.length && !isLoading" name="empty" />
    <slot v-for="item of items" :key="item.key" :item="item.data" />
  </div>
</template>

<script lang="ts">
import { times } from '@bd/helpers'
import { useElementSize, useEventListener } from '@vueuse/core'
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  PropType,
  shallowReactive,
  shallowRef,
  watch,
} from 'vue'

import {
  ErrorPage,
  GetItemKeyFn,
  GetPageFn,
  GetPageResult,
  Item,
  LoadedPage,
  LoadingPage,
  Page,
  PageSpec,
} from './types'

interface ScrollState<T = unknown> {
  pages: Page<T>[]
  pageSize: number
  scrollTop: number
}

export default defineComponent({
  props: {
    getPage: {
      type: Function as PropType<GetPageFn>,
      required: true,
    },
    getItemKey: {
      type: Function as PropType<GetItemKeyFn>,
      required: true,
    },
    scrollElem: {
      type: [String, Object] as PropType<string | HTMLElement>,
      required: false,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxRetries: {
      type: Number,
      default: 2,
    },
    storeState: {
      type: Function as PropType<(state: unknown) => void>,
      required: false,
    },
    recoverState: {
      type: Function as PropType<() => unknown>,
      required: false,
    },
  },

  emits: ['is-loading', 'has-error', 'total-count'],
  setup(props, { emit }) {
    const self = shallowRef<HTMLElement>()
    const pages = shallowReactive([] as Page[])

    // flattens data from individual pages into array of unique items. Stops on
    // first not loaded page so that the result list remains continuous.
    const items = computed(() => {
      const seen = new Set()
      const uniq = [] as Item[]

      for (const page of pages) {
        if (page.status !== 'ok') break

        for (const item of page.content) {
          if (!seen.has(item.key)) {
            uniq.push(item)
          }
        }
      }

      return uniq
    })

    const totalCount = computed(() => {
      const page = [...pages].reverse().find((p) => p.status === 'ok')
      return (page as LoadedPage | undefined)?.totalCount
    })

    const hasNext = computed(() => {
      if (!pages.length || !pages.every((p) => p.status === 'ok')) {
        return true
      }

      const lastPageIdx = pages.length - 1
      const lastPage = pages[lastPageIdx] as LoadedPage
      const lastIdx = lastPageIdx * props.pageSize + lastPage.content.length

      return lastPage.totalCount != null
        ? lastPage.totalCount > lastIdx + 1
        : lastPage.content.length === props.pageSize
    })

    const isLoading = computed(() => {
      return pages.some((p) => p.status === 'loading')
    })

    const firstError = computed(() => {
      const page = pages.find((p) => p.status === 'gave up')
      return (page as ErrorPage | undefined)?.error
    })

    const scrollElem = computed(() => {
      const selector = props.scrollElem || self.value
      const scrollElem =
        typeof selector === 'string'
          ? document.querySelector<HTMLElement>(selector)
          : selector
      if (!scrollElem && self.value) {
        throw Error(`Selector "${props.scrollElem}" didn't match anything`)
      }
      return scrollElem
    })

    watch(
      () => ({ ...props }),
      () => reset(),
    )

    onBeforeMount(() => {
      const state = props.recoverState?.() as ScrollState | undefined
      if (!state || state.pageSize !== props.pageSize) {
        load(0)
        return
      }

      props.storeState?.(undefined)
      pages.push(...state.pages)

      onMounted(() => {
        nextTick(() => {
          if (scrollElem.value) {
            scrollElem.value.scrollTop = state.scrollTop
          }
        })
      })
    })

    onBeforeUnmount(() => {
      const state: ScrollState = {
        pages,
        pageSize: props.pageSize,
        scrollTop: scrollElem.value?.scrollTop || 0,
      }
      props.storeState?.(state)
    })

    watch(isLoading, (loading) => emit('is-loading', loading))
    watch(firstError, (error) => emit('has-error', error))
    watch(totalCount, (count) => emit('total-count', count))

    const stopScrollListener = useEventListener(
      scrollElem,
      'scroll',
      loadNextIfNecessary,
      { passive: true },
    )

    const { width, height } = useElementSize(scrollElem)
    const stopResizeListener = watch([width, height], loadNextIfNecessary)

    return {
      self,
      items,
      isLoading,
    }

    function getDataPagesPerViewPage() {
      const firstItem = self.value?.firstElementChild as HTMLElement
      const lastItem = self.value?.lastElementChild as HTMLElement
      if (!firstItem || !lastItem) {
        return 1
      }

      const firstTop = firstItem.offsetTop
      const lastBottom = lastItem.offsetTop + lastItem.clientHeight
      const heightPerItem = (lastBottom - firstTop) / items.value.length
      const itemsPerView = scrollElem.value!.clientHeight / heightPerItem
      const pagesPerView = itemsPerView / props.pageSize
      return Math.max(1, Math.round(pagesPerView))
    }

    function isBottomReached() {
      const lastElem = self.value?.lastElementChild as HTMLElement
      if (!lastElem || !scrollElem.value) {
        return true
      }

      const { scrollTop, clientHeight } = scrollElem.value
      const scrollBottom = scrollTop + clientHeight
      return lastElem.offsetTop < scrollBottom
    }

    function loadNextIfNecessary() {
      if (isBottomReached() && pages.every((p) => p.status === 'ok')) {
        if (hasNext.value) {
          times(getDataPagesPerViewPage(), () => {
            load(pages.length)
          })
        }
      }
    }

    function onLoaded(index: number, page: LoadingPage) {
      return (result: GetPageResult) => {
        if (pages[index] !== page) {
          return
        }
        if (page.status !== 'loading') {
          throw Error('Invalid state')
        }

        pages[index] = {
          status: 'ok',
          totalCount: result.totalCount,
          content: result.content.map((data) => {
            const key = props.getItemKey(data)
            return { key, data }
          }),
        }

        nextTick(loadNextIfNecessary)
      }
    }

    function onError(index: number, page: LoadingPage) {
      return (error: unknown) => {
        if (pages[index] !== page) {
          return
        }
        if (page.status !== 'loading') {
          throw Error('Invalid state')
        }

        const status = page.triedTimes < props.maxRetries ? 'err' : 'gave up'
        pages[index] = { status, triedTimes: page.triedTimes, error }

        if (status === 'err') {
          load(index)
        } else {
          stopScrollListener()
          stopResizeListener()
        }
      }
    }

    function load(index: number) {
      const spec: PageSpec = {
        pageIndex: index,
        pageSize: props.pageSize,
      }

      const realIndex = index > pages.length ? pages.length : index
      const page = pages[realIndex] as Page | undefined

      if (page?.status === 'loading') {
        return
      }

      const newPage: LoadingPage = (pages[realIndex] = {
        status: 'loading',
        triedTimes: page?.status === 'ok' ? 1 : (page?.triedTimes || 0) + 1,
        promise: props.getPage(spec),
      })

      newPage.promise.then(onLoaded(index, newPage), onError(index, newPage))
    }

    function reset() {
      pages.length = 0
      load(0)
    }
  },
})
</script>
