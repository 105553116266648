
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import { useAppStore } from '@bd/client/store'
import { TextInput, Svg } from '@bd/components'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DashboardSearch',
  components: { TextInput, Svg },
  emits: ['on-search'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const searchText = ref('')
    const route = useRoute()
    const router = useRouter()

    const search = async () => {
      if (searchText.value === store.state.offers?.filters.address) {
        return
      }
      await store.dispatch('offers/setFilterAndRefresh', {
        address: searchText.value,
        cityIds: [],
        districtIds: [],
      })
    }

    const onSearch = async () => {
      if (route.name !== 'Dashboard') {
        await router.push({ name: 'Dashboard' })
      }
      search()
      emit('on-search')
    }

    const filterAddress = computed(() => store.state.offers?.filters.address)
    const queryAddress = computed(() => route.query.address?.toString())

    const openMobileFilters = () => {
      store.dispatch('dialogVisibility', {
        dashboardFilters: true,
      })
    }

    watch(queryAddress, (addressValue) => {
      if (!addressValue) {
        return
      }
      searchText.value = addressValue
    })

    watch(filterAddress, (addressValue) => {
      const query = {
        ...route.query,
        address: addressValue ? addressValue : undefined,
      }
      router.replace({ query })
    })

    onMounted(() => {
      if (!queryAddress.value) {
        return
      }
      searchText.value = queryAddress.value
      search()
    })

    return {
      t,
      onSearch,
      searchText,
      openMobileFilters,
    }
  },
})
