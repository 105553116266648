import { MutationTree } from 'vuex'
import { Filters } from '@bd/store-modules/types'
import { ClientOfferState } from '@bd/client/types'
import { addOrRemoveFilter } from '@bd/client/services/mutation.service'
import { TimeSlot } from '@bd/api'
import { ResultPageDto, CityWithProvinceDto } from '@bd/api/common'
import { uniqBy } from '@bd/helpers'

export const mutations: MutationTree<ClientOfferState> = {
  SET_CITIES_AND_PROVINCES_LOADING(state, isLoading: boolean) {
    state.citiesAndProvinces.isLoading = isLoading
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload
  },
  ADD_CITIES_AND_PROVINCES_CHUNK(
    state,
    payload: ResultPageDto<CityWithProvinceDto>,
  ) {
    const currentContent = state.citiesAndProvinces.content
    state.citiesAndProvinces.content = {
      ...payload,
      content: currentContent
        ? uniqBy(
            [...currentContent.content, ...payload.content],
            (x) => x.cityId,
          )
        : payload.content,
    }
  },
  SET_SCROLL_STATE(state, scrollState: unknown) {
    state.scrollState = scrollState
  },
  SET_FILTERS(state, payload: Filters) {
    const result = addOrRemoveFilter({ ...state.filters }, payload)
    if (result) {
      state.filters = result
    }
  },
  SET_OFFER(state, payload) {
    state.offerDetails = payload
  },
  SET_AVAILABLE_TIME_SLOTS(state, payload: TimeSlot.TimeSlotDto[]) {
    state.availableTimeSlots.content = payload
  },
  SET_AVAILABLE_TIME_SLOTS_LOADING(state, payload: boolean) {
    state.availableTimeSlots.isLoading = payload
  },
  APPLY_FILTERS(state) {
    state.searchedFilters = state.filters
  },
}
