import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { ErrorResponse } from '@bd/api'

const t = i18n.global.t

const errorResponseKeys: Record<string, string> = {
  PURCHASE_OFFER_ALREADY_ACCEPTED: t('validation.offer_already_accepted'),
  PURCHASE_OFFER_WAITING_FOR_RESPONSE: t(
    'validation.offer_waiting_for_response',
  ),
  CANT_ACCEPT_OWN_OFFER: t('validation.cant_accept_own_offer'),
  PRICE_LOWER_THAN_LATEST: t('validation.price_lower_than_latest'),
  PRICE_HIGHER_THAN_LATEST: t('validation.price_higher_than_latest'),
}
const errorFieldResponses: Record<number, string> = {
  460: t('validation.incorrect_price'),
  464: t('validation.offer_not_found'),
}

export const purchaseOfferService = {
  handleError: function (e: ErrorResponse | undefined): string | undefined {
    if (e && e.resultKey) {
      return errorResponseKeys[e.resultKey]
    } else if (e && e.code) {
      return errorFieldResponses[e.code]
    }
  },
}
