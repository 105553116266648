
import { UserCalendarEventDetailsDto } from '@bd/api'
import { Svg, useFormattedDate } from '@bd/components'
import {
  useEventAddress,
  useEventDuration,
  useUserData,
} from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: { Svg },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['eventEditClick'],
  setup(props) {
    const { user } = useUserData()
    const calendarEvent = computed(() => props.event.calendarEventDto)
    const offer = computed(() => calendarEvent.value.calendarEventOfferDto)
    const date = computed(() => calendarEvent.value.date)

    const address = useEventAddress(offer)
    const eventDate = useFormattedDate(date)
    const eventTime = useEventDuration(calendarEvent, user)

    return {
      eventDate,
      eventTime,
      address,
    }
  },
})
