import { AppStore } from '@bd/store-modules'
import { isUserLogged } from '@bd/store-modules/modules/auth/getters-helpers'
import {
  RouteLocationNormalizedLoaded,
  NavigationGuardNext,
  RouteLocationRaw,
} from 'vue-router'

export const isLoggedGuard = (store: AppStore) => (
  _to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  const state = store.state
  if (isUserLogged(state)) {
    next()
  } else {
    next({ path: '/login' })
  }
}

export const isNotLoggedGuard = (
  store: AppStore,
  redirectTo?: RouteLocationRaw,
) => (
  _to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  const state = store.state
  if (!isUserLogged(state)) {
    next()
  } else {
    next(redirectTo ?? { path: '/' })
  }
}
