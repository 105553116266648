<template>
  <div class="event-details-card">
    <div v-if="showEditIcon" class="edit-icon-container d-md-none">
      <Button
        class="p-button-link ml-auto px-0"
        @click="$emit('eventEditClick')"
      >
        <div class="edit-icon">
          <Svg
            :src="require('@bd/assets/icons/edit.svg')"
            style="width: 14px; height: 14px"
          />
        </div>
      </Button>
    </div>
    <div class="event-icon">
      <Svg :src="require('@bd/assets/icons/navigation/calendar.svg')" />
    </div>
    <div class="details-group">
      <div class="text-muted text-uppercase">
        {{ eventDate.day }} {{ eventDate.month }}
      </div>
      <div
        v-if="showAddress && address"
        class="d-md-none text-muted text-uppercase text-center"
      >
        <div class="city" v-if="address.cityDto && address.cityDto.cityName">
          {{ address.cityDto.cityName }}
        </div>
        <span v-if="address.districtDto && address.districtDto.districtName">
          {{ address.districtDto.districtName }},
        </span>
        {{ address.street }}
        {{ address.buildingNumber }}{{ address.houseNumber }}
      </div>

      <h2 class="details-heading h-2 d-md-none text-center">
        {{ event.calendarEventDto.meetingTypeDto.meetingTypeName }}
      </h2>
      <h4
        v-if="showAddress && address"
        class="details-heading h-4 d-none d-md-block"
      >
        <div>
          <p
            class="city desktop"
            v-if="address.cityDto && address.cityDto.cityName"
          >
            {{ address.cityDto.cityName }}
          </p>
          <p v-if="address.districtDto && address.districtDto.districtName">
            {{ address.districtDto.districtName }},
          </p>
          <p>
            {{ address.street }}
            <span>{{ address.buildingNumber }}{{ address.houseNumber }}</span>
          </p>
        </div>
      </h4>
    </div>
    <div class="event-time-container">
      <div class="event-time">
        <span class="time-part">{{ eventTime.start }}</span>
        <i class="time-separator pi pi-arrow-right"></i>
        <span class="time-part">{{ eventTime.end }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { UserCalendarEventDetailsDto } from '@bd/api'
import { Svg, useFormattedDate } from '@bd/components'
import {
  useEventAddress,
  useEventDuration,
  useUserData,
} from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: { Svg },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['eventEditClick'],
  setup(props) {
    const { user } = useUserData()
    const calendarEvent = computed(() => props.event.calendarEventDto)
    const offer = computed(() => calendarEvent.value.calendarEventOfferDto)
    const date = computed(() => calendarEvent.value.date)

    const address = useEventAddress(offer)
    const eventDate = useFormattedDate(date)
    const eventTime = useEventDuration(calendarEvent, user)

    return {
      eventDate,
      eventTime,
      address,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../calendar';

$edit-icon-size: 40px;

@mixin responsive-flex-col() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpoint-down(md) {
    align-items: center;
  }
}
.event-details-card {
  position: relative;
  background-color: $white;
  box-shadow: $default-shadow;
  border-radius: 10px;
  max-width: 360px;
  min-width: 260px;
  padding: 40px;
  @include responsive-flex-col();
  @include breakpoint-down(xl) {
    padding: 30px;
  }
  @include breakpoint-down(lg) {
    padding: 25px;
  }
  @include breakpoint-down(md) {
    max-width: unset;
    padding: 25px 20px;
    align-items: center;
  }
}
.details-group {
  width: 100%;
  margin: 1.5rem 0 1rem 0;
  @include responsive-flex-col();
}
.details-heading {
  margin: 1rem 0;
  line-height: normal;
}
.event-time-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.event-time {
  background: $event-accepted-bg;
  color: $dodger-blue;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  border-radius: 7px;
  flex: 1 0 100%;
  @include breakpoint-down(md) {
    max-width: 300px;
  }
}
.time-separator {
  border-radius: 50%;
  border: 2px solid $white;
  padding: 1rem;
}
.edit-icon-container {
  position: absolute;
  right: 20px;
  top: 25px;
  .edit-icon {
    @include edit-icon($edit-icon-size);
  }
}

.city {
  font-size: 0.875rem;
  color: $port-gore;
  line-height: 1.25rem;

  &.desktop {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
