<template>
  <transition name="dropdown-transition">
    <div class="notifications-dropdown" v-if="modelValue" ref="container">
      <NotificationsCenter
        @notificationClick="$emit('notificationClick', $event)"
        @calendarNotificationClick="$emit('calendarNotificationClick', $event)"
        @proposalNotificationClick="$emit('proposalNotificationClick', $event)"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { onClickOutside } from '@vueuse/core'
import { defineComponent, ref } from 'vue'
import NotificationsCenter from './NotificationsCenter.vue'

export default defineComponent({
  components: {
    NotificationsCenter,
  },
  props: {
    modelValue: { type: Boolean, default: false },
  },
  emits: [
    'bottomReach',
    'outsideClick',
    'notificationClick',
    'calendarNotificationClick',
    'proposalNotificationClick',
  ],
  setup(_, { emit }) {
    const container = ref<HTMLElement>()
    onClickOutside(container, (event) => {
      emit('outsideClick', event)
    })

    return {
      container,
    }
  },
})
</script>

<style lang="scss" scoped>
.notifications-dropdown {
  position: absolute;
  right: 0;
  top: $top-navigation-height;
  max-height: 80vh;
  width: clamp(420px, 25vw, 500px);
  @include breakpoint-down(lg) {
    width: clamp(360px, 45vw, 420px);
  }
  z-index: 100;
  border-radius: 7px;
  box-shadow: $notifications-shadow;
  margin-right: 2rem;
  padding: 1.25rem;
  overflow-y: hidden;
  @include flex($direction: column, $justify: center);
  background-color: $white;
  transition: all 0.15s ease-in-out;
}
.dropdown-transition-enter-active,
.dropdown-transition-leave-active {
  transition: all 0.15s;
}
.dropdown-transition-enter-from,
.dropdown-transition-leave-to {
  opacity: 0;
  height: 0px;
  transform: translateY(-10px);
}
</style>
