import { useAppStore } from '@bd/store-modules'
import { computed } from 'vue'

export default function useRootData() {
  const store = useAppStore()
  const isLoading = computed(() => {
    return store.state.isLoading
  })
  return {
    isLoading,
  }
}
