
import { ResultPageDto } from '@bd/api'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import {
  MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
  SearchInput,
  useLoadableResourceDataState,
  useResultListStats,
} from '@bd/components'
import { LoadableOptional } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '../../types'
import CalendarEventAttendeesList from './AttendeesList/CalendarEventAttendeesList.vue'
import { EventAttendeeAvailableTypes } from './types'

export default defineComponent({
  components: {
    SearchInput,
    CalendarEventAttendeesList,
    ProgressSpinner,
  },
  props: {
    searchPhrase: {
      type: String,
      required: true,
    },
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
    attendees: {
      type: Object as PropType<
        LoadableOptional<ResultPageDto<MeetingAttendeeDto> | undefined>
      >,
      required: true,
    },
  },
  emits: ['update:searchPhrase', 'searchAttendee', 'selectAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { attendees } = toRefs(props)
    const attendeesPayload = computed(() => attendees.value.payload)
    const attendeesPayloadContent = computed<
      ResultPageDto<MeetingAttendeeDto> | undefined
    >(() => attendeesPayload.value?.content)
    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      attendeesPayload,
      (res) => !!(res?.content?.content && res.content.content.length),
    )

    const selectedAttendeeId = computed(() => props.selectedAttendee?.id)

    const attendeeItems = computed<CalendarEventAttendeeItem[] | undefined>(
      () => {
        const attendeesContent = attendeesPayloadContent.value?.content
        const mappedAttendees:
          | CalendarEventAttendeeItem[]
          | undefined = attendeesContent?.map(
          ({ id, userRole: _, email: __, ...rest }) => {
            return {
              id,
              ...rest,
              selected: id === selectedAttendeeId.value,
            }
          },
        )
        return mappedAttendees
      },
    )

    const resultStats = useResultListStats(attendeesPayloadContent)

    const onAttendeeSelected = (attendee: CalendarEventAttendeeItem) => {
      if (attendee.id === selectedAttendeeId.value) {
        return
      }
      emit('selectAttendee', attendee)
    }

    return {
      t,
      attendeeItems,
      isLoading,
      isEmpty,
      isPresent,
      resultStats,
      onAttendeeSelected,
      MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
    }
  },
})
