<template>
  <Dialog
    class="dialog-calendar-events-list"
    header="&nbsp;"
    :showHeader="true"
    :visible="visible"
    :modal="true"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="content-wrapper">
      <div class="multiple-events-description">
        <h4 class="h-4">
          {{ t('calendar.events.multiple_events.description') }}
        </h4>
        <p class="body-sm mt-1">
          {{ t('calendar.events.multiple_events.hint') }}
        </p>
      </div>
      <ul class="events-list">
        <CalendarEventListItem
          v-for="event of events"
          :key="event.userCalendarEventId"
          :event="event"
          :showAddress="showAddress"
          @click="$emit('eventClicked', event)"
        />
      </ul>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import { UserCalendarEventDto } from '@bd/api'
import CalendarEventListItem from './CalendarEventListItem.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventListItem,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
  },
  emits: ['update:visible', 'eventClicked'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.dialog-calendar-events-list) {
  min-width: 420px;
  max-height: 600px;
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include mobile-fullscreen-dialog();
  .p-dialog-content {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    @include breakpoint-down(md) {
      padding: 0 1rem 1rem 1rem;
    }
  }
}
.content-wrapper {
  height: 100%;
  padding: 0 0.75rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.events-list {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @include breakpoint-down(md) {
    align-items: center;
  }
}
.multiple-events-description {
  margin: 0.5rem;
}
</style>
