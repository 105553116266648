
import { defineComponent, PropType } from 'vue'
import { useBreakpoints } from '@bd/components'
import Dialog from 'primevue/dialog'
import ProposalModalNumberInput from './ProposalModalNumberInput.vue'

export default defineComponent({
  name: 'ProposalModal',
  emits: ['hide', 'update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: null,
      required: false,
    },
    header: {
      type: String,
    },
    priceTitle: {
      type: String,
    },
    price: {
      type: Number as PropType<number | null | undefined>,
      required: false,
    },
    isInputDisabled: { type: Boolean },
  },
  components: { Dialog, ProposalModalNumberInput },
  setup() {
    const { mobileSize } = useBreakpoints()

    return { mobileSize }
  },
})
