<template>
  <div class="sidebar">
    <DashboardOfferGallery
      class="offer-gallery"
      :offerDetails="offerDetails"
      :showStatistics="showStatistics"
      @toggleFullscreen="onGalleryFullscreenToggle"
    />
    <div class="sidebar-top inner-padding">
      <p class="caption">{{ offer?.displayableId }}</p>
      <p class="offer-name">
        <span v-if="offer?.districtDto?.districtName">
          {{ offer?.districtDto?.districtName }},
        </span>
        <span>{{ streetName }}</span>
      </p>
      <p class="offer-city">
        {{ cityName }}
      </p>
      <p class="offer-title text-break-word">{{ offer?.title }}</p>
    </div>
    <div class="price-detail inner-margin">
      <p class="price-text">
        {{ priceValue }}
      </p>
      <p class="caption">
        <span v-if="pricePerM2">
          {{ pricePerM2 }} /
          <span>m<sup>2</sup></span>
        </span>
        <span v-else>
          {{ numericPlaceholder }}
        </span>
      </p>
    </div>
    <div class="offer-detail inner-padding">
      <div class="row-detail">
        <div class="row-detail-name-mobile">{{ t('rooms') }}</div>
        <div class="row-detail-left">
          <div>
            <Svg :src="require('@bd/assets/icons/rooms.svg')" class="icon" />
          </div>
          <p class="row-detail-name">{{ t('rooms') }}</p>
          <div class="row-detail-right d-md-none">{{ roomsCount }}</div>
        </div>
        <div class="row-detail-right d-none d-md-block">{{ roomsCount }}</div>
      </div>
      <hr />
      <div class="row-detail">
        <div class="row-detail-name-mobile">{{ t('area') }}</div>
        <div class="row-detail-left">
          <Svg :src="require('@bd/assets/icons/square.svg')" class="icon" />
          <p class="row-detail-name">{{ t('area') }}</p>
          <div class="row-detail-right d-md-none">
            <span v-if="area">{{ area + ' ' }}m<sup>2</sup></span>
            <span v-else>{{ numericPlaceholder }}</span>
          </div>
        </div>
        <div class="row-detail-right d-none d-md-block">
          <span v-if="area">{{ area + ' ' }}m<sup>2</sup></span>
          <span v-else>{{ numericPlaceholder }}</span>
        </div>
      </div>
      <hr />
      <div class="row-detail">
        <div class="row-detail-name-mobile">{{ t('floor') }}</div>
        <div class="row-detail-left">
          <div>
            <Svg :src="require('@bd/assets/icons/stairs.svg')" class="icon" />
          </div>
          <p class="row-detail-name">{{ t('floor') }}</p>
          <div class="row-detail-right d-md-none">
            {{ floorNumber }}/{{ levelsCount }}
          </div>
        </div>
        <div class="row-detail-right d-none d-md-block">
          {{ floorNumber }}/{{ levelsCount }}
        </div>
      </div>
    </div>
    <div class="offer-map inner-padding">
      <DashboardOfferMap :offerDetails="offerDetails" />
    </div>
    <DashboardOfferUser
      v-if="displayedUsers.includes('seller')"
      :typeOfUser="UserType.Seller"
      :userData="offerDetails.offerSellerDto"
      class="inner-margin d-md-none"
    />
    <DashboardOfferUser
      v-if="displayedUsers.includes('agent')"
      :typeOfUser="UserType.Agent"
      :userData="offerDetails.offerAgentDto"
      class="inner-margin d-md-none"
    />
    <div class="offer-description inner-padding">
      <h3>{{ t('property_description') }}</h3>
      <DashboardOfferDescription
        :description="offerDetails?.description || ''"
      />
    </div>
    <div class="offer-details inner-padding">
      <h3>{{ t('more_details') }}</h3>
      <DashboardOfferDetails :offerDetails="offerDetails" />
    </div>
    <DashboardOfferFooter
      :displayedButtons="displayedButtons"
      :offerDetails="offerDetails"
      @openAppointment="$emit('openAppointment')"
      @openProposal="$emit('openProposal')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue'
import {
  DashboardOfferFooter,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferDetails,
  DashboardOfferDescription,
  DashboardOfferUser,
  Svg,
  useTextOrPlaceholder,
  numericPlaceholder,
  UserType,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'
import { DisplayedOfferUsers, OfferFooterButtons } from './types'

export default defineComponent({
  name: 'DashboardOfferSidebar',
  components: {
    DashboardOfferMap,
    DashboardOfferUser,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferGallery,
    DashboardOfferFooter,
    Svg,
  },
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
    displayedButtons: {
      type: Array as PropType<OfferFooterButtons[]>,
      required: true,
    },
    displayedUsers: {
      type: Array as PropType<DisplayedOfferUsers[]>,
      required: true,
    },
    showStatistics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openAppointment', 'openProposal', 'toggleGalleryFullscreen'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const showMore = ref(false)
    const offer = computed(() => props.offerDetails)
    const { currency, locale } = LOCALISATION_SETTINGS

    const area = computed(() => {
      return props.offerDetails?.houseArea
        ? props.offerDetails?.houseArea
        : props.offerDetails?.landArea
    })

    const numericPlaceholderOpts = { placeholder: numericPlaceholder }

    const street = computed(() => offer.value?.street)
    const city = computed(() => offer.value?.cityDto?.cityName)
    const streetName = useTextOrPlaceholder(street, {
      transformFn: (val) => `${t('street_prefix')} ${val}`,
    })
    const cityName = useTextOrPlaceholder(city)

    const rooms = computed(() => offer.value?.rooms)
    const roomsCount = useTextOrPlaceholder(rooms, numericPlaceholderOpts)

    const floor = computed(() => offer.value?.floor)
    const levels = computed(() => offer.value?.levels)

    const floorNumber = useTextOrPlaceholder(floor, numericPlaceholderOpts)
    const levelsCount = useTextOrPlaceholder(levels, numericPlaceholderOpts)

    const totalPrice = computed(() => offer.value?.price)
    const priceValue = useTextOrPlaceholder(totalPrice, {
      transformFn: (val) => {
        return getFormattedPrice(val as number, currency, locale) as string
      },
      ...numericPlaceholderOpts,
    })

    const pricePerM2 = computed(() => {
      if (area.value && totalPrice.value) {
        return getFormattedPrice(
          totalPrice.value / area.value,
          currency,
          locale,
        )
      }
      return null
    })

    const draftHint = computed(() =>
      offer.value?.draft ? t('offerDetails.draft_proposal_hint') : null,
    )

    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      emit('toggleGalleryFullscreen', isFullscreen)
    }

    return {
      t,
      showMore,
      offer,
      currency,
      locale,
      getFormattedPrice,
      area,
      streetName,
      cityName,
      roomsCount,
      numericPlaceholder,
      floorNumber,
      levelsCount,
      priceValue,
      pricePerM2,
      draftHint,
      onGalleryFullscreenToggle,
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped>
.inner-margin {
  @include breakpoint-down(md) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

h1,
h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: $port-gore;
  display: flex;
  align-items: center;
  height: 80px;
}

.offer-map,
.offer-details,
.offer-description,
.offer-title,
.offer-gallery {
  @include breakpoint-up(md) {
    display: none;
  }
}

.inner-padding {
  @include breakpoint-down(md) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.offer-name {
  font-size: 1.3rem;
  color: $port-gore;
  font-weight: 600;
  padding: 5px 0 10px 0;
  @include breakpoint-down(md) {
    font-size: 1.6rem;
  }
}

.offer-title {
  color: $port-gore;
  @include breakpoint-down(md) {
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.3;
  }
}

.offer-city {
  font-weight: 500;
  @include breakpoint-down(md) {
    font-size: 1.1rem;
    color: $primary-color;
  }
}

.gallery {
  width: 100%;
  object-fit: cover;
  img {
    width: 100%;
  }
}

:deep(.offer-footer) {
  @include breakpoint-down(md) {
    display: none;
  }
}

.sidebar {
  overflow: auto;
  position: sticky;
  top: 8%;
  width: 28%;
  background-color: $alabaster;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 1.5rem;

  @include breakpoint-down(md) {
    width: 100%;
    padding: 0 0 50px 0;
  }

  @include breakpoint-up(md) {
    padding: 25px 40px 50px 40px;
    border-radius: 6px;
  }
}

.sidebar-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70px;
  padding-top: 5px;
}

.price-detail {
  border-radius: 6px;
  box-shadow: 0 5px 73px 0 $light-shadow;
  padding: 20px;
  background: #ffff;

  .caption {
    line-height: 1;
  }
}

.price-text {
  padding-bottom: 5px;
  font-size: 1.3rem;
  line-height: 1.4;
  font-weight: 600;
  color: $port-gore;

  @include breakpoint-down(md) {
    font-size: 1.7rem;
  }
}

.offer-detail {
  display: flex;
  flex-direction: column;

  @include breakpoint-down(md) {
    justify-content: space-around;
    flex-direction: row;
  }

  hr {
    border: 0;
    border-top: 1px solid $athens-gray;
    opacity: 0.5;
    margin: 1rem 0;
    border: 0.5px solid $athens-gray;

    @include breakpoint-up(md) {
      display: none;
    }
  }
  .row-detail {
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    @include breakpoint-down(md) {
      flex-direction: column;
      justify-content: space-around;
      height: 60px;
    }

    .upper-text {
      width: 100%;
      display: block;
    }

    img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }

  .row-detail-left {
    align-items: center;
    display: flex;
    color: $manatee;
    font-size: 1rem;
  }

  .row-detail-right {
    color: $port-gore;
    font-weight: 500;
  }

  .row-detail-name-mobile {
    color: $manatee;
    font-size: 0.8rem;
    font-weight: 500;
    @include breakpoint-up(md) {
      display: none;
    }
  }

  .row-detail-name {
    font-size: 0.8rem;
    @include breakpoint-down(md) {
      display: none;
    }
  }
}

.icon {
  margin-right: 7px;
  color: $primary-color;
}
</style>
