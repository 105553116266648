import {
  BaseAddress,
  ResultPageDto,
  TimeSlot,
  UserDto,
  UserRole,
} from '@bd/api'
import {
  MeetingAttendeeDto,
  MeetingOfferDto,
} from '@bd/api/common/types/calendar-event'
import { LoadableResource, LocalIsoDate } from '@bd/helpers'

export enum OfferSelectionTab {
  Mine = 0, // Only assigned to the agent
  All = 1, // All system-wide offers
}

export interface CalendarEventOffer {
  id: number
  address: BaseAddress
}

export interface CalendarEventAttendee {
  id: UserDto['id']
  displayableId: string
  firstName: string
  lastName: string
  phoneNumber?: string
}

export interface CalendarEventMeetingType {
  predefinedId?: number // Only for predefined meeting types
  isCustom?: boolean // Should be true only for custom meetings
  name: string
  agentDurationMinutes?: number // Only for predefined meeting types
  customerPresenceRequired?: boolean
}

export interface EventSaveData {
  eventId?: number // Should only be present when editing the calendar event
  offer?: CalendarEventOffer
  buyer?: CalendarEventAttendee // Buyer is optional only for certain meeting types
  seller?: CalendarEventAttendee
  agentId?: number
  date?: LocalIsoDate
  timeSlotIds?: TimeSlot.TimeSlotID[]
  meetingType?: CalendarEventMeetingType
}

export interface EventSaveMetaData {
  selectedOffersTab: OfferSelectionTab // Only for agent
  offersSearchPhrase: string
  attendeesSearchPhrase: string
  offers: LoadableResource<ResultPageDto<MeetingOfferDto> | undefined>
  attendees: LoadableResource<ResultPageDto<MeetingAttendeeDto> | undefined>
  availableTimeSlots: LoadableResource<TimeSlot.TimeSlotDto[] | undefined> // Both for predefined and custom meeting types
  keepBuyer?: boolean // True when event should be initialized with a buyer
  keepDate?: boolean // True when event should be initialized with preselected date
  disabledTimeSlots: TimeSlot.TimeSlotID[]
}

export interface CommonCalendarEventState {
  eventSaveData: EventSaveData
  eventSaveMetaData: EventSaveMetaData
}

export interface MeetingOfferSearchPayload {
  isAgent: boolean
  pageSize: number
}

export interface MeetingAttendeeSearchPayload {
  pageSize: number
  userRole: UserRole
}

export interface MeetingOfferSelectPayload {
  offer: CommonCalendarEventState['eventSaveData']['offer']
  seller: CommonCalendarEventState['eventSaveData']['seller']
}

export interface EventBuyerSelectPayload {
  buyer: CalendarEventAttendee
  keepBuyer?: boolean
}
export interface EventDateSelectPayload {
  date: LocalIsoDate
  keepDate?: boolean
}
