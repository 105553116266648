import {
  TransactionList,
  TransactionHistoryDetailsDto,
  TransactionHistoryByCustomerDto,
} from '@bd/api/common/types/transaction'

export const getDefaultTransactionsObject = (): TransactionList => {
  return {
    content: [],
  }
}
export const getDefaultTransactionDetails = (): TransactionHistoryDetailsDto[] => {
  return []
}
export const getDefaultTransactionHistory = (): TransactionHistoryByCustomerDto[] => {
  return []
}
