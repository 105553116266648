<template>
  <div class="field-spacing" :class="{ 'white-bg': whiteBackground }">
    <div class="d-flex flex-column">
      <label
        class="mb-2 body-12 text-500"
        :class="errorMessage ? 'label-error' : 'label-valid'"
        :for="name"
      >
        {{ label }}
      </label>
      <Dropdown
        :class="{ 'dropdown-error': errorMessage }"
        v-model="inputValue"
        :options="options"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :name="name"
        :placeholder="placeholder"
      />
      <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import Dropdown from 'primevue/dropdown'

export default defineComponent({
  components: { Dropdown },
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(props.name)

    return {
      errorMessage,
      inputValue,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon-space {
  top: 30px;
}
.dropdown-error.p-dropdown {
  border: $error-border;
  &:not(.p-disabled):hover {
    border: $error-border;
  }
}
</style>
