
import { ResponsiveContent, WeekdayPicker } from '@bd/components'
import MonthCalendar from '@bd/components/Calendar/MonthCalendar.vue'
import CalendarDateTitle from '@bd/components/Calendar/CalendarDateTitle.vue'
import { LocalIsoDate } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { canFetchTimeSlots } from '@bd/store-modules/modules/calendar-event/utils'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarDateTitle,
    WeekdayPicker,
    MonthCalendar,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const selectedDate = computed(
      () => store.state.calendarEvent?.eventSaveData.date,
    )

    const canFetchAvailableTimeSlots = computed(() =>
      canFetchTimeSlots(store.state.calendarEvent),
    )

    const onSelectedDayChange = async (day: LocalIsoDate | LocalIsoDate[]) => {
      await store.dispatch('calendarEvent/setSelectedDate', {
        date: Array.isArray(day) ? day[0] : day,
      })
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    return {
      t,
      selectedDate,
      canFetchAvailableTimeSlots,
      onSelectedDayChange,
    }
  },
})
