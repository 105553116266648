import { Module } from 'vuex'
import { CommonAppState } from '@bd/store-modules/types'
import { UserProfileState } from '@bd/store-modules/types/userProfile'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const userProfile: Module<UserProfileState, CommonAppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default userProfile
