import { ActionTree } from 'vuex'
import { CommonAppState, AuthState } from '@bd/store-modules/types'
import {
  commonApi,
  LoginRequestDto,
  PasswordResetRequestDto,
  SignupRequestDto,
} from '@bd/api'
import { logErr } from '@bd/helpers'
import * as SW from '@bd/api/firebase-sw'

export const actions: ActionTree<AuthState, CommonAppState> = {
  async authLogin({ commit, dispatch }, payload: LoginRequestDto) {
    commit('SET_LOADING', true, { root: true })
    commit('CLEAR_ERROR')
    try {
      const { data } = await commonApi.login(payload)
      commit('SET_AUTH', data)
      await dispatch('staticData/loadData', null, { root: true })
    } catch (e) {
      commit('SET_ERROR', e)
      logErr(e)
      throw e
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async authLogout({ commit }) {
    try {
      await commonApi.logout()
      await SW.deleteToken()
    } catch (e) {
      logErr(e)
    } finally {
      commit('REMOVE_AUTH')
    }
  },

  async authSignup({ commit }, payload: SignupRequestDto) {
    commit('SET_LOADING', true, { root: true })
    commit('CLEAR_ERROR')
    try {
      await commonApi.userSignup(payload)
    } catch (e) {
      commit('SET_ERROR', e)
      logErr(e)
      throw e
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },

  async resetPassword({ commit }, payload: PasswordResetRequestDto) {
    commit('SET_LOADING', true, { root: true })
    commit('CLEAR_ERROR')
    try {
      await commonApi.passwordResetInit(payload)
    } catch (e) {
      commit('SET_ERROR', e)
      logErr(e)
      throw e
    } finally {
      commit('SET_LOADING', false, { root: true })
    }
  },
}
