import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { ErrorResponse, AuthErrorResult } from '@bd/api'
import { default as common } from '@/config/common.json'

const t = i18n.global.t

const responsiveMessageKeys = ['USER_ACCOUNT_LOCKED']
const { helpline } = common

const errorResponse: Record<string | number, AuthErrorResult> = {
  USER_BAD_CREDENTIALS: {
    username: t('validation.user_bad_credentials'),
    password: t('validation.user_bad_credentials'),
  },
  USER_ACCOUNT_LOCKED: {
    mobile: {
      username: t('validation.user_account_locked'),
      password: t('validation.user_account_locked'),
    },
    desktop: {
      username: t('validation.user_account_locked_extended', { helpline }),
      password: t('validation.user_account_locked_extended', { helpline }),
    },
  },
  USER_ACCOUNT_DISABLED: {
    username: t('validation.user_account_disabled'),
    password: t('validation.user_account_disabled'),
  },
  USER_ACCOUNT_NOT_ACTIVATED: {
    username: t('validation.user_account_not_activated'),
    password: t('validation.user_account_not_activated'),
  },
  PHONE_NUMBER_IS_ALREADY_USED: {
    phoneNumber: t('validation.invalid_unique_phone'),
  },
  400: {
    // INVALID_INPUT
    username: t('validation.bad_data'),
    password: t('validation.bad_data'),
  },
  403: {
    // USER_IS_DISABLED_OR_HAS_INSUFFICIENT_PERMISSIONS
    email: t('validation.user_disabled_or_insufficient'),
  },
  460: {
    // FORM_VALIDATION_FAILED
    email: t('validation.check_if_data_is_valid'),
    phoneNumber: t('validation.check_if_data_is_valid'),
    password: t('validation.check_if_data_is_valid'),
    passwordRepeat: t('validation.check_if_data_is_valid'),
  },
  464: {
    // USER_NOT_FOUND
    email: t('validation.user_not_found'),
  },
  500: {
    // OTHER_AUTHENTICATION_ERROR
    username: t('validation.try_again'),
    password: t('validation.try_again'),
  },
}

const getResponsiveMessage = (
  key: string,
  isDesktopSize: boolean,
): AuthErrorResult | undefined => {
  if (responsiveMessageKeys.includes(key)) {
    return isDesktopSize
      ? errorResponse[key].desktop
      : errorResponse[key].mobile
  } else {
    return errorResponse[key]
  }
}

export const authFormService = {
  handleError: function (
    e: ErrorResponse | undefined,
    isDesktopSize: boolean,
  ): AuthErrorResult | undefined {
    if (e && e.resultKey) {
      return getResponsiveMessage(e.resultKey, isDesktopSize)
    } else if (e && e.code) {
      return errorResponse[e.code]
    } else {
      return errorResponse[500]
    }
  },
}
