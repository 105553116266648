import { TransactionType } from '@bd/api/common/types/transaction'
import { ProposalUserDto } from '@bd/components'

export interface AgentPurchaseOfferDto {
  accepted: boolean
  buyer: ProposalUserDto
  offerId: number
  price: number
  seller: ProposalUserDto
  type: TransactionType
}

export interface BuyerPurchaseOfferDto {
  offerId: number
  price: number
  type: TransactionType
}

export interface PurchaseOfferPayload {
  offerId: number
  buyerId: number
  price: number
  type: TransactionType
}

export interface AddAgentPurchaseOfferDto {
  buyerId: number
  offerId: number
}

export interface PurchaseOfferRowDto {
  accepted: boolean
  buyerId: number
  offerId: number
  price: number
  type: TransactionType
}

export interface AddPurchaseOfferDto {
  price: number
  offerId: number
}

export enum ProposalOfferMessages {
  PROPOSAL_ACCEPTED = 'PROPOSAL_ACCEPTED',
  PROPOSAL_MADE = 'PROPOSAL_MADE',
}

export interface ProposalMessage {
  message: ProposalOfferMessages
  id: number
}

