import {
  ApiResponse,
  ClientDto,
  SimpleResponse,
  TimeSlot,
  UserCalendarEventDetailsDto,
} from '@bd/api'
import {
  createCustomMeeting,
  createPredefinedMeeting,
  getCustomMeetingTypeTimeSlots,
  getPredefinedMeetingTypeTimeSlots,
  updateCustomMeeting,
  updatePredefinedMeeting,
} from '@bd/api/common/calendar-event-api'
import {
  AdminCustomMeetingDto,
  AdminCustomMeetingTypeTimeSlotsParams,
  AdminEditCustomMeetingDto,
  AgentCustomMeetingDto,
  AgentPredefinedMeetingDto,
  CommonMeetingSaveDto,
  CustomMeetingTypeTimeSlotsParams,
  EditCustomMeetingDto,
  EditPredefinedMeetingDto,
  PredefinedMeetingTypeTimeSlotsParams,
} from '@bd/api/common/types/calendar-event'
import { getLocalIsoDate, PartialBy, sameArraysContent } from '@bd/helpers'
import {
  CalendarEventMeetingType,
  CommonAppState,
  CommonCalendarEventState,
  EventSaveData,
  MeetingOfferSelectPayload,
} from '@bd/store-modules/types'

export const EVENT_SAVE_DATA_INITIAL_STATE: EventSaveData = {
  date: getLocalIsoDate(new Date()),
  buyer: undefined,
  eventId: undefined,
  meetingType: undefined,
  offer: undefined,
  seller: undefined,
  timeSlotIds: undefined,
}

/**
 * Get meeting time slot ids from event detail's.
 * Allows to map event start/end times to time slot ids.
 * @returns Custom meeting: Time slot ids spanning the event's duration.
 * Predefined meeting: single time slot id representing event's starting slot
 */
export const getMeetingTimeSlotIds = (
  rootState: CommonAppState,
  event: UserCalendarEventDetailsDto,
): TimeSlot.TimeSlotID[] => {
  const timeSlots = rootState.staticData?.timeSlots ?? []
  const eventStartTime = event.calendarEventDto.startTime
  const eventEndTime = event.calendarEventDto.agentEndTime
  const isCustomMeeting = !event.calendarEventDto.meetingTypeDto
    .predefinedMeetingType

  const startingTimeSlotIdx = timeSlots?.findIndex(
    (timeSlot) => timeSlot.startTime === eventStartTime,
  )
  if (!timeSlots || startingTimeSlotIdx < 0) {
    return []
  }

  if (!isCustomMeeting) {
    return [timeSlots[startingTimeSlotIdx].timeSlotId]
  }

  const endingTimeSlotIdx = timeSlots.findIndex(
    (timeSlot) => timeSlot.startTime === eventEndTime,
  )
  if (endingTimeSlotIdx < 0) {
    return []
  }

  const customSelectedSlots = timeSlots.slice(
    startingTimeSlotIdx,
    endingTimeSlotIdx,
  )
  return customSelectedSlots.map((timeSlot) => timeSlot.timeSlotId)
}

/**
 * Maps input user calendar event details (fetched from event details API)
 * to the type that is used for editing the event.
 * Used to initially fill the event edit DTO with data from the saved event.
 * @param event Event details fetched from API
 * @returns Event saving data, used in store
 */
export const mapEventDetailsToSaveData = (
  rootState: CommonAppState,
  event: UserCalendarEventDetailsDto,
): EventSaveData => {
  const eventDto = event.calendarEventDto
  const { id, ...rest } = eventDto.calendarEventOfferDto || {}
  const seller = eventDto.seller as Required<ClientDto>
  const buyer = eventDto.customer as Required<ClientDto>
  const offerAndSeller: MeetingOfferSelectPayload = {
    offer: {
      id,
      address: rest,
    },
    seller,
  }
  const meetingTypeDto = eventDto.meetingTypeDto

  const meetingType: CalendarEventMeetingType = {
    name: meetingTypeDto.meetingTypeName,
    agentDurationMinutes: meetingTypeDto.agentDurationMinutes,
    isCustom: !meetingTypeDto.predefinedMeetingType,
    predefinedId: meetingTypeDto.meetingTypeId,
    customerPresenceRequired: meetingTypeDto.customerPresenceRequired,
  }
  const initialData: EventSaveData = {
    ...offerAndSeller,
    buyer,
    meetingType,
    date: eventDto.date,
    timeSlotIds: getMeetingTimeSlotIds(rootState, event),
    eventId: event.userCalendarEventId,
  }

  return initialData
}

/**
 * If there is enough data filled in to fetch available time slots
 * @returns True if all necessary (for time slots fetching) fields are filled, false otherwsie
 */
export const canFetchTimeSlots = (
  state?: CommonCalendarEventState,
): boolean => {
  const eventSaveData = state?.eventSaveData
  const meetingType = eventSaveData?.meetingType
  const isCustom = meetingType?.isCustom
  return !!(
    isCustom ||
    (eventSaveData?.offer &&
      meetingType &&
      (!meetingType.customerPresenceRequired || eventSaveData?.buyer) &&
      eventSaveData?.date)
  )
}

type AvailableTimeSlotsParams =
  | CustomMeetingTypeTimeSlotsParams
  | (PredefinedMeetingTypeTimeSlotsParams &
      AdminCustomMeetingTypeTimeSlotsParams)

/**
 * Prevalidate the meeting time slots fetching method to make sure the final API
 * call is made with all the necessary data, since it's supposed to be used
 * on any change of the event saving form's inputs.
 * @param state Underlying calendar's event state
 * @returns Method to fetch available time slots if conditions are valid, null otherwise
 */
export const prevalidateAvailableTimeSlotsMethod = (
  state?: CommonAppState,
): (() => ApiResponse<TimeSlot.TimeSlotDto[]>) | null => {
  const { calendar, calendarEvent } = state ?? {}

  if (!canFetchTimeSlots(calendarEvent)) {
    return null
  }
  const eventSaveData = calendarEvent?.eventSaveData as PartialBy<
    Required<EventSaveData>,
    'eventId' | 'buyer'
  >
  const offerId = eventSaveData.offer?.id
  const { meetingType } = eventSaveData

  const params: AvailableTimeSlotsParams = {
    offerId: offerId,
    date: eventSaveData.date,
    meetingTypeId: meetingType.predefinedId!,
    customerId: eventSaveData.buyer?.id,
    userCalendarEventId: eventSaveData.eventId,
    agentId: !offerId ? calendar?.selectedAgentId : undefined, // Only provide agentId if the offer wasn't specified (Custom meeting without the offer)
  }

  const method = meetingType.isCustom
    ? getCustomMeetingTypeTimeSlots
    : getPredefinedMeetingTypeTimeSlots

  return method.bind(null, params)
}

type CustomSaveData =
  | AgentCustomMeetingDto
  | EditCustomMeetingDto
  | AdminCustomMeetingDto
  | AdminEditCustomMeetingDto

export const getEventSaveMethod = (
  state: CommonAppState,
): (() => ApiResponse<SimpleResponse<number>>) | null => {
  const { eventSaveData } = state.calendarEvent ?? {}
  if (!eventSaveData) {
    return null
  }
  const selectedAgentId = state.calendar?.selectedAgentId
  const isCustom = eventSaveData.meetingType?.isCustom
  const eventId = eventSaveData.eventId
  const isEdit = eventId != null

  const commonData: CommonMeetingSaveDto = {
    offerId: eventSaveData.offer?.id,
    customerId: eventSaveData.buyer?.id,
    date: eventSaveData.date!,
  }

  if (isCustom) {
    const saveData: CustomSaveData = {
      ...commonData,
      meetingTypeName: eventSaveData.meetingType!.name,
      timeSlotIds: eventSaveData.timeSlotIds!,
      agentId: !commonData.offerId ? selectedAgentId : undefined,
    }

    if (isEdit) {
      return updateCustomMeeting.bind(null, eventId!, saveData)
    }
    return createCustomMeeting.bind(
      null,
      saveData as AgentCustomMeetingDto | AdminCustomMeetingDto,
    )
  }
  const saveData: AgentPredefinedMeetingDto | EditPredefinedMeetingDto = {
    ...commonData,
    meetingTypeId: eventSaveData.meetingType!.predefinedId!,
    timeSlotId: eventSaveData.timeSlotIds![0],
  }
  if (isEdit) {
    return updatePredefinedMeeting.bind(
      null,
      eventId!,
      saveData as EditPredefinedMeetingDto,
    )
  }
  return createPredefinedMeeting.bind(null, saveData)
}

export const getDisabledTimeSlots = (state: CommonAppState) => {
  const allTimeSlotIds = (state.staticData?.timeSlots ?? []).map(
    (timeSlot) => timeSlot.timeSlotId,
  )

  const availableTimeSlotIds = (
    state.calendarEvent?.eventSaveMetaData.availableTimeSlots.content ?? []
  ).map((timeSlot) => timeSlot.timeSlotId)

  if (sameArraysContent(allTimeSlotIds ?? [], availableTimeSlotIds)) {
    return []
  }

  const result = allTimeSlotIds.filter(
    (timeSlot) => !availableTimeSlotIds.includes(timeSlot),
  )

  return result
}

export const isAnySelectedSlotDisabled = (state: CommonAppState) => {
  return state.calendarEvent?.eventSaveData.timeSlotIds?.some((timeSlot) =>
    state.calendarEvent?.eventSaveMetaData.disabledTimeSlots.includes(timeSlot),
  )
}
