<template>
  <div class="search-row">
    <SearchInput
      class="custom-search-class"
      :modelValue="searchPhrase"
      :placeholder="t('clients.search')"
      :debounceMs="MEETING_SEARCHABLE_LIST_DEBOUNCE_MS"
      :autofocus="true"
      @search="$emit('searchClient', $event)"
      @clear="clear"
      @update:modelValue="$emit('update:searchPhrase', $event)"
    />
  </div>
  <div class="results-container" v-if="clientItems && !isLoading && isPresent">
    <ProposalClientList
      :items="clientItems"
      :selectedClientId="selectedClientId"
      @selectClient="onClientSelected"
    />
  </div>
  <slot v-if="isEmpty && !isLoading" name="empty" />
  <div v-if="isLoading" class="progress-container">
    <ProgressSpinner />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRefs, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ResultPageDto } from '@bd/api'
import { LoadableOptional } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import {
  MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
  SearchInput,
  useLoadableResourceDataState,
} from '@bd/components'
import ProposalClientList from './ProposalClientList.vue'
import { MeetingAttendeeDto } from '@bd/api/common/types/calendar-event'
import { ProposalUserDto } from '@bd/components'

export default defineComponent({
  components: {
    SearchInput,
    ProposalClientList,
    ProgressSpinner,
  },
  props: {
    searchPhrase: {
      type: String,
      required: true,
    },
    selectedClient: {
      type: Object as PropType<MeetingAttendeeDto>,
      required: false,
    },
    clients: {
      type: Object as PropType<
        LoadableOptional<ResultPageDto<MeetingAttendeeDto> | undefined>
      >,
      required: true,
    },
  },
  emits: ['update:searchPhrase', 'searchClient', 'selectClient', 'clear'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { clients } = toRefs(props)
    const clientsPayload = computed(() => clients.value.payload)
    const clientsPayloadContent = computed<
      ResultPageDto<MeetingAttendeeDto> | undefined
    >(() => clientsPayload.value?.content)

    const { isLoading, isEmpty, isPresent } = useLoadableResourceDataState(
      clientsPayload,
      (res) => !!(res?.content?.content && res.content.content.length),
    )

    const selectedClientId = ref<number | null>(null)

    const clientItems = computed<ProposalUserDto[] | undefined>(() => {
      const clientsContent = clientsPayloadContent.value?.content

      const mappedClients: ProposalUserDto[] | undefined = clientsContent?.map(
        ({ id, phoneNumber, firstName, lastName }) => {
          return {
            id,
            phoneNumber,
            firstName,
            lastName,
          }
        },
      )
      return mappedClients
    })

    const clear = () => {
      selectedClientId.value = null
      emit('clear')
    }

    const onClientSelected = (client: ProposalUserDto) => {
      if (client.id === selectedClientId.value) {
        return
      }
      selectedClientId.value = client.id
      emit('update:searchPhrase', client.firstName + ' ' + client.lastName)
      emit('selectClient', client)
    }

    return {
      t,
      clientItems,
      isLoading,
      isEmpty,
      isPresent,
      onClientSelected,
      MEETING_SEARCHABLE_LIST_DEBOUNCE_MS,
      clientsPayload,
      selectedClientId,
      clear,
    }
  },
})
</script>

<style lang="scss" scoped>
.search-row {
  margin-bottom: 1rem;
}
:deep(.custom-search-class .p-inputtext) {
  @include breakpoint-down(md) {
    border-radius: 4px;
    background: $white;
  }
}
</style>
