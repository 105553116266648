<template>
  <main class="content-col d-none d-md-flex flex-column align-items-center">
    <div class="content-wrapper" :class="{ 'with-title': $slots.title }">
      <slot name="title"></slot>
      <slot name="calendar-header" :date="navigatedDate">
        <header class="page-header">
          <div>
            <h6 class="page-subtitle">{{ t('calendar.title') }}</h6>
            <CalendarDateTitle class="mt-3" :date="navigatedDate" />
          </div>
          <Button
            v-if="isAgent"
            class="p-button-secondary px-3 p-button-rounded"
            :label="t('calendar.edit_availability')"
            @click="editAgentAvailability"
          />
        </header>
      </slot>
      <div class="d-flex justify-content-center">
        <MonthCalendar
          :selectedDay="selectedDay"
          :navigatedDate="navigatedDate"
          :upcomingEventDates="upcomingEventDates"
          @update:navigatedDate="navigatedDateChange"
          @update:selectedDay="selectedDayChange"
          size="large"
        />
      </div>
    </div>
  </main>
  <div class="meetings-col">
    <MeetingsCalendar
      :selectedDay="selectedDay"
      :showTopControls="isAgent"
      :timeSlots="timeSlots"
      :todayCalendarEvents="todayCalendarEvents"
      :upcomingEventDates="upcomingEventDates"
      @update:selectedDay="selectedDayChange"
      @eventTileClick="eventTileClicked"
      @availabilityEdit="editAgentAvailability"
    >
      <template v-if="!isBuyer" #title-append>
        <Button
          class="p-button-primary p-button-rounded px-5"
          :label="t('calendar.add_event')"
          @click="addNewEvent"
        />
      </template>
    </MeetingsCalendar>
    <div
      class="d-md-none fab-container"
      v-if="!isBuyer && timeSlots && timeSlots.length"
    >
      <Button
        icon="pi pi-plus"
        class="fab-btn p-button-primary"
        @click="addNewEvent"
      />
    </div>
  </div>
  <CalendarEventsListModal
    v-if="overlappingEvents && overlappingEvents.length"
    v-model:visible="eventsListModalVisible"
    :events="overlappingEvents"
    :showAddress="true"
    @eventClicked="singleEventClicked"
  />
</template>

<script lang="ts">
import { UserCalendarEventDto } from '@bd/api'
import { TimeSlotDto } from '@bd/api/common/types/time-slot'
import { LocalIsoDate } from '@bd/helpers'
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UserType } from '../index'
import CalendarDateTitle from './CalendarDateTitle.vue'
import CalendarEventsListModal from './EventsList/CalendarEventsListModal.vue'
import MeetingsCalendar from './MeetingsCalendar.vue'
import MonthCalendar from './MonthCalendar.vue'
import { useAppStore } from '@bd/store-modules'

export default defineComponent({
  components: {
    CalendarDateTitle,
    MeetingsCalendar,
    MonthCalendar,
    CalendarEventsListModal,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    navigatedDate: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlotDto[]>,
      required: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: [
    'update:selectedDay',
    'update:navigatedDate',
    'eventTileClick',
    'addEventClick',
    'editAvailabilityClick',
  ],
  setup(props, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const eventsListModalVisible = ref(true)
    const overlappingEvents = ref<UserCalendarEventDto[] | null>(null)
    const isAgent = computed(() => props.userType === UserType.Agent)
    const isBuyer = computed(() => props.userType === UserType.Buyer)

    const selectedDayChange = (day: LocalIsoDate) => {
      emit('update:selectedDay', day)
    }

    const navigatedDateChange = (date: LocalIsoDate) => {
      emit('update:navigatedDate', date)
    }

    const addNewEvent = () => {
      emit('addEventClick')
      store.dispatch('calendarEvent/setSelectedDate', {
        date: props.selectedDay,
        keepDate: true,
      })
    }

    const editAgentAvailability = () => {
      emit('editAvailabilityClick')
    }

    const singleEventClicked = (event: UserCalendarEventDto) => {
      emit('eventTileClick', event)
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      if (events.length > 1) {
        overlappingEvents.value = events
        eventsListModalVisible.value = true
        return
      }
      singleEventClicked(events[0])
    }

    return {
      t,
      selectedDayChange,
      navigatedDateChange,
      addNewEvent,
      editAgentAvailability,
      eventTileClicked,
      singleEventClicked,
      eventsListModalVisible,
      overlappingEvents,
      isAgent,
      isBuyer,
    }
  },
})
</script>

<style lang="scss" scoped>
.content-col {
  flex: 0 1 75%;
  background-color: $alabaster;
  overflow-y: auto;
}
.meetings-col {
  width: 100%;
  @include breakpoint-up(md) {
    flex: 1 0 auto;
    width: auto;
    min-width: 320px;
  }
}
.fab-container {
  z-index: 9;
  position: fixed;
  bottom: calc(#{$mobile-navigation-height} + 14px);
  right: 20px;
  .fab-btn {
    border-radius: 50%;
    @include uniform-size(4.375rem);
    &.medium {
      @include uniform-size(3.5rem);
    }
    &.small {
      @include uniform-size(3rem);
    }
  }
}
.content-wrapper:not(.with-title) {
  margin-top: 3rem;
}
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem 1.5rem;
  margin-bottom: 2rem;
}
</style>
