import { i18n } from '@bd/admin/plugins/vue-i18n-next-plugin'
import { SelectData, Enum } from '@bd/admin/types'

const { t } = i18n.global

export function enumConvertToValue(enums: Enum, name: string): SelectData[] {
  const result: SelectData[] = Object.keys(enums).map((key) => ({
    name: t(`enums.${name}.${key}`),
    value: key,
  }))
  return result
}
