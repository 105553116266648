<template>
  <div class="calendar-event-offer-address" v-if="address">
    <div class="address-row">
      <header class="street">
        {{ t('street_prefix') }} {{ address.street }} {{ address.buildingNumber
        }}{{ address.houseNumber }}
      </header>
      <slot :address="address" />
    </div>
    <div class="city-and-district">
      <span>{{ address.cityDto.cityName }}</span>
      <span v-if="address.districtDto && address.districtDto.districtName">
        <span>, </span>
        {{ address.districtDto.districtName }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAddress } from '@itcraft-bestdeal/api'

export default defineComponent({
  components: {},
  props: {
    address: {
      type: Object as PropType<CalendarEventAddress>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-event-offer-address {
  @include breakpoint-down(md) {
    width: 100%;
    @include flex($align: unset, $direction: column);
    .address-row {
      width: 100%;
      @include flex($justify: flex-start);
    }
  }

  .street {
    font-size: 1.125rem;
    color: $port-gore;
    font-weight: 600;
    @include breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }
  .city-and-district {
    line-height: 1.64;
    color: $comet;
    @include breakpoint-up(md) {
      font-size: 0.875rem;
    }
  }

  &.selected {
    .street {
      color: $primary-color;
    }
  }
}
</style>
