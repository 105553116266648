<template>
  <component
    :is="is"
    class="calendar-event-offers-list-item event-resources-results-list-item"
    :class="{ selected: item.selected }"
  >
    <div class="wrapper">
      <div class="thumbnail-container">
        <img
          :src="image"
          class="thumbnail"
          :class="{ 'no-image': !item.thumbnailUrl }"
        />
        <span class="draft-chip" v-if="item.draft">{{ t('draft') }}</span>
      </div>
      <div class="offer-details">
        <CalendarEventOfferAddress
          :address="formattedAddress"
          :class="{ selected: item.selected }"
        />
        <div class="offer-id" v-if="showId">
          {{ item.displayableId }}
        </div>
      </div>
      <div v-if="$slots['item-append']" class="pl-2 item-append">
        <slot name="item-append" :item="item" />
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { CalendarEventOfferItem } from '@bd/components/Calendar/types'
import CalendarEventOfferAddress from '../CalendarEventOfferAddress.vue'
import { useEventAddress } from '@bd/components/use'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    CalendarEventOfferAddress,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    item: {
      type: Object as PropType<CalendarEventOfferItem>,
      required: true,
    },
    showId: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const regularAddress = computed(() => props.item.address)
    const formattedAddress = useEventAddress(regularAddress)
    const image = computed(() => {
      const imageUrl = props.item?.thumbnailUrl
      return imageUrl ?? require('@bd/assets/images/no-image.svg')
    })
    return {
      t,
      formattedAddress,
      image,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../event-save';

$thumbnail-desktop-height: 68px;
$thumbnail-mobile-height: 80px;

.calendar-event-offers-list-item {
  .thumbnail-container {
    display: flex;
    align-self: center;
    position: relative;
    flex: 0 0 33.33%;
    max-width: 90px;
    margin-right: 1rem;
    @include breakpoint-down(md) {
      min-width: 100px;
      max-width: 160px;
    }
    .thumbnail {
      border-radius: 6px;
      object-fit: cover;
      width: 100%;
      height: $thumbnail-desktop-height;
      @include breakpoint-down(md) {
        height: $thumbnail-mobile-height;
      }
      &.no-image {
        object-fit: contain;
        background: $alabaster;
        padding: 0.5rem;
      }
    }
  }
  .offer-details {
    @include flex($direction: column, $align: flex-start);
    height: 100%;
    flex: 1 1 auto;
    padding: 0.25rem 0;
    .offer-id {
      margin-top: auto;
      color: $manatee;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
}
:deep(.calendar-event-offer-address) {
  margin-bottom: auto;
  .street {
    font-size: 1rem;
    @include breakpoint-down(md) {
      font-size: 1.125rem;
    }
  }
  .city-and-district {
    font-size: 0.875rem;
  }
}

.draft-chip {
  position: absolute;
  top: 0;
  right: 0;
  background: $white;
  font-size: 0.75rem;
  color: $primary-color;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 2px;
  font-weight: 500;
}
</style>
