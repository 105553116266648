
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '@bd/components'

export default defineComponent({
  name: 'ProposalClientList',
  emits: ['select-client'],
  props: {
    items: {
      type: Array as PropType<CalendarEventAttendeeItem[]>,
      required: true,
    },
    selectedClientId: {
      type: Number as PropType<number | null>,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
})
