
import { useI18n } from 'vue-i18n'
import { defineComponent, computed, PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NavigationEnabledRoute } from '../types'

export default defineComponent({
  name: 'MobileAuthNavigation',
  props: {
    enabledRoutes: {
      type: Array as PropType<NavigationEnabledRoute[]>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n()
    const route = useRoute()
    const router = useRouter()

    const currentRoute = computed(() => {
      const currentPath = route.path
      return props.enabledRoutes.find((route) => route.path === currentPath)
    })

    const routeText = computed(() => {
      const currentRouteObject = currentRoute.value
      return currentRouteObject?.text
    })

    const navigate = () => {
      const navigationStrategy =
        currentRoute.value?.navigationStrategy ?? 'Back'
      if (navigationStrategy === 'Back') {
        router.back()
      } else if (currentRoute.value?.goTo) {
        router.push({ path: currentRoute.value?.goTo })
      } else {
        router.push({ path: '/' })
      }
    }

    return { ...i18n, routeText, navigate }
  },
})
