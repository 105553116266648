<template>
  <div class="accordion__item" :class="{ 'filters-bg': index === 0 }">
    <div
      :class="{ accordion__trigger_active: index === Accordion.active }"
      @click="toggleAccordionItem"
    >
      <slot name="accordion-trigger"></slot>
    </div>

    <!-- don't use v-show, carousel errors -->
    <div
      class="accordion__content"
      v-if="index === Accordion.active || index === props.accordionItemStatus"
    >
      <slot name="accordion-content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { Accordion } from './DashboardAccordion.types'

export default defineComponent({
  emits: ['accordionStatus'],
  props: {
    accordionItemStatus: {
      type: Number,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const Accordion = inject<Accordion>('Accordion')!

    const toggleAccordionItem = () => {
      if (props.index === Accordion.active) {
        Accordion.active = null
        emit('accordionStatus', null)
      } else {
        Accordion.active = props.index
        emit('accordionStatus', props.index)
      }
    }

    return {
      toggleAccordionItem,
      Accordion,
      props,
    }
  },
})
</script>

<style lang="scss" scoped>
.accordion__item {
  cursor: pointer;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid $gallery;
  position: relative;
  &:nth-last-child(2) {
    border-bottom: none;
  }
}
.filters-bg {
  background: $whisper;
}
</style>
