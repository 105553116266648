import { ResultPageDto } from '@bd/api'
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import { emptyLoadableResource, uniqBy } from '@bd/helpers'
import { NotificationsState } from '@bd/store-modules/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<NotificationsState> = {
  SET_NOTIFICATIONS_LOADING(state, isLoading: boolean) {
    state.notifications.isLoading = isLoading
  },
  ADD_NOTIFICATIONS_CHUNK(
    state,
    payload: ResultPageDto<SystemNotificationDto>,
  ) {
    const currentContent = state.notifications.content
    state.notifications.content = {
      ...payload,
      content: currentContent
        ? uniqBy(
            [...currentContent.content, ...payload.content],
            (notification) => notification.id,
          )
        : payload.content,
    }
  },
  SET_NOTIFICATIONS_CONTENT(state, payload: SystemNotificationDto[]) {
    state.notifications.content = {
      ...state.notifications.content!,
      content: payload
    }
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = emptyLoadableResource()
  },
  SET_NOTIFICATIONS_VISIBLE(state, visible: boolean) {
    state.notificationsVisible = visible
  },
  SET_UNREAD_NOTIFICATIONS_COUNT(state, payload: number) {
    state.unreadNotificationsCount = payload
  }
}
