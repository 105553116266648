<template>
  <div
    class="notification-initial"
    :class="{
      calendar: isCalendarNotification,
      proposal: isProposalNotification,
    }"
  >
    {{ initial }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { NotificationType } from '@bd/api/common/types/notifications'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  props: {
    notificationType: {
      type: String as PropType<NotificationType>,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const isCalendarNotification = computed(() => {
      return props.notificationType === NotificationType.CALENDAR
    })

    const isProposalNotification = computed(() => {
      return props.notificationType === NotificationType.PURCHASE_OFFER
    })

    const initial = computed(() => {
      const translation = t(`notificationType.${props.notificationType}`)
      return translation.charAt(0).toUpperCase()
    })

    return {
      initial,
      isCalendarNotification,
      isProposalNotification,
    }
  },
})
</script>

<style lang="scss" scoped>
.notification-initial {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  @include uniform-size(60px);
  font-size: 1.875rem;

  &.calendar {
    color: $cerulean;
    background-color: rgba($cerulean, 0.1);
  }
  &.proposal {
    color: $zest;
    background-color: rgba($zest, 0.1);
  }
}
</style>
