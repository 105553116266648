
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'

export default defineComponent({
  name: 'DashboardAutocompleteInput',
  emits: [
    'arrow-down',
    'arrow-up',
    'enter',
    'search-btn-action',
    'update:isOpen',
    'input',
  ],
  props: {
    query: { type: String, default: '' },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isOpen: Boolean,
  },
  components: { Button },
  setup(props, { emit }) {
    const i18n = useI18n()
    const computedIsOpen = useVModel(props, 'isOpen', emit)

    const onInput = (e: InputEvent) => {
      const input = (e.target as HTMLInputElement).value
      computedIsOpen.value = !!input
      emit('input', input)
    }
    const onClick = () => {
      if (props.query.length > 0) {
        computedIsOpen.value = true
      }
    }

    return {
      ...i18n,
      onClick,
      onInput,
      computedIsOpen,
    }
  },
})
