
import { defineComponent, provide } from 'vue'
import { Accordion } from './FilterAccordion.types'

export default defineComponent({
  setup() {
    provide<Accordion>('Accordion', {
      active: null,
    })
  },
})
