
import { ResponsiveContent } from '@bd/components'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import { defineComponent, PropType } from 'vue'
import CalendarEventOfferChooser from './CalendarEventOfferChooser.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventOfferChooser,
  },
  props: {
    selectedOffer: {
      type: Object as PropType<MeetingOfferSelectPayload>,
      required: false,
    },
  },
  emits: ['update:selectedOffer', 'searchOffer', 'clearOffer'],
})
