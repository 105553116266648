<template>
  <div @click="onWrapperClick" class="dropdown-wrapper" ref="wrapper">
    <slot>
      <button>Toggle</button>
    </slot>
    <transition name="dropdown-transition">
      <div
        ref="dropdown"
        v-if="isOpen"
        class="mt-2 dropdown-content"
        :class="contentClass"
        :style="dropdownContentStyle"
      >
        <slot name="content"> </slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { useBreakpoints } from '@bd/components'
import { onClickOutside, useVModel } from '@vueuse/core'
import { computed, defineComponent, ref, shallowRef } from 'vue'

const UNBOUND_TOKEN = {} as never
const CONTENT_HORIZONTAL_OFFSET = 20

export default defineComponent({
  name: 'DropdownButton',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: null, default: UNBOUND_TOKEN },
    contentClass: { type: String, default: '' },
    contentClickCloses: { type: Boolean, default: true },
    isAdjustedContent: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const wrapper = shallowRef<HTMLElement>()
    const dropdown = shallowRef<HTMLElement>()
    const { width } = useBreakpoints()

    const isOpen =
      props.modelValue === UNBOUND_TOKEN
        ? ref(false)
        : useVModel(props, 'modelValue', emit)

    const onWrapperClick = (event: MouseEvent) => {
      const dropdownClick =
        event.target === dropdown.value ||
        event.composedPath().includes(dropdown.value!)

      if (dropdownClick) {
        if (props.contentClickCloses) {
          isOpen.value = false
        }
      } else {
        isOpen.value = !isOpen.value
      }
    }

    const dropdownContentOverflowsRight = () => {
      if (!dropdown.value || !wrapper.value) {
        return false
      }
      const contentEl = dropdown.value
      const boundingRect = contentEl.getBoundingClientRect()
      return boundingRect.right + CONTENT_HORIZONTAL_OFFSET > width.value
    }

    const dropdownContentStyle = computed(() => {
      return props.isAdjustedContent
        ? dropdownContentOverflowsRight()
          ? { right: 0 }
          : { left: 0 }
        : false
    })

    onClickOutside(wrapper, () => {
      if (isOpen.value) {
        isOpen.value = false
      }
    })
    return {
      isOpen,
      onWrapperClick,
      dropdown,
      wrapper,
      dropdownContentStyle,
      width,
    }
  },
})
</script>

<style lang="scss" scoped>
.dropdown-transition-enter-active,
.dropdown-transition-leave-active {
  transition: all 0.1s;
}
.dropdown-transition-enter,
.dropdown-transition-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
  .dropdown-content {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}
</style>
