import { CommonAppState } from '@bd/store-modules/types'
import { CommonCalendarState } from '@bd/store-modules/types/calendar'
import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const calendar: Module<CommonCalendarState, CommonAppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default calendar
