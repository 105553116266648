<template>
  <Dialog
    class="dialog-meeting-type-selection"
    :header="t('calendar.events.save_event.meeting_type.title')"
    :showHeader="true"
    :modal="true"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <CalendarEventMeetingTypesList
      :meetingTypes="meetingTypeEntries"
      @selectMeetingType="onMeetingTypeSelected"
    />
  </Dialog>
</template>

<script lang="ts">
import { CalendarEventMeetingTypeItem } from '@bd/components'
import { CalendarEventMeetingType } from '@bd/store-modules/types'
import Dialog from 'primevue/dialog'
import { defineComponent, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventMeetingTypesList from './MeetingTypesList/CalendarEventMeetingTypesList.vue'
import { useMeetingTypeEntries } from './utils'

export default defineComponent({
  components: {
    Dialog,
    CalendarEventMeetingTypesList,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    meetingTypes: {
      type: Array as PropType<CalendarEventMeetingType[]>,
      required: true,
    },
    selectedMeetingType: {
      type: Object as PropType<CalendarEventMeetingType>,
      required: false,
    },
  },
  emits: ['update:selectedMeetingType', 'update:visible'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const { meetingTypes, selectedMeetingType } = toRefs(props)
    const meetingTypeEntries = useMeetingTypeEntries(
      meetingTypes,
      selectedMeetingType,
    )
    const onMeetingTypeSelected = (
      meetingType: CalendarEventMeetingTypeItem,
    ) => {
      emit('update:selectedMeetingType', meetingType)
      emit('update:visible', false)
    }
    return {
      t,
      onMeetingTypeSelected,
      meetingTypeEntries,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.dialog-meeting-type-selection) {
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include mobile-fullscreen-dialog();
  .p-dialog-header {
    padding-bottom: 0;
  }
  .p-dialog-content {
    padding: 1rem 0 0 0;
    color: $bright-gray;
  }
}
</style>
