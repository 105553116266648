<template>
  <div
    class="calendar-event-save-view"
    :class="{ 'is-loading': meetingTypesLoading }"
  >
    <ResponsiveContent v-if="!meetingTypesLoading">
      <template #mobile>
        <div class="mobile">
          <section class="offer-section">
            <CalendarEventOffer
              :selectedOffer="selectedOffer"
              @searchOffer="$emit('searchOffer', $event)"
              @clearOffer="onSelectedOfferClear"
            />
          </section>
          <div class="sections-group">
            <section class="meeting-type-section">
              <CalendarEventMeetingType />
            </section>
            <template v-if="showSecondaryMobileSections">
              <section class="attendee-buyer-section">
                <CalendarEventAttendees />
              </section>
              <template v-if="canFetchAvailableTimeSlots">
                <section class="date-section">
                  <CalendarEventDate />
                </section>
                <section class="time-section">
                  <CalendarEventTime />
                </section>
              </template>
            </template>
          </div>
        </div>
      </template>
      <template #desktop>
        <div class="desktop d-md-flex">
          <div class="left-col">
            <div class="cards-row offer-attendee-row">
              <section class="offer-section mr-3">
                <CalendarEventOffer
                  :selectedOffer="selectedOffer"
                  @searchOffer="$emit('searchOffer', $event)"
                  @clearOffer="onSelectedOfferClear"
                />
              </section>
              <section class="attendees-section">
                <CalendarEventAttendees />
              </section>
            </div>
            <div class="cards-row mt-4">
              <section class="meeting-type-section">
                <CalendarEventMeetingType />
              </section>
            </div>
          </div>
          <div class="right-col">
            <section class="date-section">
              <CalendarEventDate />
            </section>
            <section
              class="time-section mt-4"
              v-if="canFetchAvailableTimeSlots"
            >
              <CalendarEventTime />
            </section>
          </div>
        </div>
      </template>
    </ResponsiveContent>
    <ProgressSpinner v-if="meetingTypesLoading" />
  </div>
</template>

<script lang="ts">
import { ResponsiveContent, useLoadableResourceDataState } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { canFetchTimeSlots } from '@bd/store-modules/modules/calendar-event/utils'
import { getPredefinedMeetingTypes } from '@bd/store-modules/modules/static-data/getters-helpers'
import { MeetingOfferSelectPayload } from '@bd/store-modules/types'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendees from './CalendarEventAttendees/CalendarEventAttendees.vue'
import CalendarEventDate from './CalendarEventDate/CalendarEventDate.vue'
import CalendarEventMeetingType from './CalendarEventMeetingType/CalendarEventMeetingType.vue'
import CalendarEventOffer from './CalendarEventOffer/CalendarEventOffer.vue'
import CalendarEventTime from './CalendarEventTime/CalendarEventTime.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    ProgressSpinner,
    CalendarEventOffer,
    CalendarEventMeetingType,
    CalendarEventAttendees,
    CalendarEventDate,
    CalendarEventTime,
  },
  emits: ['searchOffer'],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const isOfferChosen = ref(false)

    const predefinedMeetingTypes = computed(() =>
      getPredefinedMeetingTypes(store),
    )
    const { isLoading: meetingTypesLoading } = useLoadableResourceDataState(
      predefinedMeetingTypes,
      (res) => !!(res?.content && res.content.length),
    )

    const canFetchAvailableTimeSlots = computed(() =>
      canFetchTimeSlots(store.state.calendarEvent),
    )

    const showSecondaryMobileSections = computed(() => {
      const saveData = store.state.calendarEvent?.eventSaveData
      return !!(
        saveData?.meetingType &&
        (saveData.offer || saveData?.meetingType?.isCustom)
      )
    })

    const selectedOffer = computed<MeetingOfferSelectPayload | undefined>(
      () => {
        if (!store.state.calendarEvent) {
          return undefined
        }
        const { offer, seller } = store.state.calendarEvent.eventSaveData
        return offer && seller
          ? {
              offer,
              seller,
            }
          : undefined
      },
    )

    const onSelectedOfferClear = () => {
      store.commit('calendarEvent/SET_SELECTED_OFFER', undefined)
    }

    return {
      t,
      isOfferChosen,
      meetingTypesLoading,
      showSecondaryMobileSections,
      selectedOffer,
      canFetchAvailableTimeSlots,
      onSelectedOfferClear,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-event-save-view {
  margin-top: 3rem;
  height: 100%;

  @include breakpoint-down(md) {
    overflow-y: auto;
    background-color: $white;
  }

  &.is-loading {
    height: 100%;
    @include flex;
  }

  @include breakpoint-down(md) {
    margin-top: 0;
  }
  &.admin .desktop {
    @media (max-width: 1366px) {
      flex-wrap: wrap;
      padding-bottom: 2rem;
      .right-col {
        margin-top: 1rem;
      }
    }
  }
  .desktop {
    justify-content: space-between;

    [class*='-col'] {
      @include flex($direction: column, $justify: flex-start);
      flex: 0 1 auto;
    }

    @include breakpoint-down(xl) {
      flex-direction: column;
      padding-bottom: 2rem;
    }

    .left-col {
      margin-right: 1rem;
      @include breakpoint-down(lg) {
        width: 100%;
      }
      .cards-row {
        align-items: flex-start;
      }
    }
    .right-col {
      .time-section {
        width: 100%;
      }
      @include breakpoint-down(xl) {
        align-items: flex-start;
        .date-section {
          margin-top: 1.5rem;
        }
      }
    }
  }
}
.cards-row {
  display: flex;
  width: 100%;
}
.sections-group {
  background-color: $white;
}
</style>
