<template>
  <div class="field-spacing" :class="{ 'white-bg': whiteBackground }">
    <div class="d-flex flex-column">
      <label
        v-if="label"
        class="mb-2 body-12 text-500"
        :class="errorMessage ? 'label-error' : 'label-valid'"
        :for="name"
      >
        {{ label }}
      </label>
      <Textarea
        v-model.trim="inputValue"
        class="text-area"
        :class="{
          'horizontal-resize': horizontalResize,
          'vertical-resize': verticalResize,
          'error-border': errorMessage,
        }"
        :name="name"
        :placeholder="placeholder"
        :label="label"
        :rows="rows"
        @blur="handleBlur"
      />
      <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import Textarea from 'primevue/textarea'

export default defineComponent({
  components: { Textarea },
  props: {
    rows: {
      type: String,
      default: '5',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    horizontalResize: {
      type: Boolean,
      default: true,
    },
    verticalResize: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleBlur } = useField(props.name)

    return {
      handleBlur,
      errorMessage,
      inputValue,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon-space {
  top: 30px;
}
.text-area {
  &:not(.horizontal-resize) {
    min-width: 100%;
    max-width: 100%;
  }
  &:not(.vertical-resize) {
    min-height: 100%;
    max-height: 100%;
  }
}
.error-border {
  border: $error-border;
}
</style>
