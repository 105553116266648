
import { TimeSlotID } from '@bd/api/common/types/time-slot'
import { ResponsiveContent } from '@bd/components'
import { useAppStore } from '@bd/store-modules'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventTimeSlotsPicker from './CalendarEventTimeSlotsPicker.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventTimeSlotsPicker,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const isCustomMeeting = computed(
      () =>
        store.state.calendarEvent?.eventSaveData.meetingType?.isCustom ?? false,
    )

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])

    const availableTimeSlots = computed(
      () => store.state.calendarEvent?.eventSaveMetaData.availableTimeSlots,
    )

    const onSelectedTimeSlotsChange = (timeSlotIds: TimeSlotID[]) => {
      store.dispatch('calendarEvent/setSelectedTimeSlots', timeSlotIds)
    }

    const disabledTimeSlotIds = computed(
      () =>
        store.state.calendarEvent?.eventSaveMetaData.disabledTimeSlots ?? [],
    )

    const selectedTimeSlotIds = computed(() =>
      (store.state.calendarEvent?.eventSaveData.timeSlotIds ?? []).filter(
        (timeSlot) => !disabledTimeSlotIds.value.includes(timeSlot),
      ),
    )

    return {
      t,
      timeSlots,
      availableTimeSlots,
      selectedTimeSlotIds,
      isCustomMeeting,
      disabledTimeSlotIds,
      onSelectedTimeSlotsChange,
    }
  },
})
