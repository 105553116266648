
import {
  BottomNavigation,
  NotificationsHost,
  OfflineScreen,
  TopNavigation,
  useBreakpoints,
  useNavigationBadges,
  usePurchaseOffers,
} from '@bd/components'
import { useUserData } from '@bd/components/use'
import { useOnline } from '@vueuse/core'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { buildNavigation } from '../config'
import { useAppStore } from '../store'
import OfferAppointment from './Appointment/OfferAppointment.vue'
import BuyerProposalHost from './BuyerProposalHost.vue'
import DashboardLoginModal from './Dashboard/DashboardLoginModal.vue'
import DashboardSearch from './Dashboard/DashboardSearch.vue'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'AppLayout',
  components: {
    TopNavigation,
    BottomNavigation,
    DashboardSearch,
    NotificationsHost,
    BuyerProposalHost,
    OfferAppointment,
    DashboardLoginModal,
    OfflineScreen,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const { isLogged } = useUserData()
    const { mobileSize } = useBreakpoints()

    const navigationItems = computed(() =>
      buildNavigation(store, router, mobileSize),
    )

    const showBottomNavigation = computed(() => {
      return store.state.bottomNavigationVisible
    })

    const isNotificationsRoute = computed(() => route.name === 'Notifications')
    const showAppointmentModal = computed({
      get: () => !!store.state.dialog.appointmentModal,
      set: (visible) => {
        if (!visible) {
          store.dispatch('dialogVisibility', {
            appointmentModal: false,
          })
        }
      },
    })
    const selectedOfferId = computed(() => {
      const modal = store.state.dialog.appointmentModal
      return modal && modal.offerId
    })

    const showLoginModal = computed({
      get: () => {
        return !!store.state.dialog.dashboardLoginModal
      },
      set: (visible) => {
        if (!visible) {
          store.dispatch('dialogVisibility', {
            dashboardLoginModal: false,
          })
        }
      },
    })

    const online = useOnline()

    useNavigationBadges()
    usePurchaseOffers()

    return {
      t,
      navigationItems,
      showBottomNavigation,
      isNotificationsRoute,
      isLogged,
      showAppointmentModal,
      selectedOfferId,
      showLoginModal,
      online,
      domainURL,
    }
  },
})
