<template>
  <div class="input-wrapper">
    <div class="input-field">
      <span v-if="searchIcon" class="p-input-icon-left">
        <i class="pi pi-search" />
      </span>
      <input
        class="body-sm"
        type="text"
        @input="onInput"
        :value="query"
        @click="onClick"
        @keydown.down="$emit('arrow-down')"
        @keydown.up="$emit('arrow-up')"
        @keydown.enter="$emit('enter')"
        :placeholder="placeholder || ''"
      />
      <span class="p-input-icon-right">
        <i>
          <img
            v-if="query.length"
            @click="$emit('input', '')"
            src="@bd/assets/icons/action-close.svg"
          />
        </i>
      </span>
    </div>
    <Button
      class="p-button-link close-popup-btn"
      v-if="showCloseButton"
      @click="computedIsOpen = false"
    >
      {{ t('cancel') }}
    </Button>
  </div>
</template>

<script lang="ts">
import { useVModel } from '@vueuse/core'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from 'primevue/button'

export default defineComponent({
  name: 'DashboardAutocompleteInput',
  emits: [
    'arrow-down',
    'arrow-up',
    'enter',
    'search-btn-action',
    'update:isOpen',
    'input',
  ],
  props: {
    query: { type: String, default: '' },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isOpen: Boolean,
  },
  components: { Button },
  setup(props, { emit }) {
    const i18n = useI18n()
    const computedIsOpen = useVModel(props, 'isOpen', emit)

    const onInput = (e: InputEvent) => {
      const input = (e.target as HTMLInputElement).value
      computedIsOpen.value = !!input
      emit('input', input)
    }
    const onClick = () => {
      if (props.query.length > 0) {
        computedIsOpen.value = true
      }
    }

    return {
      ...i18n,
      onClick,
      onInput,
      computedIsOpen,
    }
  },
})
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;

  .close-popup-btn.close-popup-btn.close-popup-btn {
    color: $primary-color;
    align-self: center;
    margin-left: 10px;
    font-weight: inherit;
  }
}

.input-field {
  display: grid;
  grid-template-columns: auto 1fr auto;

  .p-input-icon-right i {
    top: 42%;
  }

  .p-input-icon-left {
    @include breakpoint-up(md) {
      margin-right: 15px;
    }
  }

  input {
    background: none;
    caret-color: $primary-color;
    height: 50px;
    padding: 3px 3px 3px 15px;
    border-radius: 4px;
    border-style: none;
    width: 100%;

    &:focus {
      border-style: none;
      outline: none;
    }

    @include breakpoint-down(md) {
      background: $alabaster;
      padding: 3px 3px 3px 35px;
    }
  }
}
</style>
