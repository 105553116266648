<template>
  <div>
    <div class="more-details">
      <div v-for="detail in subset" :key="detail.key">
        <p class="caption">{{ detail.label }}</p>
        <p class="h-4">{{ detail.value }}</p>
        <hr />
      </div>
    </div>
    <p v-if="mobileSize" class="c-primary my-3" @click="showMore = !showMore">
      {{ t('show') }} {{ showMore ? t('less') : t('more') }}
      <span :class="`pi pi-chevron-${showMore ? 'up' : 'down'}`" />
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  convertCamelCaseToSnakeCase,
  getFormattedPrice,
  removeNullValuesFromObject,
} from '@bd/helpers'
import {
  SELECTED_DETAILS_IN_OFFER,
  LOCALISATION_SETTINGS,
  useBreakpoints,
  BUILDING_TYPES,
} from '@bd/components'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'
import { PropertyType } from '@bd/api'

export default defineComponent({
  name: 'DashboardOfferDetails',
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
  },
  setup(props) {
    const showMore = ref(false)
    const i18n = useI18n()
    const { mobileSize } = useBreakpoints()
    const { currency, locale } = LOCALISATION_SETTINGS

    const propertyType = computed(() => {
      return props.offerDetails?.propertyType
    })

    const translateProperty = (name: string) => {
      if (name === 'buildingType') {
        return propertyType.value === PropertyType.PLOT
          ? i18n.t('offerDetails.plot_type')
          : i18n.t('offerDetails.building_type')
      }
      const snake = convertCamelCaseToSnakeCase(name)
      return i18n.t(`offerDetails.${snake}`)
    }

    const translateValue = (value: unknown, name: string) => {
      if (name === 'rent' && typeof value === 'number') {
        return getFormattedPrice(+value, currency, locale)
      }

      if (name === 'buildingType' && propertyType.value) {
        const { translationKey } = BUILDING_TYPES[propertyType.value]
        return i18n.t(`enums.${translationKey}.${value}`)
      }

      switch (typeof value) {
        case 'string':
          return i18n.t(`enums.${name}.${value}`)
        case 'boolean':
          return value ? i18n.t('yes') : i18n.t('no')
        case 'number':
          return value
        default:
          return value
      }
    }

    const subset = computed(() => {
      const details = props.offerDetails

      if (!details) {
        return {}
      }
      const detailsWithoutNulls = removeNullValuesFromObject(details)

      const toShow = !mobileSize.value || showMore.value ? Infinity : 4
      return SELECTED_DETAILS_IN_OFFER.filter(
        (key) => key in detailsWithoutNulls,
      )
        .slice(0, toShow)
        .map((key) => {
          return {
            key,
            label: translateProperty(key),
            value: translateValue(detailsWithoutNulls[key], key),
          }
        })
    })

    return {
      ...i18n,
      mobileSize,
      showMore,
      subset,
    }
  },
})
</script>

<style lang="scss" scoped>
.more-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  hr {
    border: 0;
    border-top: 1px solid $athens-gray;
    opacity: 0.5;
    margin: 1rem 0;
  }
  div {
    margin: 10px 10px;
    width: 140px;
    height: 60px;
    @include breakpoint-up(md) {
      width: 30%;
    }
  }
}
</style>
