<template>
  <div
    class="meeting-type-grid-tile meeting-type-card event-save-card"
    :class="{ selected: meetingType.selected, disabled: meetingType.disabled }"
  >
    <div class="content">
      <h5 class="h-5">{{ meetingType.name }}</h5>
      <div class="duration-box">
        <h5 v-if="formattedDuration" class="h-5">{{ formattedDuration }}</h5>
        <Svg
          v-else
          class="custom-meeting-icon"
          :src="require('@bd/assets/icons/custom-meeting-clock.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarEventMeetingTypeItem, Svg } from '@bd/components'
import { formatDuration } from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    Svg,
  },
  props: {
    meetingType: {
      type: Object as PropType<CalendarEventMeetingTypeItem>,
      required: true,
    },
  },
  setup(props) {
    const formattedDuration = computed<string | null>(() => {
      const type = props.meetingType
      return type.agentDurationMinutes && !type.isCustom
        ? formatDuration(type.agentDurationMinutes)
        : null
    })

    return {
      formattedDuration,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../calendar';
@import '../event-save';

$card-size: 140px;

.meeting-type-card {
  @include uniform-size($card-size);
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  border-radius: 8px;
  &.selected {
    background-color: $port-gore;
    .content * {
      color: $white;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .content {
    padding: 1rem;
    @include uniform-size(100%);
    @include flex(
      $direction: column,
      $justify: space-between,
      $align: flex-start
    );
  }
}
</style>
