
import { ResponsiveContent } from '@bd/components'
import { defineComponent } from 'vue'
import CalendarEventDateChooser from './CalendarEventDateChooser.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventDateChooser,
  },
})
