
import { defineComponent, PropType } from 'vue'
import { UserAvatar } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { TransactionHistoryUserDto } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ProfileCardContractor',
  components: { UserAvatar },
  emits: ['remove-note'],
  props: {
    contractor: {
      type: Object as PropType<TransactionHistoryUserDto>,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
})
