const STORAGE_PREFIX: string = process.env.VUE_APP_STORAGE_PREFIX || null
const FINAL_PREFIX = STORAGE_PREFIX ? STORAGE_PREFIX + '-' : ''

export const getItem = (key: string) => {
  return localStorage.getItem(`${FINAL_PREFIX}${key}`)
}

export const setItem = (key: string, value: string) => {
  localStorage.setItem(`${FINAL_PREFIX}${key}`, value)
}

export const removeItem = (key: string) => {
  localStorage.removeItem(`${FINAL_PREFIX}${key}`)
}
