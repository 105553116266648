<template>
  <Dialog
    maximizable
    class="dialog-login"
    :class="{ 'p-dialog-maximized': mobileSize }"
    header="&nbsp;"
    :showHeader="true"
    :modal="true"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="text-center">
      <h1 class="header h-1">{{ t('path_login') }}</h1>
      <p class="body-bg">
        {{ t(`login_or_register.${reason}`) }}
      </p>
      <img
        class="image-wrapper"
        :src="require('@bd/assets/images/illustrations-calendar.svg')"
      />
    </div>
    <Button class="p-button-primary submit" @click="goToLogin">
      {{ t('login') }}
    </Button>
    <p class="mt-4 body-bg text-center">
      {{ t('create_account_3') }}
      <router-link
        class="p-link text-inherit-size text-decoration-underline"
        to="/registration"
      >
        {{ t('create_account_4') }}
      </router-link>
    </p>
  </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Dialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'
import { useBreakpoints } from '@bd/components'
import { useRouter } from 'vue-router'
import { useAppStore } from '@bd/client/store'
import { LoginModalDialogData } from '@bd/client/types'

export default defineComponent({
  components: { Dialog },
  props: {
    visible: Boolean,
  },
  emits: ['update:visible'],
  setup() {
    const { t } = useI18n()
    const { mobileSize } = useBreakpoints()
    const router = useRouter()
    const store = useAppStore()

    const reason = computed(() => {
      const data = store.state.dialog.dashboardLoginModal
      return (data as LoginModalDialogData).reason
    })

    const goToLogin = () => {
      store.dispatch('dialogVisibility', {
        dashboardLoginModal: false,
      })
      router.push({ name: 'Login' })
    }

    return {
      t,
      mobileSize,
      goToLogin,
      reason,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 30px;
}

.image-wrapper {
  padding: 60px 0;
}

.time-slot-picker,
.weekday-picker {
  margin-bottom: 60px;
}

:deep(.dialog-login) {
  width: min(100vw, 488px);
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0 77px 40px;
    @include breakpoint-down(xs) {
      padding: 0 25px 20px;
    }
  }
}

.submit {
  width: 100%;
  height: 50px;
  justify-content: center;
  font-size: 14px;
}
</style>
