import { User } from '@bd/store-modules/types/userProfile'

export const getDefaultUserObject = (): User => {
  return {
    avatarUrl: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    phoneNumber: '',
    roles: [],
    displayableId: ''
  }
}
