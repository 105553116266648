<template>
  <div>
    <InputNumber
      v-model="inputValue"
      :useGrouping="isUsedGroupping"
      @focus="isUsedGroupping = false"
      @blur="isUsedGroupping = true"
      class="proposal-number-input"
      :suffix="` ${currencyDecimal}`"
      :disabled="isInputDisabled"
      :min="0"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import InputNumber from 'primevue/inputnumber'
import { LOCALISATION_SETTINGS } from '../../../config'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ProposalModalNumberInput',
  components: { InputNumber },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: null,
      required: false,
    },
    isInputDisabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const isUsedGroupping = ref(true)
    const inputValue = useVModel(props, 'modelValue', emit)

    return {
      ...LOCALISATION_SETTINGS,
      inputValue,
      isUsedGroupping,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.proposal-number-input .p-inputnumber-input) {
  font-size: 2.25rem;
  text-align: center;
  font-weight: 600;
  width: 100%;
  background: white;
  padding: 0;
  border: none;
}

.price {
  color: $port-gore;
  font-weight: 600;
  font-size: 2.25rem;
}
</style>
