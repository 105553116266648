<template>
  <div
    class="meetings-calendar"
    :class="{ 'has-top-controls': showTopControls }"
  >
    <div class="header">
      <div class="d-flex d-md-none top-controls-row" v-if="showTopControls">
        <slot name="top-controls">
          <Button
            @click="editAvailability"
            class="p-button-secondary p-button-circle ml-auto px-0"
          >
            <Svg :src="require('@bd/assets/icons/edit.svg')" />
          </Button>
        </slot>
      </div>
      <div class="date-container">
        <div class="d-md-none">
          <CalendarDateTitle
            class="date-title text-center"
            :date="selectedDay"
          />
          <WeekdayPicker
            class="weekday-picker"
            mode="date"
            :selected="[selectedDay]"
            :highlightToday="true"
            :upcomingEventDates="upcomingEventDates"
            @update:selected="selectedDayUpdated"
          />
        </div>
        <div class="d-none d-md-block">
          <CalendarDateTitle :date="selectedDay" :showFullDate="true" />
          <div class="d-none d-md-flex justify-content-center">
            <slot name="title-append"></slot>
          </div>
        </div>
      </div>
    </div>
    <MeetingsTimeline
      v-if="timeSlots && timeSlots.length"
      :timeSlots="timeSlots"
      :todayCalendarEvents="todayCalendarEvents"
      :showNowIndicator="showNowIndicator"
      @eventTileClick="eventTileClicked"
    />
    <div class="flex-centered h-100" v-else>
      <ProgressSpinner />
    </div>
  </div>
</template>

<script lang="ts">
import { TimeSlot, UserCalendarEventDto } from '@bd/api'
import { LocalIsoDate } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import { defineComponent, PropType, computed } from 'vue'
import Svg from '../Svg/Svg.vue'
import WeekdayPicker from '../WeekdayPicker/WeekdayPicker.vue'
import CalendarDateTitle from './CalendarDateTitle.vue'
import MeetingsTimeline from './MeetingsTimeline.vue'
import { isSameDay } from 'date-fns'

export default defineComponent({
  components: {
    ProgressSpinner,
    WeekdayPicker,
    CalendarDateTitle,
    MeetingsTimeline,
    Svg,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    showTopControls: {
      type: Boolean,
      default: false,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: ['update:selectedDay', 'eventTileClick', 'availabilityEdit'],
  setup(props, { emit }) {
    const selectedDayUpdated = (value: LocalIsoDate[]) => {
      emit('update:selectedDay', value[0])
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      emit('eventTileClick', events)
    }

    const editAvailability = () => {
      emit('availabilityEdit')
    }

    const showNowIndicator = computed(() => {
      const selectedDate = new Date(`${props.selectedDay}`)
      return isSameDay(new Date(), selectedDate)
    })

    return {
      selectedDayUpdated,
      eventTileClicked,
      editAvailability,
      showNowIndicator,
    }
  },
})
</script>

<style lang="scss" scoped>
$top-controls-margin-top: 20px;
$date-label-margin-top: 16px;
$edit-icon-size: 40px;

.meetings-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  background-color: $white;
  @include breakpoint-down(md) {
    height: calc(100% - #{$mobile-navigation-height});
  }
}

.header {
  padding: 0 20px 15px 20px;
  @include breakpoint-up(md) {
    padding-bottom: 5vh;
  }
}

.top-controls-row {
  margin-top: $top-controls-margin-top;
}

.bottom-controls-row {
  margin-top: 1rem;
}

.date-title {
  margin: calc(
      #{$date-label-margin-top} + #{$top-controls-margin-top} + #{$edit-icon-size}
    )
    0 60px 0;
  .has-top-controls & {
    margin: $date-label-margin-top 0 60px 0;
  }
}

.date-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem 0;

  .day-label {
    font-size: 4.375rem;
    font-weight: normal;
    line-height: normal;
  }
  .month-year-label {
    color: $comet;
  }
}

.edit-icon {
  @include edit-icon($edit-icon-size);
}
</style>
