<template>
  <div class="calendar-event-meeting-type">
    <ResponsiveContent>
      <template #mobile>
        <div class="mobile">
          <CalendarEventMeetingTypeChooser />
        </div>
      </template>
      <template #desktop>
        <div class="desktop d-md-flex">
          <CalendarEventMeetingTypeChooser />
        </div>
      </template>
    </ResponsiveContent>
  </div>
</template>

<script lang="ts">
import { ResponsiveContent } from '@bd/components'
import { defineComponent } from 'vue'
import CalendarEventMeetingTypeChooser from './CalendarEventMeetingTypeChooser.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventMeetingTypeChooser,
  },
})
</script>

<style lang="scss" scoped></style>
