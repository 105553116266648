<template>
  <div class="calendar-event-meeting-types-list modal-list-container">
    <CalendarEventMeetingTypesListItem
      v-for="meetingType of meetingTypes"
      :key="meetingType.tileId"
      :item="meetingType"
      @click="$emit('selectMeetingType', meetingType)"
    />
  </div>
</template>

<script lang="ts">
import { CalendarEventMeetingTypeItem } from '@bd/components'
import { defineComponent, PropType } from 'vue'
import CalendarEventMeetingTypesListItem from './CalendarEventMeetingTypesListItem.vue'

export default defineComponent({
  components: {
    CalendarEventMeetingTypesListItem,
  },
  props: {
    meetingTypes: {
      type: Array as PropType<CalendarEventMeetingTypeItem[]>,
      required: true,
    },
  },
  emits: ['selectMeetingType'],
})
</script>

<style lang="scss" scoped>
@import '../../event-save';

.modal-list-container {
  padding: 1rem;
}
</style>
