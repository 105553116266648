export default {
  loading_results: 'Ładowanie wyników…',
  min_characters_needed: 'Minimalna ilość znaków: {n}',
  no_results: 'Brak wyników',
  search: 'Znajdź…',
  submit: 'Wyślij',
  loading: 'Ładowanie',
  validation: {
    required: 'Pole jest wymagane',
    invalid: 'Wpisany tekst jest nieprawidłowy',
    email: 'Adres e-mail jest nieprawidłowy',
    min: 'Pole ma za małą ilość znaków',
    max: 'Przekroczono dozwoloną ilość znaków',
    min_max_number: 'Nieprawidłowa liczba',
    wrong_login_input: 'Błędny format danych do logowania',
    wrong_password_input: 'Błędny format hasła',
    must_contain__min_9_characters: 'Pole musi zawierać minimum 9 cyfr',
    must_contain_number: 'Pole musi zawierać co najmniej 1 cyfrę',
    must_contain_capital_letter: 'Pole musi zawierać co najmniej 1 dużą literę',
    try_again: 'Spróbuj ponownie później',
    check_if_data_is_valid: 'Upewnij się że wprowadzone dane są poprawne',
    bad_data: 'Błędnie wprowadzone dane',
    password: 'Hasło musi się zgadzać',
    invalid_current_password: 'Nieprawidłowe hasło',
    user_not_found: 'Nie znaleziono użytkownika z podanym adresem e-mail',
    user_disabled_or_insufficient:
      'Uzytkownik jest nieaktywny lub nie ma niezbędnych uprawnień',
    user_bad_credentials: 'Podano błędne dane do logowania',
    user_account_locked_extended: `Twoje konto zostało zablokowane. Skontaktuj się z infolinią pod numerem {helpline}`,
    user_account_locked: 'Konto jest zablokowane',
    user_account_disabled: 'Konto jest wyłączone',
    user_account_not_activated: 'Konto jest nieaktywne',
    incorrect_price: 'Podana przez Ciebie kwota jest nieprawidłowa',
    offer_already_accepted: 'Twoja oferta została już zaakceptowana',
    offer_waiting_for_response: 'Twoja oferta czeka na odpowiedź',
    invalid_unique_phone: 'Ten numer telefonu jest już zajęty',
    invalid_unique_email: 'Ten adres e-mail jest już zajęty',
    invalid_value: 'Podano nieprawidłową wartość',
    invalid_phone_format: 'Format numeru telefonu jest nieprawidłowy',
    html_not_allowed: 'Tagi HTML nie są akceptowalne',
    invalid_number_range: 'Wartość nie mieści się w dozwolonym zakresie',
    invalid_text_length: 'Długość tekstu nie mieści się w dozwolonym zakresie',
    invalid_pattern: 'Wprowadzone dane nie pasują do wzoru',
    password_too_weak:
      'Hasło musi zawierać co najmniej 8 znaków, małą i dużą literę, cyfry i znaki specjalne',
    levels_cant_be_lower_than_floor:
      'Liczba pięter nie może być niższa niż piętro',
    failed: 'Operacja zakończona niepowodzeniem',
    cant_accept_own_offer: 'Nie możesz zaakceptować swojej własnej oferty',
    offer_not_found: 'Nie znaleziono takiej oferty',
    customer_not_found: 'Nie znaleziono takiego kontrahenta',
    purchase_offer_not_found: 'Nie znaleziono takiej transakcji',
    price_lower_than_latest:
      'Kwota powinna być większa od kontrpropozycji kupującego',
    price_higher_than_latest:
      'Kwota powinna być mniejsza od kontrpropozycji sprzedającego',
  },
  offer_errors: {
    city_not_found: 'Nie znaleziono miasta o takiej nazwie',
    offer_not_found: 'Oferta nie znaleziona',
    district_not_found: 'Nie znaleziono dzielnocy o takiej nazwie',
    seller_not_found: 'Nie znaleziono takiego sprzedawcy',
    seller_cant_have_more_offers:
      'Wybrany sprzedawca nie może posiadać więcej ofert',
    empty_land_area: 'Powierzchnia działki nie może być pusta',
    empty_house_area: 'Powierzchnia domu nie może być pusta',
    no_file_uploaded: 'Plik nie został przesłany',
    uploaded_file_is_not_image: 'Przesłany plik nie jest obrazkiem',
    photo_not_exist: 'Zdjęcie nie istnieje',
    wrong_building_type:
      'Typ budynku nie pasuje do wybranego typu nieruchomości',
    edit_seller_in_published_offer_not_allowed:
      'Oferta opublikowana. Nie można zmienić sprzedającego',
    photo_ids_not_unique: 'ID zdjęć powinny być unikalne',
    empty_property_type: 'Typ nieruchomości nie może być pusty',
    floor_greater_than_levels: 'Piętro nie może być wyższe niż liczba pięter',
  },
  navigation: {
    dashboard: 'Lista ofert',
    calendar: 'Kalendarz',
    profile: 'Mój profil',
  },
  userType: {
    agent: 'Agent',
    buyer: 'Kupujący',
    seller: 'Sprzedający',
    admin: 'Admin',
    callcenter: 'Call Center',
  },
  user: {
    id: 'ID',
  },

  price_from: 'Od',
  price_to: 'Do',
  house_area_from: 'Od',
  house_area_to: 'Do',
  land_area_from: 'Od',
  land_area_to: 'Do',
  floor_from: 'Od',
  floor_to: 'Do',
  rooms_from: 'Od',
  rooms_to: 'Do',
  construction_year_from: 'Od',
  construction_year_to: 'Do',
  construction_year: 'Rok budowy',

  sell_apartment: 'Chcesz sprzedać mieszkanie?',
  contact_helpline: 'Skontaktuj się z infolinią pod numerem',
  create_account: 'Załóż konto',
  create_account_1: 'Tworzenie konta',
  create_account_2:
    'Wprowadź poniższe dane, aby poprawnie założyć konto w Immobi.',
  create_account_3: 'Nie posiadasz jeszcze konta?',
  create_account_4: 'Załóż teraz',
  email: 'E-mail',
  email_or_phone: 'E-mail lub numer telefonu',
  phone: 'Numer telefonu',
  password: 'Hasło',
  password_repeat: 'Powtórz hasło',
  password_forgot: 'Zapomniałeś hasła?',
  login: 'Zaloguj',
  logout: 'Wyloguj się',
  path_registration: 'Rejestracja',
  path_login: 'Logowanie',
  path_reset: 'Resetuj hasło',
  logged_as: 'Jesteś zalogowany jako:',
  user_not_logged: 'Użytkownik niezalogowany',
  reset_password: 'Zresetuj hasło',
  reset_password_email:
    'Aby zresetować hasło, wprowadź adres e-mail podany przy rejestracji konta.',
  reset_email_question: 'Nie pamiętasz adresu e-mail?',
  reset_password_button:
    'Jeżeli jesteś pewien że chcesz zresetować swoje hasło, wciśnij poniższy przycisk.',
  email_sent: 'Wysłaliśmy Ci e-mail',
  email_instruction:
    'Na Twój adres e-mail został wysłany link resetujący hasło. Postępuj zgodnie z instrukcją, aby zresetować hasło.',
  back_to_login: 'Wróć do logowania',
  cancel: 'Anuluj',
  for: 'dla',
  location: 'Lokalizacja',
  property_description: 'Opis nieruchomości',
  more_details: 'Więcej szczegółów',
  show_details: 'Pokaż szczegóły',
  make_a_proposal: 'Zaproponuj cenę',
  yes: 'Tak',
  no: 'Nie',
  no_image_available: 'Zdjęcie niedostępne',
  save: 'Zapisz',
  remove: 'Usuń',
  add: 'Dodaj',
  edit: 'Edytuj',
  confirmation_header: 'Potwierdź wybór',
  change: 'Zmień',
  street_prefix: 'ul.',
  optional: 'Opcjonalnie',
  transaction_history: 'Historia transakcji',
  settings: 'Ustawienia',
  no_information: 'Brak informacji',
  terms_and_conditions: 'Regulamin',
  privacy_policy: 'Polityka prywatności',
  previous: 'Poprzednie',
  next: 'Następne',
  of: 'z',
  first_name: 'Imię',
  last_name: 'Nazwisko',
  consent_1: 'Przeczytałem i akceptuję',
  consent_2: 'Regulamin',
  consent_3: 'zgadzam się na przetwarzanie moich danych osobowych.',
  client: 'Klient',
  draft: 'DRAFT',

  register: {
    success: 'Konto zostało pomyślnie utworzone',
    error: 'Nie udało się utworzyć konta',
  },

  search_result: {
    found: 'Wyświetlono',
    from: 'z',
    results: 'wyników',
  },

  negotiation_details: 'Szczegóły negocjacji',
  collapse: 'Zwiń',
  acceptation: 'Akceptacja',
  negotiation: 'Negocjacja',
  transactions_empty_state: {
    title: 'Brak transakcji',
  },
  transaction_type: {
    buyer_to_seller: 'Kontrpropozycja kupującego',
    seller_to_buyer: 'Kontrpropozycja sprzedającego',
    buyer_to_seller_proposal: 'Propozycja kupującego',
    seller_to_buyer_proposal: 'Propozycja sprzedającego',
  },
  proposal: {
    first_and_last_name: 'Imię i nazwisko',
    buyer_first_and_last_name: 'Imię i nazwisko kupującego',
    seller_price_proposal: 'Propozycja złożona przez sprzedającego',
    buyer_price_proposal: 'Propozycja złożona przez kupującego',
    price_proposal_accept: 'Czy na pewno chcesz zaakceptować ofertę cenową?',
    buyer_make_a_proposal: 'Złóż',
    transaction_participants: 'Uczestnicy transakcji',
    accept_on_behalf_of_the_buyer: 'Zaakceptuj w imieniu kupującego',
    accept_on_behalf_of_the_seller: 'Zaakceptuj w imieniu sprzedającego',
    make_a_counterproposal: 'Złóż kontrpropozycję',
    make_a_counterproposal_on_behalf_of_the_seller:
      'Złóż kontrpropozycję w imieniu sprzedającego',
    make_a_counterproposal_on_behalf_of_the_buyer:
      'Złóż kontrpropozycję w imieniu kupującego',
    cancel_proposal: 'Anuluj',
    accept: 'Zaakceptuj',
    proposal_accepted: 'Propozycja została już zaakceptowana',
    proposal_approved: 'Propozycja została zaakceptowana',
    proposal_made: 'Twoja propozycja cenowa została wysłana.',
    counterproposal_made_success: 'Twoja propozycja cenowa została wysłana',
    counterproposal_made_error: 'Nie udało się złożyć kontrpropozycji cenowej',
    your_proposal: 'Twoja propozycja',
    price_proposal_question: 'Czy na pewno chcesz złożyć ofertę cenową?',
    your_proposed_price: 'Proponowana przez Ciebie cena',
    make_proposal: 'Złóż propozycję',
  },

  notificationType: {
    CALENDAR: 'Kalendarz',
    PURCHASE_OFFER: 'Propozycja cenowa',
  },

  notifications: {
    empty_state: {
      title: 'Brak powiadomień',
      description: 'Tutaj znajdziesz swoją historię powiadomień',
    },
    expired: {
      title: 'Wydarzenie wygasło',
    },
    mark_seen: {
      error: {
        title: 'Nie udało się przeczytać wiadomości',
      },
    },
  },

  calendar: {
    add_event: 'Dodaj spotkanie',
    events: {
      multiple_events: {
        title: 'Więcej spotkań',
        description: 'W wybranym slocie czasowym rozpoczyna się wiele spotkań.',
        hint: 'Wybierz wydarzenie aby przejść do szczegółów.',
      },
      save_event: {
        add_action: 'Utwórz spotkanie',
        add_title: 'Dodawanie wydarzenia',
        edit_title: 'Edytowanie wydarzenia',
        choose: 'Wybierz nieruchomość',
        offer: 'Nieruchomość',
        offer_address: 'Adres nieruchomości',
        offers: {
          mine: 'Przypisane do mnie',
          all: 'Wszystkie',
          search_placeholder: 'Znajdź nieruchomość…',
          empty_state: {
            no_offers_added: {
              title: 'Brak ofert',
              description: 'Tutaj pojawią się Twoje oferty, gdy zostaną dodane',
            },
            no_search_results: {
              title: 'Brak ofert',
              description:
                'Nie znaleziono ofert o podanych parametrach wyszukiwania',
            },
          },
        },
        meeting_type: {
          title: 'Cel',
          title_desktop: 'Cel spotkania',
          custom_name: 'Inne',
        },
        attendees: {
          title: 'Osoby',
          customer_required: 'Kupujący jest wymagany',
          empty_state: {
            title: 'Brak wyników',
            description:
              'Nie znaleziono klientów o podanych parametrach wyszukiwania',
          },
        },
        date: {
          title: 'Data spotkania',
        },
        time: {
          title: 'Godzina',
          no_available_slots: 'Brak dostępnych terminów',
          fill_data: 'Uzupełnij dane by wyświetlić dostępne terminy',
        },
        success: 'Wydarzenie zostało zapisane',
        error: 'Nie udało się zapisać wydarzenia',
        no_agent: {
          title: 'Agent nie został wybrany',
          description: 'Aby utworzyć wydarzenie musisz wybrać agenta',
        },
      },
    },
    title: 'Kalendarz',
    event_details: {
      page_title: 'Szczegóły wydarzenia',
      time_and_place: 'Czas i miejsce',
      attendees: 'Uczestnicy',
      accept: {
        title: 'Potwierdź',
        confirm_title: 'Czy na pewno chcesz potwierdzić to spotkanie?',
        confirm_description:
          'Jeśli w tym slocie czasowym widoczne są inne spotkania, to zostaną automatycznie odrzucone.',
        success: 'Wydarzenie zostało potwierdzone',
        error: 'Nie udało się potwierdzić spotkania',
      },
      cancel_full: 'Odwołaj wydarzenie',
      reject: {
        title: 'Odrzuć',
        confirm_title: 'Czy na pewno chcesz odrzucić to wydarzenie?',
        success: 'Wydarzenie zostało odrzucone',
        error: 'Nie udało się odrzucić wydarzenia',
      },
      edit: 'Edytuj',
      empty_state: {
        title: 'Nie znaleziono wydarzenia',
        back: 'Wróć do kalendarza',
      },
      cancel: {
        title: 'Odwołaj',
        confirm_title: 'Czy na pewno chcesz odwołać to wydarzenie?',
        success: 'Wydarzenie zostało odwołane',
        error: 'Nie udało się odwołać wydarzenia',
      },
    },
  },

  date_time: {
    firstDayOfWeek: '1',
    dayNames: {
      sunday: 'Niedziela',
      monday: 'Poniedziałek',
      tuesday: 'Wtorek',
      wednesday: 'Środa',
      thursday: 'Czwartek',
      friday: 'Piątek',
      saturday: 'Sobota',
    },
    dayNamesShort: {
      sunday: 'Niedz.',
      monday: 'Pon.',
      tuesday: 'Wt.',
      wednesday: 'Śr.',
      thursday: 'Czw.',
      friday: 'Pt.',
      susaturday: 'Sob.',
    },
    monthNames: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
    monthNamesShort: [
      'Sty.',
      'Lut.',
      'Mar.',
      'Kwi.',
      'Maj.',
      'Cze.',
      'Lip.',
      'Sie.',
      'Wrz.',
      'Paź.',
      'Lis.',
      'Gru.',
    ],
  },

  loading: 'Ładowanie',
  offers: {
    details: 'Szczegóły oferty',
    emptyState: {
      title: 'Brak ofert do wyświetlenia',
      description:
        'Nie znaleziono ogłoszeń spełniających podane kryteria wyszukiwania.',
    },
  },

  employees: {
    emptyState: {
      title: 'Brak wyników',
      description:
        'Nie znaleziono pracowników spełniających podane kryteria wyszukiwania.',
    },
  },

  clients: {
    emptyState: {
      title: 'Brak wyników',
      description:
        'Nie znaleziono klientów spełniających podane kryteria wyszukiwania.',
    },
    add: 'Dodaj klienta',
    call_seller: 'Zadzwoń do sprzedającego',
    search: 'Znajdź…',
    form: {
      wanted_apartment: 'Poszukiwane mieszkanie',
      area: 'Powierzchnia',
      price: 'Cena',
      market: 'Rynek',
    },
    note: 'Notatka',
    add_note: 'Dodaj notatkę',
    note_adding: 'Dodawanie notatki',
    note_add_messages: {
      successSave: 'Dodawanie notatki zakończone sukcesem',
      errorSave: 'Dodawanie notatki nie powiodło się',
    },
    note_remove_messages: {
      success: 'Usuwanie notatki zakończone sukcesem',
      error: 'Usuwanie notatki nie powiodło się',
    },
    note_placeholder: 'Wprowadź notatkę o kliencie',
    note_remove_confirmation: 'Czy jesteś pewien że chcesz usunąć notatkę?',
    notes_empty: 'Brak notatek',
  },

  price: 'Cena',
  area: 'Powierzchnia',
  city: 'Miasto',
  rooms_number: 'Liczba pokoi',
  plot_area: 'Powierzchnia działki',
  any: 'Dowolny',
  district: 'Dzielnica',
  apartment: 'Mieszkania',
  house: 'Domy',
  plot: 'Działki',
  office: 'Biura',
  rooms: 'Pokoje',
  floor: 'Piętro',
  show: 'Pokaż',
  more: 'więcej',
  less: 'mniej',
  make_an_appointment: 'Umów się na wizytę',
  submit_appointment: 'Umów wizytę',
  property_type: 'Rodzaj nieruchomości',

  offerDetails: {
    rent: 'Czynsz',
    basement: 'Piwnica / Komórka',
    elevator: 'Winda',
    balcony: 'Balkon',
    garden: 'Ogród',
    internet: 'Internet',
    security: 'Ochrona',
    parking_spot: 'Miejsce parkingowe',
    construction_year: 'Rok budowy',
    property_type: 'Rodzaj nieruchomości',
    building_material: 'Materiał budowy',
    building_type: 'Typ budynku',
    plot_type: 'Przeznaczenie działki',
    property_ownership: 'Forma własności',
    property_market: 'Rynek',
    windows_type: 'Typ okien',
    kitchen_type: 'Rodzaj kuchni',
    heating_type: 'Rodzaj ogrzewania',
    building_finish_state: 'Rodzaj wykończenia',
    apartment_levels: 'Liczba poziomów',
    no_title: 'Brak tytułu',
    draft_proposal_hint:
      'Nie można złożyć propozycji dla nieopublikowanej oferty',
    empty_state: {
      title: 'Nie znaleziono oferty',
      back: 'Wróć do listy ofert',
    },
  },

  version_update: {
    title: 'Dostępna jest nowa wersja aplikacji',
    description: 'Czy chcesz odświeżyć stronę, aby pobrać nową zawartość?',
  },

  offline: {
    title: 'Brak połączenia',
    description_1: 'Brak połączenia z internetem.',
    description_2:
      'Sprawdź ustawienia sieci i spróbuj ponownie załadować stronę.',
    refresh: 'Odśwież stronę',
  },

  enums: {
    propertyType: {
      APARTMENT: 'Mieszkanie',
      HOUSE: 'Dom',
      PLOT: 'Działka',
      OFFICE: 'Biuro',
    },
    buildingMaterial: {
      PRECAST_CONCRETE_SLABS: 'Wielka Płyta',
      BRICK: 'Cegła',
      YTONG: 'Ytong',
      H_FRAME: 'Rama H',
      CONCRETE: 'Beton',
      WOOD: 'Drewno',
      AIR_BRICK: 'Pustak',
      CERAMICS: 'Ceramika',
      OTHER: 'Inny',
      MONOLITH: 'Monolit',
      MIXED: 'Mieszany',
      BLOCKS: 'Bloczki',
      STEEL: 'Stal',
      GLASS: 'Szkło',
      AERATED: 'Gazobeton',
      SAND_LIME_BRICK: 'Silikat',
    },
    buildingType: {},
    apartmentBuildingType: {
      APARTMENT_OFFICE_BUILDING: 'Mieszkalno-biurowy',
      OFFICE_BUILDING: 'Budynek biurowy',
      HOUSE: 'Dom',
      TENEMENT_HOUSE: 'Kamienica',
      APARTMENT_RETAIL_BUILDING: 'Apartamentowo-handlowy',
      OFFICE_RETAIL_BUILDING: 'Biurowo-handlowy',
      APARTMENT_BUILDING: 'Budynek apartamentowy',
      STORAGE_AND_OFFICE_CENTER: 'Magazynowo-biurowy',
      SEAL: 'Plomba',
      LOW_BLOCK: 'Niski blok',
      HIGH_BLOCK: 'Wysoki blok',
      VILLA: 'Willa',
      LOFT: 'Loft',
      HOUSE_MULTI_PLACES: 'Dom wielolokalowy',
      APARTMENT_BLOCK: 'Blok',
      SECTION_HOUSE: 'Segment',
      SHOPPING_CENTER: 'Centrum handlowe',
      GARAGE: 'Garaż',
      PAVILION: 'Pawilon',
      HOTEL: 'Hotel',
      COURT: 'Dwór',
      PALACE: 'Pałac',
      PENSION: 'Pensjonat',
      HISTORICAL: 'Zabytkowy',
      OTHER: 'Inna lub Inny',
      RESTAURANT: 'Restauracja',
      COTTAGE: 'Chata',
      PART_OF_HOUSE: 'Część domu',
      WAVY_BLOCK: 'Falowiec',
      OUT_BUILDING: 'Oficyna',
      REPAIR_SHOP: 'Warsztat',
      HALL: 'Hala',
      SKY_SCRAPER: 'Wieżowiec',
      MOTEL: 'Motel',
      WAREHOUSE: 'Magazyn',
      MILL: 'Młyn',
      SHOP: 'Sklep',
      PETROL_STATION: 'Stacja benzynowa',
      GRANARY: 'Spichlerz',
      PUB: 'Pub',
      NEWSSTAND: 'Kiosk',
      SALOON: 'Salon',
      INN: 'Zajazd',
      CAFE: 'Kawiarnia',
      CABINET: 'Gabinet',
      DISTRIBUTION_CENTRE: 'Centrum dystrybucyjne',
      GRANGE: 'Folwark',
      AGRITURISMO: 'Gospodarstwo agroturystyczne',
      FARM: 'Gospodarstwo lub Gospodarstwo rolne',
      PRODUCTION_HALL: 'Hala produkcyjna',
      OFFICE_SPACE: 'Powierzchnia biurowa',
    },
    houseBuildingType: {
      TERRACED: 'Szeregowy',
      SEMI_DETACHED: 'Bliźniak',
      DETACHED: 'Wolnostojący',
      COUNTRY: 'Letniskowy',
      PERIOD: 'Stylowy',
      CENTER_SEGMENT: 'Segment środkowy',
      EXTERNAL_SEGMENT: 'Segment skrajny',
      HALF_OF_SEMI_DETACHED: 'Pół bliźniaka',
      TENEMENT: 'Kamienica',
      VILLA: 'Willa',
      CITY_VILLA: 'Willa miejska',
      RESIDENCE: 'Rezydencja',
      MANOR_HOUSE: 'Dworek',
      HOUSE_FLOOR: 'Piętro domu',
      HOLIDAY: 'Rekreacyjny',
      PALACE: 'Pałacyk',
      SEGMENT: 'Segment',
      OTHER: 'Inny',
      COTTAGE: 'Chata',
      PART_OF_HOUSE: 'Część domu',
    },
    plotType: {
      RESIDENTAL_BUILDING: 'Budowlana',
      COMMERCIAL: 'Komercyjna',
      RECREATIONAL: 'Rekreacyjna',
      FOREST: 'Leśna',
      HABITAT: 'Siedliskowa',
      AGRICULTURAL: 'Rolna',
      INVESTMENT: 'Inwestycyjna',
      INDUSTRIAL_LAND: 'Przemysłowa',
      OTHER: 'Inna',
      CRAFTS: 'Rzemieślnicza',
      SERVICES: 'Usługowa',
      AGRI_BUILDING: 'Rolno-budowlana',
      FARM: 'Gospodarstwo',
      FOREST_BUILDING: 'Leśna z prawem budowy',
    },
    officeBuildingType: {
      SERVICES: 'Handlowo/Usługowe',
      MEDICAL: 'Medyczne',
      RECREATION: 'Sportowo/Rekreacyjne,',
      CATERING: 'Gastronomiczne',
      OFFICE: 'Biurowe',
      OTHER: 'Inne',
    },
    propertyOwnership: {
      MORTGAGE: 'Własność',
      HOUSING_COOPERATIVE: 'Spółdzielcze własnościowe prawo',
      SHARED: 'Udział',
      OTHER: 'Inna',
      HOUSING_COOPERATIVE_KW: 'Spółdzielcze własnościowe prawo z KW',
      TENANT_COOPERATIVE: 'Spółdzielcze lokatorskie prawo',
    },
    propertyMarket: {
      SECONDARY: 'Wtórny',
      PRIMARY: 'Pierwotny',
    },
    windowsType: {
      ALUMINIUM: 'Aluminiowe',
      PCV: 'PCV',
      WOODEN_OLD_TYPE: 'Drewniane (stary typ)',
      WOODEN_NEW_TYPE: 'Drewniane (nowy typ)',
      STEEL: 'Stalowe',
      OLD: 'Stare',
      BURGLAR: 'Antywłamaniowe',
      SHADES: 'Z roletami',
      PORTCULLIS: 'Z kratami',
      ROOF: 'Połaciowe',
    },
    kitchenType: {
      WITH_WINDOW: 'Widna',
      WITHOUT_WINDOW: 'Ciemna',
      WITH_GAP: 'Prześwit',
      ADJOINING: 'Aneks',
      OPEN: 'Otwarta',
      SEPARATE: 'Oddzielna',
      OTHER: 'Inny',
      NO_KITCHEN: 'Brak',
      WITH_EQUIPMENT: 'Z wyposażeniem',
      BUILT: 'W zabudowie',
    },
    heatingType: {
      COAL: 'Węglowe',
      FIREPLACE: 'Kominkowe',
      CITY: 'CO miejskie',
      STOVE: 'Piec',
      GAS: 'Gazowe',
      OTHER: 'Inne',
      ENVIRONMENTAL: 'Ekologiczne',
      OWN: 'CO własne',
      OIL: 'Olejowe',
      ELECTRIC: 'Elektryczne',
      HEAT_PUMP: 'Pompa ciepła',
      HOT_AIR_BLOWER_USING_WATER: 'Nagrzewnice wodne',
      FLOOR: 'Podłogowe',
      HOT_AIR_BLOWER_USING_GAS: 'Nagrzewnice gazowe',
      INFRARED_HEATER: 'Promienniki podczerwieni',
      SOLID_FUEL: 'Paliwo stałe',
      BIOMASS: 'Biomasa',
    },
    buildingFinishState: {
      PERFECT: 'Idealny',
      VERY_GOOD: 'Bardzo dobry',
      GOOD: 'Dobry',
      NEEDS_SMALL_RENOVATION: 'Do drobnego remontu',
      NEEDS_TOTAL_RENOVATION: 'Do kapitalnego remontu',
      NEEDS_CONVERSION: 'Do adaptacji',
      NEEDS_FINISHING: 'Do wykończenia',
      DEVELOPMENT_STATE: 'Stan deweloperski',
      OPEN_RAW_STATE: 'Surowy otwarty',
      CLOSE_RAW_STATE: 'Surowy zamknięty',
      FOR_DEMOLITION: 'Do rozbiórki',
      ABANDONED: 'Opuszczony/zaniedbany',
      TO_RENOVATE: 'Do remontu',
      TO_REFRESH: 'Do odświeżenia',
      AFTER_RENOVATION: 'Po remoncie',
      RENOVATED: 'Odnowione',
      HIGH_STANDARD: 'Wysoki standard',
      WITHOUT_WHITE_MONTAGE: 'Bez białego montażu',
      TO_LIVE: 'Do zamieszkania',
      ABOVE_CONSTRUCTION: 'W budowie',
      WITH_TENANTS: 'Z lokatorami',
      NEGOTIATED: 'Do uzgodnienia',
      TO_RENEW: 'Do odnowienia',
    },
    apartmentLevels: {
      ONE_LEVEL: 'Jednopoziomowe',
      TWO_LEVEL: 'Dwupoziomowe',
      MULTILEVEL: 'Wielopoziomowe',
    },
    userRole: {
      ADMIN: 'Admin',
      AGENT: 'Agent',
      CALL_CENTER: 'Call Center',
      CUSTOMER: 'Kupujący',
      SELLER: 'Sprzedający',
    },
  },
}
