
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAddress } from '@itcraft-bestdeal/api'

export default defineComponent({
  components: {},
  props: {
    address: {
      type: Object as PropType<CalendarEventAddress>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
