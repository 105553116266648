import {
  ProvincesCitiesDistrictsDto,
  TransactionParams,
  CityWithProvinceDto,
  DistrictDto,
  ProvinceDto,
  BooleanDto,
  ResultPageDto,
} from '@bd/api'
import http from './http-client'
import {
  ApiResponse,
  LoginRequestDto,
  LoginResponseDto,
  PasswordResetRequestDto,
  SignupRequestDto,
  UserDto,
  UserEditDto,
} from './types'
import { TimeSlotDto } from './types/time-slot'
import {
  TransactionHistoryDetailsDto,
  TransactionList,
} from './types/transaction'
import {
  MeetingAttendeeDto,
  MeetingAttendeeSearchParams,
} from './types/calendar-event'
import {
  AgentPurchaseOfferDto,
  BuyerPurchaseOfferDto,
} from './types/purchase-offers'
import {
  TransactionType,
  TransactionDetailsPayload,
  TransactionHistoryByCustomerDto,
} from '@bd/api/common/types/transaction'

export interface PageableParams {
  pageIndex?: number
  pageSize?: number
}

export const login = (data: LoginRequestDto): ApiResponse<LoginResponseDto> => {
  return http.post('/login', data)
}
export const logout = (): ApiResponse<void> => {
  return http.post('/logout')
}
export const userSignup = (data: SignupRequestDto): ApiResponse<void> => {
  return http.post('/user/signup', data)
}
export const userProfile = (): ApiResponse<UserDto> => {
  return http.get('/user/profile')
}
export const updateUserProfile = (): ApiResponse<UserEditDto> => {
  return http.put('/user/profile')
}
export const transactionHistory = (): ApiResponse<TransactionList> => {
  return http.get('/transaction-history')
}

export const transactionHistoryCustomer = (
  params: TransactionParams,
): ApiResponse<TransactionList> => {
  return http.get('/transaction-history/customer', { params: { ...params } })
}
export const transactionHistoryDetail = (
  offerId: number,
): ApiResponse<TransactionHistoryDetailsDto> => {
  return http.get(`/transaction-history/${offerId}`)
}

export const transactionHistoryDetailAgent = (
  payload: TransactionDetailsPayload,
): ApiResponse<ResultPageDto<TransactionHistoryByCustomerDto>> => {
  return http.get(`/transaction-history/${payload.offerId}`, {
    params: { customerId: payload.customerId },
  })
}
export const passwordResetInit = (
  data: PasswordResetRequestDto,
): ApiResponse<void> => {
  return http.post('/password/reset/init', data)
}
export const getTimeSlots = (): ApiResponse<TimeSlotDto[]> => {
  return http.get('/time-slots')
}

export const citiesAndProvinces = (): ApiResponse<ProvincesCitiesDistrictsDto> => {
  return http.get('/provinces-with-cities-and-districts')
}
export const citiesAndProvincesUsed = (
  params: PageableParams,
): ApiResponse<ResultPageDto<CityWithProvinceDto>> => {
  return http.get('/city/used', { params })
}
export const districts = (cityId: number): ApiResponse<DistrictDto> => {
  return http.get(`/city/${cityId}/districts`)
}

export const provinces = (): ApiResponse<ProvinceDto[]> => {
  return http.get('/provinces')
}

export const getMeetingAttendeesByQuery = (
  params: MeetingAttendeeSearchParams,
): ApiResponse<ResultPageDto<MeetingAttendeeDto>> => {
  return http.get('/client-by-query', {
    params: {
      ...params,
    },
  })
}

export const purchaseOfferAgent = (payload: {
  offerId: number
  buyerId: number
}): ApiResponse<AgentPurchaseOfferDto> => {
  return http.get(`/purchase-offers/${payload.offerId}/${payload.buyerId}`)
}
export const purchaseOfferCustomer = (payload: {
  offerId: number
}): ApiResponse<BuyerPurchaseOfferDto> => {
  return http.get(`/purchase-offers/${payload.offerId}`)
}
export const purchaseOfferList = (
  offerId: number,
): ApiResponse<AgentPurchaseOfferDto[]> => {
  return http.get(`/purchase-offers/${offerId}`)
}
export const addPurchase = (payload: {
  offerId: number
  buyerId: number
  price: number
  type: TransactionType
}): ApiResponse<AgentPurchaseOfferDto> => {
  return http.post(`/purchase-offers/${payload.offerId}/${payload.buyerId}`, {
    price: payload.price,
    type: payload.type,
  })
}
export const addPurchaseBuyer = (payload: {
  offerId: number
  price: number
}): ApiResponse<void> => {
  return http.post(`/purchase-offers/${payload.offerId}`, {
    price: payload.price,
  })
}
export const acceptPurchase = (payload: {
  offerId: number
  buyerId: number
}): ApiResponse<void> => {
  return http.post(
    `/purchase-offers/accept/${payload.offerId}/${payload.buyerId}`,
  )
}
export const acceptPurchaseBuyer = (offerId: number): ApiResponse<void> => {
  return http.post(`/purchase-offers/accept/${offerId}`)
}
export const purchaseOfferAvailability = (
  offerId: number,
): ApiResponse<BooleanDto> => {
  return http.get(`/purchase-offers/${offerId}/available`)
}
