
import { TextInput, useRootData, UserType } from '@bd/components'
import { useIsFormValid } from 'vee-validate'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ForgotPasswordLink from './AuthForgotPasswordLink.vue'

export default defineComponent({
  components: { ForgotPasswordLink, TextInput },
  name: 'AuthFormLogin',
  props: {
    showPasswordResetLink: {
      type: Boolean,
      default: true,
    },
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const passwordFieldType = ref('password')
    const { isLoading } = useRootData()
    const eyeIcon = computed(() => {
      return `pi ${
        passwordFieldType.value === 'password' ? 'pi-eye-slash' : 'pi-eye'
      }`
    })

    const switchPasswordVisibility = () => {
      passwordFieldType.value === 'password'
        ? (passwordFieldType.value = 'text')
        : (passwordFieldType.value = 'password')
    }

    const isFormValid = useIsFormValid()

    const loginFieldLabel = computed(() =>
      props.userType === UserType.Buyer ? t('email_or_phone') : t('email'),
    )

    return {
      t,
      isLoading,
      loginFieldLabel,
      passwordFieldType,
      switchPasswordVisibility,
      eyeIcon,
      isFormValid,
    }
  },
})
