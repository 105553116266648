<template>
  <nav class="navigation-container d-md-none">
    <div class="row justify-content-between align-items-center no-gutters">
      <div class="col-3 text-left">
        <Button
          @click="navigate"
          icon="pi pi-chevron-left"
          class="p-button-text p-button-rounded"
        />
      </div>
      <div class="col-6 h-4 text-600 text-center">
        {{ routeText && t(routeText) }}
      </div>
      <div class="col-3"></div>
    </div>
  </nav>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { defineComponent, computed, PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NavigationEnabledRoute } from '../types'

export default defineComponent({
  name: 'MobileAuthNavigation',
  props: {
    enabledRoutes: {
      type: Array as PropType<NavigationEnabledRoute[]>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n()
    const route = useRoute()
    const router = useRouter()

    const currentRoute = computed(() => {
      const currentPath = route.path
      return props.enabledRoutes.find((route) => route.path === currentPath)
    })

    const routeText = computed(() => {
      const currentRouteObject = currentRoute.value
      return currentRouteObject?.text
    })

    const navigate = () => {
      const navigationStrategy =
        currentRoute.value?.navigationStrategy ?? 'Back'
      if (navigationStrategy === 'Back') {
        router.back()
      } else if (currentRoute.value?.goTo) {
        router.push({ path: currentRoute.value?.goTo })
      } else {
        router.push({ path: '/' })
      }
    }

    return { ...i18n, routeText, navigate }
  },
})
</script>

<style lang="scss" scoped>
.navigation-container {
  padding: 5px;
  width: 100%;
  z-index: 10;
  height: $mobile-header-height;
}
</style>
