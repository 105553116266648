<template>
  <div
    class="month-calendar"
    :class="[size, { 'no-date-header': !showDateHeader }]"
  >
    <Calendar
      :inline="true"
      :minDate="minDate"
      v-model="currentDate"
      @month-change="onMonthChange"
    >
      <template #date="{ date }">
        <div>
          <div v-if="displayDot(date)" class="dot"></div>
          {{ date.day }}
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script lang="ts">
import { getLocalIsoDate, LocalIsoDate } from '@bd/helpers'
import Calendar from 'primevue/calendar'
import { computed, defineComponent, PropType } from 'vue'
import { ComponentSize } from '@bd/helpers'
import { CalendarNavigationEvent } from './types'

export default defineComponent({
  components: {
    Calendar,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    size: {
      type: String as PropType<Exclude<ComponentSize, 'medium'>>,
      required: false,
      default: 'large',
    },
    showDateHeader: {
      type: Boolean,
      default: false,
    },
    navigatedDate: {
      type: String as PropType<LocalIsoDate>,
      required: false,
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: ['update:selectedDay', 'update:navigatedDate', 'month-change'],
  setup(props, { emit }) {
    // Map the model to suit both PrimeVue Calendar component and our custom components models
    const currentDate = computed<Date>({
      get: () => {
        return new Date(props.selectedDay)
      },
      set: (value: Date) => {
        emit('update:selectedDay', getLocalIsoDate(value))
      },
    })

    const onMonthChange = ($event: CalendarNavigationEvent) => {
      emit('month-change', $event)
      const paddedMonth = ($event.month + 1).toString().padStart(2, '0') // Month + 1 - offset due to first month being 0
      emit(
        'update:navigatedDate',
        getLocalIsoDate(new Date(`${$event.year}-${paddedMonth}-01`)),
      )
    }

    const minDate = computed(() => {
      return props.disablePastDates ? new Date() : undefined
    })

    const displayDot = (date: {
      day: number
      month: number
      year: number
      today: boolean
      selectable: boolean
    }) => {
      return (
        props.upcomingEventDates &&
        props.upcomingEventDates.includes(
          getLocalIsoDate(new Date(date.year, date.month, date.day)),
        )
      )
    }

    return {
      currentDate,
      minDate,
      onMonthChange,
      displayDot,
    }
  },
})
</script>

<style lang="scss" scoped>
.month-calendar {
  &.no-date-header {
    :deep(.p-datepicker .p-datepicker-header .p-datepicker-title) {
      display: none;
    }
  }
  &.large {
    :deep {
      .p-datepicker {
        table td {
          padding: 0.5rem 1.25rem;
          @include breakpoint-down(xxl) {
            padding: 0.5rem 0.75rem;
          }
          @include breakpoint-down(xl) {
            padding: 0.5rem 0.25rem;
          }
          > span {
            @include uniform-size(72px);
            border-radius: 28px;
            @include breakpoint-down(xxl) {
              @include uniform-size(64px);
              border-radius: 24px;
            }
            @include breakpoint-down(xl) {
              @include uniform-size(48px);
              border-radius: 18px;
            }
          }
        }
        table th > span {
          font-size: 0.9375rem;
        }
      }
      .p-datepicker-next,
      .p-datepicker-prev {
        @include uniform-size(64px);
        .pi {
          font-size: 1.25rem;
        }
        @include breakpoint-down(xl) {
          @include uniform-size(52px);
          .pi {
            font-size: 1rem;
          }
        }
      }
      .p-highlight {
        box-shadow: 0 23px 35px 0 rgba($heliotrope, 0.41);
      }
    }
  }
  &.small {
    :deep {
      .p-datepicker {
        background: $white;
        color: $comet;
        box-shadow: 0 5px 20px 0 rgb(23 25 51 / 1%),
          0 18px 9px 0 rgb(182 207 231 / 12%);
        border-radius: 14px;
        padding: 20px 40px;
        table {
          margin: 0;
        }
        table td {
          padding: 0.1rem 0.25rem;
        }
        table td > span {
          @include uniform-size(36px);
          border-radius: 14px;
          font-size: 0.75rem;
          font-weight: 500;
        }
        table th > span {
          font-size: 0.875rem;
        }
      }
      .p-datepicker-next,
      .p-datepicker-prev {
        background: $white;
        border: 1px solid $athens-gray;
        @include uniform-size(40px);
      }
    }
  }
  :deep {
    .p-datepicker {
      background: inherit;
      color: $port-gore;
      border: none;
      font-weight: normal;
      table th > span {
        font-weight: 500;
      }
      table td {
        padding: 0.25rem 0.5rem;
        span:focus {
          box-shadow: 0 0 0 0.2rem $primary-color;
        }
      }
      .p-datepicker-title {
        font-size: 1rem;
        color: $port-gore;
      }
      .p-datepicker-other-month {
        opacity: 0.3;
      }
      .p-datepicker-header {
        background: inherit;
        border-bottom: none;
      }
      .p-datepicker-today > span {
        background-color: transparent;
        border: 2px solid $primary-color;
        &.p-highlight {
          background-color: $primary-color;
          color: $white;
        }
      }
      .p-highlight {
        background-color: $primary-color;
        color: $white;
      }
      &:not(.p-disabled)
        table
        td
        span:not(.p-highlight):not(.p-disabled):hover {
        background-color: $clickable-hover-bg;
      }
      .p-datepicker-next,
      .p-datepicker-prev {
        &:enabled:hover {
          background-color: $clickable-hover-bg;
        }
        > span {
          color: $primary-color;
        }
      }
    }
  }
}
.dot {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 8px;
  height: 8px;
  background-color: $zest;
  border-radius: 50%;
  @include breakpoint-down(xl) {
    top: 5px;
    width: 5px;
    height: 5px;
  }
}
</style>
