import { createStore, Store, useStore } from 'vuex'
import { AppState } from '@bd/client/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

import {
  auth,
  staticData,
  userProfile,
  transactionHistory,
  notifications,
  calendar,
} from '@bd/store-modules'
import offers from './modules/offers'
import purchaseOffers from './modules/purchase-offers'
import { IS_DEV, vuexLogger } from '@bd/helpers'

export default createStore({
  strict: IS_DEV,
  actions,
  mutations,
  state,
  modules: {
    auth,
    offers,
    staticData,
    calendar,
    userProfile,
    transactionHistory,
    notifications,
    purchaseOffers,
  },
  plugins: [vuexLogger('vuex.client')],
})

export type AppStore = Store<AppState>
export const useAppStore = (): AppStore => useStore<AppState>()
