import { DATE_FORMAT, TIME_FORMAT, RELATIVE_TIME_FORMAT } from '@bd/components'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns'
import { computed, Ref } from 'vue'
import { useI18n } from 'vue-i18n'

/**
 * Returns a text-based distance between now and given date.
 * Uses Intl.RelativeTimeFormat which is only supported by newest browsers
 * so the function also provides a fallback which outputs a formatted date-time.
 * @param date First operand for the distance of two dates (second one is "now" date-time)
 */
export const useRelativeTimeDistance = (date: Ref<Date>) => {
  const { locale } = useI18n()

  // Fallback for when RelativeTimeFormat is unsupported
  if (!Intl.RelativeTimeFormat) {
    const formatter = new Intl.DateTimeFormat(locale.value, {
      ...DATE_FORMAT.standardDate,
      ...TIME_FORMAT.standardTime,
    })
    return computed(() => formatter.format(date.value))
  }

  const formatter = new Intl.RelativeTimeFormat(
    locale.value,
    RELATIVE_TIME_FORMAT.common,
  )
  return computed(() => {
    const now = new Date()

    const minutesDiff = differenceInMinutes(date.value, now)
    const absMinutesDiff = Math.abs(minutesDiff)

    if (absMinutesDiff >= 60 * 24 * 365) {
      return formatter.format(differenceInYears(date.value, now), 'years')
    }

    if (absMinutesDiff >= 60 * 24 * 30) {
      return formatter.format(differenceInMonths(date.value, now), 'months')
    }

    if (absMinutesDiff >= 60 * 24 * 7) {
      return formatter.format(differenceInWeeks(date.value, now), 'weeks')
    }

    if (absMinutesDiff >= 60 * 24) {
      return formatter.format(differenceInDays(date.value, now), 'days')
    }

    if (absMinutesDiff >= 60) {
      return formatter.format(differenceInHours(date.value, now), 'hours')
    }

    return formatter.format(minutesDiff, 'minutes')
  })
}
