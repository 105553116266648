
import { defineComponent, PropType, computed } from 'vue'
import ProfileCardDate from './ProfileCardDate.vue'
import { useI18n } from 'vue-i18n'
import { PurchaseOfferDto, TransactionType } from '@bd/api'
import { LOCALISATION_SETTINGS } from '@bd/client/config'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'ProfileCardDetail',
  components: { ProfileCardDate },
  props: {
    detail: {
      type: Object as PropType<PurchaseOfferDto>,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS
    const label = computed(() => {
      if (props.isFirst) {
        return props.detail.type === TransactionType.BUYER_TO_SELLER
          ? 'buyer_to_seller_proposal'
          : 'seller_to_buyer_proposal'
      }
      return props.detail.type.toLowerCase()
    })
    return { t, currency, locale, getFormattedPrice, label }
  },
})
