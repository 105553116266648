
import { defineComponent, computed, ref, PropType } from 'vue'
import Dialog from 'primevue/dialog'
import { useI18n } from 'vue-i18n'
import { FilterProp } from '@bd/client/types'
import DashboardAccordion from '../DashboardAccordion/DashboardAccordion.vue'
import DashboardAccordionItem from '../DashboardAccordion/DashboardAccordionItem.vue'
import { CarouselTiles, NumberRange, CarouselButtons } from '../FilterElements'
import { FiltersState, FilterData } from '@bd/store-modules/types'
import { CityWithProvinceDto, DistrictDto } from '@bd/api/common'

export default defineComponent({
  name: 'FiltersDialog',
  components: {
    Dialog,
    DashboardAccordion,
    DashboardAccordionItem,
    CarouselTiles,
    CarouselButtons,
    NumberRange,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array as PropType<FilterData[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
    districts: {
      type: Array as PropType<DistrictDto[]>,
      required: true,
    },
    filtersState: {
      type: Object as PropType<FiltersState>,
      required: true,
    },
  },
  emits: ['update:visible', 'filter-results', 'add-to-queue'],
  setup(props, { emit }) {
    const i18n = useI18n()

    const hasDistricts = computed(() => {
      return (
        Array.isArray(props.filtersState?.['cityIds']) &&
        props.filtersState?.['cityIds'].length === 1
      )
    })
    const isDialogVisible = computed({
      get: () => props.visible,
      set: (value: boolean) => {
        emit('update:visible', value)
      },
    })

    const openedFilter = ref<number | null>(null)
    const statusChange = (val: number | null) => {
      openedFilter.value = val
    }

    const filterResults = async () => {
      emit('filter-results')
    }
    const addToQueue = (queueObj: FilterProp) => {
      emit('add-to-queue', queueObj)
    }

    return {
      ...i18n,
      statusChange,
      openedFilter,
      isDialogVisible,
      addToQueue,
      filterResults,
      hasDistricts,
    }
  },
})
