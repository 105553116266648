import { LocalIsoDate } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { TimeSlotDto, TimeSlotID } from '@bd/api/common/types/time-slot'
import { ClientDto, UserRole, CityDto, DistrictDto } from '@bd/api'

export interface AgentOfferRowDto {
  buildingNumber?: string
  cityDto: CityDto
  districtDto: DistrictDto
  houseArea: number
  houseNumber?: string
  id: number
  price: number
  rooms: number
  sellerFirstName?: string
  sellerLastName?: string
  street: string
  img: string
  draft: boolean
}

export interface WeekdayAvailability {
  mondayAvailability: boolean
  tuesdayAvailability: boolean
  wednesdayAvailability: boolean
  thursdayAvailability: boolean
  fridayAvailability: boolean
  saturdayAvailability: boolean
  sundayAvailability: boolean
}
export interface AgentAvailabilityRuleDto extends WeekdayAvailability {
  agentAvailabilityRuleId: number
  timeSlotDto: TimeSlotDto
}

export interface SaveAgentAvailabilityRuleDto extends WeekdayAvailability {
  timeSlotId: TimeSlotID
}

export interface AgentAvailabilityExceptionDto {
  date: LocalIsoDate
  timeSlots: TimeSlotDto[]
  exists: boolean
}

export interface AddAgentAvailabilityExceptionDto {
  date: LocalIsoDate
  timeSlotIds: TimeSlotID[]
}

export enum StatisticsDataResolution {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface OfferStatisticsParams {
  startDate?: LocalIsoDate
  endDate?: LocalIsoDate
  offerId: OfferDetailsDto['id']
  statisticsDataResolution: StatisticsDataResolution
}

export interface OfferStatisticsDto {
  offerId: OfferDetailsDto['id']
  offerStatisticsInPeriods: OfferStatisticsInPeriod[]
  statisticsDataResolution: StatisticsDataResolution
}

export interface OfferStatisticsInPeriod {
  startDate: LocalIsoDate
  endDate: LocalIsoDate
  numberOfInquiries: number
  numberOfPeriod: number
  numberOfViews: number
  year: number
}

export interface OfferStatisticsSummaryDto {
  offerId: OfferDetailsDto['id']
  statisticsDataResolution: StatisticsDataResolution
  numberOfInquiries: number
  numberOfViews: number
  similarInquiries: number
  similarViews: number
  totalInquiries: number
  totalViews: number
  week: number
  year: number
}

export interface QuickSearchRowDto {
  client: QuickSearchClientDto
  offers?: QuickSearchOfferDto[]
}

export interface QuickSearchClientDto {
  id: ClientDto['id']
  description: string
  displayableId: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  roles: UserRole[]
}

export interface QuickSearchOfferDto {
  id: OfferDetailsDto['id']
  description: string
  displayableId: string
  title: string
}
