import { TransactionType } from '@bd/api/common/types/transaction'
import { ToastAddParams } from '@bd/components/utils'
import { DictOf } from '@bd/helpers'

export type TimeFormatOptions = Pick<
  Intl.DateTimeFormatOptions,
  'hour' | 'minute' | 'second'
>
export type DateFormatOptions = Pick<
  Intl.DateTimeFormatOptions,
  'day' | 'month' | 'year'
>
export type RelativeTimeFormatOptions = Intl.RelativeTimeFormatOptions

export type MonthFormatOptions = Pick<Intl.DateTimeFormatOptions, 'month'>

export const DateFormatConfig = DictOf<DateFormatOptions>()
export const TimeFormatConfig = DictOf<TimeFormatOptions>()

export interface RelativeTimeFormatConfig {
  common: RelativeTimeFormatOptions
}

export interface CustomScrollEvent extends Event {
  target: HTMLElement
}

export interface ProposalUserDto {
  id: number
  firstName: string
  lastName: string
  phoneNumber: string
}

export interface ProposalDto {
  buyer: ProposalUserDto
  seller: ProposalUserDto
  offerId: number
  price: number
  type: TransactionType
}

export interface DropdownItem<T> {
  label: string
  value: T
}

export const Toasts = DictOf<ToastAddParams>()
