import { computed, Ref } from 'vue'
import { LocalIsoDate } from '@bd/helpers'
import { useI18n } from 'vue-i18n'
import { DATE_FORMAT } from '@bd/components'

export const useFormattedDayAndMonth = (date: Ref<LocalIsoDate>) => {
  const dateObj = new Date(date.value)
  const { locale } = useI18n()
  const dateFormat = DATE_FORMAT.transaction

  return computed(() => {
    return {
      day: dateObj.getDate(),
      month: dateObj.toLocaleDateString(locale.value, {
        month: dateFormat.month,
      }),
    }
  })
}
