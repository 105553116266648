<template>
  <component
    :is="is"
    class="notifications-list-item"
    :class="{ seen: item.seen }"
  >
    <div class="wrapper">
      <div class="initial-container">
        <NotificationInitial :notificationType="item.notificationType" />
      </div>
      <div class="notification-details-container">
        <div class="notification-details">
          <span class="title">{{ item.title }}</span>
          <span class="body">{{ item.body }}</span>
          <span class="relative-time">{{ relativeTime }}</span>
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import { useRelativeTimeDistance } from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'
import NotificationInitial from './NotificationInitial.vue'

export default defineComponent({
  components: {
    NotificationInitial,
  },
  props: {
    is: {
      type: String,
      default: 'li',
    },
    item: {
      type: Object as PropType<SystemNotificationDto>,
      required: true,
    },
  },
  setup(props) {
    const createdAt = computed(() => new Date(props.item.createdAt))
    const relativeTime = useRelativeTimeDistance(createdAt)

    return {
      relativeTime,
    }
  },
})
</script>

<style lang="scss" scoped>
.notifications-list-item {
  display: flex;
  cursor: pointer;
  background-color: $selago;

  &.seen {
    background-color: $white;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 1.5rem;
  }
  .initial-container {
    margin-right: 1rem;
  }
  .notification-details-container {
    @include uniform-size(100%);
    padding: 2rem 0;
    .notification-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
  &:not(:last-of-type) .notification-details-container {
    border-bottom: $divider-border;
  }
}
.title,
.body {
  font-size: 0.875rem;
}
.title {
  color: $comet;
  line-height: normal;
  margin-bottom: 0.25rem;
}
.body {
  color: $port-gore;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.25rem;
}
.relative-time {
  font-size: 0.75rem;
  color: $manatee;
}
</style>
