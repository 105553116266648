import { emptyLoadableResource } from '@bd/helpers'
import { CommonCalendarState } from '@bd/store-modules/types/calendar'

export const state: CommonCalendarState = {
  todayEvents: [],
  eventDetails: emptyLoadableResource(),
  selectedDate: undefined,
  selectedAgentId: undefined,
  todayEventsCount: 0,
  upcomingEventDates: []
}
