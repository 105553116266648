import {
  getSystemNotifications,
  getUnreadNotificationsCount,
  markNotificationAsSeen,
} from '@bd/api/common/notifications-api'
import { PageableParams } from '@bd/api'
import { CommonAppState, NotificationsState } from '@bd/store-modules/types'
import { ActionTree } from 'vuex'
import {
  allNotificationsLoaded,
  getNextPageIndex,
  isLoadingChunk,
} from './getters-helpers'
import { logErr } from '@bd/helpers'
import { updateNotificationsAfterSeen } from './utils'
import { isUserLogged } from '../auth/getters-helpers'

export const actions: ActionTree<NotificationsState, CommonAppState> = {
  async getSystemNotifications({ state, commit }, pageSize: number) {
    if (isLoadingChunk(state) || allNotificationsLoaded(state)) {
      return
    }

    const params: PageableParams = {
      pageSize: pageSize,
      pageIndex: getNextPageIndex(state),
    }

    commit('SET_NOTIFICATIONS_LOADING', true)
    try {
      const { data } = await getSystemNotifications(params)
      commit('ADD_NOTIFICATIONS_CHUNK', data)
    } finally {
      commit('SET_NOTIFICATIONS_LOADING', false)
    }
  },

  async markNotificationAsSeen(ctx, notificationId: number) {
    try {
      await markNotificationAsSeen(notificationId)
      updateNotificationsAfterSeen(ctx, notificationId)
    } catch (err) {
      logErr(err)
      throw err
    }
  },

  clearNotifications({ commit }) {
    commit('CLEAR_NOTIFICATIONS')
  },

  setNotificationsVisible({ commit }, visible: boolean) {
    commit('SET_NOTIFICATIONS_VISIBLE', visible)
  },

  async getUnreadNotificationsCount({ rootState, commit }) {
    if (!isUserLogged(rootState)) {
      return
    }
    try {
      const { data: { value } } = await getUnreadNotificationsCount()
      commit('SET_UNREAD_NOTIFICATIONS_COUNT', value)
    } catch (e) {
      logErr(e)
      throw e
    }
  }
}
