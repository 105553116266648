
import { CalendarEventStatus, TimeSlot, UserCalendarEventDto } from '@bd/api'
import { minutes } from '@bd/api/common/types/time-slot'
import { parseLocalIsoTime, stringifyLocalTime } from '@bd/helpers'
import { useIntervalFn } from '@vueuse/core'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { getCurrentTimeStep, useTimeSteps } from '../use'
import CalendarEventTile from './CalendarEventTile.vue'
import { TemplateRef } from '@bd/helpers'

const NOW_INDICATOR_UPDATE_TICK = 60 * 1000

interface TimeSlotEntry extends TimeSlot.TimeSlotDto {
  timeLabel: string
  durationMinutes: number
  events: UserCalendarEventDto[]
}

export default defineComponent({
  components: {
    CalendarEventTile,
  },
  props: {
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
    todayCalendarEvents: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    showNowIndicator: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['eventTileClick'],
  setup(props, { emit }) {
    const meetingsTimelineRef = ref<TemplateRef>(null)
    const nowIndicatorRef = ref<TemplateRef>(null)
    const nowIndicatorPosTop = ref<number | null>(
      nowIndicatorRef.value?.clientTop ?? null,
    )

    const totalTimelineMinutes = computed(
      () =>
        minutes(props.timeSlots[props.timeSlots.length - 1].endTime) -
        minutes(props.timeSlots[0].startTime),
    )

    const firstSlotStartTime = computed(() =>
      parseLocalIsoTime(props.timeSlots[0].startTime),
    )
    const timeSteps = useTimeSteps(
      firstSlotStartTime.value,
      totalTimelineMinutes.value,
    )

    const findTimeSlotEvents = (slot: TimeSlot.TimeSlotDto) => {
      return props.todayCalendarEvents.filter(
        (event: UserCalendarEventDto) =>
          event.calendarEventDto.startTime === slot.startTime,
      )
    }

    const timeSlotEntries = computed<TimeSlotEntry[]>(() => {
      return props.timeSlots.map((slot) => ({
        timeSlotId: slot.timeSlotId,
        startTime: slot.startTime,
        endTime: slot.endTime,
        timeLabel: stringifyLocalTime(parseLocalIsoTime(slot.startTime)),
        durationMinutes: minutes(slot.endTime) - minutes(slot.startTime),
        events: findTimeSlotEvents(slot),
      }))
    })

    const updateNowIndicator = () => {
      const currentTimeStep = getCurrentTimeStep(timeSteps.value)
      if (currentTimeStep) {
        nowIndicatorPosTop.value = currentTimeStep
      }
    }

    const eventTileClicked = (events: UserCalendarEventDto[]) => {
      emit('eventTileClick', events)
    }

    const firstEventId = computed(() => {
      return timeSlotEntries.value.find((x) => !!x.events.length)?.timeSlotId
    })

    onMounted(() => {
      updateNowIndicator()
      useIntervalFn(updateNowIndicator, NOW_INDICATOR_UPDATE_TICK)
    })

    return {
      timeSlotEntries,
      nowIndicatorRef,
      nowIndicatorPosTop,
      meetingsTimelineRef,
      eventTileClicked,
      CalendarEventStatus,
      firstEventId,
    }
  },
})
