import { keysToValArray } from '@bd/helpers'
import { PrimeVueConfiguration } from 'primevue/config'

type PrimeVueConfig = { config: PrimeVueConfiguration }
export type I18nValue = { [key: string]: I18nValue & string }

const getDateTimeTranslations = (i18nMessages: I18nValue, locale: string) => {
  return i18nMessages[locale].date_time
}

/**
 * Setup PrimeVue global translation (used mainly to localise Calendar month & weekday names)
 * Key takeaways:
 * 1. PrimeVue's Calendar component can only use one set of e.g. weekday translations per one PrimeVue instance.
 * 2. usePrimeVue() seems to have wrong typing - therefore a makeup type is needed
 * 3. Custom translations for PrimeVue come from the common translations
 *
 * Reference: {@link https://primefaces.org/primevue/showcase/#/locale}
 */
export const translatePrimeVue = (
  i18nMessages: I18nValue,
  locale: string,
  primeVue: PrimeVueConfiguration,
) => {
  const { config } = primeVue as PrimeVueConfig // Workaround because `usePrimeVue()` (which the `primeVue` argument should be sourced from) typing doesn't seem to match the reality
  const dateTimeTranslations = getDateTimeTranslations(i18nMessages, locale)
  const { dayNamesShort, monthNames } = dateTimeTranslations
  const resultDayNames = keysToValArray(dayNamesShort)
  const resultMonthNames = keysToValArray(monthNames)

  if (config.locale) {
    config.locale.dayNamesMin = resultDayNames
    config.locale.monthNames = resultMonthNames
    config.locale.firstDayOfWeek = +dateTimeTranslations.firstDayOfWeek
  }
}
