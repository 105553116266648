import { BaseAddress, ClientDto } from '@bd/api/common'
import { ID, LocalIsoDate, LocalIsoTime } from '@bd/helpers'

export enum MeetingOrganizer {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  CALL_CENTER = 'CALL_CENTER',
}

export enum CalendarEventStatus {
  WAITING_FOR_AGENT_ACCEPTANCE = 'WAITING_FOR_AGENT_ACCEPTANCE',
  WAITING_FOR_SELLER_ACCEPTANCE = 'WAITING_FOR_SELLER_ACCEPTANCE',
  ACCEPTED = 'ACCEPTED',
  REJECTED_BY_SELLER = 'REJECTED_BY_SELLER',
  REJECTED_BY_AGENT = 'REJECTED_BY_AGENT',
  CANCELLED_BY_SELLER = 'CANCELLED_BY_SELLER',
  CANCELLED_BY_AGENT = 'CANCELLED_BY_AGENT',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CREATED_BY_AGENT = 'CREATED_BY_AGENT',
  CREATED_BY_ADMIN = 'CREATED_BY_ADMIN',
  CREATED_BY_CALL_CENTER = 'CREATED_BY_CALL_CENTER',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  CANCELLED_BY_CALL_CENTER = 'CANCELLED_BY_CALL_CENTER',
}

export interface MeetingTypeDto {
  meetingTypeId: ID<'MeetingType'>
  meetingTypeName: string
  agentDurationMinutes: number
  clientDurationMinutes: number
  meetingOrganizers: MeetingOrganizer[]
  predefinedMeetingType: boolean
  customerPresenceRequired: boolean
}

export interface CalendarEventDateTime {
  startTime: LocalIsoDate
  agentEndTime: LocalIsoTime
  clientEndTime: LocalIsoTime
  date: LocalIsoDate
}

export type CalendarEventAddress = BaseAddress
export interface CalendarEventOfferDetailsDto extends CalendarEventAddress {
  id: ID<'CalendarEventOffer'>
  latitude?: number
  longitude?: number
}

export interface CalendarEventDto extends CalendarEventDateTime {
  calendarEventOfferDto: CalendarEventOfferDetailsDto
  calendarEventId: ID<'CalendarEvent'>
  calendarEventStatus: CalendarEventStatus
  meetingTypeDto: MeetingTypeDto
}

export interface EventDetailsActionFlags {
  rejectionPossibility?: boolean
  acceptancePossibility?: boolean
  editionPossibility?: boolean
  cancelationPossibility?: boolean
}

export interface CalendarEventDetailsDto
  extends CalendarEventDto,
    EventDetailsActionFlags {
  seller?: Partial<ClientDto>
  customer?: Partial<ClientDto>
  agent?: Partial<ClientDto>
}

export type UserCalendarEventID = ID<'UserCalendarEvent'>

export interface UserCalendarEventDto {
  userCalendarEventId: UserCalendarEventID
  calendarEventDto: CalendarEventDto
}

export interface UserCalendarEventDetailsDto {
  userCalendarEventId: UserCalendarEventID
  calendarEventDto: CalendarEventDetailsDto
}

export interface CommonUserCalendarEventsParams {
  date: LocalIsoDate
}

export interface AgentUserCalendarEventsParams
  extends CommonUserCalendarEventsParams {
  allStatuses?: boolean
}
export interface AdminUserCalendarEventsParams
  extends CommonUserCalendarEventsParams {
  agentId: number
}

export interface ResultListDtoOfLocalDate {
  content: LocalIsoDate[]
}
