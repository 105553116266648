import { UserCalendarEventDto } from './calendar';
import { LocalIsoDate } from '@bd/helpers';

export enum NotificationType {
  CALENDAR = 'CALENDAR',
  PURCHASE_OFFER = 'PURCHASE_OFFER',
  OFFER_STATISTIC = 'OFFER_STATISTIC',
  OFFER = 'OFFER'
}

export enum PurchaseOfferType {
  BUYER_TO_SELLER, SELLER_TO_BUYER
}
export interface SystemNotificationPurchaseOfferDto {
  buyerId: number
  offerId: number
  price: number
  type: PurchaseOfferType
}
export interface SystemNotificationDto {
  body: string
  createdAt: LocalIsoDate
  id: number
  notificationType: NotificationType
  seen: boolean
  title: string
  userCalendarEventDto: UserCalendarEventDto | null
  navigationEnabled: boolean
  purchaseOfferDto: SystemNotificationPurchaseOfferDto
}

