
import { useOfflineImg } from '@bd/components'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  setup() {
    const { t } = useI18n()

    const refresh = () => {
      window.location.reload()
    }

    const offlineImg = useOfflineImg()

    return {
      t,
      refresh,
      offlineImg,
    }
  },
})
