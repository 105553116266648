<template>
  <div
    class="calendar-event-attendee-details"
    :class="{ column: isAttendeeChosen }"
  >
    <span
      class="section-label"
      :class="[sectionLabelClass, { 'mb-1': isAttendeeChosen }]"
    >
      {{ sectionLabel }}
    </span>
    <span class="text-wrap-2" v-if="isAttendeeChosen">
      <span class="name">
        {{ nameAndLastName }}
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { CalendarEventAttendee } from '@bd/store-modules/types'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { EventAttendeeAvailableTypes } from './types'
import { useNameAndLastName } from '@bd/components'

export default defineComponent({
  components: {},
  props: {
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    attendee: {
      type: Object as PropType<CalendarEventAttendee>,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const isAttendeeChosen = computed(() => !!props.attendee)
    const nameAndLastName = useNameAndLastName(
      props.attendee?.firstName,
      props.attendee?.lastName,
    )
    const sectionLabel = computed(() => {
      const lowerCaseUserType = props.userType.toLowerCase()
      return t(`userType.${lowerCaseUserType}`)
    })

    const sectionLabelClass = computed(() => ({
      'section-label-sm': isAttendeeChosen.value,
      'text-500': !isAttendeeChosen.value,
    }))

    return {
      t,
      isAttendeeChosen,
      sectionLabel,
      sectionLabelClass,
      nameAndLastName,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';
.calendar-event-attendee-details {
  @include flex($justify: flex-start);

  &.mobile,
  &.column {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
