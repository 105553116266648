<template>
  <div class="wrapper">
    <div
      class="item"
      v-for="item in items"
      :key="item.id"
      @click="$emit('select-client', item)"
    >
      <div
        class="text-truncate"
        :class="{ 'selected-item': selectedClientId === item.id }"
      >
        {{ item.firstName }} {{ item.lastName }}
      </div>
      <div class="link-dark">
        <img src="@bd/assets/icons/phone.svg" />
        {{ item.phoneNumber }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarEventAttendeeItem } from '@bd/components'

export default defineComponent({
  name: 'ProposalClientList',
  emits: ['select-client'],
  props: {
    items: {
      type: Array as PropType<CalendarEventAttendeeItem[]>,
      required: true,
    },
    selectedClientId: {
      type: Number as PropType<number | null>,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
})
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}
.item {
  padding: 1rem;
  border-bottom: $divider-border;
  border-radius: 6px;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
  }
  @include flex(space-between, center);
  @include breakpoint-down(md) {
    height: 5rem;
    padding: 1rem 0;
    @include flex(space-between, flex-start, column);
  }
}
.selected-item {
  color: $primary-color;
}
</style>
