
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import Dropdown from 'primevue/dropdown'

export default defineComponent({
  components: { Dropdown },
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(props.name)

    return {
      errorMessage,
      inputValue,
    }
  },
})
