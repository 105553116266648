
import { getLocalIsoDate, LocalIsoDate } from '@bd/helpers'
import Calendar from 'primevue/calendar'
import { computed, defineComponent, PropType } from 'vue'
import { ComponentSize } from '@bd/helpers'
import { CalendarNavigationEvent } from './types'

export default defineComponent({
  components: {
    Calendar,
  },
  props: {
    selectedDay: {
      type: String as PropType<LocalIsoDate>,
      required: true,
    },
    size: {
      type: String as PropType<Exclude<ComponentSize, 'medium'>>,
      required: false,
      default: 'large',
    },
    showDateHeader: {
      type: Boolean,
      default: false,
    },
    navigatedDate: {
      type: String as PropType<LocalIsoDate>,
      required: false,
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
    upcomingEventDates: {
      type: Array as PropType<LocalIsoDate[]>,
      required: false,
    },
  },
  emits: ['update:selectedDay', 'update:navigatedDate', 'month-change'],
  setup(props, { emit }) {
    // Map the model to suit both PrimeVue Calendar component and our custom components models
    const currentDate = computed<Date>({
      get: () => {
        return new Date(props.selectedDay)
      },
      set: (value: Date) => {
        emit('update:selectedDay', getLocalIsoDate(value))
      },
    })

    const onMonthChange = ($event: CalendarNavigationEvent) => {
      emit('month-change', $event)
      const paddedMonth = ($event.month + 1).toString().padStart(2, '0') // Month + 1 - offset due to first month being 0
      emit(
        'update:navigatedDate',
        getLocalIsoDate(new Date(`${$event.year}-${paddedMonth}-01`)),
      )
    }

    const minDate = computed(() => {
      return props.disablePastDates ? new Date() : undefined
    })

    const displayDot = (date: {
      day: number
      month: number
      year: number
      today: boolean
      selectable: boolean
    }) => {
      return (
        props.upcomingEventDates &&
        props.upcomingEventDates.includes(
          getLocalIsoDate(new Date(date.year, date.month, date.day)),
        )
      )
    }

    return {
      currentDate,
      minDate,
      onMonthChange,
      displayDot,
    }
  },
})
