
import { TextInput } from '@bd/components'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'
import { useIsFormValid } from 'vee-validate'
import Checkbox from 'primevue/checkbox'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  components: { TextInput, Checkbox },
  name: 'AuthFormRegister',
  setup() {
    const i18n = useI18n()
    const passwordFieldType = ref('password')
    const { isLoading } = useRootData()
    const isFormValid = useIsFormValid()

    const consent = ref(false)

    return {
      ...i18n,
      consent,
      isLoading,
      passwordFieldType,
      isFormValid,
      domainURL,
    }
  },
})
