
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return { ...useI18n() }
  },
})
