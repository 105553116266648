<template>
  <div class="list">
    <CounterProposalListItem userType="Buyer" :participant="listData.buyer" />
    <div class="divider-border" />
    <CounterProposalListItem userType="Seller" :participant="listData.seller" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import CounterProposalListItem from './CounterProposalListItem.vue'
import { ProposalDto } from '@bd/components'

export default defineComponent({
  name: 'CounterProposalList',
  components: { CounterProposalListItem },
  props: {
    listData: {
      type: Object as PropType<ProposalDto>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.list {
  background: $white;
  box-shadow: $default-shadow;
  padding: 1.125rem;
}
.divider-border {
  border-top: $divider-border;
  margin: 20px;
}
</style>
