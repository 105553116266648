
import { onClickOutside } from '@vueuse/core'
import { defineComponent, ref } from 'vue'
import NotificationsCenter from './NotificationsCenter.vue'

export default defineComponent({
  components: {
    NotificationsCenter,
  },
  props: {
    modelValue: { type: Boolean, default: false },
  },
  emits: [
    'bottomReach',
    'outsideClick',
    'notificationClick',
    'calendarNotificationClick',
    'proposalNotificationClick',
  ],
  setup(_, { emit }) {
    const container = ref<HTMLElement>()
    onClickOutside(container, (event) => {
      emit('outsideClick', event)
    })

    return {
      container,
    }
  },
})
