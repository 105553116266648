<template>
  <Dialog
    class="confirmation-dialog"
    :header="t('confirmation_header')"
    :showHeader="true"
    :modal="true"
  >
    <div class="confirmation-content">
      <h3 class="h-3">{{ title }}</h3>
      <div class="description mt-2" v-if="description">
        {{ description }}
      </div>
    </div>
    <div class="buttons-row">
      <Button
        icon="pi pi-check"
        class="p-button-text"
        :label="t('yes')"
        :disabled="submitPending"
        @click="$emit('confirm')"
      />
      <Button
        class="p-button-text p-button-warning"
        icon="pi pi-times"
        :autofocus="true"
        :label="t('no')"
        :disabled="submitPending"
        @click="$emit('reject')"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    submitPending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm', 'reject'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.confirmation-dialog) {
  width: 450px;
  @include dialog-border-radius(6px);
  @include breakpoint-down(md) {
    margin: 0 1rem;
  }
}
.buttons-row {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5rem;
  > button:first-of-type {
    grid-column-start: 2;
  }
}
</style>
