<template>
  <div @click="handler" class="list-element" :class="item.containerClass ?? ''">
    <div class="icon-wrapper" :class="opts.iconWrapperColor ?? ''">
      <Svg class="svg-class" :src="item.icon" :class="opts.iconClass" />
    </div>

    <template v-if="type === 'simple'">
      <div class="details">
        <span v-if="item.routerLink" class="text" v-text="item.name" />
        <span v-else class="text" v-text="item.name" />
        <div class="arrow-desktop">
          <i class="pi pi-chevron-right c-primary" />
        </div>
      </div>
      <div class="arrow ml-auto">
        <i class="pi pi-chevron-right c-primary" />
      </div>
    </template>
    <template v-if="type === 'advanced'">
      <div class="details">
        <p class="caption py-md-1">{{ item.name }}</p>
        <div class="text text-wrap-3">
          {{ item.value }} <span v-if="item.suffix">{{ item.suffix }}</span>
        </div>
      </div>
    </template>
  </div>
  <div v-if="isBreak" class="break"></div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { TileItem, TileOptions } from './types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TileList',
  components: { Svg },
  emits: ['item-click'],
  props: {
    item: {
      type: Object as PropType<TileItem>,
      required: true,
    },
    opts: {
      type: Object as PropType<TileOptions>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isBreak: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()

    const handler = () => {
      const { routerLink, externalLink, action } = props.item

      if (routerLink) router.push({ path: routerLink })
      if (externalLink) window.open(externalLink, '_self')
      if (action) emit('item-click', action)
    }
    return { handler }
  },
})
</script>

<style lang="scss" scoped>
:deep(.svg-class.heliotrope) {
  svg {
    g {
      stroke: $heliotrope;
    }
    path {
      stroke: $heliotrope;
    }
  }
}

.text {
  text-decoration: none;
  font-size: 1rem;
  color: $bright-gray;
  line-height: normal;
}

.list {
  &-top {
    .details {
      align-items: center;
      width: 100%;
    }
    .arrow-mobile {
      visibility: hidden;
    }
  }
  &-bottom {
    .details {
      align-items: flex-end;
      width: 100%;
      @include breakpoint-down(md) {
        width: 70%;
      }
      display: flex;
      justify-content: space-between;
    }
    .list-element {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-element {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 70px;

    @include breakpoint-up(md) {
      flex-direction: column;
      border: solid 1px $whisper;
      border-radius: 6px;
      align-items: flex-start;
      padding: 2rem;
      margin: 0 1rem 1rem 0;
      min-height: 200px;
      flex: 1 0 calc(33.333% - 20px);
      max-width: calc(33.333% - 20px);
    }
  }
  .arrow {
    &-desktop {
      @include breakpoint-down(md) {
        visibility: hidden;
      }
    }
    @include breakpoint-up(md) {
      display: none;
    }
  }

  .details {
    @include breakpoint-up(md) {
      margin-top: auto;
    }
  }
}

.zest-icon {
  background: rgba($zest, 0.2);
}
.dodger-blue-icon {
  background-color: rgba($dodger-blue, 0.1);
}
.jungle-green-icon {
  background: rgba($heliotrope, 0.2);
}

.zest-color {
  color: $zest;
}
.dodger-blue-color {
  color: $dodger-blue;
}
.icon-wrapper {
  display: flex;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 15px;
  margin-right: 1rem;
  .colorful {
    width: 25px;
    height: 25px;
    margin: auto;
  }
  .top-icons {
    color: $zest;
    height: 20px;
    margin: auto;
  }
  .transparent {
    width: 40px;
    height: 40px;
    margin: auto;
    @include breakpoint-down(md) {
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
}

.edit-button {
  &-mobile {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  &-desktop {
    position: relative;
    right: 1rem;
    top: 1rem;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
