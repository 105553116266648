<template>
  <TopNavigation :items="navigationItems" isSticky>
    <template #view-left>
      <a class="mr-3 link-new" :href="`${domainURL}biuro/`">
        <img
          class="immobi-logo"
          src="@bd/assets/images/immoby-horizontal-logo.svg"
        />
      </a>
      <DashboardSearch class="dashboard-search" />
      <div class="links" v-if="!isLogged">
        <a class="mr-3 link-new" :href="`${domainURL}oferty/`">
          {{ t('buy') }}
        </a>
        <a class="link-new" :href="`${domainURL}aplikacja/`">
          {{ t('sell') }}
        </a>
      </div>
    </template>

    <template #view-right v-if="!isLogged">
      <div class="unauthorized-buttons">
        <Button
          :label="t('login')"
          class="p-button-secondary p-button-rounded text-600 login-btn"
          @click="$router.push({ name: 'Login' })"
        />

        <Button
          :label="t('create_account')"
          class="p-button-primary p-button-rounded text-600 register-btn"
          @click="$router.push({ name: 'Registration' })"
        />
      </div>
    </template>
  </TopNavigation>

  <div class="content-container" v-if="online">
    <router-view />
  </div>
  <div class="offline-container" v-show="!online">
    <OfflineScreen />
  </div>

  <DashboardLoginModal v-model:visible="showLoginModal" />
  <OfferAppointment
    v-if="showAppointmentModal && selectedOfferId"
    v-model:modalVisible="showAppointmentModal"
    :offerId="selectedOfferId"
  />
  <NotificationsHost view-type="dropdown" v-if="!isNotificationsRoute" />
  <BottomNavigation :items="navigationItems" v-if="showBottomNavigation" />
  <BuyerProposalHost />
</template>

<script lang="ts">
import {
  BottomNavigation,
  NotificationsHost,
  OfflineScreen,
  TopNavigation,
  useBreakpoints,
  useNavigationBadges,
  usePurchaseOffers,
} from '@bd/components'
import { useUserData } from '@bd/components/use'
import { useOnline } from '@vueuse/core'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { buildNavigation } from '../config'
import { useAppStore } from '../store'
import OfferAppointment from './Appointment/OfferAppointment.vue'
import BuyerProposalHost from './BuyerProposalHost.vue'
import DashboardLoginModal from './Dashboard/DashboardLoginModal.vue'
import DashboardSearch from './Dashboard/DashboardSearch.vue'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'AppLayout',
  components: {
    TopNavigation,
    BottomNavigation,
    DashboardSearch,
    NotificationsHost,
    BuyerProposalHost,
    OfferAppointment,
    DashboardLoginModal,
    OfflineScreen,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const { isLogged } = useUserData()
    const { mobileSize } = useBreakpoints()

    const navigationItems = computed(() =>
      buildNavigation(store, router, mobileSize),
    )

    const showBottomNavigation = computed(() => {
      return store.state.bottomNavigationVisible
    })

    const isNotificationsRoute = computed(() => route.name === 'Notifications')
    const showAppointmentModal = computed({
      get: () => !!store.state.dialog.appointmentModal,
      set: (visible) => {
        if (!visible) {
          store.dispatch('dialogVisibility', {
            appointmentModal: false,
          })
        }
      },
    })
    const selectedOfferId = computed(() => {
      const modal = store.state.dialog.appointmentModal
      return modal && modal.offerId
    })

    const showLoginModal = computed({
      get: () => {
        return !!store.state.dialog.dashboardLoginModal
      },
      set: (visible) => {
        if (!visible) {
          store.dispatch('dialogVisibility', {
            dashboardLoginModal: false,
          })
        }
      },
    })

    const online = useOnline()

    useNavigationBadges()
    usePurchaseOffers()

    return {
      t,
      navigationItems,
      showBottomNavigation,
      isNotificationsRoute,
      isLogged,
      showAppointmentModal,
      selectedOfferId,
      showLoginModal,
      online,
      domainURL,
    }
  },
})
</script>

<style lang="scss" scoped>
.links {
  @include flex($align: center);
  margin: 0 20px;
}

.search-input-wrapper {
  flex: 0 1 30%;
  margin-left: 1.875rem;
  max-width: 30%;
  min-width: 140px;
}

.content-container {
  width: 100%;
  margin: 0 auto;
}

.unauthorized-buttons {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
}

.home-link {
  display: flex;
  height: inherit;
}

.offline-container {
  height: calc(100vh - #{$top-navigation-height});
}

.dashboard-search {
  max-width: 350px;
}
.register-btn {
  margin-left: 10px;
}
.login-btn {
  min-width: 127px;
}

.immobi-logo {
  margin-top: 4px;
}
</style>
