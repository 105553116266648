import { ApiResponse } from '@bd/api/common'
import { MeetingOrganizer, MeetingTypeDto } from './types/calendar'
import http from './http-client'

export const getPredefinedMeetingTypes = (
  meetingOrganizer: MeetingOrganizer,
): ApiResponse<MeetingTypeDto[]> => {
  return http.get(`/meeting-types/predefined`, {
    params: {
      meetingOrganizer,
    },
  })
}
