
import { defineComponent, PropType, computed } from 'vue'
import { SystemNotificationDto } from '@bd/api/common/types/notifications'
import NotificationsListItem from './NotificationsListItem.vue'
import { useInfiniteScroll, useLoadableResourceDataState } from '@bd/components'
import { ResultPageDto } from '@bd/api'
import { LoadableResource } from '@bd/helpers'
import ProgressSpinner from 'primevue/progressspinner'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    NotificationsListItem,
    ProgressSpinner,
    EmptyState,
  },
  props: {
    notifications: {
      type: Object as PropType<
        LoadableResource<ResultPageDto<SystemNotificationDto> | undefined>
      >,
      required: true,
    },
    isChunkLoading: {
      type: Boolean,
      required: true,
    },
    isFirstTimeLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['bottomReach', 'notificationClick'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const notifications = computed(() => props.notifications)
    const { isEmpty, isPresent } = useLoadableResourceDataState(
      notifications,
      (res) => !!(res?.content?.content && res.content.content.length),
    )

    const notificationItems = computed(
      () => notifications.value.content?.content ?? [],
    )

    const onScroll = useInfiniteScroll(() => {
      emit('bottomReach')
    })

    return {
      t,
      onScroll,
      isEmpty,
      isPresent,
      notificationItems,
    }
  },
})
