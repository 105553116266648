<template>
  <div
    class="empty-state w-100 h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <img
      class="empty-state-icon"
      :src="require('@bd/assets/icons/empty-state-icon.svg')"
    />
    <span v-if="title" class="h-1 my-4 text-center">{{ title }}</span>
    <span
      v-if="description"
      :class="{ 'mt-4': !title }"
      class="body-bg text-center mb-4"
      >{{ description }}</span
    >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmptyState',
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.empty-state-icon {
  width: 250px;
}
</style>
