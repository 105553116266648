<template>
  <div
    class="event-tile"
    ref="eventTile"
    :class="eventTileClass"
    :style="eventDurationStyle"
  >
    <UserAvatar
      class="agent-avatar"
      v-if="firstCalendarEvent.calendarEventStatus === CREATED_BY_AGENT"
      :user="emptyUser"
      overrideWithUserType
      :userType="Agent"
    />
    <span class="event-text" v-if="!hasMultipleEvents">{{
      firstCalendarEvent.meetingTypeDto.meetingTypeName
    }}</span>
    <span class="event-text" v-else
      >{{ t('calendar.events.multiple_events.title') }} ({{
        events.length
      }})</span
    >
  </div>
</template>

<script lang="ts">
import { UserCalendarEventDto, CalendarEventStatus } from '@bd/api'
import { UserRole } from '@itcraft-bestdeal/api'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useEventStatusClass, useUserData } from '@bd/components/use'
import { TemplateRef } from '@bd/helpers'
import { UserAvatar, UserType } from '@bd/components'

export default defineComponent({
  components: { UserAvatar },
  props: {
    events: {
      type: Array as PropType<UserCalendarEventDto[]>,
      required: true,
    },
    isFirstThisDay: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { user } = useUserData()
    const { CREATED_BY_AGENT } = CalendarEventStatus
    const { Agent } = UserType
    const emptyUser = { firstName: '', lastName: '' }

    const firstCalendarEvent = computed(() => props.events[0].calendarEventDto)
    const hasMultipleEvents = computed(() => props.events.length > 1)
    const eventTileClass = useEventStatusClass(
      firstCalendarEvent,
      hasMultipleEvents,
    )

    const eventDurationStyle = computed(() => {
      const meeting = firstCalendarEvent.value.meetingTypeDto
      return {
        '--event-duration': user.value?.roles.includes(UserRole.CUSTOMER)
          ? meeting.clientDurationMinutes
          : meeting.agentDurationMinutes,
      }
    })

    const eventTile = ref<TemplateRef>()

    onMounted(() => {
      if (props.isFirstThisDay) {
        eventTile.value?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    })

    return {
      t,
      eventTileClass,
      eventDurationStyle,
      firstCalendarEvent,
      hasMultipleEvents,
      CalendarEventStatus,
      eventTile,
      Agent,
      CREATED_BY_AGENT,
      emptyUser,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './calendar';

.event-tile {
  transform: translateY(var(--event-offset));
  height: calc(
    1px * (var(--event-duration) * var(--minute-size)) -
      (var(--event-offset) * 2)
  );

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
  text-align: center;
  left: $event-tile-gutter;
  width: calc(100% - #{$event-tile-gutter} * 2);
  font-size: 0.938rem;
  font-weight: 500;
  border-radius: 16px;
  background-color: $white;
  z-index: 2;
  cursor: pointer;
  border: 1px solid $white;
  transition: all 0.1s ease;

  &.accepted {
    background-color: $event-accepted-bg;
    color: $dodger-blue;
    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }

  &.pending-agent {
    @include pending-event-gradient($event-pending-agent-bg, $white);
    border: 1px solid $event-pending-agent-bg;
    color: $port-gore;
  }
  &.pending-seller {
    @include pending-event-gradient($white, $event-pending-seller-bg);
    color: $dodger-blue;
  }
  &.multiple {
    background-color: $event-multiple-bg;
    color: $zest;
  }
  &:hover,
  &:focus {
    z-index: 3;
    transform: translateY(var(--event-offset)) scale(1.01);
    box-shadow: 0 0 10px 0px rgba(162, 162, 162, 0.261);
  }
}

.agent-avatar {
  width: 40px;
  height: 40px;
}
.event-text {
  flex: auto;
}
</style>
