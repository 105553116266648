import { TOAST_CONFIG, useBreakpoints } from '@bd/components'
import { computed } from 'vue'

export const useToastConfig = () => {
  const { mobileSize } = useBreakpoints()
  const toastPosition = computed(() =>
    mobileSize.value ? 'bottom-center' : 'bottom-right',
  )
  const { zIndex: toastZIndex } = TOAST_CONFIG

  return {
    toastPosition,
    toastZIndex,
  }
}
