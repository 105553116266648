<template>
  <Dialog
    maximizable
    class="dialog-proposal"
    header="&nbsp;"
    :class="{ 'p-dialog-maximized': mobileSize }"
    showHeader
    modal
    @hide="$emit('hide')"
  >
    <div class="content-wrapper">
      <div class="picker-container">
        <div class="header-container">
          <h1 class="header h-1" v-text="header" />
        </div>

        <div class="price-box-wrapper">
          <div class="price-box">
            <div>
              <span class="price-box-title" v-text="priceTitle" />
            </div>
            <div class="divider-border" />
            <ProposalModalNumberInput
              :modelValue="modelValue"
              :isInputDisabled="isInputDisabled"
              @update:modelValue="$emit('update:modelValue', $event)"
            />
          </div>
        </div>
        <slot name="content" />
      </div>

      <div class="button-container">
        <slot name="footer" />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useBreakpoints } from '@bd/components'
import Dialog from 'primevue/dialog'
import ProposalModalNumberInput from './ProposalModalNumberInput.vue'

export default defineComponent({
  name: 'ProposalModal',
  emits: ['hide', 'update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: null,
      required: false,
    },
    header: {
      type: String,
    },
    priceTitle: {
      type: String,
    },
    price: {
      type: Number as PropType<number | null | undefined>,
      required: false,
    },
    isInputDisabled: { type: Boolean },
  },
  components: { Dialog, ProposalModalNumberInput },
  setup() {
    const { mobileSize } = useBreakpoints()

    return { mobileSize }
  },
})
</script>

<style lang="scss" scoped>
.divider-border {
  border-top: $divider-border;
  margin: 30px 40px;
}

.price-box-wrapper {
  background: $alabaster;
  border-radius: 6px;
  height: 260px;
  position: relative;
  padding: 40px;
}

.price-box {
  @include flex($justify: center, $direction: column);
  box-shadow: $default-shadow;
  border-radius: 6px;
  background: white;
  width: 100%;
  text-align: center;
  height: 100%;
  &-title {
    color: $manatee;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

:deep(.dialog-proposal) {
  width: min(100vw, 488px);
  @include breakpoint-up(md) {
    @include dialog-border-radius(6px);
  }
  .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
}

.content-wrapper {
  height: 100%;
  display: flex;
  padding: 1rem 2rem 2rem 2rem;
  flex-direction: column;
  overflow-y: auto;
  @include breakpoint-down(md) {
    padding: 0;
    background: $alabaster;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @include breakpoint-down(md) {
    margin-top: auto;
    padding: 1rem;
  }
}
.header-container {
  @include breakpoint-down(md) {
    padding: 0.5rem 0 0.25rem 0;
    background: white;
  }
}
</style>
