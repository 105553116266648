export const sizes = {
  xs: 488,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1260,
}

export const windowType = (windowWidth: number) => {
  if (windowWidth <= sizes.xs) {
    return 'xs'
  } else if (windowWidth > sizes.xs && windowWidth <= sizes.sm) {
    return 'sm'
  } else if (windowWidth > sizes.sm && windowWidth <= sizes.md) {
    return 'md'
  } else if (windowWidth > sizes.md && windowWidth <= sizes.lg) {
    return 'lg'
  } else {
    return 'xl'
  }
}

export const determinePageSize = () => {
  switch (windowType(window.innerWidth)) {
    case 'xs':
    case 'sm':
      return 10
    case 'md':
    case 'lg':
      return 15
    case 'xl':
      return 20
    default:
      return 15
  }
}
