<template>
  <div
    class="calendar-event-meeting-types-list-item event-resources-results-list-item"
    :class="{ selected: item.selected, disabled: item.disabled }"
  >
    <div class="wrapper">
      <div class="duration-box">
        <h5 v-if="formattedDuration" class="h-5">{{ formattedDuration }}</h5>
        <Svg
          v-else
          class="custom-meeting-icon"
          :src="require('@bd/assets/icons/custom-meeting-clock.svg')"
        />
      </div>
      <div class="name">
        {{ item.name }}
      </div>
      <i class="item-append-icon pi pi-chevron-right"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarEventMeetingTypeItem, Svg } from '@bd/components'
import { formatDuration } from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<CalendarEventMeetingTypeItem>,
      required: true,
    },
  },
  setup(props) {
    const formattedDuration = computed<string | null>(() => {
      const type = props.item
      return type.agentDurationMinutes && !type.isCustom
        ? formatDuration(type.agentDurationMinutes)
        : null
    })

    return {
      formattedDuration,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../../event-save';

.calendar-event-meeting-types-list-item {
  .duration-box {
    margin-right: 1rem;
  }
  &.selected {
    color: $primary-color;
    font-weight: 500;
    .duration-box {
      > {
        color: $primary-color;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.item-append-icon {
  color: $primary-color;
  margin-left: auto;
}
</style>
