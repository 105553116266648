
import { defineComponent, ref } from 'vue'
import InputNumber from 'primevue/inputnumber'
import { LOCALISATION_SETTINGS } from '../../../config'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'ProposalModalNumberInput',
  components: { InputNumber },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: null,
      required: false,
    },
    isInputDisabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const isUsedGroupping = ref(true)
    const inputValue = useVModel(props, 'modelValue', emit)

    return {
      ...LOCALISATION_SETTINGS,
      inputValue,
      isUsedGroupping,
    }
  },
})
