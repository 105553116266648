
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'

export default defineComponent({
  name: 'AuthFormConfirmation',
  setup() {
    const i18n = useI18n()
    const { isLoading } = useRootData()

    return {
      ...i18n,
      isLoading,
    }
  },
})
