
import { defineComponent, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { APP_SETTINGS } from '@bd/client/config'
import { useBreakpoints } from '@bd/components'

export default defineComponent({
  name: 'DashboardOfferDescription',
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const i18n = useI18n()
    const showMore = ref(false)
    const { mobileSize } = useBreakpoints()
    const { characterLimitMobile, characterLimitDesktop } = APP_SETTINGS
    const characterLimit = computed(() => {
      return mobileSize.value ? characterLimitMobile : characterLimitDesktop
    })

    const computedDescription = computed(() => {
      if (props.description.length >= characterLimit.value) {
        return showMore.value
          ? props.description
          : props.description.substring(0, characterLimit.value) + '…'
      } else {
        return props.description
      }
    })
    return { ...i18n, showMore, computedDescription, characterLimit }
  },
})
