import firebase from 'firebase/app'
import 'firebase/messaging'
import { register } from 'register-service-worker'
import { deferred, trimSlash } from '@bd/helpers'
import { NotificationType } from './common/types/notifications'
import { TransactionType } from '@bd/api/common/types/transaction'

let isInitialized = false

export type Message = {
  data: {
    IMMOBY_NOTIFICATION_TYPE: NotificationType
    RESOURCE_ID: string
    SYSTEM_NOTIFICATION_ID: string
    BUYER_ID: string
    OFFER_ID: string
    PROPOSAL: TransactionType
  }
  notification: {
    title: string
    body: string
  }
  messageType: string
  [key: string]: unknown
}

const [registration, setRegistration] = deferred<ServiceWorkerRegistration>()

/**
 Register service worker for Firebase Messaging and for PWA.
 */
const registerSW = () => {
  const serviceWorkerPath = `${trimSlash(process.env.VUE_APP_ROOT_URL)}/serviceWorkerFcm.js`
  console.log('registerSW:', serviceWorkerPath)

  register(serviceWorkerPath, {
    ready(registration) {
      setRegistration(registration)
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', {
          detail: reg,
        }),
      )
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}

export const init = () => {
  registerSW()
  if (!firebase.messaging.isSupported()) {
    console.warn('Firebase messaging is not supported')
    return
  }
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
  }
  firebase.initializeApp(firebaseConfig)

  isInitialized = true
}

/**
 * Gets the FCM token that can be used to associate it with the logged user to later receive notifications
 * Asks user for permission to access Notifications beforehand if the permission is not granted
 * Throws an error if the user didn't grant the permission
 */
export const getFcmToken = async () => {
  if (!isInitialized) {
    return
  }
  const vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY
  try {
    return await firebase.messaging().getToken({
      vapidKey,
      serviceWorkerRegistration: await registration,
    })
  } catch (e) {
    console.error("User declined notification permission - can't get FCM token")
    return
  }
}

export const deleteToken = async () => {
  if (!isInitialized) {
    return
  }
  return await firebase
    .messaging()
    .deleteToken()
    .then(
      (result: boolean) =>
        result && console.log('[firebase-sw.ts] Token deleted'),
    )
}

/**
 * On foreground message - while the app's browser tab is in focus
 * @param fn Handler to be called when foreground push message is received
 */
export const onForegroundMessage = (fn: (msg: Message) => void) => {
  if (!isInitialized) {
    return
  }
  firebase.messaging().onMessage(fn)
}

/**
 * On background message - while the app's browser tab is either opened
 * but not currently in focus, or when the app is not opened at all
 * @param fn Handler to be called when background push message is received
 */
export const onBackgroundMessage = (
  pushCb: (msg: Message) => void,
  clickCb?: (msg: Message) => void,
) => {
  if (!isInitialized) {
    return
  }
  navigator.serviceWorker.addEventListener('message', async (ev) => {
    console.log('[firebase-sw.ts] Message event:', ev)
    if (ev.data?.messageType === 'notification-clicked') {
      console.log('[firebase-sw.ts] Push clicked event:', ev)
      clickCb?.(ev.data)
    }
    if (ev.data?.type === 'FCM_BG_PUSH') {
      console.log('[firebase-sw.ts] Background message event:', ev)
      pushCb(ev.data.message)
    }
  })
}
