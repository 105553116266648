
import { defineComponent, PropType, shallowRef, watch } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { CityDto } from '@bd/api'
import { useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'CityAutocomplete',
  components: { AutoComplete },
  emits: ['search'],
  props: {
    items: {
      type: Array as PropType<CityDto[]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { value, errorMessage } = useOfferField('cityDto')
    const selectionOrQuery = shallowRef<typeof value.value | string>(
      value.value || '',
    )

    const onClear = () => {
      value.value = undefined
      emit('search', '')
    }

    watch(value, (city) => {
      if (city) selectionOrQuery.value = city
    })

    return {
      value,
      selectionOrQuery,
      onClear,
      errorMessage,
    }
  },
})
