
import { UserCalendarEventDto } from '@bd/api'
import { Svg } from '@bd/components'
import {
  useEventAddress,
  useEventDuration,
  useEventStatusClass,
  useFormattedDate,
  useUserData,
} from '@bd/components/use'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: { Svg },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDto>,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    is: {
      type: String,
      default: 'li',
    },
  },
  setup(props) {
    const calendarEvent = computed(() => props.event.calendarEventDto)
    const offer = computed(() => calendarEvent.value.calendarEventOfferDto)
    const date = computed(() => calendarEvent.value.date)
    const { user } = useUserData()

    const address = useEventAddress(offer)
    const eventDate = useFormattedDate(date)
    const eventTime = useEventDuration(calendarEvent, user)
    const eventClass = useEventStatusClass(calendarEvent)

    return {
      eventDate,
      eventTime,
      address,
      eventClass,
    }
  },
})
