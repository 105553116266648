<template>
  <DashboardCarousel :items="items" class="carousel-buttons">
    <template v-slot:slide-item="{ item }">
      <Button
        class="text-400 body-sm-medium p-button-mobile"
        :class="{
          active: currentActiveFilters.includes(
            isCityValue ? item.cityId : item.districtId,
          ),
        }"
        :label="getItemName(item)"
        :key="item"
        @click="emitFilterData(item)"
      />
    </template>
  </DashboardCarousel>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { AdditionalFilter } from '@bd/store-modules/types'
import DashboardCarousel from './DashboardCarousel.vue'

export default defineComponent({
  name: 'CarouselButtons',
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AdditionalFilter[]>,
      required: true,
    },
    activeFilters: {
      // TODO add type
      type: Object,
      required: true,
    },
  },
  emits: ['filter-data'],
  components: { DashboardCarousel },
  setup(props, { emit }) {
    const currentActiveFilters = computed(() => {
      if (props.value.includes('cityIds')) {
        return props.activeFilters?.cityIds ?? []
      } else if (props.value.includes('districtIds')) {
        return props.activeFilters?.districtIds ?? []
      } else {
        return []
      }
    })
    const getItemName = (cityObject: AdditionalFilter) => {
      if (cityObject.districtName) {
        return cityObject.districtName
      } else {
        return cityObject.cityName
      }
    }

    const emitFilterData = (item: AdditionalFilter) => {
      emit('filter-data', {
        value: item.districtId || item.cityId,
        prop: props.value,
      })
    }

    const isCityValue = computed(() => {
      return !!props.value.includes('cityIds')
    })

    return { currentActiveFilters, getItemName, emitFilterData, isCityValue }
  },
})
</script>

<style lang="scss" scoped>
.carousel-buttons {
  .carousel__slide {
    button {
      width: 125px;
      padding: 13px 5px;
      height: auto;
      margin: 5px;
    }
  }
  .active {
    background: $primary-color !important;
    color: white !important;
  }
}
</style>
