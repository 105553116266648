
import { defineComponent, PropType, ref, computed } from 'vue'
import { NavigationItem } from '../types'
import { useNavigationItemIconStyle } from '../utils'
import { DropdownButton, useBadgeValue } from '@bd/components'
import { useRouter } from 'vue-router'
import { Svg } from '@bd/components'

export default defineComponent({
  components: {
    DropdownButton,
    Svg,
  },
  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
      validator: (item: NavigationItem) => !!(item.label || item.icon),
    },
  },
  setup(props) {
    const router = useRouter()
    const navigationDropdownOpen = ref(false)

    const computedClass = computed(() => {
      const { item } = props
      return {
        'with-badge': item.badge,
        text: item.label,
        icon: !item.label && item.icon,
        'dropdown-active': navigationDropdownOpen.value,
        dropdown: item.children,
        active: item.active || item.children?.some((child) => child.active),
        disabled: item.disabled,
      }
    })

    const itemIconStyle = useNavigationItemIconStyle(props.item)

    const badge = computed(() => props.item.badge)
    const badgeValue = useBadgeValue(badge)

    const onItemClick = (item: NavigationItem) => {
      if (item.action) {
        item.action()
      } else if (item.path) {
        router.push({ path: item.path })
      }
    }

    return {
      navigationDropdownOpen,
      computedClass,
      itemIconStyle,
      onItemClick,
      badgeValue,
    }
  },
})
