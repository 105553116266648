
import { TextInput } from '@bd/components'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRootData } from '@bd/components'
import { default as common } from '@/config/common.json'
import { useIsFormValid } from 'vee-validate'

export default defineComponent({
  components: { TextInput },
  name: 'AuthFormReset',
  props: {
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const i18n = useI18n()
    const { isLoading } = useRootData()
    const { helpline } = common
    const isFormValid = useIsFormValid()

    return {
      ...i18n,
      isLoading,
      helpline,
      isFormValid,
    }
  },
})
