<template>
  <div class="calendar-event-attendees">
    <ResponsiveContent>
      <template #mobile>
        <div class="mobile">
          <CalendarEventAttendeeChooser
            :userType="UserType.Buyer"
            :selectedAttendee="selectedBuyer"
            :isAttendeeRequired="isBuyerRequired"
            :attendeeRequiredLabel="
              t('calendar.events.save_event.attendees.customer_required')
            "
            @attendeeChooserClick="attendeeSelectionModalVisible = true"
            @clearAttendee="onBuyerClear"
          />
        </div>
      </template>
      <template #desktop>
        <div class="desktop d-md-flex">
          <div class="event-save-desktop-section">
            <h4 class="header h-4">
              {{ t('calendar.events.save_event.attendees.title') }}
            </h4>
            <div class="event-save-card">
              <div class="content">
                <div class="chooser-wrapper">
                  <CalendarEventAttendeeChooser
                    :userType="UserType.Seller"
                    :selectedAttendee="selectedSeller"
                    disabled
                  />
                </div>
                <div class="chooser-wrapper" ref="buyerChooserWrapper">
                  <CalendarEventAttendeeChooser
                    :userType="UserType.Buyer"
                    :selectedAttendee="selectedBuyer"
                    :isAttendeeRequired="isBuyerRequired"
                    :attendeeRequiredLabel="
                      t(
                        'calendar.events.save_event.attendees.customer_required',
                      )
                    "
                    @attendeeChooserClick="onAttendeeChooserClick"
                    @clearAttendee="onBuyerClear"
                  />
                  <CalendarEventAttendeeSelectionOverlay
                    v-model:visible="attendeeOverlayVisible"
                    :userType="UserType.Buyer"
                    :selectedAttendee="selectedBuyer"
                    @searchAttendee="$emit('searchAttendee', $event)"
                    @selectAttendee="onBuyerSelect"
                    @outsideClick="onAttendeeChooserOutsideClick"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ResponsiveContent>
    <CalendarEventAttendeeSelectionModal
      v-model:visible="attendeeSelectionModalVisible"
      :userType="UserType.Buyer"
      :selectedAttendee="selectedBuyer"
      @searchAttendee="$emit('searchAttendee', $event)"
      @selectAttendee="onBuyerSelect"
    />
  </div>
</template>

<script lang="ts">
import { ResponsiveContent, UserType } from '@bd/components'
import { defineComponent, computed, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendeeChooser from './CalendarEventAttendeeChooser.vue'
import { useAppStore } from '@bd/store-modules'
import CalendarEventAttendeeSelectionOverlay from './CalendarEventAttendeeSelectionOverlay.vue'
import CalendarEventAttendeeSelectionModal from './CalendarEventAttendeeSelectionModal.vue'
import { TemplateRef } from '@bd/helpers'
import { CalendarEventAttendeeItem } from '../../types'
import { CalendarEventAttendee } from '@bd/store-modules/types'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventAttendeeChooser,
    CalendarEventAttendeeSelectionOverlay,
    CalendarEventAttendeeSelectionModal,
  },
  props: {},
  emits: ['searchAttendee'],
  setup() {
    const { t } = useI18n()
    const store = useAppStore()

    const buyerChooserWrapper = shallowRef<TemplateRef>()
    const selectedMeetingType = computed(
      () => store.state.calendarEvent?.eventSaveData.meetingType,
    )

    const attendeeSelectionModalVisible = ref(false)

    const selectedSeller = computed(
      () => store.state.calendarEvent?.eventSaveData.seller,
    )
    const selectedBuyer = computed(
      () => store.state.calendarEvent?.eventSaveData.buyer,
    )

    const isBuyerRequired = computed(
      () =>
        store.state.calendarEvent?.eventSaveData.meetingType
          ?.customerPresenceRequired,
    )

    const attendeeOverlayVisible = ref(false)

    const isBuyerChooserClicked = ($event: PointerEvent) => {
      return (
        $event.target === buyerChooserWrapper.value ||
        $event.composedPath().includes(buyerChooserWrapper.value!)
      )
    }

    const onAttendeeChooserClick = ($event: PointerEvent) => {
      if (isBuyerChooserClicked($event)) {
        attendeeOverlayVisible.value = !attendeeOverlayVisible.value
      }
    }

    const onBuyerSelect = async (attendee: CalendarEventAttendeeItem) => {
      const { selected: _, ...rest } = attendee
      const selectedBuyerPayload: CalendarEventAttendee = rest
      await store.dispatch('calendarEvent/setSelectedBuyer', {
        buyer: selectedBuyerPayload,
      })
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }

    const onBuyerClear = async () => {
      const shouldClearBuyerRequirement =
        selectedMeetingType.value?.isCustom &&
        selectedMeetingType.value.customerPresenceRequired
      await store.dispatch('calendarEvent/setSelectedBuyer', {
        buyer: undefined,
      })
      if (shouldClearBuyerRequirement) {
        store.dispatch('calendarEvent/clearBuyerRequired')
      }
      store.dispatch('calendarEvent/getMeetingAvailableTimeSlots')
    }
    const onAttendeeChooserOutsideClick = ($event: PointerEvent) => {
      if (!isBuyerChooserClicked($event)) {
        attendeeOverlayVisible.value = false
      }
    }

    return {
      t,
      UserType,
      attendeeSelectionModalVisible,
      selectedSeller,
      selectedBuyer,
      buyerChooserWrapper,
      isBuyerRequired,
      attendeeOverlayVisible,
      onAttendeeChooserClick,
      onBuyerSelect,
      onBuyerClear,
      onAttendeeChooserOutsideClick,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';
@import '../../calendar';

$attendee-row-margin: 1rem;

.event-save-desktop-section {
  @include offers-attendees-card;
  .event-save-card .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .chooser-wrapper {
      @include uniform-size(100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      &:not(:last-of-type) {
        border-bottom: $divider-border;
      }
      &:first-of-type .desktop-attendee-chooser {
        margin-bottom: $attendee-row-margin;
      }
      &:last-of-type .desktop-attendee-chooser {
        margin-top: $attendee-row-margin;
      }
    }
  }
}
:deep(.attendee-selection-overlay) {
  top: calc(100% - 10px) !important;
}
</style>
