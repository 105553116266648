import { CustomScrollEvent } from '@bd/components'

/** Generates a scroll handler for infinite scrolling functionality.
 * Assumes there should be a default offset before the actual bottom of the container,
 * which when scrolled to - triggers the bottomReachedCallback.
 * @param bottomReachedCallback Function to be called when the bottom is reached
 * @param reachedBottomOffset (With a default value) Amount of units to be subtracted from the bottom of the container to trigger the callback
 * @returns Scroll event handler which calls a function back when the bottom of the container was reached
 */
export const useInfiniteScroll = (
  bottomReachedCallback: () => void,
  reachedBottomOffset = 5,
) => ($event: CustomScrollEvent) => {
  const target = $event.target
  if (
    target.scrollTop + target.clientHeight >=
    target.scrollHeight - reachedBottomOffset
  ) {
    bottomReachedCallback()
  }
}
