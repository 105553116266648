<template>
  <div
    class="filter-accordion-item"
    :class="{ 'filters-bg': filtersBackground && index === 0 }"
  >
    <div
      :class="{
        accordion__trigger_active: accordionItemStatus.includes(index),
      }"
      @click="toggleAccordionItem"
    >
      <slot name="accordion-trigger"></slot>
    </div>

    <!-- don't use v-show, carousel errors -->
    <div
      class="filter-accordion-item-content"
      v-if="accordionItemStatus.includes(index)"
    >
      <slot name="accordion-content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onUnmounted, PropType, ref } from 'vue'
import { Accordion } from './FilterAccordion.types'

export default defineComponent({
  emits: ['accordionStatus'],
  props: {
    accordionItemStatus: {
      type: Array as PropType<number[]>,
      default: [],
    },
    filtersBackground: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  inject: ['Accordion'],
  setup(props, { emit }) {
    const Accordion = inject<Accordion>('Accordion')!

    const toggleAccordionItem = () => {
      if (props.index === Accordion.active) {
        Accordion.active = null
      } else {
        Accordion.active = props.index
      }
      emit('accordionStatus', props.index)
    }

    return {
      toggleAccordionItem,
      Accordion,
      props,
    }
  },
})
</script>

<style lang="scss" scoped>
.filter-accordion-item {
  cursor: pointer;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid $gallery;
  position: relative;
  &:nth-last-child(2) {
    border-bottom: none;
  }
}
.filters-bg {
  background: $whisper;
}
</style>
