import { i18n } from '@bd/client/plugins/vue-i18n-next-plugin'
import { FilterData, ComputedFilter } from '@bd/store-modules/types'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { convertCamelCaseToSnakeCase } from '@bd/helpers'
import { CityWithProvinceDto, DistrictDto } from '@bd/api/common'

const listValues = ['propertyType', 'city', 'district']
const UNITS: Record<string, string> = {
  area: 'm2',
  plotArea: 'm2',
  price: LOCALISATION_SETTINGS.currencyDecimal,
}

export const labelWrapper = (
  filter: FilterData,
  filtersActive: ComputedFilter[],
  citiesAndProvinces: CityWithProvinceDto[],
  districts: DistrictDto[],
): string | undefined => {
  const t = i18n.global.t
  const unit = UNITS[filter.filterName] || ''

  const findCityName = (cityId: number) => {
    if (citiesAndProvinces && citiesAndProvinces.length) {
      return citiesAndProvinces.find((city) => city.cityId === cityId)?.cityName
    }
  }
  const findDistrictName = (id: number) => {
    if (citiesAndProvinces && citiesAndProvinces.length) {
      return districts.find(({ districtId }) => districtId === id)?.districtName
    }
  }

  const foundFilter = filtersActive.find(
    (x) => x.filterName === filter.filterName,
  )

  const name = t(convertCamelCaseToSnakeCase(filter.filterName))
  if (foundFilter && foundFilter.isRange) {
    const first = foundFilter.children?.[0]
    const second = foundFilter.children?.[1]

    if (first && second) {
      if (first.value === second.value) {
        return [`${name}:`, first.value, unit].join(' ')
      }

      return [
        `${name}:`,
        t(convertCamelCaseToSnakeCase(first.name)),
        first.value,
        unit,
        t(convertCamelCaseToSnakeCase(second.name)),
        second.value,
        unit,
      ].join(' ')
    } else if (first) {
      return [
        `${name}:`,
        t(convertCamelCaseToSnakeCase(first.name)),
        first.value,
        unit,
      ].join(' ')
    }
  } else if (
    foundFilter &&
    !foundFilter.isRange &&
    foundFilter?.children?.length &&
    foundFilter.filterName
  ) {
    const firstChild = foundFilter.children[0].value
    if (listValues.includes(foundFilter.filterName)) {
      if (foundFilter.filterName === 'propertyType') {
        return t(foundFilter.children[0].value.toString().toLowerCase())
      } else if (foundFilter.filterName === 'city') {
        if (firstChild.length === 1) {
          const cityId = +foundFilter.children[0].value[0]
          return findCityName(cityId)
        } else if (firstChild.length > 1) {
          return `${t(foundFilter.filterName)}: ${firstChild.length}`
        } else {
          return t('city')
        }
      } else if (foundFilter.filterName === 'district') {
        if (firstChild.length === 1) {
          const districtId = +foundFilter.children[0].value[0]
          return findDistrictName(districtId)
        } else if (firstChild.length > 1) {
          return `${t(foundFilter.filterName)}: ${firstChild.length}`
        } else {
          return t(foundFilter.filterName)
        }
      }
    } else {
      if (firstChild.length > 1 && foundFilter.filterName) {
        return `${t(foundFilter.filterName)}: ${firstChild.length}`
      } else {
        return foundFilter.children[0].value[0]
      }
    }
  } else if (filter.filterName) {
    return t(convertCamelCaseToSnakeCase(filter.filterName))
  } else {
    return ''
  }
}
