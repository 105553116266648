import { EmployeeEvent } from '@bd/admin/config'
import {
  ClientDetailsDto,
  ClientDto,
  ClientParams,
  ClientRole,
  PropertyMarket,
  UserRole,
  ResultPageDto,
  CityWithProvinceDto,
} from '@bd/api'
import { EmployeeDetailsDto, EmployeeRowDto } from '@bd/api/admin-api/types'
import { DropdownItem, UserAvatarInput } from '@bd/components'
import {
  CommonAppState,
  Filters,
  FiltersState,
  OfferDetailsDto,
} from '@bd/store-modules/types'
import { LoadableResource } from '@bd/helpers'

export interface AppState extends CommonAppState {
  offers?: AdminOfferState
  agents?: AgentState
  employees?: EmployeeState
  clients?: ClientState
}

export interface AgentState {
  agents: Agents
}

export interface EmployeeState {
  employees: Employees
  filters: EmployeeParams
  employeeDetails?: EmployeeDetailsDto
}

export interface ClientState {
  clients: ClientResults
  filters: ClientParams
  clientDetails?: ClientDetailsDto
  agentsSuggestions: ClientDto[]
  selectedClientType: ClientRole
}

export interface AdminOfferState {
  offers: Offers
  citiesAndProvinces: LoadableResource<
    ResultPageDto<CityWithProvinceDto> | undefined
  >
  districts: DistrictDto[]
  scrollState?: unknown
  filters: FiltersState
  searchedFilters: Filters
  offerDetails?: OfferDetailsDto
}

export interface Offers {
  pageNumber: number
  pageSize: number
  totalCount: number
  content: OfferContent[]
}

export interface OfferContent {
  id: number
  rooms: number
  houseArea: number
  price: number
  street: string
  imageUrl: string
  cityDto: CityDto
  districtDto: DistrictDto
  buildingNumber: string
  houseNumber: string
  sellerFirstName: string
  sellerLastName: string
  draft: boolean
}

export interface CityWithDistrictDto {
  cityId: number
  cityName: string
  districtDtos: DistrictDto[]
}

export interface DistrictDto {
  districtId: number
  districtName: string
}

export interface CityDto {
  cityId: number
  cityName: string
}

export interface DistrictDto {
  districtId: number
  districtName: string
}

export interface cityWithDistrictDto {
  cityId: number
  cityName: string
  districtDtos: DistrictDto[]
}

export enum PropertyType {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  PLOT = 'PLOT',
  OFFICE = 'OFFICE',
}

export interface Agents {
  content: AgentContent[]
}

export interface AgentContent {
  id: number
  name: string
}

export interface Employees {
  totalCount: number
  content: EmployeeRowDto[]
}

export interface ClientResults {
  hasNext: boolean
  content: ClientRowDto[]
}

export interface AgentDropdownItem extends AgentContent, UserAvatarInput {
  id: number
  name: string
  firstName: string
  lastName: string
  avatarUrl?: string
}

export interface PropertyTypeData {
  name: string
  value: string
}

export interface PropertyMarketTypeData {
  name: string
  value: PropertyMarket
}

export interface ChildrenFilterData {
  name: string
  value: string | string[]
}

export interface AssetData {
  name: string
  option: string
  icon?: string
}

export interface FilterData {
  name: string
  value: string[]
  filterName: string
  type: string
  isAdditional: boolean
  position: number
  options?: ChildrenFilterData[]
  assets?: AssetData[]
}

export interface CityDistrictDto {
  cityId: number
  cityName: string
  districtDtos: DistrictDto[]
}

export interface AdditionalFilter {
  cityId?: number
  cityName?: string
  provinceId?: number
  provinceName?: string
  districtDtos?: DistrictDto[]
  districtId?: number
  districtName?: string
  name?: string
  value?: string | string[]
  cityWithDistrictsDtos?: CityDistrictDto[]
}

export type FilterProp = {
  [Prop in keyof Filters]: { prop: Prop; value: Filters[Prop] }
}[keyof Filters]

export interface CustomInputTarget extends HTMLElement {
  files: File[]
}

export interface CustomInputEvent {
  target: CustomInputTarget
}

export interface SelectData {
  name: string
  value: string | number | boolean
}

export enum InputType {
  TEXT_INPUT,
  NUMBER_INPUT,
  SELECT,
}

export interface AdditionalInformationOffer {
  type: InputType
  name: string
  label: string
  placeholder?: string
  options?: SelectData[]
  unit?: string
}

export interface Enum {
  [s: string]: string
}

export type RoleDropdownItem = DropdownItem<UserRole>

export interface EmployeeParams {
  pageIndex?: number
  pageSize?: number
  role?: UserRole
}
export interface ConfirmDialogState {
  visible: boolean
  title: string
  submitPending: boolean
}

export interface EmployeeConfirmDialogState extends ConfirmDialogState {
  actionType?: EmployeeEvent
}

export interface WantedFlatDetailsType {
  city: string
  areaMax: number
  areaMin: number
  priceMax: number
  priceMin: number
  market: PropertyMarket
}

export interface SearchAgentsParams {
  query: string
  size: number
}
export interface ClientRowDto {
  developer: boolean
  displayableId: string
  email: string
  firstName: string
  id: number
  lastName: string
  phoneNumber: string
  userRole: UserRole[]
}
