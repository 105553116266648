<template>
  <Sidebar v-model:visible="sidebar" position="right">
    <div class="row mt-3 mb-5">
      <div class="col-10">
        <span class="h-1 sidebar-title d-flex align-items-center pl-2">{{
          t('filters')
        }}</span>
      </div>
    </div>
    <FilterAccordion class="filters-accordion">
      <FilterAccordionItem
        @accordionStatus="statusChange"
        :accordionItemStatus="openedFilter"
        v-for="(filter, index) in filters"
        :key="filter.name"
        :index="index"
      >
        <template #accordion-trigger>
          <div class="accordion-item">
            <div>
              <h3 class="h-3">{{ filter.name }}</h3>
            </div>
            <div>
              <span
                :class="`pi chevron-color pi-chevron-${
                  openedFilter.includes(index) ? 'up' : 'down'
                }`"
              />
            </div>
          </div>
        </template>
        <template #accordion-content>
          <div class="filter-wrapper">
            <SingleSelectOption
              v-if="filter.filterName === 'propertyType'"
              :items="filter.assets"
              :value="filter.value[0]"
              :filtersObject="filtersState"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'price'"
              :filtersObject="filtersState"
              unit="currency"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <MultipleSelectOptions
              v-if="filter.filterName === 'city'"
              :items="citiesAndProvinces"
              :activeFilters="filtersState"
              :value="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <MultipleSelectOptions
              v-if="filter.filterName === 'district' && hasDistricts"
              :items="districts"
              :activeFilters="filtersState"
              :value="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'rooms'"
              :filtersObject="filtersState"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'area'"
              :filtersObject="filtersState"
              unit="area"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'plotArea'"
              :filtersObject="filtersState"
              unit="area"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'floor'"
              :filtersObject="filtersState"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
            <NumberRange
              v-if="filter.filterName === 'constructionYear'"
              :filtersObject="filtersState"
              :items="filter.value"
              @filter-data="$emit('add-to-queue', $event)"
            />
          </div>
        </template>
      </FilterAccordionItem>
      <div class="bottom-wrapper">
        <Button
          @click="filterResults"
          :label="t('search')"
          class="p-button-primary w-100"
        />
      </div>
    </FilterAccordion>
  </Sidebar>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import Sidebar from 'primevue/sidebar'
import FilterAccordion from '@bd/components/FilterAccordion/FilterAccordion.vue'
import FilterAccordionItem from '@bd/components/FilterAccordion/FilterAccordionItem.vue'
import { NumberRange } from '@bd/components/FilterElements'
import SingleSelectOption from './SingleSelectOption.vue'
import MultipleSelectOptions from './MultipleSelectOptions.vue'
import { FiltersState, FilterData } from '@bd/store-modules/types'
import { DistrictDto, CityWithProvinceDto } from '@bd/api'

export default defineComponent({
  name: 'FiltersSidebar',
  components: {
    Sidebar,
    FilterAccordion,
    FilterAccordionItem,
    SingleSelectOption,
    MultipleSelectOptions,
    NumberRange,
  },
  props: {
    filtersState: {
      type: Object as PropType<FiltersState>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array as PropType<FilterData[]>,
      required: true,
    },
    citiesAndProvinces: {
      type: Array as PropType<CityWithProvinceDto[]>,
      required: true,
    },
    districts: {
      type: Array as PropType<DistrictDto[]>,
      required: true,
    },
  },
  emits: ['update:visible', 'add-to-queue', 'filter-results'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const sidebar = computed({
      get: () => props.visible,
      set: (value: boolean) => {
        emit('update:visible', value)
      },
    })

    const openedFilter = ref<number[]>([])

    const statusChange = (val: number) => {
      if (openedFilter.value.includes(val)) {
        openedFilter.value = openedFilter.value.filter(
          (item: number) => item !== val,
        )
      } else {
        openedFilter.value.push(val)
      }
    }

    const hasDistricts = computed(() => {
      return (
        Array.isArray(props.filtersState?.['cityIds']) &&
        props.filtersState?.['cityIds'].length === 1
      )
    })

    const filterResults = () => {
      sidebar.value = false
      emit('filter-results')
    }

    return {
      t,
      sidebar,
      statusChange,
      openedFilter,
      hasDistricts,
      filterResults,
    }
  },
})
</script>

<style lang="scss" scoped>
.p-sidebar {
  width: 435px;
  overflow-y: auto;
}

.sidebar-title {
  height: 32px;
}

.accordion {
  .filter-wrapper {
    padding: 1.5rem 0.5rem;
  }
  .bottom-wrapper {
    margin: 10px 0;
  }
  .accordion-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    .chevron-color {
      color: $primary-color;
    }
  }
}
</style>
