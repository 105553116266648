export default {
  suggested_words: 'PROPONOWANE SŁOWA',
  buy: 'Kup',
  sell: 'Sprzedaj',
  filters: 'Filtry',
  results_found: 'Wyników',
  estate_address: 'Adres nieruchomości…',
  options: 'Opcje',
  dashboard_search_hint: 'Miasto, dzielnica, ulica',
  calendar: {
    title: 'Kalendarz',
  },
  login_or_register: {
    appointment: 'W celu umówienia wizyty, zaloguj się lub zarejestruj',
    proposal: 'W celu złożenia propozycji, zaloguj się lub zarejestruj',
  },
  change_password: 'Zmień hasło',
  appointment: {
    empty_state: {
      title: 'Brak terminów w wybranym dniu',
    },
    invitation: {
      error: 'Nie można wysłać zaproszenia',
      success: {
        title: 'Zaproszenie na wizytę wysłane',
        description: 'Sprzedający wkrótce potwierdzi termin spotkania.',
      },
    },
  },

  welcome: {
    title: 'Witaj!',
    hint: 'Wybierz co chcesz zrobić',
    sell: 'Chcę sprzedać mieszkanie',
    browse: 'Chcę przejrzeć dostępne oferty',
    login: 'Zaloguj się',
  },

  profile: {
    edit: 'Edycja profilu',
    description:
      'W celu edycji adresu e-mail lub telefonu prosimy o kontakt z infolinią pod numerem {helpline}',
    call: 'Zadzwoń do centrali',
  },
}
