<template>
  <transition name="overlay-transition">
    <div class="overlay-container" v-if="visible" ref="container">
      <div class="content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['outsideClick', 'update:visible'],
  setup(_props, { emit }) {
    const container = ref<HTMLElement>()

    onClickOutside(container, (event) => {
      emit('outsideClick', event)
    })

    return {
      container,
    }
  },
})
</script>

<style lang="scss" scoped>
$arrow-size: 10px;

.overlay-container {
  position: absolute;
  z-index: 100;
  background: $white;
  @include uniform-size(450px);
  padding: 1rem;
  border-radius: 10px;
  top: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 25px 190px 0 rgba(23, 25, 51, 0.1),
    0 18px 9px 0 rgba(182, 207, 231, 0.12);

  .content {
    overflow: hidden;
    height: 100%;
  }

  &:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    content: '';

    border: solid $arrow-size transparent;
    border-color: transparent;
    border-bottom-color: $white;
    border-width: $arrow-size;
    margin-left: -$arrow-size;
  }
}
.overlay-transition-enter-active,
.overlay-transition-leave-active {
  transition: opacity 0.15s, height 0.15s;
}
.overlay-transition-enter-from,
.overlay-transition-leave-to {
  opacity: 0;
  height: 0px;
}
</style>
