
import { UserCalendarEventDetailsDto } from '@bd/api'
import { usePossibleEventActions } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { EventAttendeesCardGroup, EventDetailsCard } from '..'
import { EventActionType } from '../types'
import EventActionsGroup from './EventActionsGroup.vue'

export const emits = [
  'eventEdit',
  'eventCancel',
  'eventAccept',
  'eventReject',
] as const
type EmitTypes = typeof emits[number]

export default defineComponent({
  components: {
    EventActionsGroup,
    EventDetailsCard,
    EventAttendeesCardGroup,
  },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: [...emits],
  setup(props, { emit }) {
    const { t } = useI18n()

    const calendarEvent = computed(() => props.event.calendarEventDto)
    const hasAttendees = computed(() => {
      const { seller, customer } = calendarEvent.value
      return seller || customer
    })

    const possibleEventActions = usePossibleEventActions(calendarEvent)

    const onAction = (actionType: EventActionType) => {
      emit(`event${actionType}` as EmitTypes)
    }

    return {
      t,
      hasAttendees,
      onAction,
      possibleEventActions,
    }
  },
})
