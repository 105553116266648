import { NotificationType } from '@bd/api/common/types/notifications'
import {
  Message,
  onBackgroundMessage,
  onForegroundMessage,
} from '@bd/api/firebase-sw'
import { NAVIGATION_BADGES_REFRESH_INTERVAL_MS } from '@bd/components'
import { getLocalIsoDate } from '@bd/helpers'
import { useAppStore } from '@bd/store-modules'
import { onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

export const useNavigationBadges = () => {
  const store = useAppStore()
  const route = useRoute()

  let messageHandlerTimeout: number | undefined

  const fetchTodayEventsCount = () => {
    store.dispatch('calendar/getTodayEventsCount')
  }

  const fetchUnreadNotifications = () => {
    store.dispatch('notifications/getUnreadNotificationsCount')
  }

  const fetchEvents = () => {
    store.dispatch('calendar/userCalendarEvents', {
      date: store.state.calendar?.selectedDate ?? getLocalIsoDate(new Date()),
    })
  }

  const fetchBadgeValues = () => {
    fetchTodayEventsCount()
    fetchUnreadNotifications()
  }

  const handlePushMessage = (msg: Message) => {
    /* Timeout to at least give the system some time to register notifications
     * Otherwise cases where frontend fetches unread notifications/calendar events count
     * too early may occurr regularly
     */
    messageHandlerTimeout = setTimeout(() => {
      if (msg.data.IMMOBY_NOTIFICATION_TYPE === NotificationType.CALENDAR) {
        fetchTodayEventsCount()

        if (route.name === 'Calendar') {
          fetchEvents()
        }
      }
      fetchUnreadNotifications()
    }, 500)
  }

  onForegroundMessage((msg: Message) => {
    handlePushMessage(msg)
  })

  onBackgroundMessage((msg: Message) => {
    handlePushMessage(msg)
  })

  const badgeRefreshInterval = setInterval(() => {
    fetchBadgeValues()
  }, NAVIGATION_BADGES_REFRESH_INTERVAL_MS)

  onUnmounted(() => {
    clearInterval(badgeRefreshInterval)
    clearTimeout(messageHandlerTimeout)
  })

  fetchBadgeValues()
}
