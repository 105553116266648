
import { defineComponent, PropType, computed } from 'vue'
import { AssetData } from '@bd/admin/types'
import { FiltersState } from '@bd/store-modules/types'

export default defineComponent({
  name: 'SingleSelectOption',
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<AssetData[]>,
      required: true,
    },
    filtersObject: {
      type: Object as PropType<FiltersState | undefined>,
      required: true,
    },
  },
  emits: ['filter-data'],
  setup(props) {
    const activeProperty = computed(() => {
      if (props.filtersObject && props.filtersObject.propertyType) {
        return [props.filtersObject.propertyType]
      } else {
        return []
      }
    })
    return { activeProperty }
  },
})
