
import { defineComponent, computed, PropType } from 'vue'
import { loginSchema } from '@bd/components/yup'
import { authFormService, useBreakpoints, UserType } from '@bd/components'
import { LoginRequestDto } from '@bd/api'
import { Form, SubmissionHandler } from 'vee-validate'
import AuthFormLogin from '../AuthForm/AuthFormLogin.vue'
import { useAppStore } from '@bd/store-modules'
import { ToType } from '@bd/helpers'
import * as SW from '@bd/api/firebase-sw'

export default defineComponent({
  name: 'AuthLogin',
  components: { AuthFormLogin, Form },
  props: {
    userType: {
      type: String as PropType<UserType>,
      required: true,
    },
  },
  emits: ['submit', 'login'],
  setup(_, { emit }) {
    const store = useAppStore()
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)

    const onSubmit: SubmissionHandler<ToType<LoginRequestDto>> = async (
      values,
      actions,
    ) => {
      emit('submit')
      const fcmToken = await SW.getFcmToken()
      const payload: LoginRequestDto = { ...values, fcmToken }
      try {
        await store.dispatch('auth/authLogin', payload)
        emit('login')
        fcmToken &&
          console.log('📋 User logged successfully, FCM token:', fcmToken)

        await Promise.all([
          store.dispatch('userProfile/userProfile'),
          store.dispatch('staticData/loadData'),
        ])
      } catch (err) {
        const errorsData = authFormService.handleError(
          store.state.auth?.error,
          isDesktopSize.value,
        )
        if (errorsData) {
          actions.setErrors(errorsData)
        }
      }
    }

    return {
      loginSchema,
      onSubmit,
    }
  },
})
