<template>
  <div class="multiple-select-option d-flex flex-wrap">
    <template v-if="value.includes('cityIds')">
      <Button
        v-for="item of items"
        :key="item.cityName"
        class="multiple-select-option-item text-400 p-button-outlined p-button-mobile mb-2 mr-2"
        @click="$emit('filter-data', { value: item.cityId, prop: value })"
        :class="{ active: currentActiveFilters.includes(item.cityId) }"
      >
        <span>{{ item.cityName }}</span>
      </Button>
    </template>
    <template v-if="value.includes('districtIds')">
      <Button
        v-for="item of items"
        :key="item.districtName"
        class="multiple-select-option-item text-400 p-button-outlined p-button-mobile mb-2 mr-2"
        @click="$emit('filter-data', { value: item.districtId, prop: value })"
        :class="{ active: currentActiveFilters.includes(item.districtId) }"
      >
        <span>{{ item.districtName }}</span>
      </Button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { AdditionalFilter } from '@bd/admin/types'

export default defineComponent({
  name: 'MultipleSelectOptions',
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<AdditionalFilter[]>,
      required: true,
    },
    activeFilters: {
      // TODO add type
      type: Object,
      required: true,
    },
  },
  emits: ['filter-data'],
  setup(props) {
    const currentActiveFilters = computed(() => {
      if (props.value.includes('cityIds')) {
        return props.activeFilters?.cityIds ?? []
      } else if (props.value.includes('districtIds')) {
        return props.activeFilters?.districtIds ?? []
      } else {
        return []
      }
    })

    return { currentActiveFilters }
  },
})
</script>

<style lang="scss" scoped>
.multiple-select-option-item {
  padding: 13px 5px;
  height: auto;
  border-color: $blue-haze !important;
}

.active {
  background: $primary-color !important;
  border: solid 1px $primary-color !important;
  color: white !important;
}
</style>
