<template>
  <div class="desktop-header d-none d-md-block">
    <h6 class="page-subtitle mb-3">
      {{ t('calendar.event_details.page_title') }}
    </h6>
    <div class="actions-row">
      <h1 class="page-title">
        {{ event.calendarEventDto.meetingTypeDto.meetingTypeName }}
      </h1>
      <EventActionsGroup
        v-if="possibleEventActions.any"
        @action="onAction"
        :actionFlags="event.calendarEventDto"
      />
    </div>
  </div>
  <div class="event-details-container">
    <div class="details">
      <h4 class="d-none d-md-block h-4">
        {{ t('calendar.event_details.time_and_place') }}
      </h4>
      <EventDetailsCard
        class="my-md-3"
        :event="event"
        :showAddress="true"
        :showEditIcon="showEditIcon && possibleEventActions.edit"
        @eventEditClick="$emit('eventEdit')"
      />
    </div>
    <div class="attendees mt-4 mt-md-0" v-if="hasAttendees">
      <h4 class="d-none d-md-block h-4">
        {{ t('calendar.event_details.attendees') }}
      </h4>
      <h3 class="h-3 mb-3 d-md-none">
        {{ t('calendar.event_details.attendees') }}
      </h3>
      <EventAttendeesCardGroup :event="event" />
    </div>
  </div>
  <div
    class="mobile-actions-row d-md-none"
    v-if="possibleEventActions.anyButEdit"
  >
    <EventActionsGroup
      :isMobile="true"
      @action="onAction"
      :actionFlags="event.calendarEventDto"
    />
  </div>
</template>

<script lang="ts">
import { UserCalendarEventDetailsDto } from '@bd/api'
import { usePossibleEventActions } from '@bd/components'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { EventAttendeesCardGroup, EventDetailsCard } from '..'
import { EventActionType } from '../types'
import EventActionsGroup from './EventActionsGroup.vue'

export const emits = [
  'eventEdit',
  'eventCancel',
  'eventAccept',
  'eventReject',
] as const
type EmitTypes = typeof emits[number]

export default defineComponent({
  components: {
    EventActionsGroup,
    EventDetailsCard,
    EventAttendeesCardGroup,
  },
  props: {
    event: {
      type: Object as PropType<UserCalendarEventDetailsDto>,
      required: true,
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: [...emits],
  setup(props, { emit }) {
    const { t } = useI18n()

    const calendarEvent = computed(() => props.event.calendarEventDto)
    const hasAttendees = computed(() => {
      const { seller, customer } = calendarEvent.value
      return seller || customer
    })

    const possibleEventActions = usePossibleEventActions(calendarEvent)

    const onAction = (actionType: EventActionType) => {
      emit(`event${actionType}` as EmitTypes)
    }

    return {
      t,
      hasAttendees,
      onAction,
      possibleEventActions,
    }
  },
})
</script>

<style lang="scss" scoped>
.desktop-header {
  padding-bottom: 4rem;
  border-bottom: $divider-border;
  margin: 2rem 0 3rem 0;
}
.event-details-container {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint-down(md) {
    flex-direction: column;
  }
}
.details {
  margin-right: 5rem;
  @include breakpoint-down(xl) {
    margin-right: 4rem;
  }
  @include breakpoint-down(lg) {
    margin-right: 3rem;
  }
  @include breakpoint-down(md) {
    margin-right: 0;
  }
}
.actions-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  h1 {
    flex: 1 0 auto;
  }
}
.actions-group {
  display: flex;
  margin: 10px 0;
}
.action-btn {
  width: 162px;
  &:not(:first-of-type) {
    margin-left: 0.25rem;
  }

  &:not(:last-of-type) {
    margin-right: 0.25rem;
  }
}
.mobile-actions-row {
  width: 100%;
  background-color: $alabaster;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 1rem;
  height: $mobile-actions-row-height;
}
</style>
