<template>
  <div class="col-8 col-md-12 order-1 order-md-2">
    <div class="text-position">
      <router-link to="/password-reset" class="link">
        <slot />
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-position {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
}

@include breakpoint-up(md) {
  .text-position {
    text-align: center;
    padding-top: 35px;
  }
}
</style>
