import { CityDto, DistrictDto } from '@bd/api'
import { LocalIsoDate } from '@bd/helpers'


export enum TransactionStatus {
  ACCEPTED = 'ACCEPTED',
  NEGOTIATION_IN_PROGRESS = 'NEGOTIATION_IN_PROGRESS',
}

export enum TransactionType {
  BUYER_TO_SELLER = 'BUYER_TO_SELLER',
  SELLER_TO_BUYER = 'SELLER_TO_BUYER',
}

export interface TransactionList {
  content: TransactionHistoryDto[]
}

export interface TransactionHistoryOfferDto {
  id: number
  cityDto: CityDto
  districtDto: DistrictDto
  street: string
}

export interface TransactionHistoryDto {
  date: LocalIsoDate
  offerDto: TransactionHistoryOfferDto
  price: number
  status: TransactionStatus
}

export interface TransactionHistoryUserDto {
  firstName: string
  id: number
  lastName: string
}

export interface TransactionHistoryDetailsDtoAgent {
  customer: TransactionHistoryUserDto
  date: LocalIsoDate
  offerDto: TransactionHistoryOfferDto
  price: number
  purchaseOffers: PurchaseOfferDto[]
  seller: TransactionHistoryUserDto
  status: TransactionStatus
}

export interface PurchaseOfferDto {
  date: LocalIsoDate
  offerId: number
  price: number
  type: TransactionType
}

export interface TransactionHistoryDetailsDto {
  date: LocalIsoDate
  offerDto: TransactionHistoryOfferDto
  price: number
  purchaseOffers: PurchaseOfferDto[]
  status: TransactionStatus
}

export interface TransactionDetailsPayload {
  offerId: number
  customerId: number
}

export interface TransactionHistoryByCustomerDto {
  accepted: boolean
  customer: TransactionHistoryUserDto
  date: LocalIsoDate
  id: number
  offerId: number
  seller: TransactionHistoryUserDto
  status: TransactionStatus
  type: TransactionType
}
