import { ActionTree } from 'vuex'
import { CommonAppState } from '@bd/store-modules/types'
import { UserProfileState } from '@bd/store-modules/types/userProfile'
import { isUserLogged } from '@bd/store-modules/modules/auth/getters-helpers'
import { commonApi } from '@bd/api'
import { logErr } from '@bd/helpers'

export const actions: ActionTree<UserProfileState, CommonAppState> = {
  async userProfile({ commit, rootState }) {
    if (!isUserLogged(rootState)) {
      return
    }
    try {
      const { data } = await commonApi.userProfile()
      commit('SET_USER', data)
    } catch (e) {
      logErr(e)
    }
  },
  async clearProfile({ commit }) {
    try {
      commit('CLEAR_USER')
    } catch (e) {
      logErr(e)
    }
  },
}
