
import { defineComponent, computed, PropType } from 'vue'
import { OfferDetailsDto } from '@bd/store-modules/types/offers'

export default defineComponent({
  name: 'DashboardOfferMap',
  props: {
    offerDetails: {
      type: Object as PropType<OfferDetailsDto>,
    },
  },
  setup(props) {
    const mapUrl = computed(() => {
      const KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
      const { latitude: lat, longitude: long } = props.offerDetails || {}
      const query = `key=${KEY}&q=${lat},${long}`
      return `https://www.google.com/maps/embed/v1/place?${query}`
    })

    return { mapUrl }
  },
})
