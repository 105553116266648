<template>
  <div class="field-spacing w-100" :class="{ 'white-bg': whiteBackground }">
    <div class="select-wrapper">
      <SelectButton
        v-model="inputValue"
        :options="options"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :name="name"
        class="custom-select"
      />
      <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import SelectButton from 'primevue/selectbutton'

export default defineComponent({
  components: { SelectButton },
  props: {
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(props.name)

    return {
      errorMessage,
      inputValue,
    }
  },
})
</script>

<style lang="scss" scoped></style>
