import { TOAST_CONFIG } from '../../../config'
import { i18n } from '@bd/components/i18n'
import { ToastAddParams } from '../../utils'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

type ToastMessages = 'saveSuccess' | 'saveError'
export const EventSaveMessages: { [key in ToastMessages]: ToastAddParams } = {
  saveSuccess: {
    severity: 'success',
    life: messageDuration.success,
    summary: t('calendar.events.save_event.success'),
  },
  saveError: {
    severity: 'error',
    life: messageDuration.error,
    summary: t('calendar.events.save_event.error'),
  },
}
