<template>
  <ResponsiveContent>
    <template #mobile>
      <div class="mobile-attendee-chooser mobile-section mobile-section-simple">
        <CalendarEventAttendeeDetails
          class="mobile"
          :userType="userType"
          :attendee="selectedAttendee"
        />
        <div
          class="attendee-required-container"
          v-if="isAttendeeRequired && !isAttendeeChosen"
          v-tooltip.focus="attendeeRequiredLabel"
          tabindex="0"
        >
          <i class="attendee-required-icon pi pi-exclamation-triangle" />
        </div>
        <div
          class="attendee-optional-container"
          v-if="!isAttendeeRequired && !isAttendeeChosen"
        >
          {{ t('optional') }}
        </div>
        <div
          class="control-buttons"
          :class="{ 'ml-auto': !isAttendeeRequired || isAttendeeChosen }"
        >
          <Button
            v-if="showControlButtons"
            :label="t('remove')"
            class="btn-clear-attendee btn-mobile-sm p-button-text"
            @click="$emit('clearAttendee')"
          />
          <Button
            :label="t(isAttendeeChosen ? 'change' : 'add')"
            class="btn-choose-attendee btn-mobile-sm p-button-text"
            @click="$emit('attendeeChooserClick', $event)"
          />
        </div>
      </div>
    </template>
    <template #desktop>
      <div
        class="desktop-attendee-chooser"
        :class="{ chosen: isAttendeeChosen, disabled: disabled }"
        @click="onAttendeeChooserClick"
      >
        <div class="content-container">
          <div
            class="plus-icon-container"
            v-if="!disabled && !isAttendeeChosen"
          >
            <Svg
              :src="require('@bd/assets/icons/plus.svg')"
              class="mr-3 c-primary"
            />
          </div>
          <div class="avatar-container" v-if="isAttendeeChosen">
            <UserAvatar
              class="avatar"
              :userType="userType"
              :user="selectedAttendee"
            />
          </div>
          <CalendarEventAttendeeDetails
            class="desktop"
            :userType="userType"
            :attendee="selectedAttendee"
          />
          <div
            class="attendee-required-container"
            v-if="isAttendeeRequired && !isAttendeeChosen"
            v-tooltip="attendeeRequiredLabel"
          >
            <i class="attendee-required-icon pi pi-exclamation-triangle" />
          </div>
        </div>
        <div class="control-buttons">
          <div
            class="clear-button-container"
            v-if="showControlButtons"
            @click.stop="$emit('clearAttendee')"
          >
            <i class="pi pi-times clear-button" />
          </div>
          <div
            class="edit-button-container"
            v-if="showControlButtons"
            ref="editButton"
          >
            <div class="edit-button">
              <Svg
                :src="require('@bd/assets/icons/edit.svg')"
                style="width: 12px; height: 12px"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveContent>
</template>

<script lang="ts">
import { ResponsiveContent, Svg, UserAvatar } from '@bd/components'
import { CalendarEventAttendee } from '@bd/store-modules/types'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CalendarEventAttendeeDetails from './CalendarEventAttendeeDetails.vue'
import { EventAttendeeAvailableTypes } from './types'
import { TemplateRef } from '@bd/helpers'

export default defineComponent({
  components: {
    ResponsiveContent,
    Svg,
    UserAvatar,
    CalendarEventAttendeeDetails,
  },
  props: {
    userType: {
      type: String as PropType<EventAttendeeAvailableTypes>,
      required: true,
    },
    selectedAttendee: {
      type: Object as PropType<CalendarEventAttendee>,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAttendeeRequired: {
      type: Boolean,
      default: false,
    },
    attendeeRequiredLabel: {
      type: String,
      required: false,
    },
  },
  emits: ['attendeeChooserClick', 'clearAttendee'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const isAttendeeChosen = computed(() => !!props.selectedAttendee)
    const showControlButtons = computed(
      () => !props.disabled && isAttendeeChosen.value,
    )

    const editButton = ref<TemplateRef>(null)
    const onAttendeeChooserClick = ($event: PointerEvent) => {
      if (isAttendeeChosen.value && $event.target !== editButton.value) {
        return
      }
      emit('attendeeChooserClick', $event)
    }

    return {
      t,
      isAttendeeChosen,
      editButton,
      showControlButtons,
      onAttendeeChooserClick,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../event-save';

.desktop-attendee-chooser {
  display: flex;
  height: 100%;
  &:not(.chosen):not(.disabled) {
    cursor: pointer;
  }
  .content-container {
    display: flex;
    align-items: center;
    width: 100%;
    .plus-icon-container {
      @include flex;
      i {
        @include uniform-size(35px);
      }
    }
    .avatar {
      display: flex;
      font-size: 1rem;
      margin-right: 0.75rem;
      @include uniform-size(38px);
    }
  }
}
.mobile-section-simple {
  justify-content: flex-start;
  border-bottom: $divider-border;
}
.control-buttons {
  @include flex($align: flex-start);
}
.edit-button-container {
  cursor: pointer;
  padding: 0.25rem;
  .edit-button {
    @include edit-icon(30px);
    pointer-events: none;
  }
}
.clear-button-container {
  padding: 0.25rem;
  .clear-button {
    @include edit-icon(30px);
    cursor: pointer;
  }
}
.attendee-required-container {
  border-radius: 50%;
  padding: 0.5rem;
  margin-left: auto;
  &:hover {
    background-color: $default-bg-color;
  }
  &:focus,
  &:focus-visible {
    outline: none;
  }
  .attendee-required-icon {
    color: $zest;
  }
}
.attendee-optional-container {
  font-size: 0.75rem;
  color: $comet;
  border: $divider-border;
  border-radius: 15px;
  padding: 0.5rem 0.6rem;
  margin-left: 0.5rem;
}
</style>
