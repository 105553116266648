<template>
  <div>
    <transition name="fade">
      <div class="loader-overlay" v-if="isLoading"></div>
    </transition>
    <transition name="fade">
      <div class="loader" :style="{ left: `${marginLeft}px` }" v-if="isLoading">
        <div class="loader__content">
          <div class="loader__item">
            <div class="pi pi-spin pi-spinner" />
            <div>{{ t('loading') }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return { ...useI18n() }
  },
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loader-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2;
  transition: all 1s;
}

.loader {
  height: 10%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  &__content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__item {
    display: flex;
    justify-content: space-around;
    width: 120px;
    padding: 0 10px;
    border-radius: 20px;
    height: 40px;
    background: $black;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    div {
      align-self: center;
    }
  }
}
@include breakpoint-down(sm) {
  .loader {
    left: 0 !important;
  }
}
</style>
