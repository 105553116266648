import http from './http-client'
import { ApiResponse } from './types'
import { NoteRequestDto } from './types/client'

export const addNote = (note: NoteRequestDto): ApiResponse<void> => {
  return http.post(`/client/${note.userId}/note`, { value: note.value })
}

export const deleteNote = (noteId: number): ApiResponse<void> => {
  return http.delete(`/client/note/${noteId}`)
}
