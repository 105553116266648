
import { ResponsiveContent } from '@bd/components'
import { defineComponent } from 'vue'
import CalendarEventMeetingTypeChooser from './CalendarEventMeetingTypeChooser.vue'

export default defineComponent({
  components: {
    ResponsiveContent,
    CalendarEventMeetingTypeChooser,
  },
})
