import { ClientOfferState } from '@bd/client/types'
import { emptyLoadableResource } from '@bd/helpers'

export const state: ClientOfferState = {
  offerDetails: undefined,
  citiesAndProvinces: emptyLoadableResource(),
  districts: [],
  filters: {
    pageSize: 20,
  },
  searchedFilters: {},
  availableTimeSlots: {
    isLoading: false,
    content: [],
  },
}
