
import { defineComponent, computed, PropType } from 'vue'
import { useAppStore } from '@bd/store-modules'
import MobileHeader from '../MobileHeader/MobileHeader.vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'PageContent',
  components: {
    MobileHeader,
  },
  inheritAttrs: false,
  props: {
    showMobileHeader: {
      type: Boolean,
      default: false,
    },
    headerLabel: {
      type: String,
      required: false,
    },
    backRoute: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useAppStore()
    const bottomNavigationVisible = computed(
      () => store.state.bottomNavigationVisible,
    )

    return {
      bottomNavigationVisible,
    }
  },
})
